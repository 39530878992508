import { 
  collection, 
  query, 
  where, 
  getDocs, 
  setDoc, 
  doc, 
  Timestamp, 
  deleteDoc 
} from 'firebase/firestore';
import { db } from '../firebase';

const VISITOR_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

export async function trackVisitor(userId?: string) {
  try {
    const visitorId = userId || generateVisitorId();
    const visitorRef = doc(db, 'visitors', visitorId);
    
    await setDoc(visitorRef, {
      id: visitorId,
      userId: userId || null,
      lastSeen: Timestamp.now(),
      userAgent: navigator.userAgent,
      path: window.location.pathname
    });

    // Only attempt cleanup if we have write permissions
    if (userId) {
      await cleanupOldVisitors().catch(() => {
        // Silently handle cleanup errors - this is not critical functionality
      });
    }
  } catch (error) {
    // Silently handle tracking errors - this is not critical functionality
    console.debug('Visitor tracking unavailable');
  }
}

export async function getActiveVisitors(): Promise<number> {
  try {
    const fiveMinutesAgo = new Date(Date.now() - VISITOR_TIMEOUT);
    const visitorsQuery = query(
      collection(db, 'visitors'),
      where('lastSeen', '>=', Timestamp.fromDate(fiveMinutesAgo))
    );

    const snapshot = await getDocs(visitorsQuery);
    return snapshot.size;
  } catch (error) {
    // Return 0 if we can't access visitor data
    return 0;
  }
}

async function cleanupOldVisitors() {
  try {
    const fiveMinutesAgo = new Date(Date.now() - VISITOR_TIMEOUT);
    const oldVisitorsQuery = query(
      collection(db, 'visitors'),
      where('lastSeen', '<', Timestamp.fromDate(fiveMinutesAgo))
    );

    const snapshot = await getDocs(oldVisitorsQuery);
    
    // Only attempt to delete if we found old visitors
    if (!snapshot.empty) {
      const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);
    }
  } catch (error) {
    // Silently handle cleanup errors - this is not critical functionality
  }
}

function generateVisitorId(): string {
  // Generate a random visitor ID using timestamp and random string
  return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
}