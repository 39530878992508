import React from 'react';
import { Star, Edit, ExternalLink, RefreshCw } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Listing } from '../../../types/listing';
import { generateNumericId } from '../../../utils/ids';

interface ListingCardProps {
  listing: Listing;
  onEdit: () => void;
  onFeature: () => void;
  onRepublish: () => void;
}

export function ListingCard({ listing, onEdit, onFeature, onRepublish }: ListingCardProps) {
  const isUnavailable = listing.status === 'unavailable';
  const isFeatured = listing.featured?.active && new Date(listing.featured.expiresAt) > new Date();
  
  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEdit();
  };

  const handleFeature = (e: React.MouseEvent) => {
    e.stopPropagation();
    onFeature();
  };

  const handleRepublish = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRepublish();
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
      <div className="relative">
        <img
          src={listing.images?.[0] || '/placeholder.jpg'}
          alt={listing.title}
          className="w-full h-48 object-cover rounded-t-lg"
        />
        {isFeatured && (
          <div className="absolute top-2 right-2 bg-yellow-400 text-yellow-800 text-xs font-medium px-2 py-1 rounded-full">
            In Evidenza
          </div>
        )}
        {isUnavailable && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-t-lg">
            <span className="text-white font-medium px-3 py-1 bg-red-600 rounded-full text-sm">
              Non disponibile
            </span>
          </div>
        )}
      </div>

      <div className="p-4">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 mb-2">
          <h3 className="font-semibold text-lg truncate">{listing.title}</h3>
          <span className="text-sm text-gray-500 whitespace-nowrap">
            {generateNumericId(listing.id)}
          </span>
        </div>

        <div className="text-sm text-gray-500">
          <span className="line-clamp-1">{listing.location?.formattedAddress || 'Indirizzo non specificato'}</span>
        </div>

        <div className="text-sm text-gray-500 mt-1">
          Pubblicato il {listing.createdAt.toLocaleDateString()}
        </div>

        <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-4 gap-3">
          <span className="text-lg font-bold text-blue-600 whitespace-nowrap">
            €{listing.price}
            <span className="text-sm font-normal text-gray-500">/mese</span>
          </span>

          <div className="flex items-center gap-2 flex-wrap sm:flex-nowrap">
            <Link
              to={`/listing/${listing.id}`}
              className="p-2 text-gray-500 hover:text-gray-700 transition-colors"
              title="Visualizza annuncio"
              onClick={(e) => e.stopPropagation()}
            >
              <ExternalLink className="h-5 w-5" />
            </Link>

            {isUnavailable ? (
              <button
                onClick={handleRepublish}
                className="flex-1 sm:flex-none text-sm text-blue-600 hover:text-blue-700 font-medium px-3 py-1 border border-blue-600 rounded-md hover:bg-blue-50 transition-colors flex items-center justify-center gap-1 min-w-[120px]"
              >
                <RefreshCw className="h-4 w-4" />
                <span>Ripubblica</span>
              </button>
            ) : (
              <>
                <button
                  onClick={handleEdit}
                  className="p-2 text-gray-500 hover:text-gray-700 transition-colors"
                  title="Modifica annuncio"
                >
                  <Edit className="h-5 w-5" />
                </button>
                {!isFeatured && (
                  <button
                    onClick={handleFeature}
                    className="flex-1 sm:flex-none text-sm text-blue-600 hover:text-blue-700 font-medium px-3 py-1 border border-blue-600 rounded-md hover:bg-blue-50 transition-colors flex items-center justify-center gap-1 min-w-[120px]"
                  >
                    <Star className="h-4 w-4" />
                    <span className="sm:hidden">Evidenza</span>
                    <span className="hidden sm:inline">Metti in evidenza</span>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}