import React from 'react';
import { Calendar } from 'lucide-react';

export type DateRange = 'today' | 'yesterday' | 'last7days' | 'last30days' | 'thisMonth' | 'lastMonth' | 'allTime';

interface DateFilterProps {
  selectedRange: DateRange;
  onChange: (range: DateRange) => void;
}

export function DateFilter({ selectedRange, onChange }: DateFilterProps) {
  return (
    <div className="flex items-center gap-2 mb-6">
      <Calendar className="h-5 w-5 text-gray-400" />
      <select
        value={selectedRange}
        onChange={(e) => onChange(e.target.value as DateRange)}
        className="block w-48 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
      >
        <option value="today">Oggi</option>
        <option value="yesterday">Ieri</option>
        <option value="last7days">Ultimi 7 giorni</option>
        <option value="last30days">Ultimi 30 giorni</option>
        <option value="thisMonth">Questo mese</option>
        <option value="lastMonth">Mese scorso</option>
        <option value="allTime">Da sempre</option>
      </select>
    </div>
  );
}