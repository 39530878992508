import React, { useState, useEffect, useRef } from 'react';
import { Message } from '../../types/chat';
import { useAuthContext } from '../../contexts/AuthContext';
import { onMessagesChange, sendMessage, markMessageAsRead } from '../../lib/chat';
import { ChatMessage } from './ChatMessage';
import { ChatInput } from './ChatInput';

interface ChatWindowProps {
  chatId: string;
  otherPartyName: string;
}

export function ChatWindow({ chatId, otherPartyName }: ChatWindowProps) {
  const { userProfile } = useAuthContext();
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const unsubscribe = onMessagesChange(chatId, (updatedMessages) => {
      setMessages(updatedMessages);
      setLoading(false);
    });

    return () => {
      unsubscribe();
      setMessages([]);
    };
  }, [chatId]);

  useEffect(() => {
    if (isAtBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isAtBottom]);

  const handleScroll = () => {
    if (!chatContainerRef.current) return;
    
    const { scrollHeight, scrollTop, clientHeight } = chatContainerRef.current;
    const isBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;
    setIsAtBottom(isBottom);
  };

  const handleSendMessage = async (content: string) => {
    if (!userProfile) return;

    setSending(true);
    try {
      await sendMessage(
        chatId,
        userProfile.id, // Use Firebase Auth UID
        userProfile.displayName,
        content
      );
      setIsAtBottom(true);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setSending(false);
    }
  };

  if (loading) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full bg-white rounded-2xl overflow-hidden">
      <div 
        ref={chatContainerRef}
        onScroll={handleScroll}
        className="flex-1 overflow-y-auto p-4 space-y-4"
      >
        {messages.map((message) => (
          <ChatMessage 
            key={message.id} 
            message={message} 
            isOwnMessage={message.senderId === userProfile?.id} 
          />
        ))}
        <div ref={messagesEndRef} />
      </div>

      <ChatInput 
        onSendMessage={handleSendMessage}
        disabled={sending}
      />
    </div>
  );
}