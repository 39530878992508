import React, { useState, useEffect } from 'react';
import { Package, CreditCard } from 'lucide-react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { getCreditPackages } from '../../../lib/plans';
import { getUserCredits } from '../../../lib/credits';
import { PaymentModal } from '../../payments/PaymentModal';
import { toast } from 'react-toastify';
import { CreditPackage } from '../../../types/plans';

export function Credits() {
  const { userProfile } = useAuthContext();
  const [packages, setPackages] = useState<CreditPackage[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPackages();
  }, []);

  const loadPackages = async () => {
    try {
      setLoading(true);
      const data = await getCreditPackages('student');
      setPackages(data);
    } catch (error) {
      console.error('Error loading packages:', error);
      toast.error('Errore nel caricamento dei pacchetti');
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentSuccess = async () => {
    if (!selectedPackage) return;
    
    const pkg = packages.find(p => p.id === selectedPackage);
    if (!pkg) return;

    toast.success(`Hai acquistato ${pkg.credits} crediti!`);
    setSelectedPackage(null);
    await loadPackages(); // Reload packages to get updated credits
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento pacchetti...</p>
      </div>
    );
  }

  return (
    <div className="space-y-6 sm:space-y-8">
      {/* Current Credits */}
      <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-xl sm:rounded-2xl p-6 sm:p-8 text-white shadow-lg">
        <div className="flex flex-col items-start">
          <h2 className="text-lg sm:text-xl font-semibold text-white/90">I tuoi crediti</h2>
          <p className="text-xs sm:text-sm text-white/80 mt-1">Ogni candidatura costa 5 crediti</p>
          <div className="flex items-center gap-3 sm:gap-4 mt-4 sm:mt-6">
            <CreditCard className="h-8 w-8 sm:h-12 sm:w-12 text-white/90" />
            <div>
              <span className="text-3xl sm:text-5xl font-bold">
                {userProfile?.studentInfo?.credits || 0}
              </span>
              <span className="text-lg sm:text-xl ml-2">crediti</span>
            </div>
          </div>
          <div className="w-full h-1 bg-white/20 rounded-full mt-4 sm:mt-6">
            <div 
              className="h-full bg-white rounded-full" 
              style={{ 
                width: `${Math.min((userProfile?.studentInfo?.credits || 0) / 100 * 100, 100)}%` 
              }}
            />
          </div>
          <p className="text-xs sm:text-sm text-white/80 mt-2">
            {userProfile?.studentInfo?.credits && userProfile.studentInfo.credits >= 5 ? 
              `Puoi inviare ancora ${Math.floor(userProfile.studentInfo.credits / 5)} candidature` :
              'Acquista crediti per inviare candidature'
            }
          </p>
        </div>
      </div>

      {/* Credit Packages */}
      <div>
        <h3 className="text-base sm:text-lg font-medium text-gray-900 mb-4 sm:mb-6">Acquista Crediti</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {packages.map((pkg) => (
            <div
              key={pkg.id}
              className={`relative bg-white rounded-lg shadow-sm overflow-hidden ${
                pkg.popular ? 'ring-2 ring-blue-500' : ''
              }`}
            >
              {pkg.popular && (
                <div className="absolute top-2 right-2 sm:top-3 sm:right-3 bg-blue-500 text-white text-xs font-medium px-2 py-0.5 sm:px-2.5 sm:py-1 rounded-full">
                  Più popolare
                </div>
              )}
              
              <div className="p-4 sm:p-6">
                <Package className={`h-8 w-8 sm:h-12 sm:w-12 ${pkg.popular ? 'text-blue-500' : 'text-gray-400'} mb-3 sm:mb-4`} />
                <h3 className="text-xl sm:text-2xl font-bold">{pkg.name}</h3>
                <p className="text-sm text-gray-600 mt-1">{pkg.description}</p>
                
                <div className="mt-4 sm:mt-6">
                  <div className="text-2xl sm:text-3xl font-bold text-blue-600">
                    €{pkg.price}
                  </div>
                  <div className="text-sm text-gray-500">€{pkg.pricePerCredit.toFixed(2)} per credito</div>
                  <div className="text-base sm:text-lg font-semibold text-gray-700 mt-2">
                    {pkg.credits} crediti
                  </div>
                </div>

                <ul className="mt-4 sm:mt-6 space-y-2 sm:space-y-3">
                  {pkg.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-xs sm:text-sm text-gray-600">
                      <Package className="h-3 w-3 sm:h-4 sm:w-4 text-blue-500 mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>

                <button
                  onClick={() => setSelectedPackage(pkg.id)}
                  className="mt-4 sm:mt-6 w-full px-3 sm:px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Acquista
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Payment Modal */}
      {selectedPackage && (
        <PaymentModal
          amount={packages.find(p => p.id === selectedPackage)?.price || 0}
          title="Acquista Crediti"
          description={`${packages.find(p => p.id === selectedPackage)?.credits} crediti`}
          metadata={{
            type: 'credits',
            packageId: selectedPackage,
            quantity: packages.find(p => p.id === selectedPackage)?.credits || 0
          }}
          onClose={() => setSelectedPackage(null)}
          onSuccess={handlePaymentSuccess}
        />
      )}
    </div>
  );
}