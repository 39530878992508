import React, { useState, useEffect, useRef } from 'react';
import { Send, Paperclip, AlertCircle } from 'lucide-react';
import { Message, Ticket } from '../../types/ticket';
import { onTicketMessagesChange, sendMessage } from '../../lib/tickets';
import { useAuthContext } from '../../contexts/AuthContext';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { AttachmentUpload } from '../shared/AttachmentUpload';
import { Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

interface TicketChatProps {
  ticket: Ticket;
  onUpdateTicket: (ticketId: string, data: Partial<Ticket>) => void;
}

export function TicketChat({ ticket, onUpdateTicket }: TicketChatProps) {
  const { userProfile } = useAuthContext();
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [attachments, setAttachments] = useState<(string | File)[]>([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [showAttachments, setShowAttachments] = useState(false);

  useEffect(() => {
    const unsubscribe = onTicketMessagesChange(ticket.id, (updatedMessages) => {
      setMessages(updatedMessages);
    });

    return () => unsubscribe();
  }, [ticket.id]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if ((!newMessage.trim() && !attachments.length) || !userProfile) return;

    if (ticket.status === 'chiuso') {
      toast.error('Non puoi inviare messaggi su un ticket chiuso');
      return;
    }

    setLoading(true);
    try {
      if (ticket.status === 'risolto') {
        await onUpdateTicket(ticket.id, {
          status: 'aperto',
          resolvedAt: null
        });
        toast.info('Il ticket è stato riaperto');
      }

      await sendMessage({
        ticketId: ticket.id,
        userId: userProfile.id,
        userEmail: userProfile.email,
        content: newMessage.trim(),
        isAdmin: userProfile.email === 'info@unistanza.it',
        attachments: attachments.filter((att): att is File => att instanceof File)
      });

      setNewMessage('');
      setAttachments([]);
      setShowAttachments(false);
    } catch (error: any) {
      console.error('Error sending message:', error);
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('Errore durante l\'invio del messaggio');
      }
    } finally {
      setLoading(false);
    }
  };

  const formatSafeDate = (date: any): string => {
    if (!date) return '';
    
    try {
      let dateObj: Date;
      
      if (date instanceof Timestamp) {
        dateObj = date.toDate();
      } else if (typeof date === 'string') {
        dateObj = new Date(date);
      } else if (date instanceof Date) {
        dateObj = date;
      } else if (date.seconds) {
        dateObj = new Date(date.seconds * 1000);
      } else {
        return '';
      }

      if (isNaN(dateObj.getTime())) {
        return '';
      }

      return format(dateObj, 'dd/MM/yyyy HH:mm', { locale: it });
    } catch (error) {
      console.error('Errore nella formattazione della data:', error);
      return '';
    }
  };

  const renderMessage = (message: Message) => {
    return (
      <div className={`flex flex-col ${message.isAdmin ? 'items-start' : 'items-end'} mb-4`}>
        <div className={`max-w-[80%] rounded-lg p-3 ${
          message.isAdmin ? 'bg-gray-100' : 'bg-blue-100'
        }`}>
          <div className="flex items-center gap-2 mb-1">
            <span className="text-sm font-medium">{message.userEmail}</span>
            <span className="text-xs text-gray-500">
              {formatSafeDate(message.createdAt)}
            </span>
          </div>
          <p className="text-sm">{message.content}</p>
        </div>
      </div>
    );
  };

  const handleResolveTicket = async () => {
    if (!userProfile || !ticket) return;

    try {
      await onUpdateTicket(ticket.id, {
        status: 'risolto',
        resolvedAt: new Date()
      });
      toast.success('Ticket risolto con successo');
    } catch (error) {
      console.error('Errore durante la risoluzione del ticket:', error);
      toast.error('Errore durante la risoluzione del ticket');
    }
  };

  const renderTicketStatus = () => {
    if (ticket.status === 'risolto') {
      return (
        <div className="px-6 py-4 border-t bg-yellow-50">
          <div className="flex items-start gap-3">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-yellow-400" />
            </div>
            <div>
              <h3 className="text-sm font-medium text-yellow-800">
                Ticket Risolto
              </h3>
              <p className="mt-2 text-sm text-yellow-700">
                Questo ticket è stato contrassegnato come risolto. Se hai bisogno di ulteriore assistenza, 
                ti invitiamo ad aprire un nuovo ticket dalla dashboard di supporto.
              </p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="px-6 py-4 border-t bg-white">
        <form onSubmit={handleSendMessage} className="space-y-4">
          {showAttachments && (
            <div className="border rounded-lg p-4">
              <AttachmentUpload
                attachments={attachments}
                onChange={setAttachments}
                maxAttachments={5}
                title="Aggiungi allegati"
                subtitle="PNG, JPG, PDF fino a 10MB"
              />
            </div>
          )}

          <div className="flex gap-4">
            <button
              type="button"
              onClick={() => setShowAttachments(!showAttachments)}
              className="p-2 text-gray-500 hover:text-gray-700 transition-colors"
            >
              <Paperclip className="h-5 w-5" />
            </button>

            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Scrivi un messaggio..."
              className="flex-1 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
              disabled={loading}
            />

            <button
              type="submit"
              disabled={loading || (!newMessage.trim() && !attachments.length)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 transition-colors flex items-center gap-2"
            >
              <Send className="h-5 w-5" />
              {loading ? 'Invio...' : 'Invia'}
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <div className="border-b p-4 bg-white flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium text-gray-900">{ticket.subject}</h3>
          <p className="text-sm text-gray-500">Stato: {ticket.status}</p>
        </div>
        {userProfile && userProfile.id === ticket.userId && ticket.status === 'aperto' && (
          <button
            onClick={handleResolveTicket}
            className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 transition-colors"
          >
            Segna come Risolto
          </button>
        )}
      </div>

      <div className="flex-1 overflow-y-auto p-6">
        {messages.map((message) => (
          <div key={message.id}>
            {renderMessage(message)}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {renderTicketStatus()}
    </div>
  );
}