import { PayPalScriptOptions } from "@paypal/react-paypal-js";

const PAYPAL_CLIENT_ID = 'ARjAEc-I4V7mO7s8dYvtEVBhDi4WX1ioQNv88byx7l6eMUUhEOC_lMuEadlOWjRHh4NKLsPz8RB2xt4v';
const PAYPAL_SECRET = 'EAqb_rxeS32blyIWs8dZ_G5aX7Aa3MNTPL3u_4B1-_Bo69Ln81QPYot43ZyrvvrjG7KowfQvKnJd2jpz';

export const paypalConfig: PayPalScriptOptions = {
  "client-id": PAYPAL_CLIENT_ID,
  currency: "EUR",
  intent: "capture"
};

export async function createPayPalOrder(amount: number): Promise<string> {
  try {
    const response = await fetch('https://api.paypal.com/v2/checkout/orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${PAYPAL_CLIENT_ID}:${PAYPAL_SECRET}`)}`,
      },
      body: JSON.stringify({
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: 'EUR',
            value: amount.toString()
          }
        }]
      })
    });

    if (!response.ok) {
      throw new Error('Failed to create PayPal order');
    }

    const data = await response.json();
    return data.id;
  } catch (error) {
    console.error('Error creating PayPal order:', error);
    throw error;
  }
}