import { getAuth } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebase';

export async function verifyAdmin(): Promise<boolean> {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    throw new Error('Utente non autenticato');
  }

  const userDoc = await getDoc(doc(db, 'users', user.uid));
  if (!userDoc.exists()) {
    throw new Error('Utente non trovato');
  }

  const userData = userDoc.data();
  return userData.userType === 'admin' || userData.isAdmin === true;
}

export async function requireAdmin(): Promise<void> {
  const isAdmin = await verifyAdmin();
  if (!isAdmin) {
    throw new Error('Permessi insufficienti');
  }
}