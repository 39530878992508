import React, { useState, useEffect } from 'react';
import { Heart, Send, User, LineChart, CreditCard, Bell } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { ApplicationsList } from './ApplicationsList';
import { FavoriteListings } from './FavoriteListings';
import { Credits } from './Credits';
import { ProfileSettings } from '../../profile/ProfileSettings';
import { NotificationsTab } from '../NotificationsTab';

export function StudentDashboard() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('applications');

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow">
          <div className="border-b">
            <nav className="flex items-center -mb-px overflow-x-auto">
              <button
                onClick={() => setActiveTab('applications')}
                className={`flex items-center gap-2 py-4 px-6 border-b-2 font-medium text-sm whitespace-nowrap ${
                  activeTab === 'applications'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
              >
                <Send className="h-5 w-5" />
                Le Mie Candidature
              </button>
              <button
                onClick={() => setActiveTab('favorites')}
                className={`flex items-center gap-2 py-4 px-6 border-b-2 font-medium text-sm whitespace-nowrap ${
                  activeTab === 'favorites'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
              >
                <Heart className="h-5 w-5" />
                Preferiti
              </button>
              <button
                onClick={() => setActiveTab('notifications')}
                className={`flex items-center gap-2 py-4 px-6 border-b-2 font-medium text-sm whitespace-nowrap ${
                  activeTab === 'notifications'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
              >
                <Bell className="h-5 w-5" />
                Notifiche
              </button>
              <button
                onClick={() => setActiveTab('analytics')}
                className={`flex items-center gap-2 py-4 px-6 border-b-2 font-medium text-sm whitespace-nowrap ${
                  activeTab === 'analytics'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
              >
                <LineChart className="h-5 w-5" />
                Analytics
              </button>
              <button
                onClick={() => setActiveTab('credits')}
                className={`flex items-center gap-2 py-4 px-6 border-b-2 font-medium text-sm whitespace-nowrap ${
                  activeTab === 'credits'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
              >
                <CreditCard className="h-5 w-5" />
                Crediti
              </button>
              <button
                onClick={() => setActiveTab('profile')}
                className={`flex items-center gap-2 py-4 px-6 border-b-2 font-medium text-sm whitespace-nowrap ${
                  activeTab === 'profile'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
              >
                <User className="h-5 w-5" />
                Profilo
              </button>
            </nav>
          </div>

          <div className="p-6">
            {activeTab === 'applications' && <ApplicationsList />}
            {activeTab === 'favorites' && <FavoriteListings />}
            {activeTab === 'notifications' && <NotificationsTab />}
            {activeTab === 'analytics' && (
              <div className="text-center py-12">
                <LineChart className="h-12 w-12 text-gray-400 mx-auto" />
                <h3 className="mt-2 text-lg font-medium text-gray-900">
                  Analytics delle tue candidature
                </h3>
                <p className="mt-1 text-gray-500">
                  Monitora lo stato delle tue candidature e le risposte ricevute
                </p>
              </div>
            )}
            {activeTab === 'credits' && <Credits />}
            {activeTab === 'profile' && <ProfileSettings />}
          </div>
        </div>
      </div>
    </div>
  );
}