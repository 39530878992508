import React from 'react';
import { FileUpload } from './FileUpload';
import { UploadOptions } from '../../lib/upload';

interface AttachmentUploadProps {
  attachments: (string | File)[];
  onChange: (attachments: (string | File)[]) => void;
  maxAttachments?: number;
  title?: string;
  subtitle?: string;
  required?: boolean;
}

export function AttachmentUpload({
  attachments,
  onChange,
  maxAttachments = 5,
  title = 'Carica allegati',
  subtitle = 'PNG, JPG, PDF fino a 10MB',
  required = false
}: AttachmentUploadProps) {
  const options: Partial<UploadOptions> = {
    folder: 'attachments',
    allowedTypes: ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'],
    maxFiles: maxAttachments,
    maxFileSize: 10 * 1024 * 1024 // 10MB
  };

  return (
    <FileUpload
      files={attachments}
      onChange={onChange}
      options={options}
      title={title}
      subtitle={subtitle}
      required={required}
    />
  );
}