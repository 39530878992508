import React from 'react';
import { featuresLabels, kitchenLabels, bathroomLabels } from '../types/listing';
import { Listing } from '../types/listing';

interface ListingFeaturesProps {
  listing: Listing;
}

export function ListingFeatures({ listing }: ListingFeaturesProps) {
  if (!listing) return null;

  const renderFeatureSection = (
    features: Record<string, boolean>,
    labels: Record<string, string>,
    title: string
  ) => {
    const activeFeatures = Object.entries(features)
      .filter(([_, value]) => value)
      .map(([key]) => labels[key]);

    if (activeFeatures.length === 0) return null;

    return (
      <div className="mb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-3">{title}</h3>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
          {activeFeatures.map((feature, index) => (
            <div
              key={index}
              className="px-3 py-1.5 bg-gray-100 text-gray-700 rounded-lg text-sm"
            >
              {feature}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-6">Caratteristiche Dettagliate</h2>

      {renderFeatureSection(listing.features, featuresLabels, 'Caratteristiche Generali')}
      {renderFeatureSection(listing.kitchenFeatures, kitchenLabels, 'Cucina')}
      {renderFeatureSection(listing.bathroomFeatures, bathroomLabels, 'Bagno')}
    </div>
  );
}