import React, { useState, useEffect } from 'react';
import { MapPin, Heart, Building2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { getFavoritedListings, removeFavorite } from '../../../lib/favorites';
import { Listing } from '../../../types/listing';
import { toast } from 'react-toastify';

export function FavoriteListings() {
  const { userProfile } = useAuthContext();
  const [favorites, setFavorites] = useState<Listing[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userProfile?.id) {
      loadFavorites();
    }
  }, [userProfile?.id]);

  const loadFavorites = async () => {
    try {
      const favoritedListings = await getFavoritedListings(userProfile!.id);
      setFavorites(favoritedListings);
    } catch (error) {
      console.error('Error loading favorites:', error);
      toast.error('Errore nel caricamento dei preferiti');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFavorite = async (listingId: string) => {
    try {
      await removeFavorite(userProfile!.id, listingId);
      toast.success('Annuncio rimosso dai preferiti');
      loadFavorites(); // Reload the list
    } catch (error) {
      console.error('Error removing favorite:', error);
      toast.error('Errore durante la rimozione dai preferiti');
    }
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento preferiti...</p>
      </div>
    );
  }

  if (favorites.length === 0) {
    return (
      <div className="text-center py-12">
        <Heart className="h-12 w-12 text-gray-400 mx-auto" />
        <h3 className="mt-2 text-lg font-medium text-gray-900">
          Nessun annuncio nei preferiti
        </h3>
        <p className="mt-1 text-gray-500">
          Aggiungi gli annunci che ti interessano ai preferiti per trovarli facilmente
        </p>
        <Link
          to="/search"
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
        >
          <Building2 className="h-4 w-4 mr-2" />
          Cerca Alloggi
        </Link>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {favorites.map((listing) => (
        <div
          key={listing.id}
          className="bg-white rounded-lg shadow-sm overflow-hidden border border-gray-200"
        >
          <Link to={`/listing/${listing.id}`}>
            <div className="relative h-48">
              <img
                src={listing.images[0] || 'https://images.unsplash.com/photo-1554995207-c18c203602cb?auto=format&fit=crop&q=80'}
                alt={listing.title}
                className="w-full h-full object-cover"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = "https://images.unsplash.com/photo-1554995207-c18c203602cb?auto=format&fit=crop&q=80";
                }}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleRemoveFavorite(listing.id);
                }}
                className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors"
              >
                <Heart className="h-5 w-5 text-red-500" fill="currentColor" />
              </button>
            </div>
          </Link>

          <div className="p-4">
            <Link to={`/listing/${listing.id}`}>
              <h3 className="font-semibold text-lg mb-1">{listing.title}</h3>
            </Link>
            <div className="flex items-center text-gray-500 mb-2">
              <MapPin className="h-4 w-4 mr-1" />
              {listing.location.formattedAddress}
            </div>
            <div className="flex items-center justify-between">
              <span className="text-lg font-bold text-blue-600">
                €{listing.price}
                <span className="text-sm font-normal text-gray-500">/mese</span>
              </span>
              <span className="text-sm text-gray-500">
                {listing.maxOccupants} {listing.maxOccupants === 1 ? 'posto letto' : 'posti letto'}
              </span>
            </div>
            <div className="mt-2 text-xs text-gray-500">
              Salvato il {new Date(listing.createdAt).toLocaleDateString()}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}