import React, { createContext, useContext, useEffect, useState } from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { getUserProfile } from '../lib/auth';
import { UserProfile } from '../types/user';
import { useNavigate, useLocation } from 'react-router-dom';

interface AuthContextType {
  user: User | null;
  userProfile: UserProfile | null;
  loading: boolean;
  error: Error | null;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  userProfile: null,
  loading: true,
  error: null
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        setUser(user);
        if (user) {
          try {
            const profile = await getUserProfile(user);
            setUserProfile(profile);
            
            // Reindirizza SOLO se:
            // 1. L'utente è sulla pagina di login o registrazione
            // 2. Non c'è uno state di reindirizzamento precedente
            const isAuthPage = location.pathname === '/login' || location.pathname === '/register';
            const hasRedirectState = location.state?.from;
            
            if (profile && isAuthPage && !hasRedirectState) {
              navigate(`/dashboard/${profile.userType}`);
            } else if (profile && hasRedirectState) {
              navigate(location.state.from);
            }
          } catch (err) {
            console.error('Error fetching user profile:', err);
            setError(err as Error);
          }
        } else {
          setUserProfile(null);
          // Reindirizza al login SOLO se si tenta di accedere a una pagina protetta
          const isProtectedPage = location.pathname.includes('/dashboard');
          if (isProtectedPage) {
            navigate('/login', { state: { from: location.pathname } });
          }
        }
        setLoading(false);
      },
      (error) => {
        console.error('Auth state change error:', error);
        setError(error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [navigate, location.pathname]);

  return (
    <AuthContext.Provider value={{ user, userProfile, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);