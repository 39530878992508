import React, { useState } from 'react';
import { X, Calendar, GraduationCap, MapPin, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { createApplication } from '../../lib/applications';
import { toast } from 'react-toastify';
import { getDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface ApplicationConfirmModalProps {
  onClose: () => void;
  listingId: string;
  listingTitle: string;
  listingOwnerId: string;
}

export function ApplicationConfirmModal({ onClose, listingId, listingTitle, listingOwnerId }: ApplicationConfirmModalProps) {
  const { userProfile } = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  if (!userProfile?.studentInfo) {
    return null;
  }

  const handleModify = () => {
    onClose();
    navigate('/dashboard/student', { 
      state: { 
        activeTab: 'applications',
        openApplicationData: true,
        message: 'Modifica i tuoi dati prima di inviare la candidatura'
      }
    });
  };

  const handleConfirm = async () => {
    if (!userProfile) return;

    if (!userProfile.studentInfo?.gender) {
      toast.error('Dati profilo incompleti: genere mancante');
      handleModify();
      return;
    }

    setLoading(true);
    try {
      const listingRef = doc(db, 'listings', listingId);
      const listingDoc = await getDoc(listingRef);
      
      if (!listingDoc.exists()) {
        throw new Error('Annuncio non trovato');
      }
      
      const listingData = listingDoc.data();

      if (!listingData.category || !listingData.price || !listingData.location) {
        throw new Error('Dati annuncio incompleti');
      }

      const listingSnapshot = {
        id: listingId,
        version: 1,
        createdAt: serverTimestamp(),
        lastModified: serverTimestamp(),
        fields: {
          title: { value: listingTitle, status: 'original' },
          category: { value: listingData.category, status: 'original' },
          price: { value: listingData.price, status: 'original' },
          description: { value: listingData.description, status: 'original' },
          size: { value: listingData.size, status: 'original' },
          floor: { value: listingData.floor, status: 'original' },
          rooms: { value: listingData.rooms, status: 'original' },
          bathrooms: { value: listingData.bathrooms, status: 'original' },
          maxOccupants: { value: listingData.maxOccupants, status: 'original' },
          deposit: { value: listingData.deposit, status: 'original' },
          contractType: { value: listingData.contractType, status: 'original' },
          availableFrom: { value: listingData.availableFrom, status: 'original' },
          minStay: { value: listingData.minStay, status: 'original' },
          noticePeriod: { value: listingData.noticePeriod, status: 'original' },
          heating: { value: listingData.heating, status: 'original' },
          location: { value: listingData.location, status: 'original' },
          features: { value: listingData.features || {}, status: 'original' },
          kitchenFeatures: { value: listingData.kitchenFeatures || {}, status: 'original' },
          bathroomFeatures: { value: listingData.bathroomFeatures || {}, status: 'original' },
          utilities: { value: listingData.utilities || {}, status: 'original' },
          rules: { value: listingData.rules || {}, status: 'original' },
          images: { value: listingData.images || [], status: 'original' },
          floorPlans: { value: listingData.floorPlans || [], status: 'original' }
        }
      };

      const applicationData = {
        userId: userProfile.id,
        listingId,
        listingTitle,
        listingOwnerId,
        studentName: userProfile.displayName || '',
        studentEmail: userProfile.email || '',
        studentPhone: userProfile.phoneNumber || '',
        birthDate: userProfile.studentInfo.birthDate,
        nationality: userProfile.studentInfo?.nationality || '',
        residenceCity: userProfile.studentInfo.residenceCity || '',
        residenceCountry: userProfile.studentInfo.residenceCountry || '',
        studyProgram: userProfile.studentInfo.studyProgram || '',
        university: userProfile.studentInfo.university || '',
        studyYear: userProfile.studentInfo.studyYear || '',
        moveInDate: userProfile.studentInfo.moveInDate || new Date(),
        stayDuration: userProfile.studentInfo.stayDuration || 6,
        gender: userProfile.studentInfo.gender,
        listingSnapshot
      };

      const applicationId = await createApplication(applicationData);

      toast.success('Candidatura inviata con successo!');
      onClose();
      navigate('/dashboard/student', { 
        state: { 
          activeTab: 'applications',
          highlightedId: applicationId 
        }
      });
    } catch (error: any) {
      console.error('Error creating application:', error);
      toast.error(error.message || 'Errore durante l\'invio della candidatura');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-2xl w-full">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Conferma Candidatura</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="space-y-6">
            <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
              <h3 className="font-medium text-blue-900 mb-2">
                Verifica i tuoi dati prima di inviare la candidatura
              </h3>
              <p className="text-sm text-blue-700">
                La candidatura costa 5 crediti e scade dopo 24 ore se non ricevi risposta
              </p>
            </div>

            <div className="space-y-4">
              <div className="flex items-start gap-3">
                <GraduationCap className="h-5 w-5 text-gray-400 mt-1" />
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    {userProfile.studentInfo.university}
                  </p>
                  <p className="text-sm text-gray-500">
                    {userProfile.studentInfo.studyProgram} - {userProfile.studentInfo.studyYear}° anno
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-3">
                <MapPin className="h-5 w-5 text-gray-400 mt-1" />
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    {userProfile.studentInfo.residenceCity}, {userProfile.studentInfo.residenceCountry}
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-3">
                <Calendar className="h-5 w-5 text-gray-400 mt-1" />
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    Data di nascita: {new Date(userProfile.studentInfo.birthDate).toLocaleDateString()}
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-3">
                <Clock className="h-5 w-5 text-gray-400 mt-1" />
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    Check-in: {new Date(userProfile.studentInfo.moveInDate).toLocaleDateString()}
                  </p>
                  <p className="text-sm text-gray-500">
                    Durata: {userProfile.studentInfo.stayDuration} mesi
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-4 pt-4">
              <button
                type="button"
                onClick={handleModify}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Modifica Dati
              </button>
              <button
                type="button"
                onClick={handleConfirm}
                disabled={loading}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 disabled:opacity-50"
              >
                {loading ? 'Invio in corso...' : 'Conferma e Invia'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}