import React, { useState, useEffect } from 'react';
import { Eye, Users, LineChart, Building2 } from 'lucide-react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { onListingsChange } from '../../../lib/db';
import { Listing } from '../../../types/listing';
import { StatCard } from './analytics/StatCard';
import { ApplicationsBreakdown } from './analytics/ApplicationsBreakdown';
import { ListingsPerformance } from './analytics/ListingsPerformance';
import { getTotalStats } from '../../../lib/analytics/stats';
import { toast } from 'react-toastify';

export function ListingAnalytics() {
  const { userProfile } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState<Listing[]>([]);
  const [stats, setStats] = useState<ListingStats | null>(null);

  useEffect(() => {
    if (!userProfile?.id) return;

    const unsubscribe = onListingsChange(userProfile.id, async (updatedListings) => {
      try {
        setListings(updatedListings);
        if (updatedListings.length > 0) {
          const totalStats = await getTotalStats(updatedListings);
          setStats(totalStats);
        } else {
          setStats({
            views: 0,
            applications: 0,
            conversionRate: 0
          });
        }
      } catch (error) {
        console.error('Error fetching analytics:', error);
        toast.error('Errore nel caricamento delle statistiche');
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [userProfile?.id]);

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento statistiche...</p>
      </div>
    );
  }

  if (listings.length === 0) {
    return (
      <div className="text-center py-12">
        <Building2 className="h-12 w-12 text-gray-400 mx-auto" />
        <h3 className="mt-2 text-lg font-medium text-gray-900">
          Nessun annuncio pubblicato
        </h3>
        <p className="mt-1 text-gray-500">
          Pubblica il tuo primo annuncio per vedere le statistiche
        </p>
      </div>
    );
  }

  if (!stats) return null;

  const conversionRate = stats.views > 0 
    ? ((stats.applications / stats.views) * 100).toFixed(1) 
    : '0';

  return (
    <div className="space-y-6">
      {/* Main Stats */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <StatCard
          title="Visualizzazioni Totali"
          value={stats.views}
          icon={Eye}
          subtext={`${stats.views} visualizzazioni totali`}
        />

        <StatCard
          title="Candidature Ricevute"
          value={stats.applications}
          icon={Users}
          subtext={`${stats.applications} candidature totali`}
        />

        <StatCard
          title="Tasso di Conversione"
          value={`${conversionRate}%`}
          icon={LineChart}
          subtext="Visualizzazioni convertite in candidature"
        />
      </div>

      {/* Applications Status */}
      <ApplicationsBreakdown stats={stats.applicationStats} />

      {/* Listings Performance */}
      <ListingsPerformance listings={listings} />
    </div>
  );
}