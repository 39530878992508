import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Eye, Users, ChevronLeft, LineChart, Calendar, Building2 } from 'lucide-react';
import { getListingStats } from '../../../lib/analytics/stats';
import { getListing } from '../../../lib/db';
import { toast } from 'react-toastify';
import { Listing } from '../../../types/listing';
import { StatCard } from './analytics/StatCard';
import { ApplicationsBreakdown } from './analytics/ApplicationsBreakdown';
import { ListingBreadcrumb } from '../../listings/ListingBreadcrumb';
import { isListingFeatured } from '../../../utils/listings';

export function ListingAnalyticsDetail() {
  const { listingId } = useParams<{ listingId: string }>();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<ListingStats | null>(null);
  const [listing, setListing] = useState<Listing | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadData = async () => {
      if (!listingId) {
        toast.error('ID annuncio non valido');
        return;
      }

      try {
        setLoading(true);
        setError(null);

        // Carica i dati dell'annuncio e le statistiche in parallelo
        const [listingData, statsData] = await Promise.all([
          getListing(listingId),
          getListingStats(listingId)
        ]);

        if (!listingData) {
          setError('Annuncio non trovato');
          return;
        }

        setListing(listingData);
        setStats(statsData);
      } catch (error) {
        console.error('Error fetching analytics:', error);
        setError('Errore nel caricamento delle statistiche');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [listingId]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-4 text-gray-500">Caricamento statistiche...</p>
          </div>
        </div>
      </div>
    );
  }

  if (!listing || !stats) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="text-center py-12">
            <Building2 className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-900 mb-4">{error || 'Dati non disponibili'}</p>
            <Link 
              to="/dashboard/landlord"
              className="text-blue-600 hover:text-blue-700"
            >
              Torna alla dashboard
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const conversionRate = stats.views > 0 
    ? ((stats.applications / stats.views) * 100).toFixed(1) 
    : '0';

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <ListingBreadcrumb
          listingId={listingId}
          listingTitle={listing.title}
          isOwner={true}
          isAnalyticsPage={true}
        />

        {/* Main Stats */}
        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h2 className="text-xl font-semibold">Analytics dell'Annuncio</h2>
              <p className="text-sm text-gray-500 mt-1">
                Pubblicato il {listing.createdAt.toLocaleDateString()}
              </p>
            </div>
            {isListingFeatured(listing) && (
              <span className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-sm font-medium">
                In Evidenza
              </span>
            )}
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <StatCard
              title="Visualizzazioni Totali"
              value={stats.views}
              icon={Eye}
              subtext="Visualizzazioni totali dell'annuncio"
            />

            <StatCard
              title="Candidature Ricevute"
              value={stats.applications}
              icon={Users}
              subtext="Candidature totali ricevute"
            />

            <StatCard
              title="Tasso di Conversione"
              value={`${conversionRate}%`}
              icon={LineChart}
              subtext="Visualizzazioni convertite in candidature"
            />
          </div>
        </div>

        {/* Applications Status */}
        <ApplicationsBreakdown stats={stats.applicationStats} />
      </div>
    </div>
  );
}