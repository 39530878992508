import React from 'react';
import { MessageSquare, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Chat } from '../../types/chat';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/locale';

interface ChatListProps {
  chats: Chat[];
  userId: string;
}

export function ChatList({ chats, userId }: ChatListProps) {
  if (chats.length === 0) {
    return (
      <div className="text-center py-12">
        <MessageSquare className="h-12 w-12 text-gray-400 mx-auto" />
        <h3 className="mt-2 text-lg font-medium text-gray-900">
          Nessuna chat presente
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Le chat verranno create automaticamente quando le tue candidature verranno accettate
        </p>
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-100">
      {chats.map((chat) => {
        const isStudent = userId === chat.studentId;
        const otherPartyName = isStudent ? chat.landlordName : chat.studentName;

        return (
          <Link
            key={chat.id}
            to={`/chat/${chat.id}`}
            className="block p-4 hover:bg-gray-50 transition-colors"
          >
            <div className="flex justify-between items-start gap-4">
              <div className="min-w-0">
                <h3 className="font-medium text-gray-900 truncate">
                  {otherPartyName}
                </h3>
                <p className="text-sm text-gray-500 truncate">
                  {chat.listingTitle}
                </p>
                {chat.lastMessage && (
                  <p className="text-sm text-gray-600 mt-1 line-clamp-1">
                    {chat.lastMessage.senderId === userId ? 'Tu: ' : ''}{chat.lastMessage.content}
                  </p>
                )}
              </div>
              {chat.lastMessage && (
                <div className="flex items-center text-xs text-gray-500 whitespace-nowrap">
                  <Clock className="h-3 w-3 mr-1" />
                  {formatDistanceToNow(chat.lastMessage.createdAt, {
                    addSuffix: true,
                    locale: it
                  })}
                </div>
              )}
            </div>
          </Link>
        );
      })}
    </div>
  );
}