import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { API_ENDPOINTS, PAYMENT_CONFIG } from '../config/api';
import { collection, doc, setDoc, updateDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { db } from '@/lib/firebase';

let stripePromise: Promise<any> | null = null;

const getStripe = async () => {
  if (!stripePromise) {
    stripePromise = loadStripe(PAYMENT_CONFIG.stripe.publishableKey);
  }
  const stripe = await stripePromise;
  if (!stripe) {
    throw new Error('Failed to initialize Stripe');
  }
  return stripe;
};

export interface PaymentMetadata {
  userId: string;
  type: 'credits' | 'featured';
  quantity?: number;
  packageId?: string;
  listingId?: string;
  planId?: string;
}

export async function createPayment(data: Omit<Payment, 'id' | 'createdAt' | 'updatedAt'>): Promise<string> {
  try {
    const paymentId = crypto.randomUUID();
    const paymentRef = doc(paymentsCollection, paymentId);

    await setDoc(paymentRef, {
      ...data,
      id: paymentId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    return paymentId;
  } catch (error) {
    console.error('Error creating payment:', error);
    throw error;
  }
}

export async function getPayment(paymentId: string): Promise<Payment | null> {
  try {
    const paymentRef = doc(paymentsCollection, paymentId);
    const paymentDoc = await getDoc(paymentRef);
    
    if (!paymentDoc.exists()) {
      return null;
    }

    return {
      id: paymentDoc.id,
      ...paymentDoc.data(),
      createdAt: paymentDoc.data().createdAt?.toDate(),
      updatedAt: paymentDoc.data().updatedAt?.toDate()
    } as Payment;
  } catch (error) {
    console.error('Error getting payment:', error);
    return null;
  }
}

export async function createStripeCheckout(amount: number, metadata: PaymentMetadata): Promise<void> {
  try {
    if (!amount || amount <= 0) {
      throw new Error('Invalid payment amount');
    }

    const stripe = await getStripe();

    const paymentId = await createPayment({
      userId: metadata.userId,
      amount,
      status: 'pending',
      type: metadata.type === 'featured' ? 'featured_plan' : 'credit_package',
      planId: metadata.planId,
      listingId: metadata.listingId,
      packageId: metadata.packageId,
      paymentIntent: '',
      paymentMethod: 'stripe'
    });

    const response = await fetch(API_ENDPOINTS.payments.createStripeSession, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        metadata: {
          ...metadata,
          paymentId,
          timestamp: new Date().toISOString()
        },
        success_url: `${window.location.origin}/payment/success?session_id={CHECKOUT_SESSION_ID}&payment_id=${paymentId}`,
        cancel_url: `${window.location.origin}/payment/cancel?payment_id=${paymentId}`
      }),
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Network response error' }));
      throw new Error(errorData.message || 'Failed to create checkout session');
    }

    const { sessionId } = await response.json();
    if (!sessionId) {
      throw new Error('Invalid session ID received from server');
    }

    await updateDoc(doc(paymentsCollection, paymentId), {
      paymentIntent: sessionId,
      updatedAt: serverTimestamp()
    });

    const { error } = await stripe.redirectToCheckout({ sessionId });
    if (error) {
      throw error;
    }
  } catch (error: any) {
    console.error('Stripe checkout error:', error);
    toast.error(error.message || 'Payment processing failed');
    throw error;
  }
}

export interface PayPalOrderMetadata extends PaymentMetadata {
  orderId: string;
}

export async function createPayPalOrder(amount: number, metadata: PaymentMetadata): Promise<string> {
  try {
    const response = await fetch(API_ENDPOINTS.payments.createPaypalOrder, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        metadata: {
          ...metadata,
          timestamp: new Date().toISOString()
        }
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Network response error' }));
      throw new Error(errorData.message || 'Failed to create PayPal order');
    }

    const { orderId } = await response.json();
    
    if (!orderId) {
      throw new Error('Invalid order ID received from server');
    }

    return orderId;
  } catch (error: any) {
    console.error('PayPal order creation error:', { error });
    toast.error(error.message || 'Failed to create PayPal order');
    throw error;
  }
}

export async function capturePayPalOrder(orderId: string, metadata: PayPalOrderMetadata): Promise<void> {
  try {
    const response = await fetch(API_ENDPOINTS.payments.capturePaypalOrder, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderId,
        metadata: {
          ...metadata,
          timestamp: new Date().toISOString()
        }
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Network response error' }));
      throw new Error(errorData.message || 'Failed to capture PayPal payment');
    }

    await response.json();
  } catch (error: any) {
    console.error('PayPal capture error:', { error });
    toast.error(error.message || 'Failed to complete PayPal payment');
    throw error;
  }
}

const paymentsCollection = collection(db, 'payments');

export async function updatePaymentStatus(
  paymentId: string, 
  status: Payment['status']
) {
  const paymentRef = doc(paymentsCollection, paymentId);
  await updateDoc(paymentRef, {
    status,
    updatedAt: serverTimestamp()
  });
}