import React from 'react';
import { Users, Facebook } from 'lucide-react';

const communities = [
  { 
    city: "Venezia", 
    link: "https://www.facebook.com/groups/829907138257080",
    image: "https://images.unsplash.com/photo-1523906834658-6e24ef2386f9?auto=format&fit=crop&q=80"
  },
  { 
    city: "Milano", 
    link: "https://www.facebook.com/groups/1318822702247520",
    image: "https://images.unsplash.com/photo-1576457412712-c6aa249354a5?auto=format&fit=crop&q=80"
  },
  { 
    city: "Padova", 
    link: "https://www.facebook.com/groups/1174668069753150",
    image: "https://images.unsplash.com/photo-1575384043001-f37f48835528?auto=format&fit=crop&q=80"
  },
  { 
    city: "Bologna", 
    link: "https://www.facebook.com/groups/1174588239822062",
    image: "https://images.unsplash.com/photo-1632516643720-e7f5d7d6ecc9?auto=format&fit=crop&q=80"
  },
  { 
    city: "Parma", 
    link: "https://www.facebook.com/groups/1162466888006841",
    image: "https://images.unsplash.com/photo-1597655601841-214a4cfe8b2c?auto=format&fit=crop&q=80"
  },
  { 
    city: "Torino", 
    link: "https://www.facebook.com/groups/881978752791740",
    image: "https://images.unsplash.com/photo-1629140727571-9b5c6f6267b4?auto=format&fit=crop&q=80"
  },
  { 
    city: "Trieste", 
    link: "https://www.facebook.com/groups/860528958303687",
    image: "https://images.unsplash.com/photo-1555992828-35627f3b373f?auto=format&fit=crop&q=80"
  },
  { 
    city: "Cesena", 
    link: "https://www.facebook.com/groups/852645402603633",
    image: "https://images.unsplash.com/photo-1578475867333-7492693a0e1c?auto=format&fit=crop&q=80"
  },
  { 
    city: "Firenze", 
    link: "https://www.facebook.com/groups/833592544755979",
    image: "https://images.unsplash.com/photo-1534826333106-d7f08bc72eb1?auto=format&fit=crop&q=80"
  },
  { 
    city: "Vicenza", 
    link: "https://www.facebook.com/groups/684374559700536",
    image: "https://images.unsplash.com/photo-1604948619800-367f98540eea?auto=format&fit=crop&q=80"
  },
  { 
    city: "Genova", 
    link: "https://www.facebook.com/groups/654035726430187",
    image: "https://images.unsplash.com/photo-1595658658481-d53d3f999875?auto=format&fit=crop&q=80"
  },
  { 
    city: "Ferrara", 
    link: "https://www.facebook.com/groups/643896764140148",
    image: "https://images.unsplash.com/photo-1633159446314-24586e2a21e9?auto=format&fit=crop&q=80"
  },
  { 
    city: "Forlì", 
    link: "https://www.facebook.com/groups/639425974328415",
    image: "https://images.unsplash.com/photo-1589908145027-a8dd27e6f34f?auto=format&fit=crop&q=80"
  },
  { 
    city: "Roma", 
    link: "https://www.facebook.com/groups/544298487667308",
    image: "https://images.unsplash.com/photo-1552832230-c0197dd311b5?auto=format&fit=crop&q=80"
  },
  { 
    city: "Bolzano", 
    link: "https://www.facebook.com/groups/532997518251250",
    image: "https://images.unsplash.com/photo-1595656930936-4b7c24afa533?auto=format&fit=crop&q=80"
  },
  { 
    city: "Trento", 
    link: "https://www.facebook.com/groups/474045681493523",
    image: "https://images.unsplash.com/photo-1604537466158-719b1972feb8?auto=format&fit=crop&q=80"
  }
];

export function Community() {
  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <Users className="h-12 w-12 text-blue-600 mx-auto mb-4" />
          <h1 className="text-3xl font-bold text-gray-900">Community Unistanza</h1>
          <p className="mt-4 text-lg text-gray-600">
            Unisciti ai gruppi Facebook della tua città
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {communities.map((community) => (
            <a
              key={community.city}
              href={community.link}
              target="_blank"
              rel="noopener noreferrer"
              className="group relative bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="relative h-48">
                <img
                  src={community.image}
                  alt={`Città di ${community.city}`}
                  className="absolute inset-0 w-full h-full object-cover"
                  loading="lazy"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = "https://images.unsplash.com/photo-1449824913935-59a10b8d2000?auto=format&fit=crop&q=80";
                  }}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
              </div>
              
              <div className="absolute bottom-0 left-0 right-0 p-6">
                <h3 className="text-2xl font-bold text-white mb-2">
                  Unistanza {community.city}
                </h3>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2 text-white/90">
                    <Facebook className="h-5 w-5" />
                    <span className="font-medium">Gruppo Facebook</span>
                  </div>
                  <span className="px-4 py-1.5 bg-white/20 backdrop-blur-sm text-white rounded-full text-sm font-medium group-hover:bg-white/30 transition-colors">
                    Unisciti
                  </span>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}