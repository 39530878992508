// Utility function to generate a more unique numeric ID from a string
export function generateNumericId(id: string): string {
  // Use more characters from the ID (first 8 instead of 4)
  // and use a better hashing algorithm
  const num = Array.from(id.slice(0, 8)).reduce((acc, char, index) => {
    // Use position in the multiplication to make it more unique
    return (acc * 31 + char.charCodeAt(0) * (index + 1)) % 100000;
  }, 0);
  
  return `#${String(num).padStart(5, '0')}`;
}