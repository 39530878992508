import { getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { 
  getFirestore, 
  enableIndexedDbPersistence, 
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  serverTimestamp
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCcC2pTvOe9PiF3IOf6XL0YXmrkDBd_hbI",
  authDomain: "app-unistanza-49b40.firebaseapp.com",
  databaseURL: "https://app-unistanza-49b40-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "app-unistanza-49b40",
  storageBucket: "app-unistanza-49b40.firebasestorage.app",
  messagingSenderId: "1052571827181",
  appId: "1:1052571827181:web:a94f897cca6add413d05e4"
};

export const app = getApps().length === 0 
  ? initializeApp(firebaseConfig)
  : getApps()[0];

// Initialize Firestore with settings
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});

// Enable offline persistence
try {
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser does not support persistence.');
    }
  });
} catch (err) {
  console.warn('Persistence already enabled or initialization error:', err);
}

// Initialize other services
const auth = getAuth(app);
const storage = getStorage(app);

// Configure storage settings
storage.maxOperationRetryTime = 120000; // 2 minutes
storage.maxUploadRetryTime = 120000; // 2 minutes

// Initialize collections
export const collections = {
  users: 'users',
  listings: 'listings',
  applications: 'applications',
  notifications: 'notifications',
  products: 'products',
  analytics: 'analytics',
  credits: 'credits',
  featured: 'featured',
  messages: 'messages',
  chats: 'chats'
};

export { auth, db, storage, serverTimestamp };