import React, { useState } from 'react';
import { Package, CreditCard, Star, Users, Settings } from 'lucide-react';
import { ProductsTab } from './ProductsTab';
import { CreditPackagesTab } from './CreditPackagesTab';
import { FeaturedPlansTab } from './FeaturedPlansTab';
import { UsersTab } from './UsersTab';

export function AdminManagement() {
  const [activeTab, setActiveTab] = useState('products');

  return (
    <div>
      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8 overflow-x-auto">
            <button
              onClick={() => setActiveTab('products')}
              className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center gap-2 whitespace-nowrap ${
                activeTab === 'products'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              <Package className="h-5 w-5" />
              Prodotti Shop
            </button>
            <button
              onClick={() => setActiveTab('credits')}
              className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center gap-2 whitespace-nowrap ${
                activeTab === 'credits'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              <CreditCard className="h-5 w-5" />
              Pacchetti Crediti
            </button>
            <button
              onClick={() => setActiveTab('featured')}
              className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center gap-2 whitespace-nowrap ${
                activeTab === 'featured'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              <Star className="h-5 w-5" />
              Piani in Evidenza
            </button>
            <button
              onClick={() => setActiveTab('users')}
              className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center gap-2 whitespace-nowrap ${
                activeTab === 'users'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              <Users className="h-5 w-5" />
              Utenti
            </button>
          </nav>
        </div>
      </div>

      <div>
        {activeTab === 'products' && <ProductsTab />}
        {activeTab === 'credits' && <CreditPackagesTab />}
        {activeTab === 'featured' && <FeaturedPlansTab />}
        {activeTab === 'users' && <UsersTab />}
      </div>
    </div>
  );
}