import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, BarChart2 } from 'lucide-react';
import { generateNumericId } from '../../utils/ids';

interface ListingBreadcrumbProps {
  listingId: string;
  isOwner?: boolean;
  isAnalyticsPage?: boolean;
  className?: string;
}

export function ListingBreadcrumb({
  listingId,
  isOwner = false,
  isAnalyticsPage = false,
  className = ''
}: ListingBreadcrumbProps) {
  return (
    <div className="flex flex-wrap items-center justify-between gap-4">
      <nav className={`flex flex-wrap items-center gap-2 text-sm ${className}`}>
        {/* Root Link */}
        <Link 
          to={isOwner ? "/dashboard/landlord" : "/search"}
          className="text-gray-600 hover:text-gray-900 flex items-center gap-1"
        >
          <ChevronLeft className="h-4 w-4" />
          <span className="hidden sm:inline">
            {isOwner ? 'Dashboard' : 'Ricerca'}
          </span>
        </Link>

        {/* ID Annuncio */}
        <div className="flex items-center gap-2 min-w-0">
          {isAnalyticsPage ? (
            <Link 
              to={`/listing/${listingId}`}
              className="text-gray-900 font-medium hover:text-gray-700"
            >
              {generateNumericId(listingId)}
            </Link>
          ) : (
            <span className="text-gray-900 font-medium">
              {generateNumericId(listingId)}
            </span>
          )}
        </div>

        {/* Analytics Page */}
        {isAnalyticsPage && (
          <>
            <ChevronLeft className="h-4 w-4 text-gray-400" />
            <span className="text-gray-900 font-medium">
              Analytics
            </span>
          </>
        )}
      </nav>

      {/* Analytics Button */}
      {isOwner && !isAnalyticsPage && (
        <Link
          to={`/dashboard/landlord/analytics/${listingId}`}
          className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
        >
          <BarChart2 className="h-4 w-4 mr-2" />
          <span className="hidden sm:inline">Visualizza Analytics</span>
          <span className="sm:hidden">Analytics</span>
        </Link>
      )}
    </div>
  );
}