import React, { useState } from 'react';
import { X, CreditCard } from 'lucide-react';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { createStripeCheckout } from '../../lib/payments';
import { toast } from 'react-toastify';

interface PaymentModalProps {
  amount: number;
  title: string;
  description: string;
  metadata: Record<string, any>;
  onClose: () => void;
  onSuccess: (paymentId: string) => void;
}

export function PaymentModal({
  amount,
  title,
  description,
  metadata,
  onClose,
  onSuccess
}: PaymentModalProps) {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleStripePayment = async () => {
    if (isProcessing) return;

    setIsProcessing(true);
    try {
      await createStripeCheckout(amount, metadata);
    } catch (error: any) {
      console.error('Payment error:', error);
      toast.error(error.message || 'Payment processing failed');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-xl font-semibold">{title}</h2>
              <p className="text-sm text-gray-500 mt-1">{description}</p>
              <p className="text-lg font-bold text-blue-600 mt-2">€{amount}</p>
            </div>
            <button
              onClick={onClose}
              disabled={isProcessing}
              className="text-gray-400 hover:text-gray-500 disabled:opacity-50"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="space-y-4">
            <button
              onClick={handleStripePayment}
              disabled={isProcessing}
              className="w-full flex items-center justify-center gap-2 p-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <CreditCard className="h-5 w-5" />
              {isProcessing ? 'Processing...' : 'Pay with Card'}
            </button>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">or</span>
              </div>
            </div>

            <div className={isProcessing ? 'opacity-50 pointer-events-none' : ''}>
              <PayPalButtons
                style={{ layout: "horizontal" }}
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [{
                      amount: {
                        value: amount.toString(),
                        currency_code: 'EUR'
                      },
                      description: description,
                      custom_id: JSON.stringify(metadata)
                    }]
                  });
                }}
                onApprove={async (data: any) => {
                  try {
                    const { orderID } = data;
                    onSuccess(orderID);
                  } catch (error) {
                    console.error('Errore nel processamento del pagamento:', error);
                    toast.error('Errore durante il pagamento');
                  }
                }}
                onError={(err) => {
                  console.error('PayPal error:', err);
                  toast.error('PayPal payment failed');
                }}
                onCancel={() => {
                  toast.info('Payment cancelled');
                  onClose();
                }}
              />
            </div>
          </div>

          <p className="mt-4 text-xs text-gray-500 text-center">
            Payments are securely processed by Stripe and PayPal
          </p>
        </div>
      </div>
    </div>
  );
}