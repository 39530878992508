import React, { useState, useEffect, useCallback } from 'react';
import { Building2, Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { onListingsChange } from '../../../lib/db';
import { Listing } from '../../../types/listing';
import { ListingCard } from './ListingCard';
import { CreateListingModal } from './CreateListingModal';
import { EditListingModal } from './EditListingModal';
import { FeaturedModal } from './FeaturedModal';
import { toast } from 'react-toastify';
import { getUserCredits, useCredits } from '../../../lib/credits';
import { CREDITS_COST } from '../../../constants';
import { ConfirmModal } from '../../common/ConfirmModal';
import { republishListing } from '../../../lib/listings';
import { serverTimestamp } from 'firebase/firestore';
import { getListingsByUser } from '../../../lib/listings';
import { formatFirestoreDate } from '../../../lib/listings';

export function ListingsList() {
  const { userProfile } = useAuthContext();
  const navigate = useNavigate();
  const [listings, setListings] = useState<Listing[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showFeaturedModal, setShowFeaturedModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showRepublishConfirm, setShowRepublishConfirm] = useState(false);
  const [listingToRepublish, setListingToRepublish] = useState<Listing | null>(null);
  const [credits, setCredits] = useState<number | null>(null);

  const loadListings = useCallback(async () => {
    if (!userProfile?.id) return;
    
    try {
      setLoading(true);
      const listings = await getListingsByUser(userProfile.id);
      setListings(listings);
    } catch (error) {
      console.error('Errore caricamento annunci:', error);
      setError(error instanceof Error ? error.message : 'Errore durante il caricamento degli annunci');
    } finally {
      setLoading(false);
    }
  }, [userProfile?.id]);

  useEffect(() => {
    loadListings();
  }, [loadListings]);

  useEffect(() => {
    const loadUserCredits = async () => {
      if (!userProfile?.id) return;
      try {
        const userCredits = await getUserCredits(userProfile.id);
        setCredits(userCredits);
      } catch (error) {
        console.error('Errore caricamento crediti:', error);
      }
    };

    loadUserCredits();
  }, [userProfile?.id]);

  const handleCreateListing = () => {
    if (!userProfile?.landlordInfo?.credits || userProfile.landlordInfo.credits < 1) {
      toast.error('Crediti insufficienti per pubblicare un annuncio');
      return;
    }
    setShowCreateModal(true);
  };

  const handleEditListing = (listing: Listing) => {
    setSelectedListing(listing);
    setShowEditModal(true);
  };

  const handleFeatureListing = (listing: Listing) => {
    setSelectedListing(listing);
    setShowFeaturedModal(true);
  };

  const handleModalClose = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setShowFeaturedModal(false);
    setSelectedListing(null);
    setError(null);
  };

  const handleRepublishListing = async (listing: Listing) => {
    if (!userProfile) {
      toast.error('Sessione scaduta. Effettua nuovamente il login.');
      return;
    }

    try {
      // Verifica saldo crediti
      const userCredits = await getUserCredits(userProfile.id);
      if (userCredits < CREDITS_COST) {
        toast.error(
          `Crediti insufficienti. Servono ${CREDITS_COST} crediti per ripubblicare un annuncio.`
        );
        return;
      }

      setListingToRepublish(listing);
      setShowRepublishConfirm(true);
    } catch (error) {
      console.error('Errore ripubblicazione:', error);
      toast.error(error instanceof Error ? error.message : 'Errore durante la ripubblicazione');
    }
  };

  const handleConfirmRepublish = async () => {
    if (!listingToRepublish || !userProfile) return;
    setLoading(true);

    try {
      await republishListing(listingToRepublish.id, userProfile.id);
      toast.success('Annuncio ripubblicato con successo!');
      loadListings();
    } catch (error) {
      console.error('Errore ripubblicazione:', error);
      toast.error(error instanceof Error ? error.message : 'Errore durante la ripubblicazione');
    } finally {
      setShowRepublishConfirm(false);
      setListingToRepublish(null);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento annunci...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <Building2 className="mx-auto h-12 w-12 text-red-500" />
        <h3 className="mt-2 text-lg font-medium text-gray-900">
          Errore nel caricamento degli annunci
        </h3>
        <p className="mt-1 text-gray-500">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          Riprova
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:gap-6">
          <h2 className="text-xl font-semibold">I Miei Annunci</h2>
          <span className="text-sm text-gray-500">
            {credits !== null && (
              <>
                Crediti disponibili: <span className="font-semibold text-blue-600">{credits}</span>
              </>
            )}
          </span>
        </div>
        <button
          onClick={handleCreateListing}
          className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
        >
          <Plus className="h-5 w-5 mr-2" />
          Pubblica Annuncio
        </button>
      </div>

      {listings.length === 0 ? (
        <div className="text-center py-12">
          <Building2 className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-lg font-medium text-gray-900">
            Nessun annuncio pubblicato
          </h3>
          <p className="mt-1 text-gray-500">
            Inizia a pubblicare i tuoi annunci per trovare studenti!
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {listings.map((listing) => (
            <ListingCard
              key={listing.id}
              listing={listing}
              onEdit={() => handleEditListing(listing)}
              onFeature={() => handleFeatureListing(listing)}
              onRepublish={() => handleRepublishListing(listing)}
            />
          ))}
        </div>
      )}

      {showCreateModal && (
        <CreateListingModal
          onClose={handleModalClose}
          onSuccess={() => {
            handleModalClose();
            toast.success('Annuncio pubblicato con successo!');
          }}
        />
      )}

      {showEditModal && selectedListing && (
        <EditListingModal
          listingId={selectedListing.id}
          onClose={handleModalClose}
          onSuccess={() => {
            handleModalClose();
            toast.success('Annuncio aggiornato con successo!');
          }}
        />
      )}

      {showFeaturedModal && selectedListing && (
        <FeaturedModal
          listing={selectedListing}
          onClose={handleModalClose}
          onSuccess={() => {
            handleModalClose();
            toast.success('Annuncio messo in evidenza con successo!');
          }}
        />
      )}

      {showRepublishConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="space-y-4">
              <p className="text-sm text-gray-600 font-medium">
                Questa operazione costerà {CREDITS_COST} crediti. Vuoi continuare?
              </p>
              <div className="flex gap-4">
                <button
                  onClick={handleConfirmRepublish}
                  disabled={loading}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
                >
                  {loading ? 'Ripubblicazione...' : 'Conferma'}
                </button>
                <button
                  onClick={() => {
                    setShowRepublishConfirm(false);
                    setListingToRepublish(null);
                  }}
                  className="px-4 py-2 bg-white text-gray-600 border border-gray-200 rounded-md hover:bg-gray-50"
                >
                  Annulla
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}