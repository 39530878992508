import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  createListing,
  updateListing,
  deleteListing,
  republishListing,
} from '../../lib/listings';
import { uploadFiles } from '../../lib/upload';
import { toast } from 'react-toastify';
import { ListingForm } from '../../components/listings/ListingForm';

export function CreateListing() {
  const { userProfile } = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (formData: any) => {
    if (!userProfile) {
      toast.error('Sessione scaduta. Effettua nuovamente il login.');
      return;
    }

    try {
      setLoading(true);

      // Verifica saldo crediti
      const userCredits = await getUserCredits(userProfile.id);
      if (userCredits < CREDITS_COST) {
        toast.error(
          `Crediti insufficienti. Servono ${CREDITS_COST} crediti per pubblicare un annuncio.`
        );
        return;
      }

      // 1. Prima creiamo l'annuncio e otteniamo l'ID
      const listingId = await createListing(userProfile.id, formData);

      // 2. Se ci sono immagini, le carichiamo
      if (formData.images?.length > 0) {
        try {
          const imagesToUpload = formData.images.filter(
            (img: any) => img instanceof File
          );
          if (imagesToUpload.length > 0) {
            const imageUrls = await uploadFiles(imagesToUpload, {
              folder: `listings/${listingId}/images`,
              maxFiles: 15,
              allowedTypes: ['image/jpeg', 'image/png', 'image/webp'],
              maxFileSize: 10 * 1024 * 1024,
              onProgress: (progress) => {
                if (progress === 100) {
                  toast.success('Immagini caricate con successo');
                }
              }
            });

            // 3. Aggiorniamo l'annuncio con le URL delle immagini
            await updateListing(listingId, { 
              images: imageUrls,
              updatedAt: serverTimestamp()
            });
          }
        } catch (uploadError) {
          console.error('Errore upload immagini:', uploadError);
          await deleteListing(listingId);
          throw new Error('Errore durante il caricamento delle immagini');
        }
      }

      // Scala i crediti solo dopo il successo completo
      await useCredits(userProfile.id, CREDITS_COST, 'Pubblicazione annuncio');

      toast.success('Annuncio pubblicato con successo!');
      navigate('/dashboard/landlord');
    } catch (error: any) {
      console.error('Errore dettagliato:', error);
      toast.error(error.message || 'Errore durante la pubblicazione');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h1 className="text-2xl font-bold mb-8">
            Pubblica un nuovo annuncio
          </h1>

          <ListingForm
            onSubmit={handleSubmit}
            onCancel={() => navigate('/dashboard/landlord')}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
