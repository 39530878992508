import React, { useState, useEffect } from 'react';
import { X, CreditCard, MessageSquare, Key, AlertCircle } from 'lucide-react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface Transaction {
  id: string;
  type: 'purchase' | 'usage' | 'refund';
  amount: number;
  description: string;
  createdAt: Date;
  status: string;
}

interface Ticket {
  id: string;
  subject: string;
  status: string;
  createdAt: Date;
}

interface UserDetailsModalProps {
  user: any;
  onClose: () => void;
}

export function UserDetailsModal({ user, onClose }: UserDetailsModalProps) {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<'transactions' | 'tickets'>('transactions');
  const navigate = useNavigate();

  useEffect(() => {
    loadUserData();
  }, [user.id]);

  const loadUserData = async () => {
    try {
      // Load transactions
      const transactionsQuery = query(
        collection(db, 'credits'),
        where('userId', '==', user.id),
        orderBy('createdAt', 'desc')
      );
      const transactionsSnapshot = await getDocs(transactionsQuery);
      const transactionsData = transactionsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      })) as Transaction[];

      // Load tickets
      const ticketsQuery = query(
        collection(db, 'tickets'),
        where('userId', '==', user.id),
        orderBy('createdAt', 'desc')
      );
      const ticketsSnapshot = await getDocs(ticketsQuery);
      const ticketsData = ticketsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      })) as Ticket[];

      setTransactions(transactionsData);
      setTickets(ticketsData);
    } catch (error) {
      console.error('Error loading user data:', error);
      toast.error('Errore nel caricamento dei dati utente');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (window.confirm('Sei sicuro di voler inviare una email di reset password a questo utente?')) {
      try {
        // Implement password reset logic here
        toast.success('Email di reset password inviata');
      } catch (error) {
        toast.error('Errore nell\'invio dell\'email di reset');
      }
    }
  };

  const handleTicketClick = (ticketId: string) => {
    onClose();
    navigate('/dashboard/landlord', { 
      state: { 
        activeTab: 'management',
        selectedTicketId: ticketId,
        openTicket: true
      } 
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-xl font-semibold">{user.displayName}</h2>
              <p className="text-sm text-gray-500">{user.email}</p>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="mt-6 flex items-center gap-4">
            <button
              onClick={handleResetPassword}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              <Key className="h-4 w-4 mr-2" />
              Reset Password
            </button>
          </div>

          <div className="mt-6">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8">
                <button
                  onClick={() => setActiveTab('transactions')}
                  className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center gap-2 ${
                    activeTab === 'transactions'
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700'
                  }`}
                >
                  <CreditCard className="h-5 w-5" />
                  Movimenti
                </button>
                <button
                  onClick={() => setActiveTab('tickets')}
                  className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center gap-2 ${
                    activeTab === 'tickets'
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700'
                  }`}
                >
                  <MessageSquare className="h-5 w-5" />
                  Ticket
                </button>
              </nav>
            </div>

            {loading ? (
              <div className="text-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
                <p className="mt-4 text-gray-500">Caricamento...</p>
              </div>
            ) : (
              <div className="mt-6">
                {activeTab === 'transactions' && (
                  <div className="space-y-4">
                    {transactions.length === 0 ? (
                      <p className="text-center text-gray-500 py-4">Nessun movimento trovato</p>
                    ) : (
                      transactions.map((transaction) => (
                        <div
                          key={transaction.id}
                          className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                        >
                          <div>
                            <p className="font-medium">{transaction.description}</p>
                            <p className="text-sm text-gray-500">
                              {format(transaction.createdAt, 'dd/MM/yyyy HH:mm')}
                            </p>
                          </div>
                          <div className="flex items-center gap-4">
                            <span className={`text-sm font-medium ${
                              transaction.amount > 0 ? 'text-green-600' : 'text-red-600'
                            }`}>
                              {transaction.amount > 0 ? '+' : ''}{transaction.amount} crediti
                            </span>
                            <span className={`text-xs px-2 py-1 rounded-full ${
                              transaction.status === 'completed'
                                ? 'bg-green-100 text-green-800'
                                : 'bg-yellow-100 text-yellow-800'
                            }`}>
                              {transaction.status}
                            </span>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                )}

                {activeTab === 'tickets' && (
                  <div className="space-y-4">
                    {tickets.length === 0 ? (
                      <p className="text-center text-gray-500 py-4">Nessun ticket trovato</p>
                    ) : (
                      tickets.map((ticket) => (
                        <div
                          key={ticket.id}
                          className="flex items-center justify-between p-4 bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors"
                          onClick={() => handleTicketClick(ticket.id)}
                        >
                          <div>
                            <p className="font-medium">{ticket.subject}</p>
                            <p className="text-sm text-gray-500">
                              {format(ticket.createdAt, 'dd/MM/yyyy HH:mm')}
                            </p>
                          </div>
                          <span className={`text-xs px-2 py-1 rounded-full ${
                            ticket.status === 'risolto'
                              ? 'bg-green-100 text-green-800'
                              : ticket.status === 'aperto'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}>
                            {ticket.status}
                          </span>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}