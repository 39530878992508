import React, { useState, useEffect } from 'react';
import { categoryLabels } from '../../../types/listing';
import { Building2, Euro, MapPin, Calendar, Ruler, Users, Plug, Image, RefreshCw, Star, CreditCard } from 'lucide-react';
import { generateListingDescription, generateListingTitle } from '../../../utils/ai';
import { useAuthContext } from '../../../contexts/AuthContext';
import { getUserCredits } from '../../../lib/credits';

interface SummaryStepProps {
  formData: any;
  onChange: (field: string, value: any) => void;
  isEditing?: boolean;
}

export function SummaryStep({ formData, onChange, isEditing = false }: SummaryStepProps) {
  const { userProfile } = useAuthContext();
  const [tempTitle, setTempTitle] = useState(formData.title || '');
  const [tempDescription, setTempDescription] = useState(formData.description || '');
  const [isGenerating, setIsGenerating] = useState(false);
  const [credits, setCredits] = useState<number | null>(null);

  useEffect(() => {
    const initializeContent = async () => {
      setIsGenerating(true);
      try {
        if (!tempTitle) {
          const initialTitle = generateListingTitle(formData);
          setTempTitle(initialTitle);
          onChange('title', initialTitle);
        }

        if (!tempDescription) {
          const initialDescription = await generateListingDescription(formData);
          setTempDescription(initialDescription);
          onChange('description', initialDescription);
        }

        // Fetch user credits only when creating a new listing
        if (!isEditing && userProfile?.id) {
          const userCredits = await getUserCredits(userProfile.id);
          setCredits(userCredits);
        }
      } catch (error) {
        console.error('Error generating initial content:', error);
      } finally {
        setIsGenerating(false);
      }
    };

    if (!tempTitle || !tempDescription || (!isEditing && credits === null)) {
      initializeContent();
    }
  }, []);

  useEffect(() => {
    const updateTitleAndDescription = async () => {
      try {
        setIsGenerating(true);
        const newTitle = generateListingTitle(formData);
        const newDescription = await generateListingDescription(formData);
        setTempTitle(newTitle);
        setTempDescription(newDescription);
      } catch (error) {
        console.error('Error generating content:', error);
      } finally {
        setIsGenerating(false);
      }
    };

    // Aggiorna titolo e descrizione quando cambiano i dati rilevanti
    updateTitleAndDescription();
  }, [
    formData.category,
    formData.location.formattedAddress,
    formData.price,
    formData.size,
    formData.floor,
    formData.maxOccupants,
    formData.features,
    formData.utilities,
    formData.minStay,
    formData.noticePeriod,
    formData.heating
  ]);

  const handleRegenerateTitle = async () => {
    try {
      setIsGenerating(true);
      const newTitle = generateListingTitle(formData);
      setTempTitle(newTitle);
    } catch (error) {
      console.error('Error generating title:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRegenerateDescription = async () => {
    try {
      setIsGenerating(true);
      const newDescription = await generateListingDescription(formData);
      setTempDescription(newDescription);
    } catch (error) {
      console.error('Error generating description:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  if (isGenerating) {
    return (
      <div className="flex items-center justify-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Credits Info Card - Only show when creating a new listing */}
      {!isEditing && (
        <div className="bg-yellow-50 border border-yellow-200 rounded-2xl p-6">
          <div className="flex items-center gap-3 mb-4">
            <CreditCard className="h-6 w-6 text-yellow-600" />
            <h3 className="text-lg font-medium text-yellow-800">
              Informazioni sui Crediti
            </h3>
          </div>
          <p className="text-yellow-700 mb-2">
            La pubblicazione di questo annuncio costerà 10 crediti.
          </p>
          <p className="text-yellow-700">
            {credits !== null ? (
              <>
                Saldo attuale: <span className="font-semibold">{credits} crediti</span>
                <br />
                Saldo dopo la pubblicazione: <span className="font-semibold">{credits - 10} crediti</span>
              </>
            ) : (
              'Caricamento saldo crediti...'
            )}
          </p>
        </div>
      )}

      {/* Preview Card */}
      <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl p-8 text-white shadow-xl">
        <h3 className="text-xl font-semibold text-white/90 mb-6">Anteprima Annuncio</h3>
        
        <div className="space-y-6">
          {/* Title */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-white/90">Titolo</label>
              <button
                type="button"
                onClick={handleRegenerateTitle}
                disabled={isGenerating}
                className="inline-flex items-center gap-1 text-sm text-white/80 hover:text-white disabled:opacity-50"
              >
                <RefreshCw className={`h-4 w-4 ${isGenerating ? 'animate-spin' : ''}`} />
                Rigenera titolo
              </button>
            </div>
            <div className="p-4 bg-white/10 rounded-lg backdrop-blur-sm">
              <p className="text-lg font-medium text-white break-words">{tempTitle}</p>
            </div>
          </div>

          {/* Description */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-white/90">Descrizione</label>
              <button
                type="button"
                onClick={handleRegenerateDescription}
                disabled={isGenerating}
                className="inline-flex items-center gap-1 text-sm text-white/80 hover:text-white disabled:opacity-50"
              >
                <RefreshCw className={`h-4 w-4 ${isGenerating ? 'animate-spin' : ''}`} />
                Rigenera descrizione
              </button>
            </div>
            <div className="p-4 bg-white/10 rounded-lg backdrop-blur-sm">
              <p className="text-white/90 whitespace-pre-wrap break-words">{tempDescription}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Details Summary */}
      <div className="bg-white rounded-2xl p-8 shadow-xl border border-gray-100">
        <h3 className="text-xl font-semibold text-gray-900 mb-6">Riepilogo Dettagli</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="flex items-start gap-3">
            <div className="p-2 bg-blue-100 rounded-lg">
              <Building2 className="h-5 w-5 text-blue-600" />
            </div>
            <div>
              <h4 className="font-medium text-gray-700">Tipologia</h4>
              <p className="text-gray-900 mt-1">{categoryLabels[formData.category]}</p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <div className="p-2 bg-green-100 rounded-lg">
              <Euro className="h-5 w-5 text-green-600" />
            </div>
            <div>
              <h4 className="font-medium text-gray-700">Canone mensile</h4>
              <p className="text-gray-900 mt-1">€{formData.price}/mese</p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <div className="p-2 bg-orange-100 rounded-lg">
              <MapPin className="h-5 w-5 text-orange-600" />
            </div>
            <div>
              <h4 className="font-medium text-gray-700">Indirizzo</h4>
              <p className="text-gray-900 mt-1">{formData.location.formattedAddress}</p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <div className="p-2 bg-purple-100 rounded-lg">
              <Calendar className="h-5 w-5 text-purple-600" />
            </div>
            <div>
              <h4 className="font-medium text-gray-700">Disponibilità</h4>
              <p className="text-gray-900 mt-1">
                {new Date(formData.availableFrom).toLocaleDateString()}
              </p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <div className="p-2 bg-red-100 rounded-lg">
              <Users className="h-5 w-5 text-red-600" />
            </div>
            <div>
              <div className="flex items-center gap-1">
                <h4 className="font-medium text-gray-700">Posti disponibili</h4>
                <div className="relative group">
                  <div className="cursor-help text-gray-400 hover:text-gray-600">?</div>
                  <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2 w-64 bg-gray-900 text-white text-sm rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                    Per ogni candidatura accettata, il numero di posti disponibili verrà automaticamente ridotto. Quando tutti i posti saranno occupati, l'annuncio non sarà più visibile.
                    <div className="absolute top-full left-1/2 -translate-x-1/2 border-8 border-transparent border-t-gray-900"></div>
                  </div>
                </div>
              </div>
              <p className="text-gray-900 mt-1">
                {formData.category === 'single_room' 
                  ? '1 posto disponibile'
                  : formData.category === 'double_room'
                  ? '2 posti disponibili'
                  : formData.category === 'triple_room'
                  ? '3 posti disponibili'
                  : '4 posti disponibili'
                }
              </p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <div className="p-2 bg-yellow-100 rounded-lg">
              <Image className="h-5 w-5 text-yellow-600" />
            </div>
            <div>
              <h4 className="font-medium text-gray-700">Contenuti</h4>
              <p className="text-gray-900 mt-1">
                {formData.images.length} foto
                {formData.floorPlans?.length > 0 && `, ${formData.floorPlans.length} planimetrie`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}