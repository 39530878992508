import { Timestamp } from 'firebase/firestore';

export function isApplicationExpired(createdAt: Date | Timestamp | null): boolean {
  if (!createdAt) return false;
  
  let date: Date;
  if (createdAt instanceof Timestamp) {
    date = createdAt.toDate();
  } else if (createdAt instanceof Date) {
    date = createdAt;
  } else {
    try {
      date = new Date(createdAt);
    } catch {
      return false;
    }
  }

  if (isNaN(date.getTime())) return false;

  const expirationTime = date.getTime() + (24 * 60 * 60 * 1000); // 24 hours
  return Date.now() > expirationTime;
}