import { useState, useEffect } from 'react';
import { Timestamp } from 'firebase/firestore';

interface ExpirationTimerProps {
  expiresAt: Date | string | Timestamp;
}

export function ExpirationTimer({ expiresAt }: ExpirationTimerProps) {
  const [timeLeft, setTimeLeft] = useState<string>('');

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (!expiresAt) return 'Data non disponibile';
      
      let expTimestamp: number;
      
      if (expiresAt instanceof Timestamp) {
        expTimestamp = expiresAt.toMillis();
      } else if (expiresAt instanceof Date) {
        expTimestamp = expiresAt.getTime();
      } else {
        expTimestamp = new Date(expiresAt).getTime();
      }

      const now = Date.now();
      const diff = expTimestamp - now;
      
      if (diff <= 0) return 'Scaduta';
      
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      
      return `${hours}h ${minutes}m`;
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 60000);

    setTimeLeft(calculateTimeLeft());
    return () => clearInterval(timer);
  }, [expiresAt]);

  return <span>Scade in: {timeLeft}</span>;
}