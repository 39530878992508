import React, { useState, useRef, useEffect } from 'react';
import { Upload, X, GripVertical } from 'lucide-react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { toast } from 'react-toastify';

interface ImageUploadProps {
  images: (string | File)[];
  onChange: (images: (string | File)[]) => void;
  maxImages?: number;
  title?: string;
  subtitle?: string;
  required?: boolean;
  variant?: 'light' | 'dark';
  listingId?: string;
  enableDragAndDrop?: boolean;
  onDragEnd?: (result: DropResult) => void;
}

export function ImageUpload({
  images = [],
  onChange,
  maxImages = 10,
  title = 'Carica immagini',
  subtitle = 'PNG, JPG fino a 10MB',
  required = false,
  variant = 'light',
  listingId,
  enableDragAndDrop = false,
  onDragEnd
}: ImageUploadProps) {
  const [dragOver, setDragOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageKeys] = useState<string[]>(() => 
    images.map((_, index) => crypto.randomUUID())
  );

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (!files.length) return;

    if (files.length + images.length > maxImages) {
      toast.error(`Puoi caricare al massimo ${maxImages} immagini`);
      return;
    }

    // Validate files
    const validFiles = files.filter(file => {
      if (file.size > 10 * 1024 * 1024) {
        toast.error(`Il file ${file.name} supera i 10MB`);
        return false;
      }
      if (!file.type.startsWith('image/')) {
        toast.error(`Il file ${file.name} non è un'immagine valida`);
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) return;

    // Check for duplicates
    const existingFileNames = new Set(
      images
        .filter((f): f is File => f instanceof File)
        .map(f => f.name)
    );

    const uniqueFiles = validFiles.filter(file => !existingFileNames.has(file.name));

    if (uniqueFiles.length !== validFiles.length) {
      toast.warning('Alcuni file sono stati ignorati perché già presenti');
    }

    if (uniqueFiles.length > 0) {
      const newImages = [...images, ...uniqueFiles];
      uniqueFiles.forEach(() => imageKeys.push(crypto.randomUUID()));
      onChange(newImages);
    }

    // Reset input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleRemove = (index: number) => {
    const newImages = images.filter((_, i) => i !== index);
    imageKeys.splice(index, 1);
    onChange(newImages);
  };

  const getPreviewUrl = (file: string | File): string => {
    if (typeof file === 'string') return file;
    return URL.createObjectURL(file);
  };

  useEffect(() => {
    return () => {
      images.forEach(file => {
        if (file instanceof File) {
          URL.revokeObjectURL(getPreviewUrl(file));
        }
      });
    };
  }, [images]);

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setDragOver(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length === 0) return;

    if (fileInputRef.current) {
      fileInputRef.current.files = e.dataTransfer.files;
      handleFileSelect({ target: fileInputRef.current } as any);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setDragOver(false);
  };

  const textColorClass = variant === 'dark' ? 'text-white' : 'text-gray-700';
  const subtitleColorClass = variant === 'dark' ? 'text-white/70' : 'text-gray-500';
  const requiredTextColorClass = variant === 'dark' ? 'text-red-300' : 'text-red-500';
  const uploadIconColorClass = variant === 'dark' ? 'text-white/70' : 'text-gray-400';

  const renderImages = () => {
    if (!enableDragAndDrop) {
      return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
          {images.map((image, index) => (
            <div key={imageKeys[index]} className="relative aspect-square group">
              <img
                src={getPreviewUrl(image)}
                alt={`Preview ${index + 1}`}
                className="w-full h-full object-cover rounded-lg"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all rounded-lg">
                <button
                  type="button"
                  onClick={() => handleRemove(index)}
                  className="absolute top-2 right-2 p-1 bg-white rounded-full opacity-0 group-hover:opacity-100"
                >
                  <X className="h-4 w-4 text-gray-500" />
                </button>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <DragDropContext onDragEnd={onDragEnd || (() => {})}>
        <Droppable droppableId="images" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4"
            >
              {images.map((image, index) => (
                <Draggable
                  key={imageKeys[index]}
                  draggableId={imageKeys[index]}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={`relative aspect-square group ${
                        snapshot.isDragging ? 'z-50' : ''
                      }`}
                    >
                      <div className={`relative w-full h-full rounded-lg overflow-hidden ${
                        snapshot.isDragging ? 'ring-2 ring-blue-500 shadow-xl image-drag-preview' : ''
                      }`}>
                        <img
                          src={getPreviewUrl(image)}
                          alt={`Preview ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                        <div className={`absolute inset-0 bg-black transition-opacity duration-200 ${
                          snapshot.isDragging ? 'bg-opacity-20' : 'bg-opacity-0 group-hover:bg-opacity-30'
                        }`}>
                          <div
                            {...provided.dragHandleProps}
                            className={`absolute top-2 left-2 p-1.5 bg-white rounded-full transition-opacity duration-200 ${
                              snapshot.isDragging ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
                            } cursor-grab active:cursor-grabbing`}
                          >
                            <GripVertical className="h-4 w-4 text-gray-500" />
                          </div>
                          <button
                            type="button"
                            onClick={() => handleRemove(index)}
                            className={`absolute top-2 right-2 p-1.5 bg-white rounded-full transition-opacity duration-200 ${
                              snapshot.isDragging ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
                            }`}
                          >
                            <X className="h-4 w-4 text-gray-500" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <div className="space-y-4">
      {images.length > 0 && renderImages()}

      {images.length < maxImages && (
        <div
          className={`relative mt-1 flex flex-col items-center justify-center px-6 py-8 border-2 ${
            dragOver ? 'border-blue-500 bg-blue-50/10' : 
            required && images.length === 0 
              ? variant === 'dark' 
                ? 'border-red-300/50' 
                : 'border-red-300'
              : variant === 'dark'
              ? 'border-white/20'
              : 'border-gray-200'
          } border-dashed rounded-lg hover:border-opacity-50 cursor-pointer transition-all`}
          onClick={() => fileInputRef.current?.click()}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <Upload className={`h-8 w-8 mb-2 ${uploadIconColorClass}`} />
          <div className="flex flex-col items-center text-center">
            <span className={`font-medium ${textColorClass}`}>
              {title}
            </span>
            <p className={`mt-1 text-xs ${subtitleColorClass}`}>{subtitle}</p>
            {required && images.length === 0 && (
              <p className={`mt-2 text-xs ${requiredTextColorClass}`}>
                Carica almeno un'immagine
              </p>
            )}
          </div>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileSelect}
            className="hidden"
            required={required && images.length === 0}
          />
        </div>
      )}
    </div>
  );
}