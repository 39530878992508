import React, { useState, useEffect } from 'react';
import { format, startOfWeek, addDays, subDays, eachDayOfInterval, parse, isBefore, isAfter, set, addMinutes, endOfWeek, isToday, startOfDay, endOfDay } from 'date-fns';
import { it } from 'date-fns/locale';
import { X, Calendar, Clock, ChevronLeft, ChevronRight } from 'lucide-react';
import { toast } from 'react-toastify';
import { createMeeting as createDailyMeeting } from '@/lib/daily';
import { getAvailableSlots, updateMeetingStatus } from '../../lib/meetings';
import type { TimeSlot, DayAvailability } from '../../types/meeting';
import { getLandlordAvailability, updateLandlordAvailability, updateLandlordApplicationsAvailabilityStatus } from "@/lib/availability";
import { getListing } from "../../lib/listings";
import { createRoom, deleteRoom } from '@/lib/daily';
import { createMeeting, updateMeeting, getMeetingsInRange } from '@/lib/meetings';
import { Timestamp, serverTimestamp } from 'firebase/firestore';

interface BookMeetingModalProps {
  applicationId: string;
  listingId: string;
  listingTitle: string;
  studentName: string;
  studentEmail: string;
  landlordId: string;
  existingMeeting?: Meeting | null;
  onClose: () => void;
  isRescheduling?: boolean;
  onSuccess?: () => void;
  isStudent?: boolean;
}

interface TimeSlot {
  id: string;
  startTime: string;
  endTime: string;
}

interface WeeklySchedule {
  monday: TimeSlot[];
  tuesday: TimeSlot[];
  wednesday: TimeSlot[];
  thursday: TimeSlot[];
  friday: TimeSlot[];
  saturday: TimeSlot[];
  sunday: TimeSlot[];
}

// Funzione di utilità per verificare se una data è valida
const isValidDate = (date: any): boolean => {
  return date instanceof Date && !isNaN(date.getTime());
};

// Nel componente, prima di usare format:
const formatSafeDate = (date: Date | string | Timestamp | null, formatStr: string): string => {
  if (!date) return '';
  
  try {
    let dateObj: Date;
    
    if (date instanceof Timestamp) {
      dateObj = date.toDate();
    } else if (typeof date === 'string') {
      dateObj = new Date(date);
    } else if (date instanceof Date) {
      dateObj = date;
    } else {
      return '';
    }

    if (isNaN(dateObj.getTime())) {
      return '';
    }

    return format(dateObj, formatStr, { locale: it });
  } catch (error) {
    console.error('Errore nella formattazione della data:', error);
    return '';
  }
};

async function isSlotAvailable(landlordId: string, date: Date, time: string): Promise<boolean> {
  try {
    const dayStart = startOfDay(date);
    const dayEnd = endOfDay(date);
    
    const existingMeetings = await getMeetingsInRange(landlordId, dayStart, dayEnd);
    
    return !existingMeetings.some(meeting => {
      const meetingTime = meeting.time instanceof Timestamp ?
        format(meeting.time.toDate(), 'HH:mm') :
        typeof meeting.time === 'string' ?
          meeting.time :
          format(new Date(meeting.time), 'HH:mm');
          
      return meetingTime === time;
    });
  } catch (error) {
    console.error('Error checking slot availability:', error);
    return false;
  }
}

const sortTimeSlots = (slots: TimeSlot[]): TimeSlot[] => {
  return slots.sort((a, b) => {
    const timeA = parse(a.startTime, 'HH:mm', new Date());
    const timeB = parse(b.startTime, 'HH:mm', new Date());
    return timeA.getTime() - timeB.getTime();
  });
};

export function BookMeetingModal({
  applicationId,
  listingId,
  listingTitle,
  studentName,
  studentEmail,
  landlordId: landlordIdProp,
  existingMeeting,
  onClose,
  isRescheduling = false,
  onSuccess,
  isStudent = false
}: BookMeetingModalProps) {
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(() => {
    try {
      if (existingMeeting?.date instanceof Timestamp) {
        return existingMeeting.date.toDate();
      }
      if (typeof existingMeeting?.date === 'string') {
        return new Date(existingMeeting.date);
      }
      return existingMeeting?.date || new Date();
    } catch (error) {
      console.error('Error parsing meeting date:', error);
      return new Date();
    }
  });
  const [availability, setAvailability] = useState<DayAvailability[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
  const [meetingDuration, setMeetingDuration] = useState<number>(30);

  useEffect(() => {
    const loadExistingMeeting = async () => {
      if (!existingMeeting) return;
      
      try {
        // Gestione della data
        let meetingDate: Date;
        if (existingMeeting.date instanceof Timestamp) {
          meetingDate = existingMeeting.date.toDate();
        } else if (typeof existingMeeting.date === 'string') {
          meetingDate = new Date(existingMeeting.date);
        } else {
          meetingDate = new Date(existingMeeting.date);
        }

        if (!isNaN(meetingDate.getTime())) {
          setCurrentDate(meetingDate);
          setSelectedDate(format(meetingDate, 'yyyy-MM-dd'));
        }

        // Gestione dell'orario
        if (existingMeeting.time instanceof Timestamp) {
          const timeDate = existingMeeting.time.toDate();
          if (!isNaN(timeDate.getTime())) {
            setSelectedSlot(format(timeDate, 'HH:mm'));
          }
        } else if (typeof existingMeeting.time === 'string') {
          setSelectedSlot(existingMeeting.time);
        }

      } catch (error) {
        console.error('Errore nel caricamento del meeting:', error);
        toast.error('Errore nel caricamento dei dati');
      }
    };

    loadExistingMeeting();
  }, [existingMeeting]);

  useEffect(() => {
    loadAvailability();
  }, [currentDate]);

  const loadAvailability = async () => {
    const landlordId = existingMeeting?.landlordId || landlordIdProp;

    if (!landlordId) {
      console.error('Missing landlordId:', landlordId);
      toast.error('Errore: dati proprietario mancanti');
      return;
    }

    try {
      setLoading(true);
      const today = new Date();
      const currentWeekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
      const endDate = addDays(currentWeekStart, 6);
      const startDate = isBefore(currentWeekStart, today) ? today : currentWeekStart;

      // Ottieni la disponibilità del proprietario
      const landlordAvailability = await getLandlordAvailability(landlordId);

      if (!landlordAvailability || !landlordAvailability.weeklySchedule) {
        toast.info('Il proprietario non ha ancora configurato le disponibilità');
        setAvailability([]);
        setLoading(false);
        return;
      }

      // Mappa per convertire i nomi dei giorni
      const dayNameMap: { [key: string]: string } = {
        'lunedì': 'monday',
        'martedì': 'tuesday',
        'mercoledì': 'wednesday',
        'giovedì': 'thursday',
        'venerdì': 'friday',
        'sabato': 'saturday',
        'domenica': 'sunday'
      };

      const availabilityWithDays = eachDayOfInterval({ start: startDate, end: endDate })
        .map(date => {
          const italianDayName = format(date, 'EEEE', { locale: it }).toLowerCase();
          const englishDayName = dayNameMap[italianDayName];
          
          const daySlots = landlordAvailability.weeklySchedule[englishDayName] || [];

          // Genera gli slot per questo giorno
          const availableSlots = daySlots.flatMap(timeSlot => {
            const slots = [];
            const start = parse(timeSlot.startTime, 'HH:mm', date);
            const end = parse(timeSlot.endTime, 'HH:mm', date);
            
            let current = start;
            while (isBefore(current, end)) {
              // Se è oggi, controlla se lo slot è già passato
              if (isToday(date)) {
                const now = new Date();
                const slotTime = set(date, {
                  hours: current.getHours(),
                  minutes: current.getMinutes(),
                  seconds: 0,
                  milliseconds: 0
                });

                // Aggiungi solo gli slot futuri (con 5 minuti di margine)
                if (isAfter(slotTime, addMinutes(now, 5))) {
                  slots.push({
                    id: `${format(date, 'yyyy-MM-dd')}-${format(current, 'HH:mm')}`,
                    startTime: format(current, 'HH:mm'),
                    endTime: format(
                      addMinutes(current, landlordAvailability.meetingDuration),
                      'HH:mm'
                    )
                  });
                }
              } else {
                // Per i giorni futuri, aggiungi tutti gli slot
                slots.push({
                  id: `${format(date, 'yyyy-MM-dd')}-${format(current, 'HH:mm')}`,
                  startTime: format(current, 'HH:mm'),
                  endTime: format(
                    addMinutes(current, landlordAvailability.meetingDuration),
                    'HH:mm'
                  )
                });
              }
              
              current = addMinutes(
                current,
                landlordAvailability.meetingDuration + landlordAvailability.breakDuration
              );
            }
            return slots;
          });

          return {
            date: format(date, 'yyyy-MM-dd'),
            dayName: format(date, 'EEE', { locale: it }),
            dayNumber: format(date, 'd'),
            slots: availableSlots
          };
        });

      setAvailability(availabilityWithDays);
    } catch (error) {
      console.error('Errore nel caricamento delle disponibilità:', error);
      toast.error('Errore nel caricamento delle disponibilità');
    } finally {
      setLoading(false);
    }
  };

  const handlePrevWeek = () => {
    const today = new Date();
    const prevWeek = subDays(currentDate, 7);
    
    if (isBefore(endOfWeek(prevWeek, { weekStartsOn: 1 }), today)) {
      toast.info('Non puoi prenotare date passate');
      return;
    }
    
    setCurrentDate(prevWeek);
  };

  const handleNextWeek = () => {
    setCurrentDate(addDays(currentDate, 7));
  };

  const handleDateSelect = (date: string) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };

  const handleSlotSelect = (slot: string) => {
    setSelectedSlot(slot);
  };

  const handleSubmit = async () => {
    if (!selectedDate || !selectedSlot) {
      toast.error('Seleziona una data e un orario');
      return;
    }

    try {
      setLoading(true);
      const [hours, minutes] = selectedSlot.split(':');
      const meetingDateTime = new Date(selectedDate);
      meetingDateTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);

      const isAvailable = await isSlotAvailable(
        landlordIdProp,
        meetingDateTime,
        selectedSlot
      );
      
      if (!isAvailable) {
        toast.error('Lo slot selezionato non è più disponibile');
        await loadAvailability();
        return;
      }

      const meetingData = {
        applicationId,
        listingId,
        listingTitle,
        studentName,
        studentEmail,
        landlordId: landlordIdProp,
        date: Timestamp.fromDate(meetingDateTime),
        time: Timestamp.fromDate(meetingDateTime),
        status: 'confirmed' as MeetingStatus
      };

      await createMeeting(meetingData);
      toast.success('Videochiamata prenotata con successo');
      if (onSuccess) {
        onSuccess();
      }
      onClose();
    } catch (error) {
      console.error('Errore durante la prenotazione:', error);
      toast.error('Errore durante la prenotazione della videochiamata');
    } finally {
      setLoading(false);
    }
  };

  // Funzione helper per generare le date della settimana
  const getWeekDays = (startDate: Date, endDate: Date) => {
    const days = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      const formattedDate = format(currentDate, 'yyyy-MM-dd');
      const dayAvailability = availability.find(day => day.date === formattedDate);
      
      days.push({
        date: formattedDate,
        dayName: format(currentDate, 'EEEE', { locale: it }),
        dayNumber: format(currentDate, 'd'),
        slots: dayAvailability?.slots || []
      });

      currentDate = addDays(currentDate, 1);
    }

    return days;
  };

  const weekDays = getWeekDays(
    startOfWeek(currentDate, { weekStartsOn: 1 }), 
    addDays(startOfWeek(currentDate, { weekStartsOn: 1 }), 6)
  );

  // Aggiungi questo oggetto per le abbreviazioni personalizzate
  const dayAbbreviations: { [key: string]: string } = {
    'lunedì': 'LUN',
    'martedì': 'MAR',
    'mercoledì': 'MER',
    'giovedì': 'GIO',
    'venerdì': 'VEN',
    'sabato': 'SAB',
    'domenica': 'DOM'
  };

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-lg max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="p-4 sm:p-6 border-b">
          <div className="flex justify-between items-center">
            <h2 className="text-lg sm:text-xl font-semibold">
              {existingMeeting ? 'Modifica Videochiamata' : 'Prenota Videochiamata'}
            </h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5 sm:h-6 sm:w-6" />
            </button>
          </div>
        </div>

        {/* Contenuto */}
        <div className="p-4 sm:p-6 overflow-y-auto flex-1">
          {loading ? (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
              <p className="mt-4 text-gray-500">Caricamento disponibilità...</p>
            </div>
          ) : (
            <div className="space-y-6">
              {/* Controlli di navigazione */}
              <div className="flex justify-between items-center mb-4">
                <button
                  onClick={handlePrevWeek}
                  className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <ChevronLeft className="h-5 w-5" />
                </button>
                <span className="text-sm sm:text-base font-medium text-center px-2">
                  {format(startOfWeek(currentDate, { weekStartsOn: 1 }), 'd MMM', { locale: it })} - 
                  {format(endOfWeek(currentDate, { weekStartsOn: 1 }), 'd MMM yyyy', { locale: it })}
                </span>
                <button
                  onClick={handleNextWeek}
                  className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <ChevronRight className="h-5 w-5" />
                </button>
              </div>

              {/* Grid dei giorni - sostituito con scroll orizzontale */}
              <div className="relative">
                <div className="overflow-x-auto pb-4 hide-scrollbar">
                  <div className="flex gap-2 sm:gap-3">
                    {weekDays.map((day) => {
                      const hasSlots = day.slots.length > 0;
                      const isDisabled = !hasSlots || 
                        (isToday(new Date(day.date)) && !day.slots.some(slot => {
                          const [hours, minutes] = slot.startTime.split(':');
                          const slotTime = set(new Date(), {
                            hours: parseInt(hours),
                            minutes: parseInt(minutes),
                            seconds: 0,
                            milliseconds: 0
                          });
                          return isAfter(slotTime, addMinutes(new Date(), 5));
                        }));

                      return (
                        <button
                          key={day.date}
                          onClick={() => !isDisabled && handleDateSelect(day.date)}
                          disabled={isDisabled}
                          className={`
                            flex-shrink-0 w-[100px] sm:w-[120px] p-2 sm:p-4 rounded-lg border transition-all
                            ${selectedDate === day.date 
                              ? 'border-blue-500 bg-blue-50' 
                              : isDisabled
                                ? 'border-gray-200 bg-gray-50 cursor-not-allowed opacity-60'
                                : 'border-gray-200 hover:border-blue-200'
                            }
                          `}
                        >
                          <div className="text-center">
                            <div className="text-xs sm:text-sm font-medium text-gray-600">
                              {format(new Date(day.date), 'EEE', { locale: it })}
                            </div>
                            <div className="text-lg sm:text-2xl font-semibold my-1">
                              {format(new Date(day.date), 'd', { locale: it })}
                            </div>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Stile corretto per styled-jsx */}
              <style>{`
                .hide-scrollbar {
                  -ms-overflow-style: none;
                  scrollbar-width: none;
                }
                .hide-scrollbar::-webkit-scrollbar {
                  display: none;
                }
              `}</style>

              {/* Slot orari */}
              {selectedDate && (
                <div className="mt-6 pt-6 border-t">
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="text-base sm:text-lg font-medium">Orari Disponibili</h4>
                    <span className="text-xs sm:text-sm text-gray-500">
                      Durata: {meetingDuration} minuti
                    </span>
                  </div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-3">
                    {sortTimeSlots(
                      availability
                        .find(day => day.date === selectedDate)
                        ?.slots || []
                    ).map(slot => (
                      <button
                        key={slot.id}
                        onClick={() => handleSlotSelect(slot.startTime)}
                        className={`
                          p-3 sm:p-4 rounded-lg border transition-all
                          ${selectedSlot === slot.startTime 
                            ? 'border-blue-500 bg-blue-50' 
                            : existingMeeting?.time === slot.startTime
                              ? 'border-blue-300 bg-blue-50'
                              : 'border-gray-200 hover:border-blue-200'
                          }
                        `}
                      >
                        <Clock className="h-4 w-4 sm:h-5 sm:w-5 mx-auto mb-2" />
                        <span className="text-xs sm:text-sm font-medium">
                          {slot.startTime}
                        </span>
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="p-4 sm:p-6 border-t bg-gray-50 rounded-b-xl">
          <div className="flex flex-col sm:flex-row justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Annulla
            </button>
            <button
              onClick={handleSubmit}
              disabled={!selectedDate || !selectedSlot || loading}
              className={`
                px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50
              `}
            >
              {loading ? 'Prenotazione...' : 'Prenota'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}