import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MessageSquare } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { onChatsChange } from '../../lib/chat';
import { Chat } from '../../types/chat';
import { ChatList } from '../../components/chat/ChatList';
import { ChatWindow } from '../../components/chat/ChatWindow';

export function ChatPage() {
  const { chatId } = useParams();
  const { userProfile } = useAuthContext();
  const [chats, setChats] = useState<Chat[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userProfile?.id) return;

    const unsubscribe = onChatsChange(userProfile.id, (updatedChats) => {
      setChats(updatedChats);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [userProfile?.id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-4 text-gray-500">Caricamento chat...</p>
          </div>
        </div>
      </div>
    );
  }

  const selectedChat = chatId ? chats.find(chat => chat.id === chatId) : null;
  const otherPartyName = selectedChat
    ? userProfile?.id === selectedChat.studentId
      ? selectedChat.landlordName
      : selectedChat.studentName
    : '';

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-sm min-h-[600px] flex">
          {/* Chat List */}
          <div className="w-1/3 border-r">
            <div className="p-4 border-b">
              <h2 className="text-lg font-semibold flex items-center gap-2">
                <MessageSquare className="h-5 w-5" />
                Chat
              </h2>
            </div>
            <ChatList chats={chats} userId={userProfile?.id || ''} />
          </div>

          {/* Chat Window */}
          <div className="flex-1">
            {selectedChat ? (
              <>
                <div className="p-4 border-b">
                  <h3 className="font-medium">{otherPartyName}</h3>
                  <p className="text-sm text-gray-500">{selectedChat.listingTitle}</p>
                </div>
                <ChatWindow chatId={selectedChat.id} otherPartyName={otherPartyName} />
              </>
            ) : (
              <div className="h-full flex items-center justify-center">
                <div className="text-center">
                  <MessageSquare className="h-12 w-12 text-gray-400 mx-auto" />
                  <h3 className="mt-2 text-lg font-medium text-gray-900">
                    Seleziona una chat
                  </h3>
                  <p className="mt-1 text-gray-500">
                    Scegli una conversazione dalla lista per visualizzarla
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}