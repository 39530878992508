import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { createTicket } from '../../lib/tickets';
import { toast } from 'react-toastify';
import { db } from '../../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';

interface CreateTicketModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

export type TicketStatus = 'aperto' | 'in_lavorazione' | 'risolto' | 'chiuso';

export function CreateTicketModal({ onClose, onSuccess }: CreateTicketModalProps) {
  const { userProfile } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    subject: '',
    message: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userProfile) return;

    setLoading(true);
    try {
      const ticketData = {
        userId: userProfile.id,
        userEmail: userProfile.email,
        userType: userProfile.userType,
        subject: formData.subject,
        status: 'aperto' as TicketStatus,
        firstMessage: formData.message
      };

      const ticketId = await createTicket(ticketData);
      
      const ticketDoc = await getDoc(doc(db, 'tickets', ticketId));
      if (!ticketDoc.exists() || ticketDoc.data().status !== 'aperto') {
        throw new Error('Errore nella creazione del ticket');
      }

      toast.success('Ticket creato con successo');
      onSuccess();
    } catch (error) {
      console.error('Errore durante la creazione del ticket:', error);
      toast.error('Errore durante la creazione del ticket');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Nuovo Ticket di Supporto</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Oggetto
              </label>
              <input
                type="text"
                value={formData.subject}
                onChange={(e) => setFormData(prev => ({ ...prev, subject: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Messaggio
              </label>
              <textarea
                value={formData.message}
                onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
                rows={4}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Annulla
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 disabled:opacity-50"
              >
                {loading ? 'Creazione...' : 'Crea Ticket'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}