export const GOOGLE_MAPS_API_KEY = 'AIzaSyA62o-vy2zEo6kgmJcUslQUI13nMUQ9ZgE';

export const mapConfig = {
  libraries: ['places'],
  componentRestrictions: { country: 'it' },
  types: ['address'],
  fields: ['address_components', 'formatted_address', 'geometry', 'place_id']
};

export const italyBounds = {
  north: 47.092,
  south: 35.494,
  west: 6.627,
  east: 18.521,
};

// Updated to Venice coordinates
export const defaultMapCenter = {
  lat: 45.4371,
  lng: 12.3326
};