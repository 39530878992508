import { 
  collection, 
  query, 
  where, 
  getDocs,
  addDoc,
  deleteDoc,
  serverTimestamp,
  documentId,
  Timestamp,
  DocumentData,
  QueryDocumentSnapshot,
  limit,
  runTransaction,
  doc
} from 'firebase/firestore';
import { db } from './firebase';
import { Listing } from '../types/listing';
import { toast } from 'react-toastify';

const favoritesCollection = collection(db, 'favorites');
const listingsCollection = collection(db, 'listings');

const parseFirestoreDate = (date: any): Date => {
  if (date instanceof Timestamp) {
    return date.toDate();
  }
  if (date instanceof Date) {
    return date;
  }
  if (typeof date === 'string') {
    return new Date(date);
  }
  return new Date();
};

const formatListing = (doc: QueryDocumentSnapshot<DocumentData>): Listing => {
  const data = doc.data();
  return {
    id: doc.id,
    ...data,
    createdAt: parseFirestoreDate(data.createdAt),
    updatedAt: data.updatedAt ? parseFirestoreDate(data.updatedAt) : undefined,
    availableFrom: data.availableFrom ? parseFirestoreDate(data.availableFrom) : undefined,
    featured: data.featured ? {
      ...data.featured,
      expiresAt: data.featured.expiresAt ? parseFirestoreDate(data.featured.expiresAt) : null
    } : undefined
  } as Listing;
};

export async function addFavorite(userId: string, listingId: string): Promise<void> {
  if (!userId) {
    throw new Error('User ID is required');
  }
  if (!listingId) {
    throw new Error('Listing ID is required');
  }

  try {
    await runTransaction(db, async (transaction) => {
      // Check if already favorited
      const existingQuery = query(
        favoritesCollection,
        where('userId', '==', userId),
        where('listingId', '==', listingId),
        limit(1)
      );
      const existingDocs = await getDocs(existingQuery);

      if (!existingDocs.empty) {
        return; // Already favorited
      }

      // Create favorite document
      const favoriteRef = doc(favoritesCollection);
      transaction.set(favoriteRef, {
        userId,
        listingId,
        createdAt: serverTimestamp()
      });
    });

    toast.success('Annuncio aggiunto ai preferiti');
  } catch (error) {
    console.error('Error adding favorite:', error);
    throw new Error('Errore durante l\'aggiunta ai preferiti');
  }
}

export async function removeFavorite(userId: string, listingId: string): Promise<void> {
  if (!userId) {
    throw new Error('User ID is required');
  }
  if (!listingId) {
    throw new Error('Listing ID is required');
  }

  try {
    await runTransaction(db, async (transaction) => {
      const favoriteQuery = query(
        favoritesCollection,
        where('userId', '==', userId),
        where('listingId', '==', listingId),
        limit(1)
      );
      const snapshot = await getDocs(favoriteQuery);

      if (!snapshot.empty) {
        transaction.delete(snapshot.docs[0].ref);
      }
    });

    toast.success('Annuncio rimosso dai preferiti');
  } catch (error) {
    console.error('Error removing favorite:', error);
    throw new Error('Errore durante la rimozione dai preferiti');
  }
}

export async function getFavoritedListings(userId: string): Promise<Listing[]> {
  if (!userId) {
    throw new Error('User ID is required');
  }

  try {
    // Get user's favorites
    const favoritesQuery = query(
      favoritesCollection,
      where('userId', '==', userId)
    );
    const favoritesSnapshot = await getDocs(favoritesQuery);

    if (favoritesSnapshot.empty) {
      return [];
    }

    // Get the listing IDs
    const listingIds = favoritesSnapshot.docs.map(doc => doc.data().listingId);

    // Process listings in batches of 10 (Firestore limit)
    const listings: Listing[] = [];
    for (let i = 0; i < listingIds.length; i += 10) {
      const batch = listingIds.slice(i, i + 10);
      const listingsQuery = query(
        listingsCollection,
        where(documentId(), 'in', batch)
      );

      try {
        const listingsSnapshot = await getDocs(listingsQuery);
        const validListings = listingsSnapshot.docs
          .map(formatListing)
          .filter(listing => listing.status === 'active'); // Only include active listings
        listings.push(...validListings);
      } catch (error) {
        console.error('Error fetching batch:', error);
        // Continue with next batch even if one fails
      }
    }

    return listings;
  } catch (error) {
    console.error('Error getting favorited listings:', error);
    throw new Error('Errore nel caricamento dei preferiti');
  }
}

export async function isFavorited(userId: string | null, listingId: string): Promise<boolean> {
  if (!userId) return false;

  try {
    const favoritesRef = collection(db, 'favorites');
    const q = query(
      favoritesRef,
      where('userId', '==', userId),
      where('listingId', '==', listingId),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  } catch (error) {
    console.error('Error checking favorite status:', error);
    return false;
  }
}