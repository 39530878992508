import { 
  collection, 
  query, 
  where, 
  orderBy, 
  limit,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  Timestamp,
  getDocs,
  serverTimestamp
} from 'firebase/firestore';
import { db } from './firebase';
import { Ticket, Message } from '../types/ticket';
import { toast } from 'react-toastify';
import { sendNewTicketNotification } from './email';
import { uploadFiles } from './upload';

const ticketsCollection = collection(db, 'tickets');
const messagesCollection = collection(db, 'messages');

interface MessageData {
  ticketId: string;
  userId: string;
  userEmail: string;
  content: string;
  isAdmin: boolean;
  attachments?: File[];
}

export async function createTicket(data: Partial<Ticket> & { firstMessage: string }): Promise<string> {
  if (!data.userId || !data.userEmail || !data.subject || !data.firstMessage) {
    throw new Error('Dati del ticket incompleti');
  }

  try {
    const { firstMessage, ...ticketData } = data;
    
    // Assicurati che lo stato sia esplicitamente impostato come 'aperto'
    const ticketRef = await addDoc(ticketsCollection, {
      ...ticketData,
      status: 'aperto' as TicketStatus,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      description: firstMessage // Aggiungi la descrizione
    });

    // Crea il primo messaggio
    await addDoc(messagesCollection, {
      ticketId: ticketRef.id,
      userId: data.userId,
      userEmail: data.userEmail,
      content: firstMessage,
      createdAt: serverTimestamp(),
      isAdmin: false
    });

    return ticketRef.id;
  } catch (error: any) {
    console.error('Error creating ticket:', error);
    throw error;
  }
}

export async function updateTicket(ticketId: string, data: Partial<Ticket>): Promise<void> {
  if (!ticketId) {
    throw new Error('ID ticket non valido');
  }

  try {
    const docRef = doc(ticketsCollection, ticketId);
    await updateDoc(docRef, {
      ...data,
      updatedAt: serverTimestamp()
    });
    toast.success('Ticket aggiornato con successo');
  } catch (error: any) {
    console.error('Error updating ticket:', error);
    toast.error('Errore durante l\'aggiornamento del ticket: ' + (error.message || 'Errore sconosciuto'));
    throw error;
  }
}

export function onUserTicketsChange(
  userId: string, 
  callback: (tickets: Ticket[]) => void,
  onError?: (error: Error) => void
) {
  if (!userId) {
    callback([]);
    return () => {};
  }

  const q = query(
    ticketsCollection,
    where('userId', '==', userId),
    orderBy('createdAt', 'desc')
  );

  return onSnapshot(
    q, 
    (snapshot) => {
      const tickets = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        updatedAt: doc.data().updatedAt?.toDate(),
        resolvedAt: doc.data().resolvedAt?.toDate()
      })) as Ticket[];
      callback(tickets);
    },
    (error) => {
      console.error('Error in tickets subscription:', error);
      if (onError) {
        onError(error);
      }
      callback([]);
      toast.error('Errore nel caricamento dei ticket');
    }
  );
}

export function onAdminTicketsChange(callback: (tickets: Ticket[]) => void) {
  const q = query(
    ticketsCollection,
    orderBy('createdAt', 'desc')
  );

  return onSnapshot(
    q, 
    (snapshot) => {
      const tickets = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        updatedAt: doc.data().updatedAt?.toDate(),
        resolvedAt: doc.data().resolvedAt?.toDate()
      })) as Ticket[];
      callback(tickets);
    },
    (error) => {
      console.error('Error in admin tickets subscription:', error);
      callback([]);
      toast.error('Errore nel caricamento dei ticket');
    }
  );
}

export function onTicketMessagesChange(ticketId: string, callback: (messages: Message[]) => void) {
  if (!ticketId) {
    callback([]);
    return () => {};
  }

  const q = query(
    messagesCollection,
    where('ticketId', '==', ticketId),
    orderBy('createdAt', 'asc')
  );

  return onSnapshot(q, (snapshot) => {
    const messages = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate()
    })) as Message[];
    callback(messages);
  });
}

export async function sendMessage(data: MessageData): Promise<void> {
  if (!data.ticketId || !data.userId || !data.userEmail || !data.content) {
    throw new Error('Dati del messaggio incompleti');
  }

  try {
    let attachmentUrls: string[] = [];
    
    // Handle file attachments if present
    if (data.attachments && data.attachments.length > 0) {
      attachmentUrls = await uploadFiles(data.attachments, {
        folder: `tickets/${data.ticketId}/attachments`,
        maxFiles: 5,
        maxFileSize: 10 * 1024 * 1024, // 10MB
        allowedTypes: ['image/jpeg', 'image/png', 'image/webp', 'application/pdf']
      });
    }

    const messageData = {
      ticketId: data.ticketId,
      userId: data.userId,
      userEmail: data.userEmail,
      content: data.content,
      isAdmin: data.isAdmin,
      attachments: attachmentUrls.map((url, index) => ({
        url,
        name: data.attachments?.[index].name || `File ${index + 1}`,
        type: data.attachments?.[index].type || 'application/octet-stream'
      })),
      createdAt: serverTimestamp()
    };

    await addDoc(messagesCollection, messageData);

    // Update ticket's last update timestamp
    await updateDoc(doc(ticketsCollection, data.ticketId), {
      updatedAt: serverTimestamp()
    });
  } catch (error: any) {
    console.error('Error sending message:', error);
    toast.error('Errore durante l\'invio del messaggio: ' + (error.message || 'Errore sconosciuto'));
    throw error;
  }
}