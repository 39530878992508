import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DailyProvider, DailyVideo, useDaily } from '@daily-co/daily-react';
import { useAuthContext } from '../contexts/AuthContext';
import { getMeeting } from '../lib/meetings';
import { X, Mic, MicOff, Video as VideoIcon, VideoOff } from 'lucide-react';
import { toast } from 'react-toastify';

function CallUI() {
  const daily = useDaily();
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const navigate = useNavigate();

  const toggleAudio = () => {
    if (!daily) return;
    daily.setLocalAudio(!isMuted);
    setIsMuted(!isMuted);
  };

  const toggleVideo = () => {
    if (!daily) return;
    daily.setLocalVideo(!isVideoOff);
    setIsVideoOff(!isVideoOff);
  };

  const handleEndCall = () => {
    if (!daily) return;
    daily.leave();
    navigate('/dashboard/student');
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      {/* Video Container */}
      <div className="flex-1 p-4">
        <div className="w-full h-full rounded-lg overflow-hidden">
          <DailyVideo />
        </div>
      </div>

      {/* Controls */}
      <div className="p-4 bg-black/80">
        <div className="max-w-3xl mx-auto flex items-center justify-center gap-4">
          <button
            onClick={toggleAudio}
            className={`p-4 rounded-full ${
              isMuted ? 'bg-red-600' : 'bg-gray-700 hover:bg-gray-600'
            }`}
          >
            {isMuted ? (
              <MicOff className="h-6 w-6 text-white" />
            ) : (
              <Mic className="h-6 w-6 text-white" />
            )}
          </button>

          <button
            onClick={toggleVideo}
            className={`p-4 rounded-full ${
              isVideoOff ? 'bg-red-600' : 'bg-gray-700 hover:bg-gray-600'
            }`}
          >
            {isVideoOff ? (
              <VideoOff className="h-6 w-6 text-white" />
            ) : (
              <VideoIcon className="h-6 w-6 text-white" />
            )}
          </button>

          <button
            onClick={handleEndCall}
            className="p-4 rounded-full bg-red-600 hover:bg-red-700"
          >
            <X className="h-6 w-6 text-white" />
          </button>
        </div>
      </div>
    </div>
  );
}

export function VideoCall() {
  const { meetingId } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useAuthContext();
  const [meeting, setMeeting] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadMeeting = async () => {
      if (!meetingId || !userProfile) {
        navigate('/dashboard/student');
        return;
      }

      try {
        const meetingData = await getMeeting(meetingId);
        if (!meetingData) {
          toast.error('Videochiamata non trovata');
          navigate('/dashboard/student');
          return;
        }

        // Verifica che l'utente sia parte della videochiamata
        const isAuthorized = (
          meetingData.studentId === userProfile.id || 
          meetingData.studentEmail === userProfile.email ||
          meetingData.landlordId === userProfile.id ||
          userProfile.role === 'admin'
        );

        if (!isAuthorized) {
          toast.error('Non hai accesso a questa videochiamata');
          // Redirect alla dashboard appropriata in base al ruolo dell'utente
          const dashboardPath = userProfile.role === 'student' 
            ? '/dashboard/student'
            : '/dashboard/landlord';
          navigate(dashboardPath);
          return;
        }

        console.log('Meeting Data:', {
          meetingStudentId: meetingData.studentId,
          meetingStudentEmail: meetingData.studentEmail,
          meetingLandlordId: meetingData.landlordId,
          userProfileId: userProfile.id,
          userProfileEmail: userProfile.email,
          userProfileRole: userProfile.role
        });

        console.log('Dati autorizzazione:', {
          meetingStudentId: meetingData.studentId,
          meetingStudentEmail: meetingData.studentEmail,
          userProfileId: userProfile.id,
          userProfileEmail: userProfile.email,
          userProfileRole: userProfile.role,
          isAuthorized
        });

        setMeeting(meetingData);
      } catch (error) {
        toast.error('Errore nel caricamento della videochiamata');
        navigate('/dashboard/student');
      } finally {
        setLoading(false);
      }
    };

    loadMeeting();
  }, [meetingId, userProfile, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  if (!meeting?.meetingUrl) {
    toast.error('URL della videochiamata non disponibile');
    navigate('/dashboard/student');
    return;
  }

  // Validazione URL
  if (!meeting.meetingUrl.startsWith('https://')) {
    toast.error('URL della videochiamata non valido');
    navigate('/dashboard/student');
    return;
  }

  // Reindirizzamento sicuro
  window.location.href = meeting.meetingUrl;

  return (
    <DailyProvider url={meeting.meetingUrl}>
      <CallUI />
    </DailyProvider>
  );
}