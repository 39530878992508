import React from 'react';
import { SlidersHorizontal, X, Euro, Users, Building2, Calendar } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { categoryLabels } from '../../types/listing';

interface SearchFiltersProps {
  filters: {
    category: string;
    minPrice: string;
    maxPrice: string;
    maxOccupants: string;
    availableFrom?: string;
  };
  onFilterChange: (filters: any) => void;
  showFilters: boolean;
  onToggleFilters: () => void;
  totalResults: number;
}

export function SearchFilters({ 
  filters, 
  onFilterChange, 
  showFilters, 
  onToggleFilters,
  totalResults 
}: SearchFiltersProps) {
  const hasActiveFilters = Object.values(filters).some(value => value !== '');

  return (
    <div className="bg-white border-b border-gray-200">
      <div className="p-4">
        <AnimatePresence>
          {showFilters && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              
            >
              <div className="space-y-4">
                {/* Tipologia */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1.5">
                    <div className="flex items-center gap-2">
                      <Building2 className="h-4 w-4 text-gray-400" />
                      Tipologia
                    </div>
                  </label>
                  <select
                    value={filters.category}
                    onChange={(e) => onFilterChange({ ...filters, category: e.target.value })}
                    className="w-full h-12 px-3 rounded-lg border border-gray-200 bg-white focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base"
                  >
                    <option value="">Tutte le tipologie</option>
                    {Object.entries(categoryLabels).map(([value, label]) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </select>
                </div>

                {/* Prezzo */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1.5">
                    <div className="flex items-center gap-2">
                      <Euro className="h-4 w-4 text-gray-400" />
                      Prezzo mensile
                    </div>
                  </label>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="relative">
                      <input
                        type="number"
                        value={filters.minPrice}
                        onChange={(e) => onFilterChange({ ...filters, minPrice: e.target.value })}
                        placeholder="Min"
                        className="w-full h-12 px-3 pl-7 rounded-lg border border-gray-200 bg-white focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base"
                      />
                      <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">€</span>
                    </div>
                    <div className="relative">
                      <input
                        type="number"
                        value={filters.maxPrice}
                        onChange={(e) => onFilterChange({ ...filters, maxPrice: e.target.value })}
                        placeholder="Max"
                        className="w-full h-12 px-3 pl-7 rounded-lg border border-gray-200 bg-white focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base"
                      />
                      <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">€</span>
                    </div>
                  </div>
                </div>

                {/* Posti letto */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1.5">
                    <div className="flex items-center gap-2">
                      <Users className="h-4 w-4 text-gray-400" />
                      Posti letto
                    </div>
                  </label>
                  <select
                    value={filters.maxOccupants}
                    onChange={(e) => onFilterChange({ ...filters, maxOccupants: e.target.value })}
                    className="w-full h-12 px-3 rounded-lg border border-gray-200 bg-white focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base appearance-none"
                  >
                    <option value="">Qualsiasi</option>
                    <option value="1">1 posto letto</option>
                    <option value="2">2 posti letto</option>
                    <option value="3">3 posti letto</option>
                    <option value="4">4 posti letto</option>
                  </select>
                </div>

                {/* Data disponibilità */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1.5">
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-gray-400" />
                      Disponibile dal
                    </div>
                  </label>
                  <input
                    type="date"
                    value={filters.availableFrom}
                    onChange={(e) => onFilterChange({ ...filters, availableFrom: e.target.value })}
                    min={new Date().toISOString().split('T')[0]}
                    className="w-full h-12 px-3 rounded-lg border border-gray-200 bg-white focus:ring-2 focus:ring-blue-500 focus:border-transparent text-base appearance-none"
                  />
                </div>
              </div>

              {hasActiveFilters && (
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={() => onFilterChange({
                      category: '',
                      minPrice: '',
                      maxPrice: '',
                      maxOccupants: '',
                      availableFrom: ''
                    })}
                    className="text-sm text-blue-600 hover:text-blue-700 font-medium"
                  >
                    Rimuovi tutti i filtri
                  </button>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}