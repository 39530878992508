import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MessageSquare, Send, CheckCircle2, Clock } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { Notification, onNotificationsChange, markAsRead, markAllAsRead } from '../../lib/notifications';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/locale';
import { FirestoreError } from 'firebase/firestore';
import { toast } from 'react-hot-toast';

interface NotificationsDropdownProps {
  onClose: () => void;
}

export function NotificationsDropdown({ onClose }: NotificationsDropdownProps) {
  const { userProfile } = useAuthContext();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!userProfile?.id) return;

    const unsubscribe = onNotificationsChange(userProfile.id, (updatedNotifications) => {
      setNotifications(updatedNotifications);
    });

    return () => unsubscribe();
  }, [userProfile?.id]);

  const handleNotificationClick = async (notification: Notification) => {
    try {
      await markAsRead(notification.id);
      
      onClose();

      const { path, state } = getNotificationLink(notification, userProfile?.userType || 'student');
      
      navigate(path, { state });
      
    } catch (error) {
      if ((error as FirestoreError).code !== 'permission-denied') {
        console.error('Errore durante la gestione del click sulla notifica:', error);
        toast.error('Errore durante la lettura della notifica');
      }
    }
  };

  const handleMarkAllAsRead = async () => {
    if (!userProfile?.id) {
      console.warn('Nessun profilo utente trovato');
      return;
    }

    try {
      setLoading(true);
      await markAllAsRead(userProfile.id);
    } catch (error) {
      if ((error as FirestoreError).code === 'permission-denied') {
        toast.error('Non hai i permessi per eseguire questa azione');
      } else {
        console.error('Errore durante la lettura delle notifiche:', error);
        toast.error('Errore durante la lettura delle notifiche');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleViewAllClick = () => {
    const basePath = `/dashboard/${userProfile?.userType}`;
    navigate(basePath, { state: { activeTab: 'notifications' } });
    onClose();
  };

  const getNotificationLink = (notification: Notification, userType: string) => {
    // For landlords receiving applications, link to applications tab
    if (userType === 'landlord' && notification.type === 'application_received') {
      return { path: '/dashboard/landlord?tab=applications', state: { activeTab: 'applications', highlightedId: notification.applicationId } };
    }

    switch (notification.type) {
      case 'application_received':
        return { path: '/dashboard/landlord?tab=applications', state: { activeTab: 'applications', highlightedId: notification.applicationId } };
      case 'application_accepted':
        return { path: `/dashboard/student/applications/${notification.data.applicationId}`, state: { activeTab: 'applications', highlightedId: notification.applicationId } };
      case 'ticket_reply':
        return { path: `/dashboard/support/tickets/${notification.data.ticketId}`, state: { activeTab: 'support', highlightedId: notification.data.ticketId } };
      default:
        return { path: '#', state: {} };
    }
  };

  const getIcon = (type: string) => {
    switch (type) {
      case 'application_received':
        return <Send className="h-5 w-5 text-blue-500" />;
      case 'application_accepted':
        return <CheckCircle2 className="h-5 w-5 text-green-500" />;
      case 'ticket_reply':
        return <MessageSquare className="h-5 w-5 text-purple-500" />;
      default:
        return <Clock className="h-5 w-5 text-gray-500" />;
    }
  };

  const getNotificationMessage = (notification: Notification) => {
    // For landlords, hide student name in pending applications
    if (userProfile?.userType === 'landlord' && 
        notification.type === 'application_received' && 
        !notification.data.accepted) {
      return `Nuova candidatura ricevuta per "${notification.data.listingTitle}"`;
    }
    return notification.message;
  };

  return (
    <div 
      ref={dropdownRef} 
      className="fixed sm:absolute mt-2 w-80 bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden"
      style={{
        left: isMobile ? '50%' : 'auto',
        right: isMobile ? 'auto' : '0',
        transform: isMobile ? 'translateX(-50%)' : 'none',
        maxWidth: 'calc(100vw - 2rem)',
        maxHeight: isMobile ? 'calc(100vh - 120px)' : '80vh',
        top: isMobile ? '60px' : 'auto',
        zIndex: 50,
      }}
    >
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-semibold text-gray-900">Notifiche</h3>
          <button
            onClick={handleMarkAllAsRead}
            className="text-xs text-blue-600 font-medium hover:text-blue-700"
          >
            Segna tutte come lette
          </button>
        </div>
      </div>

      <div className="divide-y divide-gray-200 max-h-96 overflow-y-auto">
        {notifications.length === 0 ? (
          <div className="p-4 text-center text-gray-500">
            Nessuna notifica
          </div>
        ) : (
          notifications.map((notification) => (
            <Link
              key={notification.id}
              to={getNotificationLink(notification, userProfile?.userType || 'student').path}
              onClick={(e) => {
                e.preventDefault();
                handleNotificationClick(notification);
              }}
              className={`block p-4 hover:bg-gray-50 transition-colors ${
                !notification.read ? 'bg-blue-50' : ''
              }`}
            >
              <div className="flex gap-3">
                {getIcon(notification.type)}
                <div className="flex-1 min-w-0">
                  <p className={`text-sm font-medium text-gray-900 ${
                    !notification.read ? 'font-semibold' : ''
                  }`}>
                    {notification.title}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {getNotificationMessage(notification)}
                  </p>
                  <p className="text-xs text-gray-400 mt-1">
                    {formatDistanceToNow(notification.createdAt, { 
                      addSuffix: true,
                      locale: it 
                    })}
                  </p>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>

      <div className="p-4 border-t border-gray-200 text-center">
        <button
          onClick={handleViewAllClick}
          className="text-sm text-blue-600 font-medium hover:text-blue-700"
        >
          Vedi tutte le notifiche
        </button>
      </div>
    </div>
  );
}