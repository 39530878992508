import React, { useState, useEffect } from 'react';
import { Calendar, Clock, Plus, X } from 'lucide-react';
import { AdminAvailability, TimeSlot } from '../../../types/availability';
import { getLandlordAvailability, updateLandlordAvailability } from '../../../lib/availability';
import { useAuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';

const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;
const DEFAULT_MEETING_DURATION = 30;
const DEFAULT_BREAK_DURATION = 15;

const dayLabels = {
  monday: 'Lunedì',
  tuesday: 'Martedì',
  wednesday: 'Mercoledì',
  thursday: 'Giovedì',
  friday: 'Venerdì',
  saturday: 'Sabato',
  sunday: 'Domenica'
};

const createEmptySchedule = () => ({
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: []
});

export function AvailabilityTab() {
  const { userProfile } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [availability, setAvailability] = useState<AdminAvailability | null>(null);

  useEffect(() => {
    if (userProfile?.id) {
      loadAvailability();
    }
  }, [userProfile?.id]);

  const loadAvailability = async () => {
    if (!userProfile?.id) {
      setLoading(false);
      return;
    }
    
    try {
      setLoading(true);
      const data = await getLandlordAvailability(userProfile.id);
      
      if (data) {
        // Ensure all days exist in weeklySchedule
        const weeklySchedule = {
          ...createEmptySchedule(),
          ...data.weeklySchedule
        };
        
        setAvailability({
          ...data,
          weeklySchedule,
          customDates: data.customDates || []
        });
      } else {
        // Initialize with default values if no data exists
        setAvailability({
          id: userProfile.id,
          weeklySchedule: createEmptySchedule(),
          customDates: [],
          meetingDuration: DEFAULT_MEETING_DURATION,
          breakDuration: DEFAULT_BREAK_DURATION
        });
      }
    } catch (error) {
      console.error('Error loading availability:', error);
      toast.error('Errore nel caricamento delle disponibilità');
    } finally {
      setLoading(false);
    }
  };

  const handleAddSlot = (day: keyof AdminAvailability['weeklySchedule']) => {
    if (!availability) return;

    const newSlot: TimeSlot = {
      id: crypto.randomUUID(),
      startTime: '09:00',
      endTime: '17:00'
    };

    setAvailability(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        weeklySchedule: {
          ...prev.weeklySchedule,
          [day]: [...(prev.weeklySchedule[day] || []), newSlot]
        }
      };
    });
  };

  const handleRemoveSlot = (day: keyof AdminAvailability['weeklySchedule'], slotId: string) => {
    if (!availability) return;

    setAvailability(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        weeklySchedule: {
          ...prev.weeklySchedule,
          [day]: (prev.weeklySchedule[day] || []).filter(slot => slot.id !== slotId)
        }
      };
    });
  };

  const handleSlotChange = (
    day: keyof AdminAvailability['weeklySchedule'],
    slotId: string,
    field: 'startTime' | 'endTime',
    value: string
  ) => {
    if (!availability) return;

    setAvailability(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        weeklySchedule: {
          ...prev.weeklySchedule,
          [day]: (prev.weeklySchedule[day] || []).map(slot =>
            slot.id === slotId ? { ...slot, [field]: value } : slot
          )
        }
      };
    });
  };

  const handleSave = async () => {
    if (!userProfile?.id) {
      toast.error('Utente non autenticato');
      return;
    }

    try {
      setLoading(true);
      await updateLandlordAvailability(userProfile.id, availability);
      toast.success('Disponibilità aggiornata con successo');
      await loadAvailability(); // Reload data after successful update
    } catch (error) {
      console.error('Error updating availability:', error);
      toast.error('Errore durante l\'aggiornamento della disponibilità');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento disponibilità...</p>
      </div>
    );
  }

  return (
    <div className="space-y-4 sm:space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <h2 className="text-lg sm:text-xl font-semibold">Gestione Disponibilità</h2>
        <button
          onClick={handleSave}
          disabled={loading}
          className="w-full sm:w-auto px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
        >
          {loading ? 'Salvataggio...' : 'Salva Modifiche'}
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6">
        <h3 className="text-base sm:text-lg font-medium mb-4">Impostazioni Generali</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Durata Incontro (minuti)
            </label>
            <input
              type="number"
              value={availability?.meetingDuration || DEFAULT_MEETING_DURATION}
              onChange={(e) => setAvailability(prev => prev ? {
                ...prev,
                meetingDuration: parseInt(e.target.value)
              } : null)}
              min="15"
              step="15"
              className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Pausa tra Incontri (minuti)
            </label>
            <input
              type="number"
              value={availability?.breakDuration || DEFAULT_BREAK_DURATION}
              onChange={(e) => setAvailability(prev => prev ? {
                ...prev,
                breakDuration: parseInt(e.target.value)
              } : null)}
              min="5"
              step="5"
              className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6">
        <h3 className="text-base sm:text-lg font-medium mb-4">Disponibilità Settimanale</h3>
        <div className="space-y-4 sm:space-y-6">
          {DAYS.map((day) => (
            <div key={day} className="border-b border-gray-200 pb-4 sm:pb-6 last:border-0 last:pb-0">
              <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2 sm:gap-4 mb-4">
                <h4 className="text-sm sm:text-base font-medium">{dayLabels[day]}</h4>
                <button
                  onClick={() => handleAddSlot(day)}
                  className="w-full sm:w-auto inline-flex items-center justify-center px-3 py-1.5 text-sm font-medium text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200"
                >
                  <Plus className="h-4 w-4 mr-1" />
                  Aggiungi Slot
                </button>
              </div>

              {(!availability?.weeklySchedule[day] || availability.weeklySchedule[day].length === 0) ? (
                <p className="text-sm text-gray-500">Nessuno slot disponibile</p>
              ) : (
                <div className="space-y-3">
                  {availability.weeklySchedule[day].map((slot) => (
                    <div key={slot.id} className="flex flex-col sm:flex-row items-start sm:items-center gap-3">
                      <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 gap-3 w-full">
                        <div className="space-y-1">
                          <label className="block text-sm font-medium text-gray-700">
                            Ora Inizio
                          </label>
                          <input
                            type="time"
                            value={slot.startTime}
                            onChange={(e) => handleSlotChange(day, slot.id, 'startTime', e.target.value)}
                            className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                        <div className="space-y-1">
                          <label className="block text-sm font-medium text-gray-700">
                            Ora Fine
                          </label>
                          <input
                            type="time"
                            value={slot.endTime}
                            onChange={(e) => handleSlotChange(day, slot.id, 'endTime', e.target.value)}
                            className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                      </div>
                      <button
                        onClick={() => handleRemoveSlot(day, slot.id)}
                        className="p-2 text-gray-400 hover:text-gray-500 sm:self-end"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}