import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  Timestamp,
  serverTimestamp,
  query,
  where,
  getDocs,
  orderBy,
  limit as firestoreLimit,
  onSnapshot,
  writeBatch,
  increment,
  DocumentData,
  QuerySnapshot,
  FirestoreError
} from 'firebase/firestore';
import { db } from './firebase';
import { toast } from 'react-toastify';
import { Listing } from '../types/listing';

// Collection References
const listingsCollection = collection(db, 'listings');
const analyticsCollection = collection(db, 'analytics');
const notificationsCollection = collection(db, 'notifications');
const applicationsCollection = collection(db, 'applications');

// Helper functions
const parseFirestoreDate = (date: any): Date | undefined => {
  if (!date) return undefined;
  if (date instanceof Timestamp) return date.toDate();
  if (date instanceof Date) return date;
  if (typeof date === 'string') return new Date(date);
  if (date && typeof date === 'object' && 'seconds' in date) {
    return new Timestamp(date.seconds, date.nanoseconds).toDate();
  }
  return undefined;
};

const formatListing = (doc: DocumentData): Listing => {
  const data = doc.data();
  return {
    id: doc.id,
    ...data,
    createdAt: parseFirestoreDate(data.createdAt) || new Date(),
    updatedAt: parseFirestoreDate(data.updatedAt),
    availableFrom: parseFirestoreDate(data.availableFrom),
    featured: data.featured ? {
      ...data.featured,
      expiresAt: parseFirestoreDate(data.featured.expiresAt)
    } : undefined
  } as Listing;
};

const handleFirestoreError = (error: FirestoreError): never => {
  console.error('Firestore error:', error);
  throw error;
};

// Get listing statistics
export async function getListingStats(listingId: string) {
  if (!listingId) {
    console.error('Invalid listing ID');
    return null;
  }

  try {
    const docRef = doc(analyticsCollection, listingId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return {
        views: 0,
        applications: 0
      };
    }

    const data = docSnap.data();
    return {
      views: data.views || 0,
      applications: data.applications || 0
    };
  } catch (error) {
    console.error('Error fetching listing stats:', error);
    throw new Error('Failed to fetch listing statistics');
  }
}

// Update featured listing status
export async function updateListingFeatured(
  listingId: string, 
  planId: string,
  paymentCompleted: boolean = false
): Promise<void> {
  if (!listingId) {
    throw new Error('Listing ID is required');
  }

  try {
    const docRef = doc(listingsCollection, listingId);
    const now = new Date();
    let expiresAt: Date;

    switch (planId) {
      case 'daily':
        expiresAt = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        break;
      case 'weekly':
        expiresAt = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
        break;
      case 'monthly':
        expiresAt = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);
        break;
      default:
        throw new Error('Invalid plan');
    }

    await updateDoc(docRef, {
      featured: {
        active: paymentCompleted,
        pending: !paymentCompleted,
        plan: planId,
        expiresAt: paymentCompleted ? Timestamp.fromDate(expiresAt) : null
      },
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating featured status:', error);
    throw new Error('Error updating featured status');
  }
}

// Get a single listing
export async function getListing(listingId: string): Promise<Listing | null> {
  try {
    const docRef = doc(listingsCollection, listingId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      return null;
    }

    return formatListing(docSnap);
  } catch (error) {
    if (error instanceof FirestoreError) {
      handleFirestoreError(error);
    }
    throw error;
  }
}

// Increment listing views
export async function incrementListingViews(listingId: string): Promise<void> {
  if (!listingId) return;

  try {
    // Aggiorna prima l'annuncio
    const listingRef = doc(listingsCollection, listingId);
    await updateDoc(listingRef, {
      views: increment(1),
      updatedAt: serverTimestamp()
    });

    // Poi aggiorna o crea il documento analytics
    try {
      const analyticsRef = doc(collection(db, 'analytics'), listingId);
      const analyticsDoc = await getDoc(analyticsRef);

      if (analyticsDoc.exists()) {
        await updateDoc(analyticsRef, {
          views: increment(1),
          updatedAt: serverTimestamp()
        });
      } else {
        await setDoc(analyticsRef, {
          views: 1,
          applications: 0,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      }
    } catch (analyticsError) {
      // Se fallisce l'aggiornamento delle analytics, logghiamo l'errore ma non blocchiamo l'operazione
      console.warn('Error updating analytics:', analyticsError);
    }
  } catch (error: any) {
    console.error('Error incrementing views:', error);
    // Non rilanciare l'errore per evitare di bloccare il caricamento della pagina
    // ma registra l'errore per il monitoraggio
    if (error.code === 'permission-denied') {
      console.warn('Permission denied while incrementing views');
    }
  }
}

// Listen to listings changes
export function onListingsChange(
  userId: string,
  callback: (listings: Listing[]) => void,
  onError?: (error: Error) => void
): () => void {
  if (!userId) {
    callback([]);
    return () => {};
  }

  const listingsQuery = query(
    listingsCollection,
    where('userId', '==', userId),
    orderBy('createdAt', 'desc')
  );

  return onSnapshot(
    listingsQuery,
    (snapshot: QuerySnapshot) => {
      try {
        const listings = snapshot.docs.map(formatListing);
        callback(listings);
      } catch (error) {
        console.error('Error parsing listings:', error);
        if (onError) {
          onError(new Error('Error loading listings. Data might be corrupted.'));
        }
        callback([]);
      }
    },
    (error) => {
      console.error('Error in listings subscription:', error);
      if (onError) {
        onError(error as Error);
      }
      callback([]);
    }
  );
}