import React, { useState } from 'react';
import { User, Mail, Phone, Building2, ArrowUp, ArrowDown, MessageSquare, Star } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { updateUserProfile } from '../../lib/auth';
import { toast } from 'react-toastify';
import { DeleteAccountModal } from './DeleteAccountModal';
import { SupportDashboard } from '../support/SupportDashboard';
import { TransactionsHistory } from './TransactionsHistory';

export function ProfileSettings() {
  const { userProfile } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [formData, setFormData] = useState({
    displayName: userProfile?.displayName || '',
    email: userProfile?.email || '',
    phoneNumber: userProfile?.phoneNumber || '',
    companyName: userProfile?.landlordInfo?.companyName || '',
    vatNumber: userProfile?.landlordInfo?.vatNumber || ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await updateUserProfile(userProfile!.id, {
        displayName: formData.displayName,
        phoneNumber: formData.phoneNumber,
        ...(userProfile?.userType === 'landlord' ? {
          landlordInfo: {
            ...userProfile.landlordInfo,
            companyName: formData.companyName,
            vatNumber: formData.vatNumber
          }
        } : {})
      });
      toast.success('Profilo aggiornato con successo');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Errore durante l\'aggiornamento del profilo');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6 sm:space-y-8">
      {showSupportModal ? (
        <div className="bg-white rounded-lg shadow p-4 sm:p-6">
          <SupportDashboard onBack={() => setShowSupportModal(false)} />
        </div>
      ) : (
        <>
          {/* Profile Header Card */}
          <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-xl sm:rounded-2xl p-6 sm:p-8 text-white shadow-lg">
            <div className="flex flex-col items-start">
              <h2 className="text-lg sm:text-xl font-semibold text-white/90">Il tuo profilo</h2>
              <p className="text-xs sm:text-sm text-white/80 mt-1">Gestisci le tue informazioni personali</p>
              <div className="flex items-center gap-3 sm:gap-4 mt-4 sm:mt-6">
                <User className="h-8 w-8 sm:h-12 sm:w-12 text-white/90" />
                <div>
                  <span className="text-xl sm:text-2xl font-bold">{formData.displayName}</span>
                  <p className="text-xs sm:text-sm text-white/80">{formData.email}</p>
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={() => setShowSupportModal(true)}
            className="w-full flex items-center justify-center gap-2 p-3 sm:p-4 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors text-sm sm:text-base"
          >
            <MessageSquare className="h-4 w-4 sm:h-5 sm:w-5" />
            Assistenza e Supporto
          </button>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="bg-white rounded-lg shadow p-4 sm:p-6">
              <div className="space-y-4 sm:space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    <div className="flex items-center gap-2 mb-1">
                      <User className="h-4 w-4 text-gray-400" />
                      Nome Completo
                    </div>
                  </label>
                  <input
                    type="text"
                    name="displayName"
                    value={formData.displayName}
                    onChange={handleChange}
                    className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                  />
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    <div className="flex items-center gap-2 mb-1">
                      <Mail className="h-4 w-4 text-gray-400" />
                      Email
                    </div>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    disabled
                    className="block w-full rounded-lg border-gray-300 bg-gray-50 shadow-sm text-sm sm:text-base"
                  />
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    <div className="flex items-center gap-2 mb-1">
                      <Phone className="h-4 w-4 text-gray-400" />
                      Numero di Telefono
                    </div>
                  </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                  />
                </div>

                {userProfile?.userType === 'landlord' && (
                  <>
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">
                        <div className="flex items-center gap-2 mb-1">
                          <Building2 className="h-4 w-4 text-gray-400" />
                          Nome Azienda (opzionale)
                        </div>
                      </label>
                      <input
                        type="text"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                      />
                    </div>

                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">
                        <div className="flex items-center gap-2 mb-1">
                          <Building2 className="h-4 w-4 text-gray-400" />
                          Partita IVA (opzionale)
                        </div>
                      </label>
                      <input
                        type="text"
                        name="vatNumber"
                        value={formData.vatNumber}
                        onChange={handleChange}
                        className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Transaction History */}
            <div className="bg-white rounded-lg shadow p-4 sm:p-6">
              <TransactionsHistory />
            </div>

            <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
              <button
                type="button"
                onClick={() => setShowDeleteModal(true)}
                className="w-full sm:w-auto text-red-600 hover:text-red-700 font-medium text-sm sm:text-base"
              >
                Elimina Account
              </button>

              <button
                type="submit"
                disabled={loading}
                className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 text-sm sm:text-base font-medium"
              >
                {loading ? 'Salvataggio...' : 'Salva Modifiche'}
              </button>
            </div>
          </form>
        </>
      )}

      {showDeleteModal && (
        <DeleteAccountModal onClose={() => setShowDeleteModal(false)} />
      )}
    </div>
  );
}