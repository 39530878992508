import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { createListing } from '../../../lib/listings';
import { uploadFiles } from '../../../lib/upload';
import { toast } from 'react-toastify';
import { ListingForm } from '../../../components/listings/ListingForm';

interface CreateListingModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

const getMaxOccupants = (category: string): string => {
  switch (category) {
    case 'single_room': return '1';
    case 'double_room': return '2';
    case 'triple_room': return '3';
    case 'quad_room': return '4';
    default: return '1';
  }
};

export function CreateListingModal({ onClose, onSuccess }: CreateListingModalProps) {
  const { userProfile } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (formData: any) => {
    if (!userProfile) {
      toast.error('Sessione scaduta. Effettua nuovamente il login.');
      return;
    }

    try {
      setLoading(true);

      if (!navigator.onLine) {
        throw new Error('Nessuna connessione internet');
      }

      // Gestisci il caricamento delle immagini
      let imageUrls: string[] = [];
      if (formData.images?.length > 0) {
        const imagesToUpload = formData.images.filter((img: any) => img instanceof File);
        if (imagesToUpload.length > 0) {
          try {
            const uploadedImages = await uploadFiles(imagesToUpload, {
              folder: `listings/temp/images`,
              maxFiles: 15,
              allowedTypes: ['image/jpeg', 'image/png', 'image/webp'],
              maxFileSize: 10 * 1024 * 1024,
              onProgress: (progress) => {
                if (progress === 100) {
                  toast.success('Immagini caricate con successo');
                }
              }
            });
            imageUrls = [...uploadedImages];
          } catch (error) {
            console.error('Error uploading images:', error);
            throw new Error('Errore durante il caricamento delle immagini');
          }
        }
        // Aggiungi eventuali URL esistenti
        const existingUrls = formData.images.filter((img: any) => typeof img === 'string');
        imageUrls = [...existingUrls, ...imageUrls];
      }

      // Gestisci il caricamento delle planimetrie
      let floorPlanUrls: string[] = [];
      if (formData.floorPlans?.length > 0) {
        const plansToUpload = formData.floorPlans.filter((plan: any) => plan instanceof File);
        if (plansToUpload.length > 0) {
          try {
            const uploadedPlans = await uploadFiles(plansToUpload, {
              folder: `listings/temp/floorplans`,
              maxFiles: 5,
              allowedTypes: ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'],
              maxFileSize: 10 * 1024 * 1024,
              onProgress: (progress) => {
                if (progress === 100) {
                  toast.success('Planimetrie caricate con successo');
                }
              }
            });
            floorPlanUrls = [...uploadedPlans];
          } catch (error) {
            // Se il caricamento delle planimetrie fallisce, elimina le immagini già caricate
            await Promise.all(imageUrls.map(url => deleteFile(url)));
            console.error('Error uploading floor plans:', error);
            throw new Error('Errore durante il caricamento delle planimetrie');
          }
        }
        // Aggiungi eventuali URL esistenti
        const existingUrls = formData.floorPlans.filter((plan: any) => typeof plan === 'string');
        floorPlanUrls = [...existingUrls, ...floorPlanUrls];
      }

      // Crea l'annuncio con gli URL delle immagini e delle planimetrie
      const listingData = {
        ...formData,
        images: imageUrls,
        floorPlans: floorPlanUrls,
        userId: userProfile.id,
        status: 'active',
        views: 0,
        applications: 0,
        availableSpots: getMaxOccupants(formData.category),
        rentedUnits: 0
      };

      // Gestione della data di disponibilità
      if (listingData.availableFrom) {
        const date = new Date(listingData.availableFrom);
        if (isNaN(date.getTime())) {
          throw new Error('Data di disponibilità non valida');
        }
        date.setUTCHours(12, 0, 0, 0);
        listingData.availableFrom = date;
      }

      // Crea l'annuncio
      await createListing(userProfile.id, listingData);

      toast.success('Annuncio pubblicato con successo!');
      onSuccess();
    } catch (error: any) {
      console.error('Error creating listing:', error);
      toast.error(error.message || 'Errore durante la creazione dell\'annuncio');
    } finally {
      setLoading(false);
    }
  };

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    if (!place.formatted_address || !place.geometry?.location) {
      toast.error('Seleziona un indirizzo valido dalla lista');
      return;
    }

    const streetNumber = place.address_components?.find(c => c.types.includes('street_number'))?.long_name;
    const route = place.address_components?.find(c => c.types.includes('route'))?.long_name;
    const locality = place.address_components?.find(c => c.types.includes('locality'))?.long_name;
    const area = place.address_components?.find(c => c.types.includes('administrative_area_level_1'))?.long_name;
    const country = place.address_components?.find(c => c.types.includes('country'))?.long_name;

    // Verifica che ci siano almeno via e città
    if (!route || !locality) {
      toast.error('L\'indirizzo deve contenere almeno via e città');
      return;
    }

    onChange({
      target: {
        name: 'location',
        value: {
          formattedAddress: place.formatted_address,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          streetNumber: streetNumber || '',  // Numero civico potrebbe non esserci
          route,
          locality,
          area: area || '',  // Provincia potrebbe non esserci
          country: country || 'Italia',  // Default a Italia se non specificato
        }
      }
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">
              Pubblica un nuovo annuncio
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <ListingForm
            onSubmit={handleSubmit}
            onCancel={onClose}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}