import React, { useState, useEffect } from 'react';
import { Send, SlidersHorizontal } from 'lucide-react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { onLandlordApplicationsChange } from '../../../lib/applications';
import { Application } from '../../../types/application';
import { ApplicationCard } from './ApplicationCard';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

interface Filters {
  status: string;
  university: string;
  studyProgram: string;
  studyYear: string;
  moveInDateFrom: string;
  moveInDateTo: string;
  stayDuration: string;
  gender: string;
}

export function Applications() {
  const { userProfile } = useAuthContext();
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    status: '',
    university: '',
    studyProgram: '',
    studyYear: '',
    moveInDateFrom: '',
    moveInDateTo: '',
    stayDuration: '',
    gender: ''
  });
  const location = useLocation();
  const [highlightedId, setHighlightedId] = useState<string | null>(null);

  useEffect(() => {
    if (!userProfile?.id) return;

    const unsubscribe = onLandlordApplicationsChange(
      userProfile.id,
      (updatedApplications) => {
        setApplications(updatedApplications);
        setLoading(false);
      },
      (error) => {
        console.error('Error loading applications:', error);
        toast.error('Errore nel caricamento delle candidature');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userProfile?.id]);

  useEffect(() => {
    if (location.state?.applicationId && location.state?.highlight) {
      setHighlightedId(location.state.applicationId);
      
      // Rimuovi l'evidenziazione dopo 3 secondi
      const timer = setTimeout(() => {
        setHighlightedId(null);
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, [location.state]);

  const universities = Array.from(new Set(applications.map(app => app.university))).filter(Boolean);
  const studyPrograms = [...new Set(applications.map(app => app.studyProgram))];
  const studyYears = [...new Set(applications.map(app => app.studyYear))].sort((a, b) => Number(a) - Number(b));
  const stayDurations = [...new Set(applications.map(app => app.stayDuration))];

  const filteredApplications = applications.filter(app => {
    if (filters.status && app.status !== filters.status) return false;
    if (filters.university && app.university !== filters.university) return false;
    if (filters.studyProgram && app.studyProgram !== filters.studyProgram) return false;
    if (filters.studyYear && app.studyYear !== filters.studyYear) return false;
    if (filters.stayDuration && app.stayDuration !== filters.stayDuration) return false;
    if (filters.gender && app.gender !== filters.gender) return false;
    
    if (filters.moveInDateFrom) {
      const fromDate = new Date(filters.moveInDateFrom);
      const appDate = new Date(app.moveInDate);
      if (appDate < fromDate) return false;
    }
    
    if (filters.moveInDateTo) {
      const toDate = new Date(filters.moveInDateTo);
      const appDate = new Date(app.moveInDate);
      if (appDate > toDate) return false;
    }

    return true;
  });

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento candidature...</p>
      </div>
    );
  }

  return (
    <div className="space-y-4 sm:space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <h2 className="text-lg sm:text-xl font-semibold">Candidature</h2>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="w-full sm:w-auto flex items-center justify-center gap-2 px-3 sm:px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          <SlidersHorizontal className="h-4 w-4" />
          {showFilters ? 'Nascondi filtri' : 'Mostra filtri'}
        </button>
      </div>

      {showFilters && (
        <div className="bg-white rounded-lg border border-gray-200 p-4 sm:p-6 space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Stato
              </label>
              <select
                value={filters.status}
                onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Tutti gli stati</option>
                <option value="pending">In attesa</option>
                <option value="accepted">Accettate</option>
                <option value="rejected">Rifiutate</option>
                <option value="expired">Scadute</option>
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Università
              </label>
              <select
                value={filters.university}
                onChange={(e) => setFilters(prev => ({ ...prev, university: e.target.value }))}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Tutte le università</option>
                {universities.map((uni, index) => (
                  <option key={`university-${uni}-${index}`} value={uni}>{uni}</option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Corso di Studi
              </label>
              <select
                value={filters.studyProgram}
                onChange={(e) => setFilters(prev => ({ ...prev, studyProgram: e.target.value }))}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Tutti i corsi</option>
                {studyPrograms.map((program, index) => (
                  <option key={`program-${program}-${index}`} value={program}>{program}</option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Anno di Studio
              </label>
              <select
                value={filters.studyYear}
                onChange={(e) => setFilters(prev => ({ ...prev, studyYear: e.target.value }))}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Tutti gli anni</option>
                {studyYears.map(year => (
                  <option key={`year-${year}`} value={year}>{year}° anno</option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Sesso
              </label>
              <select
                value={filters.gender}
                onChange={(e) => setFilters(prev => ({ ...prev, gender: e.target.value }))}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Tutti</option>
                <option value="M">Uomo</option>
                <option value="F">Donna</option>
                <option value="O">Altro</option>
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Check-in Da
              </label>
              <input
                type="date"
                value={filters.moveInDateFrom}
                onChange={(e) => setFilters(prev => ({ ...prev, moveInDateFrom: e.target.value }))}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Check-in A
              </label>
              <input
                type="date"
                value={filters.moveInDateTo}
                onChange={(e) => setFilters(prev => ({ ...prev, moveInDateTo: e.target.value }))}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Durata Soggiorno
              </label>
              <select
                value={filters.stayDuration}
                onChange={(e) => setFilters(prev => ({ ...prev, stayDuration: e.target.value }))}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Tutte le durate</option>
                {stayDurations.map((duration, index) => (
                  <option key={`duration-${duration}-${index}`} value={duration}>{duration} mesi</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-4">
        {filteredApplications.length === 0 ? (
          <div className="text-center py-8 sm:py-12">
            <Send className="h-10 w-10 sm:h-12 sm:w-12 text-gray-400 mx-auto" />
            <h3 className="mt-2 text-base sm:text-lg font-medium text-gray-900">
              Nessuna candidatura trovata
            </h3>
            <p className="mt-1 text-sm sm:text-base text-gray-500">
              Non ci sono candidature che corrispondono ai filtri selezionati
            </p>
          </div>
        ) : (
          filteredApplications.map((application) => (
            <div
              key={application.id}
              className={`transition-all duration-300 ${
                highlightedId === application.id 
                  ? 'ring-2 ring-blue-500 ring-opacity-50 shadow-lg bg-blue-50' 
                  : ''
              }`}
            >
              <ApplicationCard application={application} />
            </div>
          ))
        )}
      </div>
    </div>
  );
}