import React from 'react';
import { Building2, GraduationCap, ArrowRight, Shield, Users, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';

export function ValueProposition() {
  const features = [
    {
      icon: Shield,
      title: "Sicurezza Garantita",
      description: "Verifichiamo l'identità di tutti gli utenti per garantire transazioni sicure e affidabili.",
      color: "from-purple-400 to-purple-600",
      bgColor: "bg-purple-50",
      borderColor: "border-purple-100"
    },
    {
      icon: Users,
      title: "Match Perfetto",
      description: "Il nostro sistema aiuta a trovare la corrispondenza ideale tra studenti e proprietari.",
      color: "from-emerald-400 to-emerald-600",
      bgColor: "bg-emerald-50",
      borderColor: "border-emerald-100"
    },
    {
      icon: Clock,
      title: "Processo Veloce",
      description: "Semplifichiamo ogni fase, dalla ricerca alla prenotazione, risparmiando tempo prezioso.",
      color: "from-orange-400 to-orange-600",
      bgColor: "bg-orange-50",
      borderColor: "border-orange-100"
    }
  ];

  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-24 relative z-0">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center max-w-3xl mx-auto mb-20">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-6">
            La piattaforma che connette
            <span className="text-blue-600"> studenti</span> e
            <span className="text-blue-600"> proprietari</span>
          </h2>
          <p className="text-lg leading-8 text-gray-600">
            Unistanza.it rivoluziona il modo di trovare alloggi e inquilini, rendendo il processo 
            semplice, sicuro e personalizzato per entrambe le parti.
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-20 relative z-0">
          {features.map((feature, index) => (
            <div 
              key={index}
              className={`group relative overflow-hidden ${feature.bgColor} rounded-2xl p-8 hover:shadow-lg transition-all duration-300 border ${feature.borderColor}`}
            >
              <div className="relative z-[1]">
                <div className={`inline-flex p-3 rounded-2xl bg-gradient-to-r ${feature.color} shadow-lg mb-6`}>
                  <feature.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </div>
              <div className="absolute right-0 top-0 -mr-16 -mt-16 h-40 w-40 rounded-full bg-gradient-to-r opacity-10 group-hover:opacity-20 transition-opacity duration-300 blur-2xl z-0" />
            </div>
          ))}
        </div>

        {/* Main Cards */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Per Studenti */}
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8 hover:shadow-md transition-shadow">
            <div className="flex items-center gap-4 mb-6">
              <div className="rounded-lg bg-blue-50 p-3">
                <GraduationCap className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-2xl font-bold text-gray-900">Per studenti</h3>
            </div>
            
            <p className="text-gray-600 mb-8 min-h-[80px]">
              Trova il tuo alloggio ideale navigando tra gli annunci verificati. 
              Candidati facilmente e metti in evidenza il tuo profilo per trovare 
              la sistemazione perfetta per i tuoi studi.
            </p>
            
            <Link
              to="/search"
              className="inline-flex items-center gap-2 rounded-lg bg-blue-600 px-6 py-3 text-sm font-semibold text-white hover:bg-blue-700 transition-colors"
            >
              Cerca Alloggi
              <ArrowRight className="h-4 w-4" />
            </Link>
          </div>

          {/* Per Proprietari */}
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8 hover:shadow-md transition-shadow">
            <div className="flex items-center gap-4 mb-6">
              <div className="rounded-lg bg-blue-50 p-3">
                <Building2 className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-2xl font-bold text-gray-900">Per proprietari</h3>
            </div>
            
            <p className="text-gray-600 mb-8 min-h-[80px]">
              Pubblica il tuo annuncio e raggiungi studenti verificati. 
              Gestisci le candidature in modo efficiente e trova l'inquilino 
              perfetto per il tuo immobile.
            </p>
            
            <Link
              to="/register"
              className="inline-flex items-center gap-2 rounded-lg bg-blue-600 px-6 py-3 text-sm font-semibold text-white hover:bg-blue-700 transition-colors"
            >
              Pubblica Annuncio
              <ArrowRight className="h-4 w-4" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 