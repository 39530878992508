import { DailyEventObject } from '@daily-co/daily-js';

const DAILY_API_KEY = 'dd5c7a2b3401db9e5c283736f8f9533031cfa9d3e7b013915ad42fb034e24f33';
const DAILY_DOMAIN = 'unistanza.daily.co';

interface CreateMeetingOptions {
  roomName?: string;
  expiryMinutes?: number;
}

export async function createMeeting({ 
  roomName = crypto.randomUUID(), 
  expiryMinutes = 60 
}: CreateMeetingOptions = {}) {
  try {
    const response = await fetch(`https://api.daily.co/v1/rooms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${DAILY_API_KEY}`,
      },
      body: JSON.stringify({
        name: roomName,
        properties: {
          enable_chat: true,
          enable_screenshare: true,
          enable_knocking: false,
          start_video_off: true,
          start_audio_off: false,
          lang: 'it',
          exp: Math.round(Date.now() / 1000) + (expiryMinutes * 60)
        }
      }),
    });

    if (!response.ok) {
      throw new Error(`Errore nella creazione della stanza: ${response.status}`);
    }

    const data = await response.json();
    const meetingUrl = `https://${DAILY_DOMAIN}/${data.name}`;
    
    // Validazione URL
    if (!meetingUrl || !meetingUrl.startsWith('https://')) {
      throw new Error('URL della videochiamata non valido');
    }

    return meetingUrl;
  } catch (error) {
    console.error('Errore nella creazione della videochiamata:', error);
    toast.error('Errore nella creazione della videochiamata');
    throw error;
  }
}

export function getMeetingUrl(roomName: string): string {
  return `https://${DAILY_DOMAIN}/${roomName}`;
}

export function validateMeetingUrl(url: string): boolean {
  try {
    const meetingUrl = new URL(url);
    return meetingUrl.hostname === DAILY_DOMAIN;
  } catch {
    return false;
  }
}

export async function deleteRoom(roomName: string) {
  try {
    const response = await fetch(`https://api.daily.co/v1/rooms/${roomName}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${DAILY_API_KEY}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete Daily.co room');
    }

    return true;
  } catch (error) {
    console.error('Error deleting Daily.co room:', error);
    throw new Error('Failed to delete video meeting room');
  }
}