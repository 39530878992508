import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Listing } from '../types/listing';
import { defaultMapCenter } from '../lib/maps';
import { useLocation } from 'react-router-dom';
import { CheckCircle2 } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useApplications } from '../hooks/useApplications';

mapboxgl.accessToken = 'pk.eyJ1IjoibWFudWVsZWNvZGUiLCJhIjoiY20zMnN1dHRiMTZqcTJtczhhZm00aDJvdCJ9.lYWuN6JTJvUYtmIP0NT1ig';

interface ListingsMapProps {
  listings: Listing[];
  onListingSelect: (listing: Listing) => void;
  selectedListing: Listing | null;
  onMapMove: () => void;
  mapRef: React.MutableRefObject<mapboxgl.Map | null>;
  onMarkerClick?: () => void;
}

export function ListingsMap({ listings, onListingSelect, selectedListing, onMapMove, mapRef, onMarkerClick }: ListingsMapProps) {
  const mapContainer = useRef<HTMLDivElement>(null);
  const markersRef = useRef<{ [key: string]: mapboxgl.Marker }>({});
  const location = useLocation();
  const { user } = useAuth();
  const { applications } = useApplications();

  const hasApplied = (listingId: string) => {
    if (!user) return false;
    return applications.some(app => app.listingId === listingId);
  };

  useEffect(() => {
    if (!mapContainer.current) return;

    const locationState = location.state as { center?: { lat: number; lng: number }; zoom?: number } | null;
    const initialCenter = locationState?.center || defaultMapCenter;
    const initialZoom = locationState?.zoom || 12;

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11?language=it',
      center: [initialCenter.lng, initialCenter.lat],
      zoom: initialZoom,
      locale: {
        'NavigationControl.ZoomIn': 'Aumenta zoom',
        'NavigationControl.ZoomOut': 'Diminuisci zoom',
      }
    });

    const map = mapRef.current;

    map.on('move', onMapMove);

    // Aggiungi i controlli dello zoom
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    return () => {
      map.remove();
    };
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;

    // Rimuovi i marker che non sono più presenti
    Object.keys(markersRef.current).forEach(id => {
      if (!listings.find(l => l.id === id)) {
        markersRef.current[id].remove();
        delete markersRef.current[id];
      }
    });

    // Aggiungi o aggiorna i marker
    listings.forEach(listing => {
      const { lat, lng } = listing.location;
      if (!lat || !lng) return;

      // Crea l'elemento HTML per il marker
      const el = document.createElement('div');
      el.className = 'marker';
      
      // Aggiungi l'icona se l'utente si è candidato
      const appliedIcon = hasApplied(listing.id) 
        ? '<span class="applied-icon">✓</span>' 
        : '';
      
      el.innerHTML = `
        <div class="marker-content ${selectedListing?.id === listing.id ? 'selected' : ''}">
          <span class="price">€${listing.price}</span>
          ${appliedIcon}
        </div>
      `;

      // Aggiungi l'evento click
      el.addEventListener('click', () => {
        onListingSelect(listing);
        if (onMarkerClick) {
          onMarkerClick();
        }
      });

      // Crea o aggiorna il marker
      if (markersRef.current[listing.id]) {
        markersRef.current[listing.id]
          .setLngLat([lng, lat])
          .getElement().innerHTML = el.innerHTML;
      } else {
        markersRef.current[listing.id] = new mapboxgl.Marker(el)
          .setLngLat([lng, lat])
          .addTo(mapRef.current);
      }
    });
  }, [listings, selectedListing, applications, onListingSelect, onMarkerClick]);

  // Center map on selected marker
  useEffect(() => {
    if (selectedListing?.location?.coordinates && mapRef.current) {
      const [lng, lat] = selectedListing.location.coordinates;
      mapRef.current.flyTo({
        center: [lng, lat],
        zoom: 15,
        duration: 1000
      });
    }
  }, [selectedListing]);

  return (
    <div ref={mapContainer} className="h-full w-full" />
  );
}