import React from 'react';
import { FileText, Euro, Clock, Calendar, Plug } from 'lucide-react';
import { Listing, contractLabels, utilitiesLabels } from '../types/listing';

interface ContractDetailsProps {
  listing: Listing;
}

export function ContractDetails({ listing }: ContractDetailsProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-6">Dettagli Contratto e Spese</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <FileText className="h-5 w-5 text-blue-600" />
          </div>
          <div>
            <p className="text-sm text-gray-500">Tipo Contratto</p>
            <p className="font-medium">{contractLabels[listing.contractType]}</p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div className="p-2 bg-green-50 rounded-lg">
            <Clock className="h-5 w-5 text-green-600" />
          </div>
          <div>
            <p className="text-sm text-gray-500">Permanenza minima</p>
            <p className="font-medium">{listing.minStay} mesi</p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div className="p-2 bg-purple-50 rounded-lg">
            <Calendar className="h-5 w-5 text-purple-600" />
          </div>
          <div>
            <p className="text-sm text-gray-500">Preavviso di rilascio</p>
            <p className="font-medium">{listing.noticePeriod} mesi</p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div className="p-2 bg-yellow-50 rounded-lg">
            <Plug className="h-5 w-5 text-yellow-600" />
          </div>
          <div>
            <p className="text-sm text-gray-500">Spese</p>
            <p className="font-medium">
              {listing.utilities.type === 'included' ? (
                'Incluse nel prezzo'
              ) : (
                <>
                  Non incluse
                  {listing.utilities.monthlyFee > 0 && (
                    <span className="text-gray-500 ml-1">
                      (€{listing.utilities.monthlyFee}/mese stimate)
                    </span>
                  )}
                </>
              )}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div className="p-2 bg-red-50 rounded-lg">
            <Euro className="h-5 w-5 text-red-600" />
          </div>
          <div>
            <p className="text-sm text-gray-500">Totale mensile</p>
            <p className="font-medium">
              €{listing.price}
              {listing.utilities.type === 'notIncluded' && listing.utilities.monthlyFee > 0 && (
                <span className="text-gray-500">
                  {' '}+ €{listing.utilities.monthlyFee} di spese
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}