import React from 'react';
import { MapPin, Building2, Euro, Calendar, Users, Bath, Clock, Info, ChevronDown } from 'lucide-react';
import { PlacesAutocomplete } from '../../PlacesAutocomplete';
import { categoryLabels, contractLabels, heatingLabels } from '../../../types/listing';
import { toast } from 'react-toastify';

interface BasicInfoStepProps {
  formData: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | { name: string; value: any }) => void;
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
  setIsStepValid: (isValid: boolean) => void;
}

export function BasicInfoStep({ formData, onChange, onPlaceSelect, setIsStepValid }: BasicInfoStepProps) {
  const getMaxOccupants = (category: string): string => {
    switch (category) {
      case 'single_room': return '1';
      case 'double_room': return '2';
      case 'triple_room': return '3';
      case 'quad_room': return '4';
      default: return '1';
    }
  };

  const handleNumericChange = (name: string, increment: boolean) => {
    const currentValue = parseInt(formData[name]) || 0;
    let newValue = increment ? currentValue + 1 : currentValue - 1;

    // Gestione speciale per maxOccupants
    if (name === 'maxOccupants') {
      const minOccupantsByCategory = {
        single_room: 1,
        double_room: 2,
        triple_room: 3,
        quad_room: 4
      };
      const minOccupants = minOccupantsByCategory[formData.category as keyof typeof minOccupantsByCategory];
      
      if (newValue < minOccupants) {
        toast.error(`Il numero totale di inquilini non può essere inferiore a ${minOccupants} per una ${categoryLabels[formData.category].toLowerCase()}`);
        return;
      }
    }

    // Gestione speciale per bathrooms
    if (name === 'bathrooms') {
      if (newValue < 1) {
        toast.error('Il numero di bagni non può essere inferiore a 1');
        return;
      }
    }

    // Gestione speciale per size (superficie)
    if (name === 'size') {
      if (newValue < 1) {
        toast.error('La superficie non può essere inferiore a 1 m²');
        return;
      }
    }

    onChange({ target: { name, value: newValue.toString() } });
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    
    if (name === 'category') {
      onChange({
        name,
        value,
        availableSpots: getMaxOccupants(value as ListingCategory)
      });
    } else {
      onChange({
        name,
        value
      });
    }
  };

  const validateStep = React.useEffect(() => {
    const requiredFields = [
      formData.category,
      formData.contractType,
      formData.price,
      formData.location?.formattedAddress || formData.location,
      formData.floor,
      formData.size,
      formData.maxOccupants,
      formData.bathrooms,
      formData.rooms,
      formData.availableFrom,
      formData.minStay,
      formData.noticePeriod
    ];

    const isValid = requiredFields.every(field => {
      // Gestione speciale per i numeri, incluso 0
      if (typeof field === 'number') return true;
      // Per le stringhe e altri tipi
      return field !== undefined && field !== '' && field !== null;
    });

    setIsStepValid(isValid);
  }, [formData, setIsStepValid]);

  React.useEffect(() => {
    const minOccupantsByCategory = {
      single_room: 1,
      double_room: 2,
      triple_room: 3,
      quad_room: 4
    };

    const minOccupants = minOccupantsByCategory[formData.category as keyof typeof minOccupantsByCategory];
    const currentOccupants = parseInt(formData.maxOccupants) || 0;

    if (currentOccupants < minOccupants) {
      onChange({ 
        target: { 
          name: 'maxOccupants', 
          value: minOccupants.toString() 
        } 
      });
    }
  }, [formData.category]);

  const handleSizeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Rimuove tutto ciò che non è un numero
    
    if (value === '') {
      onChange({ target: { name: 'size', value: '' } });
      return;
    }

    const numValue = parseInt(value);
    if (numValue < 1) {
      toast.error('La superficie non può essere inferiore a 1 m²');
      return;
    }

    onChange({ target: { name: 'size', value } });
  };

  const NumericInput = ({ name, value, label, min = 0 }: { 
    name: string; 
    value: string; 
    label: string;
    min?: number;
  }) => (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <div className="flex items-center">
        <button
          type="button"
          onClick={() => handleNumericChange(name, false)}
          className="w-10 h-10 flex items-center justify-center bg-gray-50 hover:bg-gray-100 text-gray-600 rounded-l-lg border border-gray-300 hover:border-gray-400 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
          disabled={parseInt(value) <= min}
        >
          <span className="text-lg font-medium">−</span>
        </button>
        <div className="h-10 px-4 flex items-center justify-center min-w-[60px] border-t border-b border-gray-300 bg-white text-gray-900 font-medium">
          {value}
        </div>
        <button
          type="button"
          onClick={() => handleNumericChange(name, true)}
          className="w-10 h-10 flex items-center justify-center bg-gray-50 hover:bg-gray-100 text-gray-600 rounded-r-lg border border-gray-300 hover:border-gray-400 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <span className="text-lg font-medium">+</span>
        </button>
      </div>
    </div>
  );

  const generateFloorOptions = () => {
    const options = [
      { value: 'PT', label: 'Piano terra' },
      { value: 'PR', label: 'Piano rialzato' }
    ];

    // Aggiungi i piani numerati da 1 a 30
    for (let i = 1; i <= 30; i++) {
      options.push({
        value: i.toString(),
        label: `${i}° Piano`
      });
    }

    return options;
  };

  // Componente wrapper per i campi select
  const SelectField = ({ label, name, value, onChange, options }: {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    options: { value: string; label: string; }[];
  }) => (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <div className="relative">
        <select
          name={name}
          value={value}
          onChange={onChange}
          className="h-10 w-full px-4 pr-12 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 appearance-none"
          required
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
          <ChevronDown className="h-5 w-5 text-gray-400" />
        </div>
      </div>
    </div>
  );

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    onChange({
      target: {
        name: 'location',
        value: {
          formattedAddress: place.formatted_address,
          lat: place.geometry?.location?.lat(),
          lng: place.geometry?.location?.lng(),
          streetNumber: place.address_components?.find(c => c.types.includes('street_number'))?.long_name,
          route: place.address_components?.find(c => c.types.includes('route'))?.long_name,
          locality: place.address_components?.find(c => c.types.includes('locality'))?.long_name,
          area: place.address_components?.find(c => c.types.includes('administrative_area_level_1'))?.long_name,
          country: place.address_components?.find(c => c.types.includes('country'))?.long_name,
        }
      }
    });
  };

  return (
    <div className="space-y-8">
      {/* Main Info Card */}
      <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl p-8 text-white shadow-xl">
        <h3 className="text-xl font-semibold text-white/90 mb-6">Informazioni Principali</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-white/90 mb-2">
              Tipologia Alloggio
            </label>
            <div className="relative">
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                disabled={!!formData.id}
                className={`h-10 w-full px-4 pr-10 rounded-lg border border-white/20 bg-white/10 text-white placeholder-white/60 focus:ring-2 focus:ring-white/50 focus:border-transparent backdrop-blur-sm appearance-none ${
                  formData.id ? 'opacity-60 cursor-not-allowed' : ''
                }`}
              >
                {Object.entries(categoryLabels).map(([value, label]) => (
                  <option key={value} value={value} className="text-gray-900">{label}</option>
                ))}
              </select>
              {!formData.id && (
                <div className="absolute top-1/2 right-3 -translate-y-1/2 pointer-events-none">
                  <ChevronDown className="h-5 w-5 text-white/70" />
                </div>
              )}
              {formData.id && (
                <p className="mt-1 text-xs text-white/70">
                  La tipologia di stanza non può essere modificata dopo la creazione
                </p>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-white/90 mb-2">
              Tipo Contratto
            </label>
            <div className="relative">
              <select
                name="contractType"
                value={formData.contractType}
                onChange={handleChange}
                className="h-10 w-full px-4 pr-12 rounded-lg border border-white/20 bg-white/10 text-white placeholder-white/60 focus:ring-2 focus:ring-white/50 focus:border-transparent backdrop-blur-sm appearance-none"
              >
                {Object.entries(contractLabels).map(([value, label]) => (
                  <option key={value} value={value} className="text-gray-900">{label}</option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                <ChevronDown className="h-5 w-5 text-white/70" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Price Card */}
      <div className="bg-white rounded-2xl p-8 shadow-xl border border-gray-100">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-green-100 rounded-lg">
            <Euro className="h-5 w-5 text-green-600" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900">Prezzo</h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Canone Mensile (€)
            </label>
            <div className="relative">
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="h-10 w-full pl-8 pr-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                required
              />
              <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">€</span>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Cauzione (€)
            </label>
            <div className="relative">
              <input
                type="number"
                name="deposit"
                value={formData.deposit}
                onChange={handleChange}
                className="h-10 w-full pl-8 pr-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                required
              />
              <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">€</span>
            </div>
          </div>
        </div>
      </div>

      {/* Location Card */}
      <div className="bg-white rounded-2xl p-8 shadow-xl border border-gray-100">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-red-100 rounded-lg">
            <MapPin className="h-5 w-5 text-red-600" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900">Indirizzo</h3>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Indirizzo dell'immobile
          </label>
          <div className="relative">
            <PlacesAutocomplete 
              onPlaceSelect={handlePlaceSelect}
              defaultValue={formData.location?.formattedAddress}
              placeholder="Inserisci l'indirizzo dell'immobile"
              className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              required
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
              <MapPin className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      {/* Details Card */}
      <div className="bg-white rounded-2xl p-8 shadow-xl border border-gray-100">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-blue-100 rounded-lg">
            <Building2 className="h-5 w-5 text-blue-600" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900">Dettagli Immobile</h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Property Details */}
          <div className="space-y-6">
            <SelectField
              label="Piano"
              name="floor"
              value={formData.floor}
              onChange={handleChange}
              options={[
                { value: '', label: 'Seleziona piano' },
                ...generateFloorOptions()
              ]}
            />

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Superficie (m²)
              </label>
              <div className="relative">
                <input
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="size"
                  value={formData.size}
                  onChange={handleSizeInput}
                  className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  required
                  min="1"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                  <span className="text-gray-500">m²</span>
                </div>
              </div>
            </div>

            <SelectField
              label="Riscaldamento"
              name="heating"
              value={formData.heating}
              onChange={handleChange}
              options={Object.entries(heatingLabels).map(([value, label]) => ({
                value,
                label
              }))}
            />
          </div>

          {/* Occupancy Details */}
          <div className="space-y-6">
            <NumericInput
              name="maxOccupants"
              value={formData.maxOccupants}
              label="Numero totale inquilini"
            />
            <NumericInput
              name="bathrooms"
              value={formData.bathrooms}
              label="Numero bagni"
              min={1}
            />
          </div>

          {/* Room Details */}
          <div className="space-y-6">
            <NumericInput
              name="rooms"
              value={formData.rooms}
              label="Numero stanze nell'immobile"
            />
          </div>
        </div>
      </div>

      {/* Availability Card */}
      <div className="bg-white rounded-2xl p-8 shadow-xl border border-gray-100">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-orange-100 rounded-lg">
            <Calendar className="h-5 w-5 text-orange-600" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900">Disponibilità e Durata</h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Disponibile dal</label>
            <input
              type="date"
              name="availableFrom"
              value={formData.availableFrom}
              onChange={handleChange}
              min={new Date().toISOString().split('T')[0]}
              className="h-10 w-full px-4 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <NumericInput
            name="minStay"
            value={formData.minStay}
            label="Permanenza minima (mesi)"
          />

          <NumericInput
            name="noticePeriod"
            value={formData.noticePeriod}
            label="Preavviso di rilascio (mesi)"
          />
        </div>
      </div>
    </div>
  );
}