import React, { useState } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

interface FloorPlanModalProps {
  floorPlans: string[];
  onClose: () => void;
}

export function FloorPlanModal({ floorPlans, onClose }: FloorPlanModalProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev === 0 ? floorPlans.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === floorPlans.length - 1 ? 0 : prev + 1));
  };

  const isPDF = (url: string) => url.toLowerCase().endsWith('.pdf');

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-hidden relative">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h3 className="text-lg font-medium">
            Planimetria {floorPlans.length > 1 ? `${currentIndex + 1}/${floorPlans.length}` : ''}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 p-2 rounded-full hover:bg-gray-100"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="relative flex items-center justify-center bg-gray-100 p-4" style={{ height: 'calc(90vh - 70px)' }}>
          {floorPlans.length > 1 && (
            <>
              <button
                onClick={handlePrevious}
                className="absolute left-4 p-2 rounded-full bg-white shadow-md hover:bg-gray-50 z-10"
              >
                <ChevronLeft className="h-6 w-6" />
              </button>
              <button
                onClick={handleNext}
                className="absolute right-4 p-2 rounded-full bg-white shadow-md hover:bg-gray-50 z-10"
              >
                <ChevronRight className="h-6 w-6" />
              </button>
            </>
          )}

          <div className="w-full h-full flex items-center justify-center">
            {isPDF(floorPlans[currentIndex]) ? (
              <iframe
                src={floorPlans[currentIndex]}
                className="w-full h-full"
                title="Floor Plan PDF"
              />
            ) : (
              <img
                src={floorPlans[currentIndex]}
                alt={`Floor Plan ${currentIndex + 1}`}
                className="max-w-full max-h-full object-contain"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}