import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getListing, updateListing, deleteListing } from '../../lib/listings';
import { toast } from 'react-toastify';
import { ListingForm } from '../../components/listings/ListingForm';
import { useAuthContext } from '../../contexts/AuthContext';
import { Listing } from '../../types/listing';
import { ArrowLeft } from 'lucide-react';

export function EditListing() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { userProfile } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [listing, setListing] = useState<Listing | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadListing = async () => {
      if (!id || !userProfile) {
        navigate('/dashboard/landlord');
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const data = await getListing(id);
        
        if (!data) {
          setError('Annuncio non trovato');
          toast.error('Annuncio non trovato');
          navigate('/dashboard/landlord');
          return;
        }

        if (data.userId !== userProfile.id) {
          setError('Non hai i permessi per modificare questo annuncio');
          toast.error('Non hai i permessi per modificare questo annuncio');
          navigate('/dashboard/landlord');
          return;
        }

        setListing(data);
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Errore durante il caricamento dell\'annuncio';
        setError(message);
        toast.error(message);
        navigate('/dashboard/landlord');
      } finally {
        setLoading(false);
      }
    };

    loadListing();
  }, [id, navigate, userProfile]);

  const handleSubmit = async (formData: Partial<Listing>) => {
    if (!id || !listing) return;

    try {
      setLoading(true);
      setError(null);
      await updateListing(id, {
        ...formData,
        updatedAt: new Date()
      });
      toast.success('Annuncio aggiornato con successo');
      navigate('/dashboard/landlord');
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Errore durante l\'aggiornamento dell\'annuncio';
      setError(message);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!id || !listing) return;
    
    if (!window.confirm('Sei sicuro di voler eliminare questo annuncio?')) {
      return;
    }

    try {
      setLoading(true);
      setError(null);
      await deleteListing(id);
      toast.success('Annuncio eliminato con successo');
      navigate('/dashboard/landlord');
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Errore durante l\'eliminazione dell\'annuncio';
      setError(message);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !listing) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24 flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error || 'Annuncio non trovato'}</p>
          <button
            onClick={() => navigate('/dashboard/landlord')}
            className="text-blue-600 hover:text-blue-700"
          >
            Torna alla dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <button
          onClick={() => navigate('/dashboard/landlord')}
          className="flex items-center text-gray-600 hover:text-gray-900 mb-6"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Torna alla dashboard
        </button>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">Modifica Annuncio</h1>
          </div>
          
          <ListingForm
            initialData={listing}
            onSubmit={handleSubmit}
            onCancel={() => navigate('/dashboard/landlord')}
            loading={loading}
            onDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}