import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { PrivateRoute } from './components/PrivateRoute';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { CookieConsent } from './components/CookieConsent';
import { Home } from './pages/Home';
import { Search } from './pages/Search';
import { Shop } from './pages/Shop';
import { Community } from './pages/Community';
import { ListingDetail } from './pages/ListingDetail';
import { Login } from './pages/auth/Login';
import { Register } from './pages/auth/Register';
import { StudentDashboard } from './components/dashboard/student/StudentDashboard';
import { LandlordDashboard } from './components/dashboard/landlord/LandlordDashboard';
import { CreateListing } from './pages/listings/CreateListing';
import { EditListing } from './pages/listings/EditListing';
import { ListingAnalyticsDetail } from './components/dashboard/landlord/ListingAnalyticsDetail';
import { ChatPage } from './pages/chat/ChatPage';
import { VideoCall } from './pages/VideoCall'; // Add this import
import { ToastContainer } from 'react-toastify';
import { trackVisitor } from './lib/analytics/visitors';
import 'react-toastify/dist/ReactToastify.css';
import { setupExpirationCheck } from './lib/applications';

function App() {
  useEffect(() => {
    // Track visitor on initial load
    trackVisitor();

    // Update visitor tracking on route changes
    const updateVisitor = () => trackVisitor();
    window.addEventListener('popstate', updateVisitor);
    
    const unsubscribe = setupExpirationCheck();
    return () => {
      window.removeEventListener('popstate', updateVisitor);
      unsubscribe();
    };
  }, []);

  return (
    <AuthProvider>
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<Search />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/community" element={<Community />} />
            <Route path="/listing/:id" element={<ListingDetail />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route 
              path="/dashboard/student" 
              element={
                <PrivateRoute requiredUserType="student">
                  <StudentDashboard />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/dashboard/landlord" 
              element={
                <PrivateRoute requiredUserType="landlord">
                  <LandlordDashboard />
                </PrivateRoute>
              } 
            />
            <Route
              path="/listings/create"
              element={
                <PrivateRoute requiredUserType="landlord">
                  <CreateListing />
                </PrivateRoute>
              }
            />
            <Route
              path="/listings/:id/edit"
              element={
                <PrivateRoute requiredUserType="landlord">
                  <EditListing />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/landlord/analytics/:listingId"
              element={
                <PrivateRoute requiredUserType="landlord">
                  <ListingAnalyticsDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/chat/:chatId?"
              element={
                <PrivateRoute>
                  <ChatPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/meeting/:meetingId"
              element={
                <PrivateRoute>
                  <VideoCall />
                </PrivateRoute>
              }
            />
          </Routes>
        </main>
        <Footer />
        <CookieConsent />
        <ToastContainer position="bottom-right" />
      </div>
    </AuthProvider>
  );
}

export default App;