import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut as firebaseSignOut,
  updateEmail,
  updatePassword,
  deleteUser,
  User,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail
} from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, deleteDoc, runTransaction } from 'firebase/firestore';
import { auth, db } from './firebase';
import { UserProfile } from '../types/user';
import { toast } from 'react-toastify';

export async function getUserProfile(user: User): Promise<UserProfile | null> {
  if (!user) return null;

  try {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      return null;
    }

    return {
      id: docSnap.id,
      ...docSnap.data()
    } as UserProfile;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
}

export async function updateUserProfile(userId: string, data: Partial<UserProfile>): Promise<void> {
  if (!userId) {
    throw new Error('User ID is required');
  }

  try {
    await runTransaction(db, async (transaction) => {
      const userRef = doc(db, 'users', userId);
      const userDoc = await transaction.get(userRef);

      if (!userDoc.exists()) {
        throw new Error('User profile not found');
      }

      const currentData = userDoc.data();
      const updatedData = {
        ...currentData,
        ...data,
        updatedAt: new Date()
      };

      if (!updatedData.email || !updatedData.userType) {
        throw new Error('Required fields are missing');
      }

      if (updatedData.userType === 'student' && updatedData.studentInfo) {
        const { birthDate, university, studyProgram, studyYear } = updatedData.studentInfo;
        if (!birthDate || !university || !studyProgram || !studyYear) {
          throw new Error('Required student information is missing');
        }
      }

      transaction.update(userRef, updatedData);
    });

    toast.success('Profilo aggiornato con successo');
  } catch (error: any) {
    console.error('Error updating profile:', error);
    toast.error(error.message || 'Errore durante l\'aggiornamento del profilo');
    throw error;
  }
}

export async function signInWithEmail(email: string, password: string) {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    const userProfile = await getUserProfile(result.user);
    return { user: result.user, userProfile };
  } catch (error: any) {
    console.error('Email sign-in error:', error);
    throw error;
  }
}

export async function signInWithGoogle(userType?: 'student' | 'landlord') {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    
    let userProfile = await getUserProfile(result.user);
    
    // If no profile exists, create one
    if (!userProfile && userType) {
      userProfile = {
        id: result.user.uid,
        email: result.user.email!,
        displayName: result.user.displayName || '',
        userType,
        isAdmin: false,
        createdAt: new Date(),
        ...(userType === 'student' ? {
          studentInfo: {
            credits: 0
          }
        } : {
          landlordInfo: {
            credits: 0,
            listingsCount: 0,
            verificationStatus: 'pending'
          }
        })
      };
      
      await setDoc(doc(db, 'users', result.user.uid), userProfile);
    }

    return { user: result.user, userProfile };
  } catch (error: any) {
    console.error('Google sign-in error:', error);
    throw error;
  }
}

export async function signUpWithEmail(email: string, password: string, userType: 'student' | 'landlord') {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    
    const userProfile: UserProfile = {
      id: result.user.uid,
      email,
      displayName: '',
      userType,
      isAdmin: false,
      createdAt: new Date(),
      ...(userType === 'student' ? {
        studentInfo: {
          credits: 0
        }
      } : {
        landlordInfo: {
          credits: 0,
          listingsCount: 0,
          verificationStatus: 'pending'
        }
      })
    };
    
    await setDoc(doc(db, 'users', result.user.uid), userProfile);
    return { user: result.user, userProfile };
  } catch (error: any) {
    console.error('Email sign-up error:', error);
    throw error;
  }
}

export async function signOut() {
  try {
    await firebaseSignOut(auth);
  } catch (error: any) {
    console.error('Sign-out error:', error);
    throw error;
  }
}

export async function deleteUserAccount(password?: string) {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('No user logged in');
  }

  try {
    // Re-authenticate if password provided
    if (password) {
      const credential = EmailAuthProvider.credential(user.email!, password);
      await reauthenticateWithCredential(user, credential);
    }

    // Delete user data from Firestore
    await deleteDoc(doc(db, 'users', user.uid));

    // Delete user authentication
    await deleteUser(user);

    toast.success('Account deleted successfully');
  } catch (error: any) {
    console.error('Error deleting account:', error);
    throw error;
  }
}

export async function updateUserEmail(newEmail: string, password: string) {
  const user = auth.currentUser;
  if (!user || !user.email) {
    throw new Error('No user logged in');
  }

  try {
    // Re-authenticate user
    const credential = EmailAuthProvider.credential(user.email, password);
    await reauthenticateWithCredential(user, credential);

    // Update email
    await updateEmail(user, newEmail);
    
    // Update profile in Firestore
    await updateDoc(doc(db, 'users', user.uid), {
      email: newEmail,
      updatedAt: new Date()
    });

    toast.success('Email updated successfully');
  } catch (error: any) {
    console.error('Error updating email:', error);
    throw error;
  }
}

export async function updateUserPassword(currentPassword: string, newPassword: string) {
  const user = auth.currentUser;
  if (!user || !user.email) {
    throw new Error('No user logged in');
  }

  try {
    // Re-authenticate user
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    await reauthenticateWithCredential(user, credential);

    // Update password
    await updatePassword(user, newPassword);

    toast.success('Password updated successfully');
  } catch (error: any) {
    console.error('Error updating password:', error);
    throw error;
  }
}

export async function sendVerificationEmailToUser() {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('No user logged in');
  }

  try {
    await sendEmailVerification(user);
    toast.success('Verification email sent');
  } catch (error: any) {
    console.error('Error sending verification email:', error);
    throw error;
  }
}

export async function sendPasswordResetEmailToUser(email: string) {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success('Password reset email sent');
  } catch (error: any) {
    console.error('Error sending password reset email:', error);
    throw error;
  }
}

export async function requireAdmin(): Promise<void> {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('Utente non autenticato');
  }

  const userDoc = await getDoc(doc(db, 'users', user.uid));
  if (!userDoc.exists() || userDoc.data().userType !== 'admin') {
    throw new Error('Permessi insufficienti');
  }
}