import React from 'react';
import { X, AlertTriangle } from 'lucide-react';
import { ChatWindow } from './ChatWindow';
import { useAuthContext } from '../../contexts/AuthContext';

interface ChatModalProps {
  chatId: string;
  otherPartyName: string;
  onClose: () => void;
}

export function ChatModal({ chatId, otherPartyName, onClose }: ChatModalProps) {
  const { userProfile } = useAuthContext();
  const isStudent = userProfile?.userType === 'student';

  return (
    <>
      <div 
        className="fixed inset-0 bg-black/50 z-50 backdrop-blur-sm"
        onClick={onClose}
      />
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <div 
          className="bg-white rounded-2xl w-full max-w-2xl h-[80vh] flex flex-col shadow-xl"
          onClick={e => e.stopPropagation()}
        >
          <div className="p-4 border-b border-gray-100 flex justify-between items-center">
            <h3 className="font-medium text-gray-900">{otherPartyName}</h3>
            <button 
              onClick={onClose} 
              className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="p-3 bg-blue-50 border-b border-blue-100">
            <div className="flex items-start gap-2 text-sm text-blue-700">
              <AlertTriangle className="h-4 w-4 mt-0.5 flex-shrink-0" />
              <p>
                {isStudent ? (
                  "L'accettazione della candidatura non garantisce l'assegnazione dell'alloggio. La decisione finale di affittare è a discrezione del proprietario."
                ) : (
                  "L'accettazione della candidatura da parte dello studente non costituisce un obbligo di prendere in affitto l'immobile."
                )}
              </p>
            </div>
          </div>

          <div className="flex-1 overflow-hidden">
            <ChatWindow chatId={chatId} otherPartyName={otherPartyName} />
          </div>
        </div>
      </div>
    </>
  );
}