import React from 'react';
import { X, User, GraduationCap, MapPin, Calendar, Clock, Globe } from 'lucide-react';
import { Application } from '../../../types/application';

interface ApplicationDataViewModalProps {
  application: Application;
  onClose: () => void;
}

export function ApplicationDataViewModal({ application, onClose }: ApplicationDataViewModalProps) {
  const calculateAge = (birthDate: string | Date) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    
    return age;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl w-full max-w-2xl max-h-[90vh] flex flex-col shadow-xl">
        {/* Header */}
        <div className="flex-shrink-0 p-6 border-b border-gray-100">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-gray-900">Dati Candidatura</h2>
            <button 
              onClick={onClose} 
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>
        </div>

        {/* Content - Scrollable */}
        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          {/* Personal Info */}
          <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl p-6">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-blue-200 rounded-lg">
                <User className="h-5 w-5 text-blue-700" />
              </div>
              <h3 className="text-lg font-semibold text-blue-900">
                Informazioni Personali
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                <User className="h-5 w-5 text-blue-600" />
                <div>
                  <p className="text-sm text-blue-900 font-medium">Sesso ed Età</p>
                  <p className="text-sm text-blue-700">
                    {application.gender === 'M' ? 'Uomo' : 
                     application.gender === 'F' ? 'Donna' : 
                     'Altro'} • {calculateAge(application.birthDate)} anni
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                <Globe className="h-5 w-5 text-blue-600" />
                <div>
                  <p className="text-sm text-blue-900 font-medium">Cittadinanza</p>
                  <p className="text-sm text-blue-700">{application.nationality}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Academic Info */}
          <div className="bg-gradient-to-br from-purple-50 to-purple-100 rounded-xl p-6">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-purple-200 rounded-lg">
                <GraduationCap className="h-5 w-5 text-purple-700" />
              </div>
              <h3 className="text-lg font-semibold text-purple-900">
                Informazioni Accademiche
              </h3>
            </div>
            <div className="space-y-4">
              <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                <GraduationCap className="h-5 w-5 text-purple-600" />
                <div>
                  <p className="text-sm text-purple-900 font-medium">Università</p>
                  <p className="text-sm text-purple-700">{application.university}</p>
                </div>
              </div>
              <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                <GraduationCap className="h-5 w-5 text-purple-600" />
                <div>
                  <p className="text-sm text-purple-900 font-medium">Corso di Studi</p>
                  <p className="text-sm text-purple-700">
                    {application.studyProgram} - {application.studyYear}° anno
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Location Info */}
          <div className="bg-gradient-to-br from-green-50 to-green-100 rounded-xl p-6">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-green-200 rounded-lg">
                <MapPin className="h-5 w-5 text-green-700" />
              </div>
              <h3 className="text-lg font-semibold text-green-900">
                Provenienza
              </h3>
            </div>
            <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
              <MapPin className="h-5 w-5 text-green-600" />
              <div>
                <p className="text-sm text-green-900 font-medium">Residenza</p>
                <p className="text-sm text-green-700">
                  {application.residenceCity}, {application.residenceCountry}
                </p>
              </div>
            </div>
          </div>

          {/* Stay Details */}
          <div className="bg-gradient-to-br from-orange-50 to-orange-100 rounded-xl p-6">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-orange-200 rounded-lg">
                <Calendar className="h-5 w-5 text-orange-700" />
              </div>
              <h3 className="text-lg font-semibold text-orange-900">
                Dettagli Soggiorno
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                <Calendar className="h-5 w-5 text-orange-600" />
                <div>
                  <p className="text-sm text-orange-900 font-medium">Check-in</p>
                  <p className="text-sm text-orange-700">
                    {new Date(application.moveInDate).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                <Clock className="h-5 w-5 text-orange-600" />
                <div>
                  <p className="text-sm text-orange-900 font-medium">Durata</p>
                  <p className="text-sm text-orange-700">
                    {application.stayDuration} mesi
                  </p>
                </div>
              </div>
            </div>
          </div>

          {application.additionalNotes && (
            <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-xl p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Note Aggiuntive
              </h3>
              <p className="text-gray-700 whitespace-pre-wrap">
                {application.additionalNotes}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}