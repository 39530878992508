import React, { useState, useEffect } from 'react';
import {
  Building2,
  Calendar,
  Clock,
  MessageSquare,
  AlertTriangle,
  CheckCircle,
  XCircle,
  Timer,
  Video,
  Info,
  User,
  Mail,
  Phone,
  FileText,
  MapPin,
  Settings
} from 'lucide-react';
import { Application } from '../../../types/application';
import { ExpirationTimer } from '../ExpirationTimer';
import { ChatModal } from '../../chat/ChatModal';
import { useUnreadMessages } from '../../../hooks/useChat';
import { generateNumericId } from '../../../utils/ids';
import { isApplicationExpired } from '../../../lib/dates';
import { BookMeetingButton } from '../../meetings/BookMeetingButton';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { onMeetingChange, generateMeetingUrl } from '../../../lib/meetings';
import { Timestamp } from 'firebase/firestore';
import { ApplicationDataViewModal } from './ApplicationDataViewModal';
import { ManageMeetingModal } from '../../../components/meetings/ManageMeetingModal';
import { toast } from 'react-toastify';
import { ListingDataViewModal } from '../../../components/listings/ListingDataViewModal';
import { BookMeetingModal } from '../../../components/meetings/BookMeetingModal';

interface ApplicationCardProps {
  application: Application;
}

export function ApplicationCard({ application }: ApplicationCardProps) {
  const [showChatModal, setShowChatModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showListingDataModal, setShowListingDataModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const { unreadCount } = useUnreadMessages(application.chatId);
  const [meeting, setMeeting] = useState<Meeting | null>(null);

  const landlordId = application.landlordId || application.listingOwnerId;
  const canBookMeeting = application.status === 'accepted' && Boolean(landlordId);

  useEffect(() => {
    if (!application.id) return;

    const unsubscribe = onMeetingChange(application.id, (updatedMeeting) => {
      setMeeting(updatedMeeting);
    });

    return () => unsubscribe();
  }, [application.id]);

  const handleJoinMeeting = (e: React.MouseEvent) => {
    e.preventDefault();
    
    if (!meeting) {
      toast.error('Nessuna videochiamata programmata');
      return;
    }

    try {
      const meetingUrl = generateMeetingUrl(meeting.id);
      window.location.href = meetingUrl;
    } catch (error) {
      console.error('Error opening video call:', error);
      toast.error('Errore nell\'apertura della videochiamata');
    }
  };

  const formatMeetingDate = (meeting: Meeting | null) => {
    if (!meeting?.date) return null;
    
    try {
      const meetingDate = meeting.date instanceof Timestamp 
        ? meeting.date.toDate() 
        : new Date(meeting.date);
        
      return format(meetingDate, "d MMMM yyyy 'alle' HH:mm", { locale: it });
    } catch (error) {
      console.error('Error formatting meeting date:', error);
      return null;
    }
  };

  const renderMeetingButton = () => {
    if (!canBookMeeting) return null;

    return (
      <BookMeetingButton
        applicationId={application.id}
        listingId={application.listingId}
        listingTitle={application.listingTitle}
        studentName={application.studentName}
        studentEmail={application.studentEmail}
        landlordId={landlordId}
        existingMeeting={meeting}
      />
    );
  };

  return (
    <>
      <div className={`bg-white rounded-xl shadow-sm overflow-hidden border-2 ${
        application.status === 'accepted' ? 'border-green-500' :
        application.status === 'rejected' ? 'border-red-500' :
        application.status === 'expired' || isApplicationExpired(application.createdAt) ? 'border-gray-500' :
        'border-yellow-500'
      }`}>
        {/* Card Header aggiornato */}
        <div className="p-4 sm:p-6 border-b border-gray-100">
          <div className="flex flex-col gap-3">
            {/* Status Badge spostato in alto */}
            <span className={`self-start inline-flex items-center gap-1.5 px-2.5 py-1 sm:px-3 sm:py-1.5 rounded-full text-xs sm:text-sm font-medium ${
              application.status === 'accepted' ? 'bg-green-100 text-green-700' :
              application.status === 'rejected' ? 'bg-red-100 text-red-700' :
              application.status === 'expired' || isApplicationExpired(application.createdAt) ? 'bg-gray-100 text-gray-700' :
              'bg-yellow-100 text-yellow-700'
            }`}>
              {application.status === 'accepted' && <CheckCircle className="h-4 w-4" />}
              {application.status === 'rejected' && <XCircle className="h-4 w-4" />}
              {application.status === 'expired' && <Timer className="h-4 w-4" />}
              {application.status === 'pending' && <AlertTriangle className="h-4 w-4" />}
              {application.status === 'accepted' ? 'Accettata' :
               application.status === 'rejected' ? 'Rifiutata' :
               application.status === 'expired' ? 'Scaduta' :
               'In attesa'}
            </span>

            {/* Titolo e indirizzo */}
            <div>
              <h3 className="text-base sm:text-lg font-semibold text-gray-900 line-clamp-1">
                {application.listingTitle}
              </h3>
              <div className="flex flex-wrap items-center gap-2 sm:gap-4 text-sm text-gray-500 mt-1">
                <div className="flex items-center gap-1.5">
                  <MapPin className="h-4 w-4 flex-shrink-0" />
                  <span className="text-sm text-gray-500 break-words">
                    {application.listingAddress}
                  </span>
                </div>
                <div className="flex items-center gap-1.5">
                  <span className="text-gray-400">Rif.</span>
                  <span>{generateNumericId(application.listingId)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Timeline aggiornato */}
        <div className="px-4 sm:px-6 py-3 sm:py-4 bg-gray-50 border-b border-gray-100">
          <div className="flex items-center gap-4 text-sm text-gray-600">
            <div className="flex items-center gap-1.5">
              <Calendar className="h-4 w-4" />
              <span>Inviata il {application.createdAt.toLocaleDateString()}</span>
            </div>
            {application.status === 'pending' && !isApplicationExpired(application.createdAt) && (
              <>
                <div className="w-1 h-1 bg-gray-300 rounded-full"></div>
                <div className="flex items-center gap-1.5">
                  <Clock className="h-4 w-4" />
                  <ExpirationTimer expiresAt={application.expiresAt} />
                </div>
              </>
            )}
          </div>
        </div>

        {/* Content aggiornato */}
        <div className="p-4 sm:p-6 space-y-4">
          {/* Avviso dati di contatto con nuovo stile */}
          {application.status === 'pending' && !isApplicationExpired(application.createdAt) && (
            <div className="p-4 bg-yellow-50 border border-yellow-100 rounded-lg">
              <div className="flex items-center gap-3">
                <AlertTriangle className="h-5 w-5 text-yellow-600" />
                <div>
                  <p className="text-sm font-medium text-yellow-900">
                    Dati di contatto nascosti
                  </p>
                  <p className="text-sm text-yellow-700">
                    I dati di contatto del proprietario saranno visibili dopo l'accettazione della candidatura
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Informazioni di contatto del proprietario con nuovo stile */}
          {application.status === 'accepted' && (
            <div className="p-4 bg-green-50 rounded-lg border border-green-100">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                {/* Nome */}
                <div className="flex items-start gap-3">
                  <div className="p-2 bg-green-100 rounded-lg">
                    <User className="h-5 w-5 text-green-600" />
                  </div>
                  <div>
                    <p className="text-sm text-green-700 font-medium">Nome</p>
                    <p className="text-base text-green-900">{application.landlordName}</p>
                  </div>
                </div>
                
                {/* Email */}
                <div className="flex items-start gap-3">
                  <div className="p-2 bg-green-100 rounded-lg">
                    <Mail className="h-5 w-5 text-green-600" />
                  </div>
                  <div>
                    <p className="text-sm text-green-700 font-medium">Email</p>
                    <p className="text-base text-green-900 break-all">{application.landlordEmail}</p>
                  </div>
                </div>

                {/* Telefono */}
                {application.landlordPhone && (
                  <div className="flex items-start gap-3">
                    <div className="p-2 bg-green-100 rounded-lg">
                      <Phone className="h-5 w-5 text-green-600" />
                    </div>
                    <div>
                      <p className="text-sm text-green-700 font-medium">Telefono</p>
                      <p className="text-base text-green-900">{application.landlordPhone}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Pulsanti azione aggiornati con altezza maggiore su mobile */}
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setShowDataModal(true)}
              className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-blue-600 bg-blue-50 border border-blue-100 rounded-md hover:bg-blue-100 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
            >
              <FileText className="h-4 w-4" />
              <span>Dati Candidatura</span>
            </button>
            
            <button
              onClick={() => setShowListingDataModal(true)}
              className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-purple-600 bg-purple-50 border border-purple-100 rounded-md hover:bg-purple-100 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
            >
              <Building2 className="h-4 w-4" />
              <span>Dati Annuncio</span>
            </button>

            {application.status === 'accepted' && (
              <>
                {/* Pulsante Gestisci Videochiamata (se esiste un meeting) */}
                {meeting && (
                  <button
                    onClick={() => setShowManageModal(true)}
                    className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-yellow-600 bg-yellow-50 border border-yellow-100 rounded-md hover:bg-yellow-100 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
                  >
                    <Settings className="h-4 w-4" />
                    <span>Gestisci</span>
                  </button>
                )}

                {/* Pulsante Partecipa Videochiamata (se esiste un meeting) */}
                {meeting && (
                  <button
                    onClick={handleJoinMeeting}
                    className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
                  >
                    <Video className="h-4 w-4" />
                    <span>Partecipa</span>
                  </button>
                )}

                {/* Pulsante Programma Videochiamata (se non esiste un meeting) */}
                {!meeting && canBookMeeting && (
                  <button
                    onClick={() => setShowBookingModal(true)}
                    className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-green-600 bg-green-50 border border-green-100 rounded-md hover:bg-green-100 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
                  >
                    <Calendar className="h-4 w-4" />
                    <span>Programma</span>
                  </button>
                )}
                
                {application.chatId && (
                  <button
                    onClick={() => setShowChatModal(true)}
                    className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none relative"
                  >
                    <MessageSquare className="h-4 w-4" />
                    <span>Chat</span>
                    {unreadCount > 0 && (
                      <span className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 text-white text-xs font-medium rounded-full flex items-center justify-center">
                        {unreadCount}
                      </span>
                    )}
                  </button>
                )}
              </>
            )}
          </div>

          {/* Meeting Info aggiornato */}
          {application.status === 'accepted' && meeting && (
            <div className="p-4 bg-blue-50 border border-blue-100 rounded-lg mt-4">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-blue-100 rounded-lg">
                  <Video className="h-6 w-6 text-blue-600" />
                </div>
                <div>
                  <p className="text-base font-medium text-blue-900">
                    Videochiamata programmata
                  </p>
                  <p className="text-sm text-blue-700 mt-0.5">
                    {formatMeetingDate(meeting)}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {showChatModal && application.chatId && (
        <ChatModal
          chatId={application.chatId}
          otherPartyName={application.landlordName}
          onClose={() => setShowChatModal(false)}
        />
      )}

      {showDataModal && (
        <ApplicationDataViewModal
          application={application}
          onClose={() => setShowDataModal(false)}
        />
      )}

      {showManageModal && meeting && (
        <ManageMeetingModal
          meeting={meeting}
          onClose={() => setShowManageModal(false)}
        />
      )}

      {showListingDataModal && (
        <ListingDataViewModal
          application={application}
          onClose={() => setShowListingDataModal(false)}
        />
      )}

      {showBookingModal && (
        <BookMeetingModal
          applicationId={application.id}
          listingId={application.listingId}
          listingTitle={application.listingTitle}
          studentName={application.studentName}
          studentEmail={application.studentEmail}
          landlordId={landlordId}
          onClose={() => setShowBookingModal(false)}
        />
      )}
    </>
  );
}