import React from 'react';
import { Link } from 'react-router-dom';
import { Eye, Users } from 'lucide-react';
import { Listing } from '../../../../types/listing';
import { isListingFeatured } from '../../../../utils/listings';

interface ListingsPerformanceProps {
  listings: Listing[];
}

export function ListingsPerformance({ listings }: ListingsPerformanceProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-medium mb-4">Performance Annunci</h3>
      <div className="space-y-4">
        {listings.map(listing => (
          <Link
            key={listing.id}
            to={`/dashboard/landlord/analytics/${listing.id}`}
            className="block p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-1 sm:gap-3">
              <div>
                <h4 className="font-medium text-gray-900">{listing.title}</h4>
                <p className="text-sm text-gray-500">
                  Pubblicato il {listing.createdAt.toLocaleDateString()}
                </p>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                <div className="flex items-center gap-1">
                  <Eye className="h-4 w-4 text-gray-400" />
                  <span className="text-sm text-gray-600">
                    {listing.views || 0} visualizzazioni
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <Users className="h-4 w-4 text-gray-400" />
                  <span className="text-sm text-gray-600">
                    {listing.applications || 0} candidature
                  </span>
                </div>
                {isListingFeatured(listing) && (
                  <span className="px-2 py-0.5 bg-yellow-100 text-yellow-800 rounded-full text-xs">
                    In Evidenza
                  </span>
                )}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}