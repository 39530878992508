import React, { useState } from 'react';
import { X, Euro, Calendar, MapPin, Building2, Bath, Users, Plug, Flame, ChevronLeft, ChevronRight, ChefHat, FileText, AlertTriangle } from 'lucide-react';
import { Application } from '../../types/application';
import { heatingLabels, categoryLabels, featuresLabels, kitchenLabels, bathroomLabels } from '../../types/listing';

interface ListingDataViewModalProps {
  application: Application;
  onClose: () => void;
  isLandlord?: boolean;
}

export function ListingDataViewModal({ application, onClose, isLandlord = false }: ListingDataViewModalProps) {
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  console.log('Application data:', {
    title: application.listingTitle,
    price: application.listingPrice,
    address: application.listingAddress,
    category: application.listingCategory,
    utilities: application.listingUtilities,
    features: application.listingFeatures,
    images: application.listingImages?.length,
    floorPlans: application.listingFloorPlans?.length,
  });

  console.log('Utilities details:', {
    type: application.listingUtilities?.type,
    monthlyFee: application.listingUtilities?.monthlyFee
  });

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setShowImageGallery(true);
  };

  const nextImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!application.listingImages) return;
    setCurrentImageIndex((prev) => (prev + 1) % application.listingImages.length);
  };

  const prevImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!application.listingImages) return;
    setCurrentImageIndex((prev) => (prev - 1 + application.listingImages.length) % application.listingImages.length);
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
          <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 flex flex-col gap-2 z-10">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold text-gray-900">
                Dati Annuncio
              </h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <X className="h-6 w-6" />
              </button>
            </div>

            {isLandlord && (
              <div className="bg-yellow-50 rounded-lg p-3">
                <div className="flex items-center gap-2 text-sm text-yellow-800">
                  <AlertTriangle className="h-4 w-4 text-yellow-600 flex-shrink-0" />
                  <p>
                    I dati visualizzati sono aggiornati al momento dell'invio della candidatura. Eventuali modifiche apportate all'annuncio successivamente non saranno visibili allo studente.
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="flex-1 overflow-y-auto p-6 space-y-6">
            {/* Main Info */}
            <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl p-6">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-blue-200 rounded-lg">
                  <Building2 className="h-5 w-5 text-blue-700" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-blue-900">{application.listingTitle}</h3>
                  <p className="text-sm text-blue-700">
                    {application.listingCategory && categoryLabels[application.listingCategory]}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                  <MapPin className="h-5 w-5 text-blue-600" />
                  <div>
                    <p className="text-sm text-blue-900 font-medium">Indirizzo</p>
                    <p className="text-sm text-blue-700">{application.listingAddress}</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                  <Euro className="h-5 w-5 text-blue-600" />
                  <div>
                    <p className="text-sm text-blue-900 font-medium">Canone mensile</p>
                    <p className="text-sm text-blue-700">
                      €{application.listingPrice}/mese
                      {application.listingUtilities?.type === 'notIncluded' && 
                       application.listingUtilities?.monthlyFee > 0 && (
                        <span className="text-gray-500"> + €{application.listingUtilities.monthlyFee} spese</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Property Details */}
            <div className="bg-gradient-to-br from-green-50 to-green-100 rounded-xl p-6">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-green-200 rounded-lg">
                  <Building2 className="h-5 w-5 text-green-700" />
                </div>
                <h3 className="text-lg font-semibold text-green-900">
                  Caratteristiche Immobile
                </h3>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                  <Users className="h-5 w-5 text-green-600" />
                  <div>
                    <p className="text-sm text-green-900 font-medium">Posti letto</p>
                    <p className="text-sm text-green-700">{application.listingMaxOccupants || 1} totali</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                  <Bath className="h-5 w-5 text-green-600" />
                  <div>
                    <p className="text-sm text-green-900 font-medium">Bagni</p>
                    <p className="text-sm text-green-700">{application.listingBathrooms || 1}</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                  <Flame className="h-5 w-5 text-green-600" />
                  <div>
                    <p className="text-sm text-green-900 font-medium">Riscaldamento</p>
                    <p className="text-sm text-green-700">
                      {application.listingHeating && heatingLabels[application.listingHeating]}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                  <Plug className="h-5 w-5 text-green-600" />
                  <div>
                    <p className="text-sm text-green-900 font-medium">Spese</p>
                    <p className="text-sm text-green-700">
                      {application.listingUtilities?.type === 'included' 
                        ? 'Incluse nel canone'
                        : application.listingUtilities?.monthlyFee > 0 
                          ? `€${application.listingUtilities.monthlyFee}/mese`
                          : 'Non specificate'}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Contract Details */}
            <div className="bg-gradient-to-br from-purple-50 to-purple-100 rounded-xl p-6">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-purple-200 rounded-lg">
                  <Calendar className="h-5 w-5 text-purple-700" />
                </div>
                <h3 className="text-lg font-semibold text-purple-900">
                  Dettagli Contratto
                </h3>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                  <Calendar className="h-5 w-5 text-purple-600" />
                  <div>
                    <p className="text-sm text-purple-900 font-medium">Permanenza minima</p>
                    <p className="text-sm text-purple-700">{application.listingMinStay || 12} mesi</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                  <Calendar className="h-5 w-5 text-purple-600" />
                  <div>
                    <p className="text-sm text-purple-900 font-medium">Preavviso</p>
                    <p className="text-sm text-purple-700">{application.listingNoticePeriod || 2} mesi</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Features */}
            {application.listingFeatures && Object.keys(application.listingFeatures).length > 0 && (
              <div className="bg-gradient-to-br from-yellow-50 to-yellow-100 rounded-xl p-6">
                <div className="flex items-center gap-3 mb-4">
                  <div className="p-2 bg-yellow-200 rounded-lg">
                    <Building2 className="h-5 w-5 text-yellow-700" />
                  </div>
                  <h3 className="text-lg font-semibold text-yellow-900">
                    Caratteristiche
                  </h3>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(application.listingFeatures).map(([key, value]) => 
                    value && (
                      <div key={key} className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                        <div className="text-sm text-yellow-700">
                          {featuresLabels[key as keyof typeof featuresLabels]}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}

            {/* Kitchen Features */}
            {application.listingKitchenFeatures && Object.keys(application.listingKitchenFeatures).length > 0 && (
              <div className="bg-gradient-to-br from-orange-50 to-orange-100 rounded-xl p-6">
                <div className="flex items-center gap-3 mb-4">
                  <div className="p-2 bg-orange-200 rounded-lg">
                    <ChefHat className="h-5 w-5 text-orange-700" />
                  </div>
                  <h3 className="text-lg font-semibold text-orange-900">
                    Cucina
                  </h3>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(application.listingKitchenFeatures).map(([key, value]) => 
                    value && (
                      <div key={key} className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                        <div className="text-sm text-orange-700">
                          {kitchenLabels[key as keyof typeof kitchenLabels]}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}

            {/* Bathroom Features */}
            {application.listingBathroomFeatures && Object.keys(application.listingBathroomFeatures).length > 0 && (
              <div className="bg-gradient-to-br from-teal-50 to-teal-100 rounded-xl p-6">
                <div className="flex items-center gap-3 mb-4">
                  <div className="p-2 bg-teal-200 rounded-lg">
                    <Bath className="h-5 w-5 text-teal-700" />
                  </div>
                  <h3 className="text-lg font-semibold text-teal-900">
                    Bagno
                  </h3>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(application.listingBathroomFeatures).map(([key, value]) => 
                    value && (
                      <div key={key} className="flex items-center gap-3 p-3 bg-white/50 rounded-lg">
                        <div className="text-sm text-teal-700">
                          {bathroomLabels[key as keyof typeof bathroomLabels]}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}

            {/* Floor Plans */}
            {application.listingFloorPlans && application.listingFloorPlans.length > 0 && (
              <div className="bg-gradient-to-br from-indigo-50 to-indigo-100 rounded-xl p-6">
                <div className="flex items-center gap-3 mb-4">
                  <div className="p-2 bg-indigo-200 rounded-lg">
                    <FileText className="h-5 w-5 text-indigo-700" />
                  </div>
                  <h3 className="text-lg font-semibold text-indigo-900">
                    Planimetrie
                  </h3>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {application.listingFloorPlans.map((plan, index) => (
                    <div
                      key={index}
                      onClick={() => handleImageClick(index)}
                      className="relative aspect-square cursor-pointer group"
                    >
                      {plan.toLowerCase().endsWith('.pdf') ? (
                        <div className="w-full h-full flex items-center justify-center bg-white/50 rounded-lg">
                          <FileText className="h-8 w-8 text-indigo-600" />
                        </div>
                      ) : (
                        <img
                          src={plan}
                          alt={`Planimetria ${index + 1}`}
                          className="w-full h-full object-cover rounded-lg hover:opacity-90 transition-opacity"
                          onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.src = "https://via.placeholder.com/300x200?text=Planimetria+non+disponibile";
                          }}
                        />
                      )}
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all rounded-lg flex items-center justify-center">
                        <span className="text-white opacity-0 group-hover:opacity-100 transition-opacity">
                          {plan.toLowerCase().endsWith('.pdf') ? 'Apri PDF' : 'Visualizza'}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Images */}
            {application.listingImages?.length > 0 && (
              <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-xl p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Foto</h3>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {application.listingImages.map((image, index) => (
                    <div
                      key={index}
                      onClick={() => handleImageClick(index)}
                      className="relative aspect-square cursor-pointer group"
                    >
                      <img
                        src={image}
                        alt={`Foto ${index + 1}`}
                        className="w-full h-full object-cover rounded-lg hover:opacity-90 transition-opacity"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.src = "https://via.placeholder.com/300x200?text=Immagine+non+disponibile";
                        }}
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all rounded-lg flex items-center justify-center">
                        <span className="text-white opacity-0 group-hover:opacity-100 transition-opacity">
                          Visualizza
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Image Gallery Modal */}
      {showImageGallery && application.listingImages && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-90 z-[60] flex items-center justify-center"
          onClick={() => setShowImageGallery(false)}
        >
          <button
            onClick={prevImage}
            className="absolute left-4 top-1/2 -translate-y-1/2 text-white p-2 hover:bg-white/10 rounded-full"
          >
            <ChevronLeft className="h-8 w-8" />
          </button>

          <div className="relative max-w-4xl max-h-[80vh] mx-4">
            <img
              src={application.listingImages[currentImageIndex]}
              alt={`Foto ${currentImageIndex + 1}`}
              className="max-h-[80vh] w-auto"
              onClick={(e) => e.stopPropagation()}
            />
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 text-white bg-black/50 px-3 py-1 rounded-full text-sm">
              {currentImageIndex + 1} / {application.listingImages.length}
            </div>
          </div>

          <button
            onClick={nextImage}
            className="absolute right-4 top-1/2 -translate-y-1/2 text-white p-2 hover:bg-white/10 rounded-full"
          >
            <ChevronRight className="h-8 w-8" />
          </button>

          <button
            onClick={() => setShowImageGallery(false)}
            className="absolute top-4 right-4 text-white p-2 hover:bg-white/10 rounded-full"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
      )}
    </>
  );
}