import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  updateDoc,
  addDoc,
  Timestamp,
  serverTimestamp,
  onSnapshot,
  orderBy,
  writeBatch,
  getDoc,
  FirestoreError
} from 'firebase/firestore';
import { db } from './firebase';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { auth } from './firebase';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';

export interface Notification {
  id: string;
  userId: string;
  type: 'application_received' | 'application_accepted' | 'ticket_reply' | 'meeting_scheduled' | 'meeting_cancelled' | 'chat_message' | 'application_expired';
  title: string;
  message: string;
  data: Record<string, any>;
  read: boolean;
  createdAt: Date;
}

const notificationsCollection = collection(db, 'notifications');

export function onNotificationsChange(
  userId: string, 
  callback: (notifications: Notification[]) => void,
  onError?: (error: Error) => void
) {
  if (!userId) {
    callback([]);
    return () => {};
  }

  const q = query(
    notificationsCollection,
    where('userId', '==', userId),
    orderBy('createdAt', 'desc')
  );

  return onSnapshot(
    q,
    (snapshot) => {
      const notifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date()
      })) as Notification[];
      callback(notifications);
    },
    (error) => {
      console.error('Error in notifications subscription:', error);
      if (onError) {
        onError(error as Error);
      }
      callback([]);
    }
  );
}

export async function markAsRead(notificationId: string): Promise<void> {
  try {
    const notificationRef = doc(db, 'notifications', notificationId);
    const notificationSnap = await getDoc(notificationRef);
    
    if (!notificationSnap.exists()) {
      console.warn('Notifica non trovata');
      return;
    }

    // Verifica che l'utente sia il proprietario della notifica
    const currentUser = auth.currentUser;
    if (!currentUser || notificationSnap.data().userId !== currentUser.uid) {
      console.warn('Permesso negato: utente non autorizzato');
      return;
    }

    await updateDoc(notificationRef, {
      read: true,
      updatedAt: serverTimestamp()
    });

    console.log(`Notifica ${notificationId} segnata come letta`);
  } catch (error) {
    if ((error as FirestoreError).code === 'permission-denied') {
      console.warn('Permesso negato durante la marcatura della notifica come letta');
      return;
    }
    console.error('Errore durante la marcatura della notifica come letta:', error);
    throw error;
  }
}

export async function markAllAsRead(userId: string): Promise<void> {
  if (!userId || userId !== auth.currentUser?.uid) {
    console.warn('Utente non autorizzato');
    return;
  }

  try {
    const batch = writeBatch(db);
    const q = query(
      collection(db, 'notifications'),
      where('userId', '==', userId),
      where('read', '==', false)
    );
    
    const snapshot = await getDocs(q);
    
    if (snapshot.empty) {
      console.log('Nessuna notifica non letta trovata');
      return;
    }

    let updateCount = 0;
    snapshot.docs.forEach((doc) => {
      batch.update(doc.ref, { 
        read: true,
        updatedAt: serverTimestamp()
      });
      updateCount++;
    });
    
    if (updateCount > 0) {
      await batch.commit();
      console.log(`Segnate ${updateCount} notifiche come lette`);
    }
  } catch (error) {
    if ((error as FirestoreError).code === 'permission-denied') {
      console.warn('Permesso negato durante la marcatura delle notifiche come lette');
      throw error;
    }
    console.error('Errore durante la marcatura delle notifiche come lette:', error);
    throw error;
  }
}

export function getUnreadCount(notifications: Notification[]): number {
  if (!notifications || notifications.length === 0) return 0;
  return notifications.filter(notification => notification.read === false).length;
}

export async function createNotification(data: Omit<Notification, 'id' | 'read' | 'createdAt'>): Promise<void> {
  try {
    await addDoc(notificationsCollection, {
      ...data,
      read: false,
      createdAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
}

export async function createChatMessageNotification(
  recipientId: string,
  chatId: string,
  messageId: string,
  senderName: string,
  content: string
): Promise<void> {
  await createNotification({
    userId: recipientId,
    type: 'chat_message',
    title: 'Nuovo messaggio',
    message: `${senderName}: ${content}`,
    data: {
      chatId,
      messageId,
      senderName
    }
  });
}

export async function createApplicationReceivedNotification(
  landlordId: string,
  listingTitle: string,
  listingId: string,
  applicationId: string
): Promise<void> {
  const notification: Notification = {
    id: uuidv4(),
    userId: landlordId,
    type: 'application_received',
    message: `Nuova candidatura ricevuta per "${listingTitle}"`,
    data: {
      listingId,
      applicationId,
      listingTitle
    },
    read: false,
    createdAt: new Date()
  };

  try {
    await addDoc(collection(db, 'notifications'), notification);
  } catch (error) {
    console.error('Error creating application received notification:', error);
    throw new Error('Errore durante la creazione della notifica');
  }
}

export async function createApplicationAcceptedNotification(
  userId: string,
  listingTitle: string,
  listingId: string,
  applicationId: string,
  isAdmin: boolean
): Promise<void> {
  await createNotification({
    userId,
    type: 'application_accepted',
    title: 'Candidatura Accettata',
    message: `La tua candidatura per "${listingTitle}" è stata accettata${isAdmin ? ' dall\'amministratore' : ''}`,
    data: {
      listingId,
      applicationId,
      listingTitle,
      accepted: true
    }
  });
}

export async function createApplicationExpiringNotification(
  userId: string,
  listingTitle: string,
  listingId: string,
  applicationId: string
): Promise<void> {
  await createNotification({
    userId,
    type: 'application_received',
    title: 'Candidatura in Scadenza',
    message: `La candidatura per "${listingTitle}" scadrà tra un'ora`,
    data: {
      listingId,
      applicationId,
      listingTitle,
      expiring: true
    }
  });
}

export async function createMeetingScheduledNotification(
  userId: string,
  meetingId: string,
  date: Date,
  time: string
): Promise<void> {
  await createNotification({
    userId,
    type: 'meeting_scheduled',
    title: 'Videochiamata Programmata',
    message: `Una videochiamata è stata programmata per il ${date.toLocaleDateString()} alle ${time}`,
    data: {
      meetingId,
      date,
      time
    }
  });
}

export async function createMeetingCancelledNotification(
  userId: string,
  meetingId: string
): Promise<void> {
  await createNotification({
    userId,
    type: 'meeting_cancelled',
    title: 'Videochiamata Cancellata',
    message: 'La tua videochiamata è stata cancellata',
    data: {
      meetingId
    }
  });
}

export async function createMeetingRescheduledNotification(
  userId: string,
  meetingId: string,
  newDate: Date,
  newTime: string
): Promise<void> {
  if (!userId) {
    throw new Error('userId is required');
  }

  await createNotification({
    userId,
    type: 'meeting_rescheduled',
    title: 'Videochiamata Riprogrammata',
    message: `La tua videochiamata è stata spostata al ${format(newDate, 'd MMMM yyyy', { locale: it })} alle ${newTime}`,
    data: {
      meetingId,
      date: newDate,
      time: newTime
    }
  });
}

export async function createApplicationExpiredNotification(
  userId: string,
  listingTitle: string,
  listingId: string,
  applicationId: string
): Promise<void> {
  await createNotification({
    userId,
    type: 'application_expired',
    title: 'Candidatura Scaduta',
    message: `La tua candidatura per "${listingTitle}" è scaduta. I crediti sono stati rimborsati.`,
    data: {
      listingId,
      applicationId,
      listingTitle,
      expired: true,
      refunded: true
    }
  });
}

export function getNotificationLink(notification: Notification, userType: string) {
  switch (notification.type) {
    case 'application_received':
      return {
        path: '/dashboard/landlord',
        state: { 
          activeTab: 'applications',
          highlightedId: notification.data.applicationId 
        }
      };
    case 'application_accepted':
      return {
        path: '/dashboard/student',
        state: { 
          activeTab: 'applications',
          highlightedId: notification.data.applicationId 
        }
      };
    case 'chat_message':
      return {
        path: `/chat/${notification.data.chatId}`,
        state: { 
          messageId: notification.data.messageId
        }
      };
    case 'ticket_reply':
      return {
        path: '/dashboard/support',
        state: null
      };
    default:
      return {
        path: '#',
        state: null
      };
  }
}