import { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { onMessagesChange } from '../lib/chat';
import { Message } from '../types/chat';

export function useUnreadMessages(chatId?: string): number {
  const { userProfile } = useAuthContext();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!chatId || !userProfile?.id) {
      setUnreadCount(0);
      return () => {};
    }

    const unsubscribe = onMessagesChange(chatId, (messages: Message[]) => {
      const count = messages.filter(msg => 
        msg.senderId !== userProfile.id && 
        !msg.readBy.includes(userProfile.id)
      ).length;
      setUnreadCount(count);
    });

    return () => unsubscribe();
  }, [chatId, userProfile?.id]);

  return unreadCount;
}