import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signUpWithEmail, signInWithGoogle } from '../../lib/auth';
import { addCredits } from '../../lib/credits';
import { toast } from 'react-toastify';
import { UserPlus, GraduationCap, Building2, AlertCircle, Mail, Lock } from 'lucide-react';

export function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState<'student' | 'landlord'>('student');
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const validatePassword = (value: string) => {
    if (value.length < 6) {
      setPasswordError('La password deve contenere almeno 6 caratteri');
      return false;
    }
    setPasswordError('');
    return true;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const handleEmailRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validatePassword(password)) {
      return;
    }

    setLoading(true);
    try {
      // Registrazione utente
      const { userProfile } = await signUpWithEmail(email, password, userType);
      
      // Aggiungi crediti di benvenuto se è un proprietario
      if (userType === 'landlord') {
        try {
          await addCredits(
            userProfile.id, 
            10, 
            'Crediti di benvenuto'
          );
        } catch (creditError) {
          console.error('Errore nell\'aggiunta dei crediti di benvenuto:', creditError);
          // Non blocchiamo la registrazione se fallisce l'aggiunta dei crediti
        }
      }
      
      toast.success('Registrazione completata!');
      navigate(`/dashboard/${userType}`);
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        toast.error('Questo indirizzo email è già registrato');
      } else {
        console.error('Errore durante la registrazione:', error);
        toast.error('Errore durante la registrazione. Riprova.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleRegister = async () => {
    try {
      const { userProfile } = await signInWithGoogle(userType);
      
      // Aggiungi crediti di benvenuto se è un proprietario
      if (userType === 'landlord') {
        try {
          await addCredits(
            userProfile.id, 
            10, 
            'Crediti di benvenuto'
          );
        } catch (creditError) {
          console.error('Errore nell\'aggiunta dei crediti di benvenuto:', creditError);
        }
      }

      toast.success('Registrazione completata con successo!');
      navigate(`/dashboard/${userType}`);
    } catch (error) {
      toast.error('Errore durante la registrazione con Google.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-blue-600 flex flex-col justify-center py-12 sm:px-6 lg:px-8 pt-24">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-xl rounded-lg sm:px-10">
          <div className="text-center mb-8">
            <UserPlus className="mx-auto h-12 w-12 text-blue-600" />
            <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
              Crea il tuo account
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Hai già un account?{' '}
              <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
                Accedi
              </Link>
            </p>
          </div>

          <div className="mb-8">
            <label className="block text-lg font-medium text-gray-700 mb-4 text-center">
              Scegli il tipo di account
            </label>
            <div className="grid grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() => setUserType('student')}
                className={`p-4 rounded-lg border-2 text-center transition-all ${
                  userType === 'student'
                    ? 'border-blue-600 bg-blue-50 text-blue-700'
                    : 'border-gray-200 hover:border-gray-300'
                }`}
              >
                <GraduationCap className="h-8 w-8 mx-auto mb-2" />
                <div className="font-medium">Studente</div>
                <p className="text-sm text-gray-500 mt-1">
                  Cerca alloggi e candidati
                </p>
              </button>
              <button
                type="button"
                onClick={() => setUserType('landlord')}
                className={`p-4 rounded-lg border-2 text-center transition-all ${
                  userType === 'landlord'
                    ? 'border-blue-600 bg-blue-50 text-blue-700'
                    : 'border-gray-200 hover:border-gray-300'
                }`}
              >
                <Building2 className="h-8 w-8 mx-auto mb-2" />
                <div className="font-medium">Proprietario</div>
                <p className="text-sm text-gray-500 mt-1">
                  Pubblica e gestisci annunci
                </p>
              </button>
            </div>
          </div>

          <form className="space-y-6" onSubmit={handleEmailRegister}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1 relative">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 pl-10 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                <Mail className="h-5 w-5 text-gray-400 absolute left-3 top-2.5" />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                  className={`appearance-none block w-full px-3 py-2 pl-10 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 ${
                    passwordError
                      ? 'border-red-300 focus:border-red-500'
                      : 'border-gray-300 focus:border-blue-500'
                  }`}
                />
                <Lock className="h-5 w-5 text-gray-400 absolute left-3 top-2.5" />
                {passwordError && (
                  <div className="mt-1 text-sm text-red-600 flex items-center">
                    <AlertCircle className="h-4 w-4 mr-1" />
                    {passwordError}
                  </div>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading || !!passwordError}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                {loading ? 'Registrazione in corso...' : 'Registrati'}
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Oppure continua con</span>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={handleGoogleRegister}
                className="w-full flex justify-center items-center gap-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 transition-colors"
              >
                <img
                  className="h-5 w-5"
                  src="https://www.svgrepo.com/show/475656/google-color.svg"
                  alt="Google logo"
                />
                <span>Google</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}