import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  updateDoc,
  addDoc,
  Timestamp,
  serverTimestamp,
  orderBy,
  limit,
  getDoc,
  increment
} from 'firebase/firestore';
import { db } from './firebase';
import { toast } from 'react-toastify';

const creditsCollection = collection(db, 'credits');
const usersCollection = collection(db, 'users');

export async function getUserCredits(userId: string): Promise<number> {
  try {
    const userRef = doc(usersCollection, userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('Utente non trovato');
    }

    const userData = userDoc.data();
    const isLandlord = userData.userType === 'landlord';
    return isLandlord ? userData.landlordInfo?.credits || 0 : userData.studentInfo?.credits || 0;
  } catch (error) {
    console.error('Error getting user credits:', error);
    throw error;
  }
}

export async function getCreditHistory(userId: string) {
  try {
    if (!userId) {
      throw new Error('User ID is required');
    }

    // Query aggiornata per includere tutti i tipi di transazione
    const creditQuery = query(
      creditsCollection,
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );

    const querySnapshot = await getDocs(creditQuery);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate() || new Date(),
      type: doc.data().type || 'usage', // Valore predefinito per retrocompatibilità
      amount: doc.data().type === 'refund' ? Math.abs(doc.data().amount) : doc.data().amount
    }));

  } catch (error) {
    console.error('Error getting credit history:', error);
    throw new Error('Errore nel recupero dello storico crediti');
  }
}

export async function addCredits(userId: string, amount: number, description: string): Promise<void> {
  try {
    const userRef = doc(usersCollection, userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const userData = userDoc.data();
    const isStudent = userData.userType === 'student';

    // Update user credits
    await updateDoc(userRef, {
      [`${isStudent ? 'studentInfo' : 'landlordInfo'}.credits`]: (isStudent ? userData.studentInfo?.credits : userData.landlordInfo?.credits || 0) + amount
    });

    // Record credit transaction
    await addDoc(creditsCollection, {
      userId,
      amount,
      description,
      type: 'purchase',
      createdAt: serverTimestamp(),
      status: 'completed'
    });

    toast.success(`${amount} crediti aggiunti con successo`);
  } catch (error) {
    console.error('Error adding credits:', error);
    throw error;
  }
}

export async function useCredits(userId: string, amount: number, reason: string): Promise<void> {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utente non trovato');
    }
    
    const userData = userDoc.data();
    const isLandlord = userData.userType === 'landlord';
    const currentCredits = isLandlord ? userData.landlordInfo?.credits || 0 : userData.studentInfo?.credits || 0;
    
    if (currentCredits < amount) {
      throw new Error('Crediti insufficienti');
    }

    await updateDoc(doc(db, 'users', userId), {
      [`${isLandlord ? 'landlordInfo' : 'studentInfo'}.credits`]: increment(-amount),
      lastCreditUpdate: serverTimestamp()
    });

    // Registra la transazione
    await addDoc(collection(db, 'creditTransactions'), {
      userId,
      amount: -amount,
      reason,
      createdAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error using credits:', error);
    throw new Error('Errore nell\'utilizzo dei crediti');
  }
}

export async function refundCredits(userId: string, amount: number, description: string): Promise<void> {
  try {
    const userRef = doc(usersCollection, userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const userData = userDoc.data();
    const isStudent = userData.userType === 'student';
    const currentCredits = isStudent ? userData.studentInfo?.credits : userData.landlordInfo?.credits || 0;

    // Update user credits
    await updateDoc(userRef, {
      [`${isStudent ? 'studentInfo' : 'landlordInfo'}.credits`]: currentCredits + amount
    });

    // Record refund transaction
    await addDoc(creditsCollection, {
      userId,
      amount,
      description,
      type: 'refund',
      createdAt: serverTimestamp(),
      status: 'completed'
    });

    toast.success(`${amount} crediti rimborsati`);
  } catch (error) {
    console.error('Error refunding credits:', error);
    throw error;
  }
}