import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
  orderBy,
  limit,
  QuerySnapshot,
  DocumentData,
  runTransaction,
  getDoc,
  enableIndexedDbPersistence
} from 'firebase/firestore';
import { db } from './firebase';
import { Chat, Message, ContactInfo } from '../types/chat';
import { toast } from 'react-toastify';
import { createChatMessageNotification } from './notifications';

const chatsCollection = collection(db, 'chats');
const messagesCollection = collection(db, 'messages');

export async function createChat(
  listingId: string,
  listingTitle: string,
  applicationId: string,
  studentInfo: ContactInfo,
  landlordInfo: ContactInfo
): Promise<string> {
  try {
    // Create chat document
    const chatRef = doc(chatsCollection);
    await setDoc(chatRef, {
      listingId,
      listingTitle,
      applicationId,
      studentId: studentInfo.email,
      studentName: studentInfo.name,
      landlordId: landlordInfo.email,
      landlordName: landlordInfo.name,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      lastMessage: null,
      unreadCount: {
        [studentInfo.email]: 0,
        [landlordInfo.email]: 0
      }
    });

    return chatRef.id;
  } catch (error) {
    console.error('Error creating chat:', error);
    throw error;
  }
}

export function onChatsChange(userId: string, callback: (chats: Chat[]) => void) {
  if (!userId) {
    callback([]);
    return () => {};
  }

  // Query for both student and landlord chats
  const studentQuery = query(
    chatsCollection,
    where('studentId', '==', userId),
    orderBy('updatedAt', 'desc')
  );

  const landlordQuery = query(
    chatsCollection,
    where('landlordId', '==', userId),
    orderBy('updatedAt', 'desc')
  );

  const unsubscribeStudent = onSnapshot(studentQuery, handleSnapshot, handleError);
  const unsubscribeLandlord = onSnapshot(landlordQuery, handleSnapshot, handleError);

  function handleSnapshot(snapshot: QuerySnapshot<DocumentData>) {
    const chats = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate() || new Date(),
      updatedAt: doc.data().updatedAt?.toDate(),
      lastMessage: doc.data().lastMessage ? {
        ...doc.data().lastMessage,
        createdAt: doc.data().lastMessage.createdAt?.toDate()
      } : undefined
    })) as Chat[];
    callback(chats);
  }

  function handleError(error: Error) {
    console.error('Error in chat subscription:', error);
    callback([]);
  }

  return () => {
    unsubscribeStudent();
    unsubscribeLandlord();
  };
}

export function onMessagesChange(chatId: string | undefined, callback: (messages: Message[]) => void) {
  if (!chatId) {
    callback([]);
    return () => {};
  }

  try {
    const unsubscribe = onSnapshot(
      doc(chatsCollection, chatId), 
      (chatDoc) => {
        if (!chatDoc.exists()) {
          callback([]);
          return;
        }

        const q = query(
          messagesCollection,
          where('chatId', '==', chatId),
          orderBy('createdAt', 'asc')
        );

        const messagesUnsubscribe = onSnapshot(
          q, 
          (snapshot) => {
            const messages = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt?.toDate() || new Date()
            })) as Message[];
            callback(messages);
          },
          (error) => {
            if (error.code === 'permission-denied') {
              callback([]);
            } else {
              console.warn('Error in messages subscription:', error);
              toast.error('Errore nel caricamento dei messaggi');
            }
          }
        );

        return () => messagesUnsubscribe();
      },
      (error) => {
        if (error.code === 'permission-denied') {
          callback([]);
        } else {
          console.warn('Error in chat subscription:', error);
          callback([]);
        }
      }
    );

    return unsubscribe;
  } catch (error) {
    console.warn('Error setting up message subscription:', error);
    callback([]);
    return () => {};
  }
}

export async function sendMessage(
  chatId: string,
  senderId: string,
  senderName: string,
  content: string,
  type: 'text' | 'system' = 'text'
): Promise<void> {
  try {
    await runTransaction(db, async (transaction) => {
      // Get chat document to verify recipient
      const chatRef = doc(chatsCollection, chatId);
      const chatDoc = await transaction.get(chatRef);
      
      if (!chatDoc.exists()) {
        throw new Error('Chat not found');
      }

      const chatData = chatDoc.data();
      const recipientId = chatData.studentId === senderId ? chatData.landlordId : chatData.studentId;

      // Create message
      const messageRef = doc(messagesCollection);
      const messageData = {
        chatId,
        senderId,
        senderName,
        type,
        content,
        createdAt: serverTimestamp(),
        readBy: [senderId]
      };

      transaction.set(messageRef, messageData);

      // Update chat's last message and unread count
      const unreadCount = chatData.unreadCount || {};
      unreadCount[recipientId] = (unreadCount[recipientId] || 0) + 1;

      transaction.update(chatRef, {
        lastMessage: {
          content,
          createdAt: serverTimestamp(),
          senderId
        },
        unreadCount,
        updatedAt: serverTimestamp()
      });

      // Create notification for recipient
      await createChatMessageNotification(
        recipientId,
        chatId,
        messageRef.id,
        senderName,
        content
      );
    });
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
}

export async function markMessageAsRead(messageId: string, userId: string): Promise<void> {
  try {
    const messageRef = doc(messagesCollection, messageId);
    const messageDoc = await getDoc(messageRef);
    
    if (!messageDoc.exists()) {
      return;
    }

    const currentReadBy = messageDoc.data().readBy || [];
    if (!currentReadBy.includes(userId)) {
      await updateDoc(messageRef, {
        readBy: [...currentReadBy, userId]
      });

      // Update unread count in chat
      const chatRef = doc(chatsCollection, messageDoc.data().chatId);
      const chatDoc = await getDoc(chatRef);
      
      if (chatDoc.exists()) {
        const unreadCount = chatDoc.data().unreadCount || {};
        if (unreadCount[userId] > 0) {
          unreadCount[userId]--;
          await updateDoc(chatRef, { unreadCount });
        }
      }
    }
  } catch (error) {
    console.error('Error marking message as read:', error);
  }
}