import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { ShoppingBag } from 'lucide-react';
import { getProducts } from '../lib/products';
import { Product } from '../types/product';
import { toast } from 'react-toastify';

export function Shop() {
  const { userProfile } = useAuthContext();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadProducts();
  }, [userProfile?.userType]);

  const loadProducts = async () => {
    try {
      const data = await getProducts(userProfile?.userType);
      setProducts(data);
    } catch (error) {
      console.error('Error getting products:', error);
      toast.error('Errore nel caricamento dei prodotti');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <ShoppingBag className="h-12 w-12 text-blue-600 mx-auto mb-4" />
          <h1 className="text-3xl font-bold text-gray-900">
            {userProfile?.userType === 'landlord' ? 'Shop per Proprietari' : 'Shop per Studenti'}
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            Prodotti selezionati per le tue esigenze
          </p>
        </div>

        {products.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500">Nessun prodotto disponibile al momento.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {products.map((product) => (
              <a
                key={product.id}
                href={product.link}
                target="_blank"
                rel="noopener noreferrer"
                className="group bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all flex flex-col h-full"
              >
                <div className="relative pt-[100%]">
                  <img
                    src={product.image}
                    alt={product.title}
                    className="absolute inset-0 w-full h-full object-contain p-4 group-hover:scale-105 transition-transform duration-300"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = "https://via.placeholder.com/400x400?text=Prodotto+non+disponibile";
                    }}
                  />
                </div>
                <div className="p-6 flex flex-col flex-grow">
                  <h3 className="text-lg font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                    {product.title}
                  </h3>
                  <p className="mt-2 text-gray-500 flex-grow">{product.description}</p>
                  <div className="mt-4">
                    <p className="text-2xl font-bold text-blue-600">€{product.price}</p>
                    <button className="mt-4 w-full bg-blue-600 text-white px-4 py-2 rounded-md group-hover:bg-blue-700 transition-colors">
                      Acquista su Amazon
                    </button>
                  </div>
                </div>
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}