import React, { useRef } from 'react';
import { Upload } from 'lucide-react';
import { toast } from 'react-toastify';

interface FileUploadProps {
  files: (string | File)[];
  onChange: (files: (string | File)[]) => void;
  maxFiles?: number;
  allowedTypes?: string[];
  title?: string;
  subtitle?: string;
  required?: boolean;
  variant?: 'light' | 'dark';
  options?: any;
}

export function FileUpload({
  files = [],
  onChange,
  maxFiles = 10,
  allowedTypes = ['image/jpeg', 'image/png', 'image/webp'],
  title = 'Carica file',
  subtitle = 'PNG, JPG fino a 10MB',
  required = false,
  variant = 'light'
}: FileUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    if (!selectedFiles.length) return;

    if (selectedFiles.length + files.length > maxFiles) {
      toast.error(`Puoi caricare al massimo ${maxFiles} file`);
      return;
    }

    // Validate files
    const validFiles = selectedFiles.filter(file => {
      if (file.size > 10 * 1024 * 1024) {
        toast.error(`Il file ${file.name} supera i 10MB`);
        return false;
      }

      if (!allowedTypes.includes(file.type)) {
        toast.error(`Il file ${file.name} non è in un formato valido`);
        return false;
      }

      return true;
    });

    if (validFiles.length === 0) return;

    // Update state with new files
    onChange([...files, ...validFiles]);

    // Clear the input to allow selecting the same file again
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const textColorClass = variant === 'dark' ? 'text-white' : 'text-gray-700';
  const subtitleColorClass = variant === 'dark' ? 'text-white/70' : 'text-gray-500';
  const requiredTextColorClass = variant === 'dark' ? 'text-red-300' : 'text-red-500';
  const uploadIconColorClass = variant === 'dark' ? 'text-white/70' : 'text-gray-400';

  return (
    <div 
      className={`mt-1 flex flex-col items-center justify-center px-6 py-4 border-2 ${
        required && files.length === 0 
          ? variant === 'dark' 
            ? 'border-red-300/50' 
            : 'border-red-300'
          : variant === 'dark'
          ? 'border-white/20'
          : 'border-gray-200'
      } border-dashed rounded-lg hover:border-opacity-50 cursor-pointer transition-all`}
      onClick={() => fileInputRef.current?.click()}
    >
      <Upload className={`h-8 w-8 mb-2 ${uploadIconColorClass}`} />
      <div className="flex flex-col items-center text-center">
        <span className={`font-medium ${textColorClass}`}>
          {title}
        </span>
        <p className={`mt-1 text-xs ${subtitleColorClass}`}>{subtitle}</p>
        {required && files.length === 0 && (
          <p className={`mt-2 text-xs ${requiredTextColorClass}`}>
            Carica almeno un file
          </p>
        )}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept={allowedTypes.join(',')}
        multiple
        onChange={handleFileSelect}
        className="hidden"
        required={required && files.length === 0}
      />
    </div>
  );
}