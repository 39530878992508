import React, { useState, useEffect, useCallback } from 'react';
import { Heart } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { addFavorite, removeFavorite, isFavorited } from '../lib/favorites';
import { toast } from 'react-toastify';

interface FavoriteButtonProps {
  listingId: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

export function FavoriteButton({ listingId, className = '', size = 'md' }: FavoriteButtonProps) {
  const { userProfile } = useAuthContext();
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkFavoriteStatus = useCallback(async () => {
    if (!userProfile?.id) {
      setLoading(false);
      return;
    }

    try {
      const status = await isFavorited(userProfile.id, listingId);
      setIsFavorite(status);
    } catch (error) {
      console.error('Error checking favorite status:', error);
    } finally {
      setLoading(false);
    }
  }, [userProfile?.id, listingId]);

  useEffect(() => {
    checkFavoriteStatus();
  }, [checkFavoriteStatus]);

  const handleToggleFavorite = async () => {
    if (!userProfile) {
      toast.error('Devi essere autenticato per aggiungere ai preferiti');
      return;
    }

    try {
      setLoading(true);
      if (isFavorite) {
        await removeFavorite(userProfile.id, listingId);
        setIsFavorite(false);
      } else {
        await addFavorite(userProfile.id, listingId);
        setIsFavorite(true);
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
      toast.error('Errore durante l\'aggiornamento dei preferiti');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="animate-pulse h-6 w-6 bg-gray-200 rounded-full" />;
  }

  return (
    <button
      onClick={handleToggleFavorite}
      className="p-2 rounded-full hover:bg-gray-100 transition-colors"
      disabled={loading}
    >
      <Heart
        className={`h-6 w-6 ${isFavorite ? 'text-red-500 fill-current' : 'text-gray-500'}`}
      />
    </button>
  );
}