import React from 'react';
import { LucideIcon } from 'lucide-react';

interface StatCardProps {
  title: string;
  value: number | string;
  icon: LucideIcon;
  subtext?: string | null;
  subtextColor?: string;
}

export function StatCard({ 
  title, 
  value, 
  icon: Icon, 
  subtext = null, 
  subtextColor = 'text-green-500' 
}: StatCardProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm text-gray-500">{title}</p>
          <p className="text-2xl font-semibold">{value}</p>
        </div>
        <Icon className="h-8 w-8 text-blue-500" />
      </div>
      {subtext && (
        <div className={`mt-2 text-xs ${subtextColor}`}>
          {subtext}
        </div>
      )}
    </div>
  );
}