import React, { useState, useEffect } from 'react';
import { Users, CreditCard, Building2, MessageSquare, LineChart, Eye, Star } from 'lucide-react';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/locale';
import { DateFilter, DateRange } from './DateFilter';
import { getActiveVisitors } from '../../../lib/analytics/visitors';
import { StatCard } from '../landlord/analytics/StatCard';
import { BreakdownChart } from '../landlord/analytics/BreakdownChart';

interface SiteStats {
  users: {
    total: number;
    students: number;
    landlords: number;
  };
  credits: {
    totalInCirculation: number;
    totalSold: number;
    totalSoldAmount: number;
    byUserType: {
      students: number;
      landlords: number;
    };
  };
  featured: {
    totalSold: number;
    totalAmount: number;
  };
  tickets: {
    total: number;
    open: number;
    inProgress: number;
    resolved: number;
    closed: number;
  };
  listings: {
    total: number;
    active: number;
    featured: number;
    views: number;
    applications: number;
  };
  realTimeVisitors: number;
}

const defaultStats: SiteStats = {
  users: { total: 0, students: 0, landlords: 0 },
  credits: {
    totalInCirculation: 0,
    totalSold: 0,
    totalSoldAmount: 0,
    byUserType: { students: 0, landlords: 0 }
  },
  featured: { totalSold: 0, totalAmount: 0 },
  tickets: {
    total: 0,
    open: 0,
    inProgress: 0,
    resolved: 0,
    closed: 0
  },
  listings: {
    total: 0,
    active: 0,
    featured: 0,
    views: 0,
    applications: 0
  },
  realTimeVisitors: 0
};

export function AnalyticsTab() {
  const [stats, setStats] = useState<SiteStats>(defaultStats);
  const [loading, setLoading] = useState(true);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  const [dateRange, setDateRange] = useState<DateRange>('last30days');
  const [timeframe, setTimeframe] = useState<'daily' | 'weekly' | 'monthly'>('daily');

  useEffect(() => {
    loadStats();
    const interval = setInterval(loadStats, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, [dateRange]);

  // Separate effect for real-time visitors
  useEffect(() => {
    const updateVisitors = async () => {
      const activeVisitors = await getActiveVisitors();
      setStats(prev => ({ ...prev, realTimeVisitors: activeVisitors }));
    };

    updateVisitors();
    const interval = setInterval(updateVisitors, 10000); // Update every 10 seconds
    return () => clearInterval(interval);
  }, []);

  const getDateRangeFilter = () => {
    const now = new Date();
    switch (dateRange) {
      case 'today':
        return Timestamp.fromDate(new Date(now.setHours(0, 0, 0, 0)));
      case 'yesterday': {
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
        return Timestamp.fromDate(yesterday);
      }
      case 'last7days': {
        const sevenDaysAgo = new Date(now);
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        return Timestamp.fromDate(sevenDaysAgo);
      }
      case 'last30days': {
        const thirtyDaysAgo = new Date(now);
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        return Timestamp.fromDate(thirtyDaysAgo);
      }
      case 'thisMonth': {
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        return Timestamp.fromDate(firstDayOfMonth);
      }
      case 'lastMonth': {
        const firstDayOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        return Timestamp.fromDate(firstDayOfLastMonth);
      }
      case 'allTime':
      default:
        return null;
    }
  };

  const loadStats = async () => {
    try {
      const dateFilter = getDateRangeFilter();

      // Users statistics
      const usersQuery = dateFilter 
        ? query(collection(db, 'users'), where('createdAt', '>=', dateFilter))
        : query(collection(db, 'users'));
      const usersSnapshot = await getDocs(usersQuery);
      const users = {
        total: usersSnapshot.size,
        students: 0,
        landlords: 0
      };

      // Calculate credits in circulation
      let totalCreditsInCirculation = 0;
      let studentCredits = 0;
      let landlordCredits = 0;

      usersSnapshot.forEach(doc => {
        const userData = doc.data();
        if (userData.userType === 'student') {
          users.students++;
          const credits = userData.studentInfo?.credits || 0;
          studentCredits += credits;
          totalCreditsInCirculation += credits;
        } else if (userData.userType === 'landlord') {
          users.landlords++;
          const credits = userData.landlordInfo?.credits || 0;
          landlordCredits += credits;
          totalCreditsInCirculation += credits;
        }
      });

      // Listings statistics
      const listingsQuery = dateFilter
        ? query(collection(db, 'listings'), where('createdAt', '>=', dateFilter))
        : query(collection(db, 'listings'));
      const listingsSnapshot = await getDocs(listingsQuery);
      const listings = {
        total: listingsSnapshot.size,
        active: 0,
        featured: 0,
        views: 0,
        applications: 0
      };

      listingsSnapshot.forEach(doc => {
        const listingData = doc.data();
        if (listingData.status === 'active') {
          listings.active++;
        }
        if (listingData.featured?.active) {
          listings.featured++;
        }
        listings.views += listingData.views || 0;
        listings.applications += listingData.applications || 0;
      });

      // Credits statistics
      const creditsQuery = dateFilter
        ? query(collection(db, 'credits'), where('createdAt', '>=', dateFilter))
        : query(collection(db, 'credits'));
      const creditsSnapshot = await getDocs(creditsQuery);
      let totalCreditsSold = 0;
      let totalCreditsAmount = 0;

      creditsSnapshot.forEach(doc => {
        const creditData = doc.data();
        if (creditData.type === 'purchase' && creditData.status === 'completed') {
          totalCreditsSold += creditData.amount;
          totalCreditsAmount += creditData.price || 0;
        }
      });

      // Featured listings statistics
      const featuredQuery = dateFilter
        ? query(collection(db, 'featured'), where('createdAt', '>=', dateFilter))
        : query(collection(db, 'featured'));
      const featuredSnapshot = await getDocs(featuredQuery);
      let totalFeatured = 0;
      let totalFeaturedAmount = 0;

      featuredSnapshot.forEach(doc => {
        const featuredData = doc.data();
        totalFeatured++;
        totalFeaturedAmount += featuredData.price || 0;
      });

      // Tickets statistics
      const ticketsQuery = dateFilter
        ? query(collection(db, 'tickets'), where('createdAt', '>=', dateFilter))
        : query(collection(db, 'tickets'));
      const ticketsSnapshot = await getDocs(ticketsQuery);
      const tickets = {
        total: ticketsSnapshot.size,
        open: 0,
        inProgress: 0,
        resolved: 0,
        closed: 0
      };

      ticketsSnapshot.forEach(doc => {
        const ticketData = doc.data();
        tickets[ticketData.status as keyof typeof tickets]++;
      });

      setStats({
        users,
        credits: {
          totalInCirculation: totalCreditsInCirculation,
          totalSold: totalCreditsSold,
          totalSoldAmount: totalCreditsAmount,
          byUserType: {
            students: studentCredits,
            landlords: landlordCredits
          }
        },
        featured: {
          totalSold: totalFeatured,
          totalAmount: totalFeaturedAmount
        },
        tickets,
        listings,
        realTimeVisitors: stats.realTimeVisitors // Preserve real-time visitors count
      });

      setLastUpdate(new Date());
    } catch (error) {
      console.error('Error loading analytics:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento statistiche...</p>
      </div>
    );
  }

  const totalRevenue = stats.credits.totalSoldAmount + stats.featured.totalAmount;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between mb-6 flex-wrap gap-4">
        <h2 className="text-xl font-semibold">Statistiche del Sito</h2>
        <div className="w-full sm:w-auto overflow-x-auto">
          <div className="flex gap-2 min-w-max">
            <button
              onClick={() => setTimeframe('daily')}
              className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                timeframe === 'daily'
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Giornaliero
            </button>
            <button
              onClick={() => setTimeframe('weekly')}
              className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                timeframe === 'weekly'
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Settimanale
            </button>
            <button
              onClick={() => setTimeframe('monthly')}
              className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                timeframe === 'monthly'
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Mensile
            </button>
          </div>
        </div>
      </div>

      <DateFilter selectedRange={dateRange} onChange={setDateRange} />

      {/* Real-time Stats */}
      <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-xl p-6 text-white">
        <div className="flex items-center gap-3 mb-4">
          <Eye className="h-6 w-6" />
          <h3 className="text-lg font-semibold">Statistiche in Tempo Reale</h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <p className="text-sm text-blue-100">Visitatori Attivi</p>
            <p className="text-3xl font-bold">{stats.realTimeVisitors}</p>
            <p className="text-sm text-blue-200">Ultimi 5 minuti</p>
          </div>
          <div>
            <p className="text-sm text-blue-100">Fatturato Totale</p>
            <p className="text-3xl font-bold">€{totalRevenue.toLocaleString()}</p>
            <p className="text-sm text-blue-200">
              {((stats.credits.totalSoldAmount / totalRevenue) * 100).toFixed(1)}% crediti,{' '}
              {((stats.featured.totalAmount / totalRevenue) * 100).toFixed(1)}% in evidenza
            </p>
          </div>
        </div>
      </div>

      {/* Main Stats Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
        <StatCard
          title="Annunci Attivi"
          value={stats.listings.active}
          icon={Building2}
          subtext={`${stats.listings.featured} in evidenza`}
          subtextColor="text-yellow-500"
        />

        <StatCard
          title="Visualizzazioni Totali"
          value={stats.listings.views}
          icon={Eye}
          subtext="+20% rispetto al periodo precedente"
          subtextColor="text-green-500"
        />

        <StatCard
          title="Candidature Totali"
          value={stats.listings.applications}
          icon={Users}
          subtext="+15% rispetto al periodo precedente"
          subtextColor="text-green-500"
        />

        <StatCard
          title="Utenti Registrati"
          value={stats.users.total}
          icon={Users}
          subtext={`${stats.users.students} studenti, ${stats.users.landlords} proprietari`}
        />

        <StatCard
          title="Crediti Venduti"
          value={stats.credits.totalSold}
          icon={CreditCard}
          subtext={`€${stats.credits.totalSoldAmount.toLocaleString()}`}
          subtextColor="text-green-500"
        />

        <StatCard
          title="Annunci in Evidenza"
          value={stats.featured.totalSold}
          icon={Star}
          subtext={`€${stats.featured.totalAmount.toLocaleString()}`}
          subtextColor="text-green-500"
        />
      </div>

      {/* Tickets Status */}
      <div className="bg-white rounded-lg shadow-sm p-4 md:p-6">
        <h3 className="text-lg font-medium mb-4">Stato Ticket Supporto</h3>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 md:gap-4">
          <div className="p-3 md:p-4 bg-yellow-50 rounded-lg">
            <div className="text-xl md:text-2xl font-bold text-yellow-700">{stats.tickets.open}</div>
            <div className="text-xs md:text-sm text-yellow-600">Aperti</div>
          </div>
          <div className="p-3 md:p-4 bg-blue-50 rounded-lg">
            <div className="text-xl md:text-2xl font-bold text-blue-700">{stats.tickets.inProgress}</div>
            <div className="text-xs md:text-sm text-blue-600">In Lavorazione</div>
          </div>
          <div className="p-3 md:p-4 bg-green-50 rounded-lg">
            <div className="text-xl md:text-2xl font-bold text-green-700">{stats.tickets.resolved}</div>
            <div className="text-xs md:text-sm text-green-600">Risolti</div>
          </div>
          <div className="p-3 md:p-4 bg-gray-50 rounded-lg">
            <div className="text-xl md:text-2xl font-bold text-gray-700">{stats.tickets.closed}</div>
            <div className="text-xs md:text-sm text-gray-600">Chiusi</div>
          </div>
        </div>
      </div>

      {/* Performance Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-medium">Andamento Visualizzazioni</h3>
            <div className="flex gap-2">
              <button
                onClick={() => setTimeframe('daily')}
                className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                  timeframe === 'daily'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                Giornaliero
              </button>
              <button
                onClick={() => setTimeframe('weekly')}
                className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                  timeframe === 'weekly'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                Settimanale
              </button>
              <button
                onClick={() => setTimeframe('monthly')}
                className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                  timeframe === 'monthly'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                Mensile
              </button>
            </div>
          </div>

          <div className="h-64 flex items-center justify-center text-gray-500">
            <LineChart className="h-6 w-6 mr-2" />
            <span>I dati vengono aggiornati in tempo reale</span>
          </div>
        </div>

        <BreakdownChart
          title="Distribuzione Utenti"
          data={{
            'Studenti': stats.users.students,
            'Proprietari': stats.users.landlords
          }}
          total={stats.users.total}
        />
      </div>
    </div>
  );
}