import React, { useState, useEffect } from 'react';
import {
  Building2,
  Calendar,
  Clock,
  MessageSquare,
  AlertTriangle,
  CheckCircle,
  XCircle,
  Timer,
  Video,
  Info,
  User,
  Mail,
  Phone,
  FileText,
  MapPin,
  Settings,
  X
} from 'lucide-react';
import { Application } from '../../../types/application';
import { ExpirationTimer } from '../ExpirationTimer';
import { ChatModal } from '../../chat/ChatModal';
import { useUnreadMessages } from '../../../hooks/useChat';
import { generateNumericId } from '../../../utils/ids';
import { isApplicationExpired } from '../../../lib/dates';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { onMeetingChange, generateMeetingUrl } from '../../../lib/meetings';
import { Timestamp } from 'firebase/firestore';
import { ApplicationDataViewModal } from './ApplicationDataViewModal';
import { ManageMeetingModal } from '../../../components/meetings/ManageMeetingModal';
import { updateApplicationStatus } from '../../../lib/applications';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ListingDataViewModal } from '../../listings/ListingDataViewModal';

interface ApplicationCardProps {
  application: Application;
}

export function ApplicationCard({ application }: ApplicationCardProps) {
  const [showChatModal, setShowChatModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { unreadCount } = useUnreadMessages(application.chatId);
  const [meeting, setMeeting] = useState<Meeting | null>(null);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showAvailabilityInfoModal, setShowAvailabilityInfoModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showListingDataModal, setShowListingDataModal] = useState(false);

  useEffect(() => {
    if (!application.id) return;

    const unsubscribe = onMeetingChange(application.id, (updatedMeeting) => {
      setMeeting(updatedMeeting);
    });

    return () => unsubscribe();
  }, [application.id]);

  const handleJoinMeeting = (e: React.MouseEvent) => {
    e.preventDefault();
    
    if (!meeting) {
      toast.error('Nessuna videochiamata programmata');
      return;
    }

    try {
      const meetingUrl = generateMeetingUrl(meeting.id);
      window.location.href = meetingUrl;
    } catch (error) {
      console.error('Error opening video call:', error);
      toast.error('Errore nell\'apertura della videochiamata');
    }
  };

  const handleAcceptConfirm = async () => {
    if (application.status === 'expired' || isApplicationExpired(application.createdAt)) {
      toast.error('Questa candidatura è scaduta e non può essere accettata');
      return;
    }

    setLoading(true);
    try {
      await updateApplicationStatus(application.id, 'accepted', false);
      toast.success('Candidatura accettata con successo');
      setShowAcceptModal(false);
    } catch (error: any) {
      console.error('Error accepting application:', error);
      toast.error(error.message || 'Errore durante l\'accettazione della candidatura');
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    if (application.status === 'expired' || isApplicationExpired(application.createdAt)) {
      toast.error('Questa candidatura è scaduta e non può essere rifiutata');
      return;
    }

    setLoading(true);
    try {
      await updateApplicationStatus(application.id, 'rejected', false);
      toast.success('Candidatura rifiutata');
      setShowRejectModal(false);
    } catch (error) {
      console.error('Error rejecting application:', error);
      toast.error('Errore durante il rifiuto della candidatura');
    } finally {
      setLoading(false);
    }
  };

  const formatMeetingDate = (meeting: Meeting | null) => {
    if (!meeting?.date) return null;
    
    try {
      const meetingDate = meeting.date instanceof Timestamp 
        ? meeting.date.toDate() 
        : new Date(meeting.date);
        
      return format(meetingDate, "d MMMM yyyy 'alle' HH:mm", { locale: it });
    } catch (error) {
      console.error('Error formatting meeting date:', error);
      return null;
    }
  };

  return (
    <>
      <div className={`bg-white rounded-xl shadow-sm overflow-hidden border-2 ${
        application.status === 'accepted' ? 'border-green-500' :
        application.status === 'rejected' ? 'border-red-500' :
        application.status === 'expired' || isApplicationExpired(application.createdAt) ? 'border-gray-500' :
        'border-yellow-500'
      }`}>
        {/* Card Header */}
        <div className="p-4 sm:p-6 border-b border-gray-100">
          <div className="flex flex-col gap-3">
            {/* Status Badge - Moved to top */}
            <span className={`self-start inline-flex items-center gap-1.5 px-2.5 py-1 sm:px-3 sm:py-1.5 rounded-full text-xs sm:text-sm font-medium ${
              application.status === 'accepted' ? 'bg-green-100 text-green-700' :
              application.status === 'rejected' ? 'bg-red-100 text-red-700' :
              application.status === 'expired' || isApplicationExpired(application.createdAt) ? 'bg-gray-100 text-gray-700' :
              'bg-yellow-100 text-yellow-700'
            }`}>
              {application.status === 'accepted' && <CheckCircle className="h-3.5 w-3.5 sm:h-4 sm:w-4" />}
              {application.status === 'rejected' && <XCircle className="h-3.5 w-3.5 sm:h-4 sm:w-4" />}
              {(application.status === 'expired' || isApplicationExpired(application.createdAt)) && application.status !== 'accepted' && application.status !== 'rejected' && <Timer className="h-3.5 w-3.5 sm:h-4 sm:w-4" />}
              {application.status === 'pending' && !isApplicationExpired(application.createdAt) && <AlertTriangle className="h-3.5 w-3.5 sm:h-4 sm:w-4" />}
              {application.status === 'accepted' ? 'Accettata' :
               application.status === 'rejected' ? 'Rifiutata' :
               application.status === 'expired' || isApplicationExpired(application.createdAt) ? 'Scaduta' :
               'In attesa'}
            </span>

            {/* Title and Address */}
            <div>
              <h3 className="text-base sm:text-lg font-semibold text-gray-900 line-clamp-1">
                {application.listingTitle}
              </h3>
              <div className="flex flex-wrap items-center gap-2 sm:gap-4 text-sm text-gray-500 mt-1">
                <div className="flex items-center gap-1.5">
                  <MapPin className="h-4 w-4 flex-shrink-0" />
                  <span className="text-sm text-gray-500 break-words line-clamp-1">
                    {application.listingAddress}
                  </span>
                </div>
                <div className="flex items-center gap-1.5">
                  <span className="text-gray-400">Rif.</span>
                  <span>{generateNumericId(application.listingId)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Timeline */}
        <div className="px-4 sm:px-6 py-3 sm:py-4 bg-gray-50 border-b border-gray-100">
          <div className="flex flex-wrap items-center gap-3 text-sm text-gray-600">
            <div className="flex items-center gap-1.5">
              <Calendar className="h-4 w-4" />
              <span>Ricevuta il {application.createdAt.toLocaleDateString()}</span>
            </div>
            {application.status === 'pending' && !isApplicationExpired(application.createdAt) && (
              <>
                <div className="w-1 h-1 bg-gray-300 rounded-full hidden sm:block"></div>
                <div className="flex items-center gap-1.5 w-full sm:w-auto">
                  <Clock className="h-4 w-4" />
                  <ExpirationTimer expiresAt={application.expiresAt} />
                </div>
              </>
            )}
          </div>
        </div>

        {/* Content */}
        <div className="p-4 sm:p-6 space-y-4">
          {/* Avviso dati di contatto - Solo per candidature in attesa */}
          {application.status === 'pending' && !isApplicationExpired(application.expiresAt) && (
            <div className="p-4 bg-yellow-50 border border-yellow-100 rounded-lg">
              <div className="flex items-center gap-3">
                <AlertTriangle className="h-5 w-5 text-yellow-600" />
                <div>
                  <p className="text-sm font-medium text-yellow-900">
                    Dati di contatto nascosti
                  </p>
                  <p className="text-sm text-yellow-700">
                    I dati di contatto dello studente saranno visibili dopo l'accettazione della candidatura
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Contact Info - Only shown when accepted */}
          {application.status === 'accepted' && (
            <div className="p-4 bg-green-50 rounded-lg border border-green-100">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                {/* Nome - Prima colonna */}
                <div className="flex items-start gap-3">
                  <div className="p-2 bg-green-100 rounded-lg">
                    <User className="h-5 w-5 text-green-600" />
                  </div>
                  <div>
                    <p className="text-sm text-green-700 font-medium">Nome</p>
                    <p className="text-base text-green-900">{application.studentName}</p>
                  </div>
                </div>
                
                {/* Email - Seconda colonna */}
                <div className="flex items-start gap-3">
                  <div className="p-2 bg-green-100 rounded-lg">
                    <Mail className="h-5 w-5 text-green-600" />
                  </div>
                  <div>
                    <p className="text-sm text-green-700 font-medium">Email</p>
                    <p className="text-base text-green-900 break-all">{application.studentEmail}</p>
                  </div>
                </div>

                {/* Telefono - Terza colonna */}
                {application.studentPhone && (
                  <div className="flex items-start gap-3 sm:justify-end">
                    <div className="p-2 bg-green-100 rounded-lg">
                      <Phone className="h-5 w-5 text-green-600" />
                    </div>
                    <div>
                      <p className="text-sm text-green-700 font-medium">Telefono</p>
                      <p className="text-base text-green-900">{application.studentPhone}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Buttons Layout */}
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setShowDataModal(true)}
              className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-blue-600 bg-blue-50 border border-blue-100 rounded-md hover:bg-blue-100 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
            >
              <FileText className="h-4 w-4" />
              <span>Dati Candidatura</span>
            </button>
            
            <button
              onClick={() => setShowListingDataModal(true)}
              className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-purple-600 bg-purple-50 border border-purple-100 rounded-md hover:bg-purple-100 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
            >
              <Building2 className="h-4 w-4" />
              <span>Dati Annuncio</span>
            </button>

            {application.status === 'pending' && !isApplicationExpired(application.createdAt) && (
              <>
                <button
                  onClick={() => setShowAcceptModal(true)}
                  className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-green-600 bg-green-50 border border-green-100 rounded-md hover:bg-green-100 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
                  disabled={loading}
                >
                  <CheckCircle className="h-4 w-4" />
                  <span>Accetta</span>
                </button>

                <button
                  onClick={() => setShowRejectModal(true)}
                  className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-red-600 bg-red-50 border border-red-100 rounded-md hover:bg-red-100 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
                  disabled={loading}
                >
                  <XCircle className="h-4 w-4" />
                  <span>Rifiuta</span>
                </button>
              </>
            )}

            {application.status === 'accepted' && (
              <>
                {meeting && (
                  <button
                    onClick={() => setShowManageModal(true)}
                    className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-yellow-600 bg-yellow-50 border border-yellow-100 rounded-md hover:bg-yellow-100 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
                  >
                    <Settings className="h-4 w-4" />
                    <span>Gestisci</span>
                  </button>
                )}

                {meeting && (
                  <button
                    onClick={handleJoinMeeting}
                    className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none"
                  >
                    <Video className="h-4 w-4" />
                    <span>Partecipa</span>
                  </button>
                )}

                {application.chatId && (
                  <button
                    onClick={() => setShowChatModal(true)}
                    className="flex items-center gap-1.5 px-3 py-2.5 sm:py-1.5 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors min-w-[120px] justify-center flex-1 sm:flex-none relative"
                  >
                    <MessageSquare className="h-4 w-4" />
                    <span>Chat</span>
                    {unreadCount > 0 && (
                      <span className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 text-white text-xs font-medium rounded-full flex items-center justify-center">
                        {unreadCount}
                      </span>
                    )}
                  </button>
                )}
              </>
            )}
          </div>

          {/* Meeting Info */}
          {application.status === 'accepted' && meeting && (
            <div className="p-4 bg-blue-50 border border-blue-100 rounded-lg mt-4">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-blue-100 rounded-lg">
                  <Video className="h-6 w-6 text-blue-600" />
                </div>
                <div>
                  <p className="text-base font-medium text-blue-900">
                    Videochiamata programmata
                  </p>
                  <p className="text-sm text-blue-700 mt-0.5">
                    {formatMeetingDate(meeting)}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {showChatModal && application.chatId && (
        <ChatModal
          chatId={application.chatId}
          otherPartyName={application.studentName}
          onClose={() => setShowChatModal(false)}
        />
      )}

      {showDataModal && (
        <ApplicationDataViewModal
          application={application}
          onClose={() => setShowDataModal(false)}
        />
      )}

      {showManageModal && meeting && (
        <ManageMeetingModal
          meeting={meeting}
          onClose={() => setShowManageModal(false)}
        />
      )}

      {showAcceptModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Conferma Accettazione</h2>
              <button 
                onClick={() => setShowAcceptModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="mb-6">
              <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-4">
                <p className="text-sm text-yellow-700">
                  Accettando questa candidatura:
                </p>
                <ul className="mt-2 text-sm text-yellow-600 list-disc list-inside">
                  <li>I tuoi dati di contatto saranno visibili allo studente</li>
                  <li>Potrai programmare una videochiamata</li>
                  <li>Avrai accesso alla chat privata</li>
                </ul>
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowAcceptModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Annulla
              </button>
              <button
                onClick={handleAcceptConfirm}
                disabled={loading}
                className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 disabled:opacity-50"
              >
                {loading ? 'Accettazione...' : 'Conferma Accettazione'}
              </button>
            </div>
          </div>
        </div>
      )}

      {showAvailabilityInfoModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            {/* ... contenuto del modale disponibilità ... */}
          </div>
        </div>
      )}

      {showRejectModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Conferma Rifiuto</h2>
              <button 
                onClick={() => setShowRejectModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="mb-6">
              <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
                <p className="text-sm text-red-700">
                  Rifiutando questa candidatura:
                </p>
                <ul className="mt-2 text-sm text-red-600 list-disc list-inside">
                  <li>L'azione non potrà essere annullata</li>
                  <li>Lo studente riceverà una notifica</li>
                  <li>Non potrai più accettare questa candidatura in futuro</li>
                </ul>
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowRejectModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Annulla
              </button>
              <button
                onClick={handleReject}
                disabled={loading}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 disabled:opacity-50"
              >
                {loading ? 'Rifiuto in corso...' : 'Conferma Rifiuto'}
              </button>
            </div>
          </div>
        </div>
      )}

      {showListingDataModal && (
        <ListingDataViewModal
          application={application}
          onClose={() => setShowListingDataModal(false)}
          isLandlord={true}
        />
      )}
    </>
  );
}