import React from 'react';
import { Listing } from '../types/listing';

interface HouseRulesProps {
  listing: Listing;
}

export function HouseRules({ listing }: HouseRulesProps) {
  if (!listing) return null;

  const rules = [
    {
      condition: !listing.rules?.fumare,
      text: 'Non è permesso fumare all\'interno dell\'appartamento'
    },
    {
      condition: !listing.rules?.animali,
      text: 'Animali domestici non ammessi'
    },
    {
      condition: listing.rules?.ospitiOccasionali,
      text: 'Ospiti occasionali consentiti previa comunicazione'
    }
  ].filter(rule => rule.condition);

  if (rules.length === 0) return null;

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-4">Regole della Casa</h2>
      <ul className="list-disc list-inside space-y-2 text-gray-600">
        {rules.map((rule, index) => (
          <li key={index}>{rule.text}</li>
        ))}
      </ul>
    </div>
  );
}