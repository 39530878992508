import React, { useState } from 'react';
import { X, Calendar, Video, Trash2 } from 'lucide-react';
import { Meeting } from '../../types/meeting';
import { deleteMeeting } from '../../lib/meetings';
import { RescheduleMeetingModal } from './RescheduleMeetingModal';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';

interface ManageMeetingModalProps {
  meeting: Meeting;
  onClose: () => void;
}

export function ManageMeetingModal({ meeting, onClose }: ManageMeetingModalProps) {
  const [showReschedule, setShowReschedule] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (!window.confirm('Sei sicuro di voler eliminare questa videochiamata?')) {
      return;
    }

    setLoading(true);
    try {
      await deleteMeeting(meeting.id);
      toast.success('Videochiamata eliminata con successo');
      onClose();
    } catch (error) {
      toast.error('Errore durante l\'eliminazione della videochiamata');
    } finally {
      setLoading(false);
    }
  };

  const formatMeetingDateTime = (meeting: Meeting): string => {
    try {
      const meetingDate = meeting.date instanceof Timestamp 
        ? meeting.date.toDate() 
        : new Date(meeting.date);
        
      const meetingTime = meeting.time instanceof Timestamp
        ? meeting.time.toDate()
        : new Date(meeting.time);

      return format(meetingDate, "d MMMM yyyy 'alle' HH:mm", { locale: it });
    } catch (error) {
      return 'Data non disponibile';
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg max-w-md w-full">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Gestisci Videochiamata</h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="space-y-6">
              {/* Meeting Info */}
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center gap-3 mb-2">
                  <Calendar className="h-5 w-5 text-gray-500" />
                  <div>
                    <p className="font-medium">Data e ora:</p>
                    <p className="text-gray-600">{formatMeetingDateTime(meeting)}</p>
                  </div>
                </div>
              </div>

              {/* Actions */}
              <div className="space-y-3">
                <button
                  onClick={() => setShowReschedule(true)}
                  className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Calendar className="h-5 w-5" />
                  Riprogramma
                </button>

                <button
                  onClick={handleDelete}
                  disabled={loading}
                  className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors disabled:opacity-50"
                >
                  <Trash2 className="h-5 w-5" />
                  {loading ? 'Eliminazione...' : 'Elimina videochiamata'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showReschedule && (
        <RescheduleMeetingModal
          meetingId={meeting.id}
          existingMeeting={meeting}
          onClose={() => setShowReschedule(false)}
          onSuccess={() => {
            setShowReschedule(false);
            onClose();
          }}
        />
      )}
    </>
  );
}