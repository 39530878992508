import React, { useState, useEffect } from 'react';
import { Mail, Phone, Calendar, Search, CreditCard, User, Building2, ArrowUp, ArrowDown } from 'lucide-react';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { UserDetailsModal } from './UserDetailsModal';

interface UserData {
  id: string;
  email: string;
  displayName: string;
  userType: 'student' | 'landlord';
  phoneNumber?: string;
  createdAt: Date;
  lastLogin?: Date;
  landlordInfo?: {
    credits: number;
    listingsCount: number;
    verificationStatus: string;
  };
  studentInfo?: {
    university: string;
    studyProgram: string;
    studyYear: string;
    applicationsCount?: number;
    credits?: number;
  };
}

type SortField = 'displayName' | 'email' | 'userType' | 'credits' | 'activity' | 'lastLogin';
type SortDirection = 'asc' | 'desc';

export function UsersTab() {
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState<'all' | 'student' | 'landlord'>('all');
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    field: SortField;
    direction: SortDirection;
  }>({
    field: 'displayName',
    direction: 'asc'
  });

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      const usersQuery = query(collection(db, 'users'), orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(usersQuery);
      const userData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        lastLogin: doc.data().lastLogin?.toDate()
      })) as UserData[];
      setUsers(userData);
    } catch (error) {
      console.error('Error loading users:', error);
      toast.error('Errore nel caricamento degli utenti');
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (field: SortField) => {
    setSortConfig(current => ({
      field,
      direction: current.field === field && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getSortedUsers = (users: UserData[]) => {
    const sortedUsers = [...users].sort((a, b) => {
      const direction = sortConfig.direction === 'asc' ? 1 : -1;
      
      switch (sortConfig.field) {
        case 'displayName':
          return direction * a.displayName.localeCompare(b.displayName);
        case 'email':
          return direction * a.email.localeCompare(b.email);
        case 'userType':
          return direction * a.userType.localeCompare(b.userType);
        case 'credits':
          const aCredits = a.userType === 'landlord' ? a.landlordInfo?.credits || 0 : a.studentInfo?.credits || 0;
          const bCredits = b.userType === 'landlord' ? b.landlordInfo?.credits || 0 : b.studentInfo?.credits || 0;
          return direction * (aCredits - bCredits);
        case 'activity':
          const aActivity = a.userType === 'landlord' ? a.landlordInfo?.listingsCount || 0 : a.studentInfo?.applicationsCount || 0;
          const bActivity = b.userType === 'landlord' ? b.landlordInfo?.listingsCount || 0 : b.studentInfo?.applicationsCount || 0;
          return direction * (aActivity - bActivity);
        case 'lastLogin':
          const aDate = a.lastLogin?.getTime() || 0;
          const bDate = b.lastLogin?.getTime() || 0;
          return direction * (aDate - bDate);
        default:
          return 0;
      }
    });

    return sortedUsers;
  };

  const getActivityStatus = (user: UserData) => {
    if (user.userType === 'landlord') {
      if (user.landlordInfo?.listingsCount === 0) {
        return 'Nessun annuncio pubblicato';
      }
      return `${user.landlordInfo?.listingsCount || 0} annunci attivi`;
    } else if (user.userType === 'student') {
      if (!user.studentInfo?.applicationsCount) {
        return 'Nessuna candidatura';
      }
      return `${user.studentInfo.applicationsCount} candidature inviate`;
    }
    return 'Nessuna attività';
  };

  const getCredits = (user: UserData) => {
    if (user.userType === 'landlord') {
      return user.landlordInfo?.credits || 0;
    } else {
      return user.studentInfo?.credits || 0;
    }
  };

  const filteredUsers = users.filter(user => {
    if (filter !== 'all' && user.userType !== filter) return false;
    
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      return (
        user.displayName?.toLowerCase().includes(searchLower) ||
        user.email?.toLowerCase().includes(searchLower) ||
        user.phoneNumber?.toLowerCase().includes(searchLower)
      );
    }
    
    return true;
  });

  const sortedUsers = getSortedUsers(filteredUsers);

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortConfig.field !== field) return null;
    return sortConfig.direction === 'asc' ? 
      <ArrowUp className="h-4 w-4" /> : 
      <ArrowDown className="h-4 w-4" />;
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento utenti...</p>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <div className="flex-1">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Cerca utenti..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setFilter('all')}
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              filter === 'all'
                ? 'bg-blue-100 text-blue-700'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            Tutti
          </button>
          <button
            onClick={() => setFilter('student')}
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              filter === 'student'
                ? 'bg-blue-100 text-blue-700'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            <User className="h-4 w-4 inline mr-2" />
            Studenti
          </button>
          <button
            onClick={() => setFilter('landlord')}
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              filter === 'landlord'
                ? 'bg-blue-100 text-blue-700'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            <Building2 className="h-4 w-4 inline mr-2" />
            Proprietari
          </button>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden rounded-md">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th 
                  scope="col" 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('displayName')}
                >
                  <div className="flex items-center gap-1">
                    Utente
                    <SortIcon field="displayName" />
                  </div>
                </th>
                <th 
                  scope="col" 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('email')}
                >
                  <div className="flex items-center gap-1">
                    Contatti
                    <SortIcon field="email" />
                  </div>
                </th>
                <th 
                  scope="col" 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('userType')}
                >
                  <div className="flex items-center gap-1">
                    Tipo
                    <SortIcon field="userType" />
                  </div>
                </th>
                <th 
                  scope="col" 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('credits')}
                >
                  <div className="flex items-center gap-1">
                    Crediti
                    <SortIcon field="credits" />
                  </div>
                </th>
                <th 
                  scope="col" 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('activity')}
                >
                  <div className="flex items-center gap-1">
                    Attività
                    <SortIcon field="activity" />
                  </div>
                </th>
                <th 
                  scope="col" 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('lastLogin')}
                >
                  <div className="flex items-center gap-1">
                    Ultimo accesso
                    <SortIcon field="lastLogin" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortedUsers.map((user) => (
                <tr 
                  key={user.id} 
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => setSelectedUser(user)}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {user.displayName}
                        </div>
                        <div className="text-sm text-gray-500">
                          Registrato {format(user.createdAt, 'dd/MM/yyyy')}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900 flex items-center">
                      <Mail className="h-4 w-4 mr-1" />
                      {user.email}
                    </div>
                    {user.phoneNumber && (
                      <div className="text-sm text-gray-500 flex items-center">
                        <Phone className="h-4 w-4 mr-1" />
                        {user.phoneNumber}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      user.userType === 'student'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-blue-100 text-blue-800'
                    }`}>
                      {user.userType === 'student' ? 'Studente' : 'Proprietario'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center text-sm text-gray-900">
                      <CreditCard className="h-4 w-4 mr-1" />
                      {getCredits(user)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {getActivityStatus(user)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {user.lastLogin ? format(user.lastLogin, 'dd/MM/yyyy HH:mm') : 'Mai'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedUser && (
        <UserDetailsModal
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
        />
      )}
    </div>
  );
}