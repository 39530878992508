import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  updateDoc,
  addDoc,
  Timestamp,
  serverTimestamp,
  runTransaction,
  orderBy,
  onSnapshot,
  writeBatch,
  increment,
  limit,
  getDoc,
  DocumentData,
  QuerySnapshot,
  FirestoreError,
  getFirestore
} from 'firebase/firestore';
import { db } from './firebase';
import { Application, ApplicationStatus } from '../types/application';
import { useCredits, addCredits, refundCredits } from './credits';
import { toast } from 'react-toastify';
import { sendApplicationAcceptedEmail } from './email';
import { createChat } from './chat';
import { 
  createApplicationReceivedNotification, 
  createApplicationExpiringNotification,
  createApplicationAcceptedNotification,
  createApplicationExpiredNotification
} from './notifications';
import { getListing } from './listings';
import { auth } from './firebase';
import { isApplicationExpired } from './dates';

const applicationsCollection = collection(db, 'applications');
const listingsCollection = collection(db, 'listings');
const usersCollection = collection(db, 'users');
const chatsCollection = collection(db, 'chats');
const availabilityCollection = collection(db, 'availability');
const CREDITS_COST = 5;
const EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const EXPIRATION_WARNING_TIME = 1 * 60 * 60 * 1000; // 1 hour in milliseconds

export async function hasAppliedToListing(userId: string, listingId: string): Promise<boolean> {
  try {
    const existingApplicationQuery = query(
      applicationsCollection,
      where('userId', '==', userId),
      where('listingId', '==', listingId),
      limit(1)
    );

    const existingApplications = await getDocs(existingApplicationQuery);
    return !existingApplications.empty;
  } catch (error) {
    console.error('Error checking application status:', error);
    return false;
  }
}

export async function createApplication(applicationData: ApplicationData): Promise<string> {
  const db = getFirestore();
  const userRef = doc(db, 'users', applicationData.userId);
  const listingRef = doc(db, 'listings', applicationData.listingId);
  const newApplicationRef = doc(collection(db, 'applications'));

  try {
    await runTransaction(db, async (transaction) => {
      // Verifica dei crediti e disponibilità come prima
      const freshUserDoc = await transaction.get(userRef);
      const freshListingDoc = await transaction.get(listingRef);
      const freshUserData = freshUserDoc.data();
      const freshListingData = freshListingDoc.data();
      const currentCredits = freshUserData.studentInfo?.credits || 0;
      
      if (currentCredits < CREDITS_COST) {
        throw new Error('Crediti insufficienti per inviare la candidatura');
      }
      
      if (freshListingData.status !== 'active' || freshListingData.availableSpots <= 0) {
        throw new Error('Annuncio non più disponibile');
      }

      // Aggiungi tutti i dati dell'annuncio alla candidatura
      const applicationWithListingData = {
        ...applicationData,
        listingCategory: freshListingData.category,
        listingPrice: freshListingData.price,
        listingAddress: freshListingData.location.formattedAddress,
        listingUtilities: {
          type: freshListingData.utilities.type,
          monthlyFee: freshListingData.utilities.monthlyFee || ''
        },
        listingFeatures: {
          airConditioning: freshListingData.features.airConditioning || false,
          elevator: freshListingData.features.elevator || false,
          furnished: freshListingData.features.furnished || false
        },
        listingMaxOccupants: freshListingData.maxOccupants,
        listingBathrooms: freshListingData.bathrooms,
        listingHeating: freshListingData.heating,
        listingMinStay: freshListingData.minStay,
        listingNoticePeriod: freshListingData.noticePeriod,
        listingKitchenFeatures: {
          balcony: freshListingData.kitchenFeatures.balcony || false,
          separate: freshListingData.kitchenFeatures.separate || false,
          window: freshListingData.kitchenFeatures.window || false
        },
        listingBathroomFeatures: {
          bathtub: freshListingData.bathroomFeatures.bathtub || false,
          shower: freshListingData.bathroomFeatures.shower || false,
          window: freshListingData.bathroomFeatures.window || false
        },
        listingImages: freshListingData.images || [],
        listingFloorPlans: freshListingData.floorPlans || [],
        createdAt: serverTimestamp(),
        expiresAt: Timestamp.fromDate(new Date(Date.now() + 24 * 60 * 60 * 1000)),
        status: 'pending'
      };

      // Crea la candidatura con tutti i dati
      transaction.set(newApplicationRef, applicationWithListingData);

      // Aggiorna i crediti e crea il movimento come prima
      transaction.update(userRef, {
        'studentInfo.credits': increment(-CREDITS_COST)
      });

      transaction.set(doc(collection(db, 'credits')), {
        userId: applicationData.userId,
        amount: -CREDITS_COST,
        type: 'usage',
        title: 'Invio candidatura',
        description: `Candidatura per "${applicationData.listingTitle}"`,
        applicationId: newApplicationRef.id,
        listingId: applicationData.listingId,
        createdAt: serverTimestamp(),
        status: 'completed'
      });
    });

    return newApplicationRef.id;
  } catch (error: any) {
    console.error('Errore durante la creazione della candidatura:', error);
    throw new Error(error.message || 'Errore durante l\'invio della candidatura');
  }
}

export async function updateApplicationStatus(
  applicationId: string, 
  status: 'accepted' | 'rejected',
  isAdmin: boolean = false
): Promise<void> {
  try {
    const applicationRef = doc(applicationsCollection, applicationId);
    const applicationSnap = await getDoc(applicationRef);
    
    if (!applicationSnap.exists()) {
      throw new Error('Candidatura non trovata');
    }

    const application = applicationSnap.data() as Application;
    
    await runTransaction(db, async (transaction) => {
      // 1. Esegui prima tutte le letture
      const freshAppSnap = await transaction.get(applicationRef);
      if (!freshAppSnap.exists() || freshAppSnap.data().status !== 'pending') {
        throw new Error('La candidatura non può essere processata');
      }

      let listingData;
      let landlordData;
      
      // Inizializza updateData all'inizio
      const updateData: Partial<Application> = {
        status,
        updatedAt: serverTimestamp()
      };
      
      if (status === 'accepted') {
        const listingRef = doc(listingsCollection, application.listingId);
        const listingSnap = await transaction.get(listingRef);
        if (!listingSnap.exists()) {
          throw new Error('Annuncio non trovato');
        }
        listingData = listingSnap.data();

        const landlordRef = doc(usersCollection, application.listingOwnerId);
        const landlordSnap = await transaction.get(landlordRef);
        if (!landlordSnap.exists()) {
          throw new Error('Proprietario non trovato');
        }
        landlordData = landlordSnap.data();

        // Crea la chat
        const chatRef = doc(collection(db, 'chats'));
        transaction.set(chatRef, {
          listingId: application.listingId,
          listingTitle: application.listingTitle,
          studentId: application.userId,
          studentName: application.studentName,
          studentEmail: application.studentEmail,
          landlordId: application.listingOwnerId,
          landlordName: landlordData.displayName,
          landlordEmail: landlordData.email,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          lastMessage: null,
          unreadCount: {
            [application.userId]: 0,
            [application.listingOwnerId]: 0
          }
        });

        // Aggiorna i dati dell'applicazione con le informazioni del proprietario
        updateData.landlordName = landlordData.displayName || '';
        updateData.landlordEmail = landlordData.email || '';
        updateData.landlordPhone = landlordData.phoneNumber || '';
        updateData.chatId = chatRef.id;

        // Aggiorna l'annuncio
        const newAvailableSpots = listingData.availableSpots - 1;
        transaction.update(listingRef, {
          availableSpots: newAvailableSpots,
          status: newAvailableSpots === 0 ? 'unavailable' : 'active'
        });
      }

      transaction.update(applicationRef, updateData);
    });
  } catch (error) {
    console.error('Errore durante l\'aggiornamento dello stato:', error);
    throw new Error('Errore durante l\'aggiornamento dello stato della candidatura');
  }
}

export function onStudentApplicationsChange(
  userId: string,
  callback: (applications: Application[]) => void,
  onError?: (error: Error) => void
) {
  if (!userId) {
    callback([]);
    return () => {};
  }

  const q = query(
    applicationsCollection,
    where('userId', '==', userId),
    orderBy('createdAt', 'desc')
  );

  let isActive = true;

  const unsubscribe = onSnapshot(
    q,
    {
      next: (snapshot) => {
        if (!isActive) return;
        const applications = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date(),
          updatedAt: doc.data().updatedAt?.toDate(),
          expiresAt: doc.data().expiresAt?.toDate()
        })) as Application[];
        callback(applications);
      },
      error: () => {
        if (!isActive) return;
        callback([]);
      }
    }
  );

  return () => {
    isActive = false;
    try {
      unsubscribe();
    } catch {
      // Ignoriamo eventuali errori durante l'unsubscribe
    }
  };
}

export function onLandlordApplicationsChange(
  userId: string,
  callback: (applications: Application[]) => void,
  onError?: (error: Error) => void
) {
  if (!userId) {
    callback([]);
    return () => {};
  }

  const q = query(
    applicationsCollection,
    where('listingOwnerId', '==', userId)
  );

  return onSnapshot(
    q,
    {
      next: (snapshot) => {
        try {
          const applications = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              createdAt: formatFirestoreDate(data.createdAt),
              updatedAt: formatFirestoreDate(data.updatedAt),
              moveInDate: formatFirestoreDate(data.moveInDate),
              expiresAt: data.expiresAt ? 
                formatFirestoreDate(data.expiresAt) : 
                new Date(formatFirestoreDate(data.createdAt).getTime() + 24 * 60 * 60 * 1000)
            };
          }).sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
          
          callback(applications);
        } catch (error) {
          console.error('Error processing applications data:', error);
          if (onError) onError(error as Error);
          callback([]);
        }
      },
      error: (error) => {
        console.error('Error in applications subscription:', error);
        if (onError) onError(error);
        callback([]);
      }
    }
  );
}

function formatFirestoreDate(date: any): Date {
  if (!date) return new Date();
  if (date instanceof Timestamp) return date.toDate();
  if (date instanceof Date) return date;
  if (typeof date === 'string') return new Date(date);
  if (date.seconds) return new Date(date.seconds * 1000);
  return new Date();
}

export function onListingApplicationsChange(
  listingIds: string[],
  callback: (applications: Application[]) => void,
  onError?: (error: Error) => void
) {
  if (listingIds.length === 0) {
    callback([]);
    return () => {};
  }

  const q = query(
    applicationsCollection,
    where('listingId', 'in', listingIds),
    orderBy('createdAt', 'desc'),
    limit(100)
  );

  return onSnapshot(
    q,
    {
      next: (snapshot) => {
        const applications = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date(),
          updatedAt: doc.data().updatedAt?.toDate(),
          expiresAt: doc.data().expiresAt?.toDate()
        })) as Application[];
        callback(applications);
      },
      error: (error) => {
        console.error('Error in applications subscription:', error);
        if (onError) {
          onError(error as Error);
        }
        callback([]); // Restituisci array vuoto in caso di errore
      }
    }
  );
}

export function onAdminApplicationsChange(
  callback: (applications: Application[]) => void,
  onError?: (error: Error) => void
) {
  const q = query(
    applicationsCollection,
    orderBy('createdAt', 'desc')
  );

  return onSnapshot(
    q,
    (snapshot) => {
      const applications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        updatedAt: doc.data().updatedAt?.toDate(),
        expiresAt: doc.data().expiresAt?.toDate()
      })) as Application[];
      callback(applications);
    },
    (error) => {
      console.error('Error in admin applications subscription:', error);
      if (onError) {
        onError(error as Error);
      }
    }
  );
}

export function onApplicationsChange(
  userId: string,
  callback: (applications: Application[]) => void,
  onError?: (error: Error) => void
) {
  return onStudentApplicationsChange(userId, callback, onError);
}

export async function checkApplicationExpiration(application: Application): Promise<void> {
  if (!application?.id) {
    console.error('ID applicazione mancante:', application);
    return;
  }

  try {
    // Verifica preliminare dello stato
    if (application.status !== 'pending') {
      return;
    }

    // Verifica della scadenza
    let createdAt: Date | null = null;
    if (application.createdAt instanceof Timestamp) {
      createdAt = application.createdAt.toDate();
    } else if (application.createdAt instanceof Date) {
      createdAt = application.createdAt;
    } else {
      try {
        createdAt = new Date(application.createdAt);
      } catch (error) {
        console.error('Errore parsing data:', error);
        return;
      }
    }

    if (!createdAt || !isApplicationExpired(createdAt)) {
      return;
    }

    // Aggiorna solo lo stato dell'applicazione
    const applicationRef = doc(applicationsCollection, application.id);
    await updateDoc(applicationRef, {
      status: 'expired',
      updatedAt: serverTimestamp()
    });

    // Gestione del rimborso crediti in una transazione separata
    await handleCreditsRefund(application);

    // Notifica dopo che tutto è stato completato con successo
    if (application.userId && application.listingTitle && application.listingId) {
      await createApplicationExpiredNotification(
        application.userId,
        application.listingTitle,
        application.listingId
      );
    }

  } catch (error: any) {
    if (error.code === 'permission-denied') {
      console.error('Permessi insufficienti per aggiornare la candidatura');
      return;
    }
    console.error('Errore durante il controllo della scadenza:', error);
    throw error;
  }
}

// Funzione helper per gestire il rimborso dei crediti
async function handleCreditsRefund(application: Application): Promise<void> {
  try {
    console.log('Inizio processo di rimborso per applicazione:', application.id);
    
    const batch = writeBatch(db);
    const userRef = doc(usersCollection, application.userId);
    const creditRef = doc(collection(db, 'credits'));

    // Verifica preliminare dell'utente
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      throw new Error('Utente non trovato');
    }

    // Aggiorna i crediti dell'utente
    batch.update(userRef, {
      'studentInfo.credits': increment(CREDITS_COST)
    });

    // Registra il movimento dei crediti
    batch.set(creditRef, {
      userId: application.userId,
      type: 'refund',
      amount: CREDITS_COST,
      title: 'Rimborso crediti',
      description: `Rimborso per candidatura scaduta - ${application.listingTitle}`,
      applicationId: application.id,
      listingId: application.listingId,
      createdAt: serverTimestamp(),
      status: 'completed'
    });

    await batch.commit();
    console.log('Rimborso completato con successo per applicazione:', application.id);
    
  } catch (error) {
    console.error('Errore dettagliato durante il rimborso dei crediti:', error);
    if (error.code === 'permission-denied') {
      console.warn('Permessi insufficienti per il rimborso dei crediti');
      return;
    }
    // Rilancia l'errore per gestirlo al livello superiore
    throw new Error(`Errore durante il rimborso dei crediti: ${error.message}`);
  }
}

export async function incrementListingViews(listingId: string, maxRetries = 3): Promise<void> {
  let attempts = 0;
  
  while (attempts < maxRetries) {
    try {
      const batch = writeBatch(db);
      
      const listingRef = doc(listingsCollection, listingId);
      batch.update(listingRef, {
        views: increment(1),
        updatedAt: serverTimestamp()
      });

      const analyticsRef = doc(analyticsCollection, listingId);
      const analyticsDoc = await getDoc(analyticsRef);

      if (analyticsDoc.exists()) {
        batch.update(analyticsRef, {
          views: increment(1),
          updatedAt: serverTimestamp()
        });
      } else {
        batch.set(analyticsRef, {
          views: 1,
          applications: 0,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      }

      await batch.commit();
      return;
      
    } catch (error) {
      attempts++;
      if (attempts === maxRetries) {
        console.error('Errore definitivo incremento visualizzazioni:', error);
        throw error;
      }
      await new Promise(resolve => setTimeout(resolve, 1000 * attempts));
    }
  }
}

// Aggiungi questa funzione per verificare se una candidatura è scaduta
export function isApplicationExpired(application: Application): boolean {
  if (!application.expiresAt) return false;
  
  const expiryDate = application.expiresAt instanceof Date 
    ? application.expiresAt 
    : new Date(application.expiresAt);
    
  return new Date() > expiryDate;
}

// Funzione per controllare automaticamente le candidature scadute
export function setupExpirationCheck() {
  console.log('Inizializzazione controllo scadenze candidature');
  
  const now = new Date();
  
  const q = query(
    applicationsCollection,
    where('status', '==', 'pending'),
    where('expiresAt', '<=', now),
    orderBy('expiresAt', 'asc')
  );

  return onSnapshot(q, async (snapshot) => {
    console.log(`Trovate ${snapshot.docs.length} candidature scadute da processare`);
    
    for (const doc of snapshot.docs) {
      const application = { id: doc.id, ...doc.data() } as Application;
      
      try {
        console.log(`Processo candidatura scaduta: ${application.id}`);
        
        // Aggiorna lo stato
        await updateDoc(doc.ref, {
          status: 'expired',
          updatedAt: serverTimestamp()
        });

        // Gestione del rimborso crediti
        await handleCreditsRefund(application);
        
        console.log(`Candidatura ${application.id} processata con successo`);
        
      } catch (error) {
        console.error(`Errore nel processo della candidatura ${application.id}:`, error);
      }
    }
  });
}

export function initializeApplicationSystem() {
  // Imposta il controllo delle scadenze
  const unsubscribe = setupExpirationCheck();
  
  // Restituisce la funzione di cleanup
  return () => {
    if (unsubscribe) {
      unsubscribe();
    }
  };
}