import React, { useState, useEffect } from 'react';
import { MessageSquare, Plus, ArrowLeft, MessageSquarePlus, InboxIcon, MessageCircle, ChevronLeft } from 'lucide-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { onUserTicketsChange, onAdminTicketsChange, updateTicket } from '../../lib/tickets';
import { Ticket } from '../../types/ticket';
import { TicketList } from './TicketList';
import { TicketChat } from './TicketChat';
import { CreateTicketModal } from './CreateTicketModal';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

interface SupportDashboardProps {
  onBack: () => void;
}

export function SupportDashboard({ onBack }: SupportDashboardProps) {
  const { userProfile } = useAuthContext();
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const isAdmin = userProfile?.email === 'info@unistanza.it';

  useEffect(() => {
    if (!userProfile?.id) return;

    const unsubscribe = isAdmin
      ? onAdminTicketsChange(setTickets)
      : onUserTicketsChange(userProfile.id, setTickets);

    setLoading(false);
    return () => unsubscribe();
  }, [userProfile?.id, isAdmin]);

  const handleUpdateTicket = async (ticketId: string, data: Partial<Ticket>) => {
    try {
      await updateTicket(ticketId, data);
      if (selectedTicket?.id === ticketId) {
        setSelectedTicket(prev => prev ? { ...prev, ...data } : null);
      }
    } catch (error) {
      console.error('Error updating ticket:', error);
    }
  };

  const formatSafeDate = (date: any): string => {
    if (!date) return '';
    
    try {
      let dateObj: Date;
      
      if (date instanceof Timestamp) {
        dateObj = date.toDate();
      } else if (typeof date === 'string') {
        dateObj = new Date(date);
      } else if (date instanceof Date) {
        dateObj = date;
      } else if (date.seconds) {
        // Gestisce il caso di oggetti Firestore timestamp
        dateObj = new Date(date.seconds * 1000);
      } else {
        return '';
      }

      if (isNaN(dateObj.getTime())) {
        return '';
      }

      return format(dateObj, 'dd/MM/yyyy HH:mm');
    } catch (error) {
      console.error('Errore nella formattazione della data:', error);
      return '';
    }
  };

  return (
    <div className="space-y-6">
      <button
        onClick={onBack}
        className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 transition-colors mb-4"
      >
        <ChevronLeft className="h-4 w-4 mr-1" />
        Torna al Profilo
      </button>

      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div>
          <h2 className="text-lg sm:text-xl font-semibold text-gray-900">Assistenza e Supporto</h2>
          <p className="text-sm text-gray-500 mt-1">Gestisci le tue richieste di supporto</p>
        </div>
        
        <button
          onClick={() => setShowCreateModal(true)}
          className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
        >
          <MessageSquarePlus className="h-4 w-4 sm:h-5 sm:w-5 mr-2" />
          Nuova Richiesta
        </button>
      </div>

      {loading ? (
        <div className="text-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-2 text-sm text-gray-500">Caricamento ticket...</p>
        </div>
      ) : (
        <div className="space-y-4">
          {selectedTicket ? (
            <div>
              <button
                onClick={() => setSelectedTicket(null)}
                className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 transition-colors mb-4"
              >
                <ChevronLeft className="h-4 w-4 mr-1" />
                Torna ai ticket
              </button>
              <TicketChat 
                ticket={selectedTicket} 
                onUpdateTicket={handleUpdateTicket}
              />
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-4">
              {tickets.map((ticket) => (
                <div
                  key={ticket.id}
                  className="bg-white shadow-sm rounded-lg p-4 sm:p-6 hover:shadow-md transition-shadow"
                >
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                    <div className="flex items-start gap-3">
                      <div className={`p-2 rounded-full ${
                        ticket.status === 'aperto' ? 'bg-green-100' : 'bg-gray-100'
                      }`}>
                        <MessageCircle className={`h-5 w-5 ${
                          ticket.status === 'aperto' ? 'text-green-600' : 'text-gray-500'
                        }`} />
                      </div>
                      
                      <div>
                        <h3 className="text-sm sm:text-base font-medium text-gray-900">{ticket.subject}</h3>
                        <p className="text-xs sm:text-sm text-gray-500 mt-1">
                          Ultimo aggiornamento: {formatSafeDate(ticket.updatedAt)}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center gap-3">
                      <span className={`text-xs px-2.5 py-1 rounded-full font-medium ${
                        ticket.status === 'aperto' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                      }`}>
                        {ticket.status === 'aperto' ? 'Aperto' : 'Chiuso'}
                      </span>
                      
                      <button
                        onClick={() => setSelectedTicket(ticket)}
                        className="text-sm text-blue-600 hover:text-blue-700 font-medium"
                      >
                        Visualizza
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {showCreateModal && (
        <CreateTicketModal
          onClose={() => setShowCreateModal(false)}
          onSuccess={() => {
            setShowCreateModal(false);
          }}
        />
      )}
    </div>
  );
}