import { 
    collection,
    doc,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    deleteDoc,
    serverTimestamp,
    query,
    where,
    orderBy
  } from 'firebase/firestore';
  import { db } from './firebase';
  import { requireAdmin } from './admin';
  import { toast } from 'react-toastify';
  
  export interface CreditPackage {
    id: string;
    name: string;
    credits: number;
    price: number;
    description: string;
    pricePerCredit: number;
    popular: boolean;
    features: string[];
    userType: 'student' | 'landlord';
    createdAt?: Timestamp;
    updatedAt?: Timestamp;
  }
  
  export interface FeaturedPlan {
    id: string;
    name: string;
    price: number;
    durationHours: number;
    durationLabel: string;
    description: string;
    popular?: boolean;
    features: string[];
    createdAt: Date;
    updatedAt?: Date;
  }
  
  const creditPackagesCollection = collection(db, 'creditPackages');
  export const featuredPlansCollection = collection(db, 'featuredPlans');
  
  // Credit Packages
  export async function getCreditPackages(userType?: 'student' | 'landlord'): Promise<CreditPackage[]> {
    try {
      let q = query(creditPackagesCollection, orderBy('price', 'asc'));
      
      if (userType) {
        q = query(q, where('userType', '==', userType));
      }
      
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        updatedAt: doc.data().updatedAt?.toDate()
      })) as CreditPackage[];
    } catch (error) {
      console.error('Error getting credit packages:', error);
      throw new Error('Errore nel caricamento dei pacchetti crediti');
    }
  }
  
  export async function createCreditPackage(data: Omit<CreditPackage, 'id' | 'createdAt'>): Promise<void> {
    try {
      await requireAdmin();
  
      const docRef = doc(creditPackagesCollection);
      await setDoc(docRef, {
        ...data,
        id: crypto.randomUUID(),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
  
      toast.success('Pacchetto crediti creato con successo');
    } catch (error: any) {
      console.error('Error creating credit package:', error);
      throw new Error(error.message || 'Errore durante la creazione del pacchetto');
    }
  }
  
  export async function updateCreditPackage(id: string, data: Partial<CreditPackage>): Promise<void> {
    try {
      await requireAdmin();
  
      const q = query(creditPackagesCollection, where('id', '==', id));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        throw new Error('Pacchetto crediti non trovato');
      }
  
      const docRef = querySnapshot.docs[0].ref;
      const { id: _, createdAt, ...updateData } = data;
  
      await updateDoc(docRef, {
        ...updateData,
        updatedAt: serverTimestamp()
      });
  
      toast.success('Pacchetto crediti aggiornato con successo');
    } catch (error: any) {
      console.error('Error updating credit package:', error);
      if (error.code === 'not-found') {
        throw new Error('Pacchetto crediti non trovato');
      }
      throw new Error(error.message || 'Errore durante l\'aggiornamento del pacchetto');
    }
  }
  
  export async function deleteCreditPackage(id: string): Promise<void> {
    try {
      await requireAdmin();
  
      const q = query(creditPackagesCollection, where('id', '==', id));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        throw new Error('Pacchetto crediti non trovato');
      }
  
      const docRef = querySnapshot.docs[0].ref;
      await deleteDoc(docRef);
      toast.success('Pacchetto crediti eliminato con successo');
    } catch (error: any) {
      console.error('Error deleting credit package:', error);
      throw new Error(error.message || 'Errore durante l\'eliminazione del pacchetto');
    }
  }
  
  // Featured Plans
  export async function getFeaturedPlans(): Promise<FeaturedPlan[]> {
    try {
      const snapshot = await getDocs(query(featuredPlansCollection, orderBy('price', 'asc')));
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        updatedAt: doc.data().updatedAt?.toDate()
      })) as FeaturedPlan[];
    } catch (error) {
      console.error('Error getting featured plans:', error);
      throw new Error('Errore nel caricamento dei piani in evidenza');
    }
  }
  
  export async function createFeaturedPlan(data: Omit<FeaturedPlan, 'id' | 'createdAt'>): Promise<void> {
    try {
      await requireAdmin();
      
      const planId = crypto.randomUUID();
      const docRef = doc(featuredPlansCollection, planId);
      
      await setDoc(docRef, {
        ...data,
        id: planId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      
      toast.success('Piano in evidenza creato con successo');
    } catch (error: any) {
      console.error('Error creating featured plan:', error);
      throw new Error(error.message || 'Errore durante la creazione del piano');
    }
  }
  
  export async function updateFeaturedPlan(id: string, data: Partial<FeaturedPlan>): Promise<void> {
    try {
      await requireAdmin();
  
      const q = query(featuredPlansCollection, where('id', '==', id));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        throw new Error('Piano in evidenza non trovato');
      }
  
      const docRef = querySnapshot.docs[0].ref;
      const { id: _, createdAt, ...updateData } = data;
  
      await updateDoc(docRef, {
        ...updateData,
        updatedAt: serverTimestamp()
      });
  
      toast.success('Piano in evidenza aggiornato con successo');
    } catch (error: any) {
      console.error('Error updating featured plan:', error);
      if (error.code === 'not-found') {
        throw new Error('Piano in evidenza non trovato');
      }
      throw new Error(error.message || 'Errore durante l\'aggiornamento del piano');
    }
  }
  
  export async function deleteFeaturedPlan(id: string): Promise<void> {
    try {
      await requireAdmin();

      const docRef = doc(featuredPlansCollection, id);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        throw new Error('Piano in evidenza non trovato');
      }

      await deleteDoc(docRef);
      toast.success('Piano in evidenza eliminato con successo');
    } catch (error: any) {
      console.error('Error deleting featured plan:', error);
      throw new Error(error.message || 'Errore durante l\'eliminazione del piano');
    }
  }
  
  export async function validateFeaturedPlan(planId: string): Promise<boolean> {
    try {
      const planRef = doc(featuredPlansCollection, planId);
      const planDoc = await getDoc(planRef);
      
      if (!planDoc.exists()) {
        console.error('Piano non trovato:', planId);
        return false;
      }

      const planData = planDoc.data();
      
      if (planData.id !== planId) {
        console.error('ID piano non corrispondente');
        return false;
      }

      // Verifica che i campi necessari esistano e siano validi
      if (!planData.durationHours) {
        console.error('Durata piano mancante');
        return false;
      }

      // Gestione sicura della conversione durata
      const durationHours = typeof planData.durationHours === 'string' 
        ? parseInt(planData.durationHours, 10) 
        : planData.durationHours;

      if (isNaN(durationHours) || durationHours <= 0) {
        console.error('Durata piano non valida:', planData.durationHours);
        return false;
      }

      // Verifica prezzo
      if (!planData.price || planData.price <= 0) {
        console.error('Prezzo piano non valido');
        return false;
      }

      return true;
    } catch (error) {
      console.error('Errore nella validazione del piano:', error);
      return false;
    }
  }