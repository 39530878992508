import React, { useRef, useEffect } from 'react';
import { Upload, X, GripVertical } from 'lucide-react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { toast } from 'react-toastify';

interface FloorPlanUploadProps {
  floorPlans: (string | File)[];
  onChange: (files: (string | File)[]) => void;
  maxFiles?: number;
  listingId?: string;
}

export function FloorPlanUpload({
  floorPlans = [],
  onChange,
  maxFiles = 5,
  listingId
}: FloorPlanUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (!files.length) return;

    // Verifica se il numero totale di file supererebbe il limite
    if (files.length + floorPlans.length > maxFiles) {
      toast.error(`Puoi caricare al massimo ${maxFiles} planimetrie`);
      return;
    }

    // Validazione files
    const validFiles = files.filter(file => {
      if (file.size > 10 * 1024 * 1024) {
        toast.error(`Il file ${file.name} è troppo grande. Dimensione massima: 10MB`);
        return false;
      }

      const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        toast.error(`Il file ${file.name} non è in un formato valido. Formati supportati: PNG, JPG, PDF`);
        return false;
      }

      return true;
    });

    if (validFiles.length === 0) return;

    // Verifica duplicati
    const existingFileNames = new Set(
      floorPlans
        .filter((f): f is File => f instanceof File)
        .map(f => f.name)
    );

    const uniqueFiles = validFiles.filter(file => !existingFileNames.has(file.name));

    if (uniqueFiles.length !== validFiles.length) {
      toast.warning('Alcuni file sono stati ignorati perché già presenti');
    }

    if (uniqueFiles.length > 0) {
      // Aggiorna lo stato con i nuovi file
      const updatedFloorPlans = [...floorPlans];
      uniqueFiles.forEach(file => {
        if (!updatedFloorPlans.some(existingFile => 
          existingFile instanceof File && existingFile.name === file.name
        )) {
          updatedFloorPlans.push(file);
        }
      });
      onChange(updatedFloorPlans);
    }

    // Reset input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleRemove = (index: number) => {
    const newFiles = floorPlans.filter((_, i) => i !== index);
    onChange(newFiles);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(floorPlans);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onChange(items);
  };

  const getPreviewUrl = (file: string | File): string => {
    if (typeof file === 'string') return file;
    return URL.createObjectURL(file);
  };

  const handleAreaClick = () => {
    fileInputRef.current?.click();
  };

  // Cleanup URLs quando il componente viene smontato
  useEffect(() => {
    return () => {
      floorPlans.forEach(file => {
        if (file instanceof File) {
          const url = getPreviewUrl(file);
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [floorPlans]);

  return (
    <div className="space-y-4">
      {/* Preview Grid */}
      {floorPlans.length > 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="floorplans" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="grid grid-cols-2 md:grid-cols-4 gap-4"
              >
                {floorPlans.map((file, index) => (
                  <Draggable
                    key={typeof file === 'string' ? file : `file-${index}`}
                    draggableId={`floorplan-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`relative aspect-square group ${
                          snapshot.isDragging ? 'z-50' : ''
                        }`}
                      >
                        {(typeof file === 'string' || (file instanceof File && file.type.startsWith('image/'))) ? (
                          <img
                            src={getPreviewUrl(file)}
                            alt={`Planimetria ${index + 1}`}
                            className="w-full h-full object-cover rounded-lg"
                          />
                        ) : (
                          <div className="w-full h-full flex items-center justify-center bg-gray-50 rounded-lg">
                            <p className="text-gray-500">PDF</p>
                          </div>
                        )}
                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all rounded-lg">
                          <div
                            {...provided.dragHandleProps}
                            className="absolute top-2 left-2 p-1 bg-white rounded-full opacity-0 group-hover:opacity-100 cursor-grab"
                          >
                            <GripVertical className="h-4 w-4 text-gray-500" />
                          </div>
                          <button
                            type="button"
                            onClick={() => handleRemove(index)}
                            className="absolute top-2 right-2 p-1 bg-white rounded-full opacity-0 group-hover:opacity-100"
                          >
                            <X className="h-4 w-4 text-gray-500" />
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {/* Upload Area */}
      <div
        onClick={handleAreaClick}
        className="relative cursor-pointer mt-1 flex flex-col items-center justify-center px-6 py-8 border-2 border-gray-200 border-dashed rounded-lg hover:border-gray-300 transition-all"
      >
        <Upload className="h-8 w-8 mb-2 text-gray-400" />
        <div className="flex flex-col items-center text-sm text-gray-600">
          <span className="font-medium text-blue-600 hover:text-blue-500">
            {floorPlans.length === 0 ? 'Carica planimetrie' : 'Sostituisci planimetrie'}
          </span>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            accept="image/jpeg,image/png,image/webp,application/pdf"
            onChange={handleFileChange}
            className="sr-only"
          />
          <p className="mt-1 text-xs text-gray-500">PNG, JPG, PDF fino a 10MB</p>
        </div>
      </div>
    </div>
  );
}