import React, { useEffect } from 'react';

declare global {
  interface Window {
    _iub: any;
    iubenda: any;
  }
}

export function CookieConsent() {
  useEffect(() => {
    // Initialize Iubenda consent management
    window._iub = window._iub || {};
    window._iub.csConfiguration = {
      askConsentAtCookiePolicyUpdate: true,
      enableCMP: true,
      googleAdditionalConsentMode: true,
      invalidateConsentWithoutLog: true,
      whitelabel: false,
      lang: 'it',
      siteId: 3350427,
      cookiePolicyId: 33504273,
      banner: {
        acceptButtonDisplay: true,
        customizeButtonDisplay: true,
        rejectButtonDisplay: true,
        position: 'float-bottom-center',
        acceptButtonColor: '#2563eb',
        acceptButtonCaptionColor: 'white',
        customizeButtonColor: '#4b5563',
        customizeButtonCaptionColor: 'white',
        rejectButtonColor: '#4b5563',
        rejectButtonCaptionColor: 'white',
        textColor: '#1f2937',
        backgroundColor: '#ffffff',
        closeButtonRejects: true,
        content: 'Utilizziamo i cookie e altre tecnologie di tracciamento per migliorare la tua esperienza di navigazione nel sito e per mostrarti contenuti personalizzati.',
        fontSizeBody: '14px',
        prependOnBody: false,
        hideButtonClose: true,
        backgroundOverlay: false,
        floating: {
          enabled: false
        }
      },
      callback: {
        onPreferenceExpressed: function(preference: any) {
          // Callback quando l'utente esprime una preferenza
          console.log("Consent preference:", preference);
        },
        onBannerShown: function() {
          // Callback quando il banner viene mostrato
          console.log("Banner shown");
        }
      }
    };

    // Load Iubenda script dynamically
    const script = document.createElement('script');
    script.src = 'https://cdn.iubenda.com/cs/iubenda_cs.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script on component unmount
      document.body.removeChild(script);
    };
  }, []);

  return null;
}