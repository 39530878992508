import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  Timestamp,
  serverTimestamp,
  onSnapshot,
  orderBy,
  limit,
  getDoc,
  DocumentData,
  QuerySnapshot,
  FirestoreError
} from 'firebase/firestore';
import { db } from './firebase';
import { Meeting, MeetingStatus } from '../types/meeting';
import { toast } from 'react-toastify';
import { format, startOfDay, endOfDay } from 'date-fns';
import { it } from 'date-fns/locale';
import { createMeeting as createDailyMeeting } from './daily';

const meetingsCollection = collection(db, 'meetings');

interface CreateMeetingData {
  applicationId: string;
  listingId: string;
  listingTitle: string;
  studentName: string;
  studentEmail: string;
  landlordId: string;
  date: Date | Timestamp;
  time: Date | Timestamp;
  status: MeetingStatus;
}

export async function createMeeting(data: CreateMeetingData): Promise<string> {
  try {
    // Validate required fields
    if (!data.applicationId || !data.listingId || !data.landlordId) {
      throw new Error('Missing required fields');
    }

    // Ensure date and time are valid
    let meetingDate: Timestamp;
    let meetingTime: Timestamp;

    try {
      if (data.date instanceof Date) {
        meetingDate = Timestamp.fromDate(data.date);
      } else if (data.date instanceof Timestamp) {
        meetingDate = data.date;
      } else {
        throw new Error('Invalid date format');
      }

      if (data.time instanceof Date) {
        meetingTime = Timestamp.fromDate(data.time);
      } else if (data.time instanceof Timestamp) {
        meetingTime = data.time;
      } else {
        throw new Error('Invalid time format');
      }
    } catch (error) {
      console.error('Error formatting date/time:', error);
      throw new Error('Invalid date or time format');
    }

    // Crea il link della videochiamata
    const meetingUrl = await createDailyMeeting({
      roomName: `meeting-${crypto.randomUUID()}`,
      expiryMinutes: 60
    });

    // Aggiungi il meetingUrl ai dati del meeting
    const completeData = {
      applicationId: data.applicationId,
      listingId: data.listingId,
      listingTitle: data.listingTitle,
      studentId: data.studentEmail,
      studentName: data.studentName,
      studentEmail: data.studentEmail,
      landlordId: data.landlordId,
      date: meetingDate,
      time: meetingTime,
      status: data.status || 'confirmed',
      meetingUrl,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    };

    console.log('Debug - Creating meeting with data:', {
      ...completeData,
      date: meetingDate.toDate(),
      time: meetingTime.toDate()
    });

    const docRef = await addDoc(meetingsCollection, completeData);

    console.log('Debug - Meeting created successfully:', {
      meetingId: docRef.id,
      applicationId: data.applicationId
    });

    return docRef.id;
  } catch (error) {
    console.error('Debug - Error creating meeting:', {
      error,
      data: {
        applicationId: data.applicationId,
        listingId: data.listingId,
        landlordId: data.landlordId
      }
    });
    throw error;
  }
}

export async function updateMeeting(meetingId: string, updates: Partial<Meeting>): Promise<void> {
  if (!meetingId) {
    throw new Error('Meeting ID is required');
  }

  try {
    const meetingRef = doc(meetingsCollection, meetingId);
    const meetingDoc = await getDoc(meetingRef);
    
    if (!meetingDoc.exists()) {
      throw new Error('Meeting not found');
    }

    // Log per debug
    console.log('Debug - Dati per l\'aggiornamento:', {
      meetingId,
      updates,
      currentData: meetingDoc.data()
    });

    // Se stiamo aggiornando data/ora, creiamo un nuovo link
    if (updates.date || updates.time) {
      const meetingUrl = await createDailyMeeting({
        roomName: `meeting-${crypto.randomUUID()}`,
        expiryMinutes: 60
      });
      updates.meetingUrl = meetingUrl;
    }

    // Log dopo l'aggiunta del meetingUrl
    console.log('Debug - Dati finali per l\'aggiornamento:', {
      meetingId,
      updates,
      hasUrl: !!updates.meetingUrl
    });

    await updateDoc(meetingRef, {
      ...updates,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Debug - Error updating meeting:', error);
    throw error;
  }
}

export async function deleteMeeting(meetingId: string): Promise<void> {
  if (!meetingId) {
    throw new Error('Meeting ID is required');
  }

  try {
    const meetingRef = doc(meetingsCollection, meetingId);
    await deleteDoc(meetingRef);
  } catch (error) {
    console.error('Debug - Error deleting meeting:', error);
    throw error;
  }
}

export async function getMeeting(id: string): Promise<Meeting | null> {
  try {
    const docRef = doc(meetingsCollection, id);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      return null;
    }

    const data = docSnap.data();
    return {
      id: docSnap.id,
      ...data,
      date: data.date?.toDate(),
      time: data.time?.toDate(),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate()
    } as Meeting;
  } catch (error) {
    console.error('Debug - Error fetching meeting:', error);
    throw error;
  }
}

export async function getMeetingByApplicationId(applicationId: string): Promise<Meeting | null> {
  try {
    const q = query(
      meetingsCollection,
      where('applicationId', '==', applicationId),
      limit(1)
    );
    
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      return null;
    }

    const doc = querySnapshot.docs[0];
    const data = doc.data();
    
    return {
      id: doc.id,
      ...data,
      date: data.date?.toDate(),
      time: data.time?.toDate(),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate()
    } as Meeting;
  } catch (error) {
    console.error('Debug - Error fetching meeting by application:', error);
    throw error;
  }
}

export async function getMeetingsInRange(
  landlordId: string,
  startDate: Date,
  endDate: Date
): Promise<Meeting[]> {
  try {
    const startTimestamp = Timestamp.fromDate(startOfDay(startDate));
    const endTimestamp = Timestamp.fromDate(endOfDay(endDate));

    const q = query(
      meetingsCollection,
      where('landlordId', '==', landlordId),
      where('status', '==', 'confirmed'),
      where('date', '>=', startTimestamp),
      where('date', '<=', endTimestamp)
    );

    const querySnapshot = await getDocs(q);
    
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      date: doc.data().date?.toDate(),
      time: doc.data().time?.toDate(),
      createdAt: doc.data().createdAt?.toDate(),
      updatedAt: doc.data().updatedAt?.toDate()
    })) as Meeting[];
  } catch (error) {
    console.error('Debug - Error fetching meetings in range:', error);
    return [];
  }
}

export function onMeetingChange(applicationId: string, callback: (meeting: Meeting | null) => void) {
  if (!applicationId) {
    console.error('applicationId required for meeting subscription');
    return () => {};
  }

  console.log('Debug - Setting up meeting subscription:', {
    applicationId,
    collection: 'meetings',
    timestamp: new Date().toISOString()
  });

  const q = query(
    meetingsCollection,
    where('applicationId', '==', applicationId)
  );

  return onSnapshot(q, {
    next: (snapshot) => {
      if (snapshot.empty) {
        console.log('Debug - No meeting found for applicationId:', applicationId);
        callback(null);
        return;
      }

      const meetingDoc = snapshot.docs[0];
      const data = meetingDoc.data();

      try {
        const meetingData = {
          id: meetingDoc.id,
          ...data,
          date: data.date instanceof Timestamp ? data.date.toDate() : new Date(data.date),
          time: data.time instanceof Timestamp ? data.time.toDate() : new Date(data.time),
          createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(),
          updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date()
        } as Meeting;

        console.log('Debug - Meeting data processed:', {
          id: meetingData.id,
          applicationId: meetingData.applicationId,
          date: meetingData.date,
          time: meetingData.time
        });

        callback(meetingData);
      } catch (error) {
        console.error('Debug - Error processing meeting data:', error);
        callback(null);
      }
    },
    error: (error) => {
      console.error('Debug - Meeting subscription error:', {
        error,
        applicationId
      });
      callback(null);
    }
  });
}

export async function isSlotAvailable(
  landlordId: string,
  date: Date,
  time: string
): Promise<boolean> {
  if (!landlordId || !date || !time) {
    console.error('Debug - Missing required parameters for slot availability check:', {
      landlordId,
      date,
      time
    });
    return false;
  }

  try {
    const dayStart = startOfDay(date);
    const dayEnd = endOfDay(date);
    
    // Get existing meetings for the day
    const q = query(
      meetingsCollection,
      where('landlordId', '==', landlordId),
      where('status', '==', 'confirmed'),
      where('date', '>=', Timestamp.fromDate(dayStart)),
      where('date', '<=', Timestamp.fromDate(dayEnd))
    );

    const querySnapshot = await getDocs(q);
    const existingMeetings = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      date: doc.data().date?.toDate(),
      time: doc.data().time?.toDate()
    })) as Meeting[];

    // Check if the requested time slot conflicts with any existing meeting
    const isSlotTaken = existingMeetings.some(meeting => {
      const meetingTime = meeting.time instanceof Timestamp ?
        format(meeting.time.toDate(), 'HH:mm') :
        typeof meeting.time === 'string' ?
          meeting.time :
          format(new Date(meeting.time), 'HH:mm');
          
      return meetingTime === time;
    });

    console.log('Debug - Slot availability check result:', {
      landlordId,
      date: format(date, 'yyyy-MM-dd'),
      time,
      existingMeetings: existingMeetings.length,
      isSlotTaken
    });

    return !isSlotTaken;
  } catch (error) {
    console.error('Debug - Error checking slot availability:', {
      error,
      landlordId,
      date: format(date, 'yyyy-MM-dd'),
      time
    });
    return false;
  }
}

export async function checkMeetingExists(applicationId: string): Promise<boolean> {
  try {
    const q = query(
      meetingsCollection,
      where('applicationId', '==', applicationId),
      limit(1)
    );
    
    const snapshot = await getDocs(q);
    return !snapshot.empty;
  } catch (error) {
    console.error('Debug - Error checking meeting existence:', error);
    return false;
  }
}

export function generateMeetingUrl(meetingId: string): string {
  return `/meeting/${meetingId}`;
}