import React from 'react';
import { ListingStats } from '../../../../lib/analytics/stats';

interface ApplicationsBreakdownProps {
  stats: ListingStats['applicationStats'];
}

export function ApplicationsBreakdown({ stats }: ApplicationsBreakdownProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-medium mb-4">Stato Candidature</h3>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="p-3 bg-yellow-50 rounded-lg">
          <div className="text-2xl font-bold text-yellow-700">{stats.pending}</div>
          <div className="text-sm text-yellow-600">In Attesa</div>
        </div>
        <div className="p-3 bg-green-50 rounded-lg">
          <div className="text-2xl font-bold text-green-700">{stats.accepted}</div>
          <div className="text-sm text-green-600">Accettate</div>
        </div>
        <div className="p-3 bg-red-50 rounded-lg">
          <div className="text-2xl font-bold text-red-700">{stats.rejected}</div>
          <div className="text-sm text-red-600">Rifiutate</div>
        </div>
        <div className="p-3 bg-gray-50 rounded-lg">
          <div className="text-2xl font-bold text-gray-700">{stats.expired}</div>
          <div className="text-sm text-gray-600">Scadute</div>
        </div>
      </div>
    </div>
  );
}