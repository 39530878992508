import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Star, FileText } from 'lucide-react';
import { ListingHeader } from '../components/ListingHeader';
import { ImageGallery } from '../components/ImageGallery';
import { PropertyFeatures } from '../components/PropertyFeatures';
import { ContractDetails } from '../components/ContractDetails';
import { HouseRules } from '../components/HouseRules';
import { ContactForm } from '../components/ContactForm';
import { ListingFeatures } from '../components/ListingFeatures';
import { FavoriteButton } from '../components/FavoriteButton';
import { getListing, incrementListingViews } from '../lib/listings';
import { Listing } from '../types/listing';
import { toast } from 'react-toastify';
import { useAuthContext } from '../contexts/AuthContext';
import { generateNumericId } from '../utils/ids';
import { FloorPlanModal } from '../components/FloorPlanModal';
import { ListingBreadcrumb } from '../components/listings/ListingBreadcrumb';

export function ListingDetail() {
  const { id } = useParams();
  const [listing, setListing] = useState<Listing | null>(null);
  const [loading, setLoading] = useState(true);
  const [viewIncremented, setViewIncremented] = useState(false);
  const [showFloorPlan, setShowFloorPlan] = useState(false);
  const { userProfile } = useAuthContext();
  const location = useLocation();
  const isAnalyticsPage = location.pathname.includes('analytics');
  const isOwner = userProfile?.id === listing?.userId;
  const isFeatured = listing?.featured?.active && new Date(listing.featured.expiresAt) > new Date();

  useEffect(() => {
    const loadListing = async () => {
      try {
        setLoading(true);
        const data = await getListing(id);
        if (data) {
          setListing(data);
          // Incrementa le visualizzazioni in background
          incrementListingViews(id).catch(error => {
            console.warn('Failed to increment views:', error);
            // Non mostrare errori all'utente per questa operazione non critica
          });
        }
      } catch (error) {
        console.error('Error loading listing:', error);
        setError('Errore nel caricamento dell\'annuncio');
      } finally {
        setLoading(false);
      }
    };

    loadListing();
  }, [id, isOwner, isAnalyticsPage, viewIncremented]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!listing) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900">Annuncio non trovato</h2>
          <p className="mt-2 text-gray-500">L'annuncio che stai cercando non esiste o è stato rimosso.</p>
        </div>
      </div>
    );
  }

  const availableSpots = listing.category === 'single_room' ? 1 : listing.availableSpots;
  const spotsText = availableSpots === 1 ? 'posto disponibile' : 'posti disponibili';

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Breadcrumb */}
        <div className="mb-6">
          <ListingBreadcrumb
            listingId={listing.id}
            listingTitle={listing.title}
            isOwner={isOwner}
            isAnalyticsPage={isAnalyticsPage}
          />
        </div>

        {/* Action Buttons */}
        <div className="flex items-center gap-2 sm:gap-4 mb-6">
          {!isOwner && (
            <FavoriteButton listingId={listing.id} size="lg" />
          )}
        </div>

        {/* Featured Badge */}
        {isFeatured && isOwner && (
          <div className="mb-4 sm:mb-6 bg-gradient-to-r from-yellow-50 to-orange-50 border border-yellow-200 rounded-lg p-3 sm:p-4 flex items-center gap-3">
            <Star className="h-5 w-5 text-yellow-500 flex-shrink-0" fill="currentColor" />
            <div className="min-w-0">
              <p className="font-medium text-yellow-800 text-sm sm:text-base">
                Annuncio in Evidenza
              </p>
              <p className="text-xs sm:text-sm text-yellow-600">
                Questo annuncio è in evidenza fino al {new Date(listing.featured.expiresAt).toLocaleDateString('it-IT')} ore {new Date(listing.featured.expiresAt).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </p>
            </div>
          </div>
        )}

        <ListingHeader listing={listing} availableSpots={availableSpots} spotsText={spotsText} />

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className={`${listing.images.length === 1 ? 'lg:col-span-3' : 'lg:col-span-2'}`}>
            <div className="space-y-4">
              <ImageGallery images={listing.images as string[]} />
              {listing.floorPlans && listing.floorPlans.length > 0 && (
                <button
                  onClick={() => setShowFloorPlan(true)}
                  className="inline-flex items-center gap-2 px-3 py-1.5 bg-white border border-gray-200 rounded-md hover:bg-gray-50 transition-colors text-sm font-medium text-gray-700"
                >
                  <FileText className="h-4 w-4 text-gray-500" />
                  Visualizza planimetria
                </button>
              )}
            </div>
          </div>

          {listing.images.length > 1 && (
            <div className="lg:col-span-1">
              <ContactForm 
                listingId={listing.id}
                listingOwnerId={listing.userId}
                listingTitle={listing.title}
                featured={listing.featured}
              />
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6">
          <div className="lg:col-span-2 space-y-6">
            {/* Description */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-4">Descrizione</h2>
              <p className="text-gray-600 whitespace-pre-wrap">{listing.description}</p>
            </div>

            <PropertyFeatures listing={listing} />
            <ListingFeatures listing={listing} />
            <ContractDetails listing={listing} />
            <HouseRules listing={listing} />
          </div>

          {listing.images.length === 1 && (
            <div className="lg:col-span-1">
              <ContactForm 
                listingId={listing.id}
                listingOwnerId={listing.userId}
                listingTitle={listing.title}
                featured={listing.featured}
              />
            </div>
          )}
        </div>
      </div>

      {showFloorPlan && listing.floorPlans && (
        <FloorPlanModal
          floorPlans={listing.floorPlans}
          onClose={() => setShowFloorPlan(false)}
        />
      )}
    </div>
  );
}