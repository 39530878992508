import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import App from './App';
import './index.css';
import { paypalConfig } from './lib/paypal';
import { getApps, initializeApp } from 'firebase/app';
import { connectToEmulators } from './firebase/emulators';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');

const firebaseConfig = {
  // ... il tuo config
};

// Inizializza Firebase solo se non è già stato inizializzato
if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}

// Connetti agli emulatori solo in ambiente di sviluppo
if (import.meta.env.DEV) {  // Usando Vite env invece di process.env
  connectToEmulators(app);
}

createRoot(rootElement).render(
  <StrictMode>
    <PayPalScriptProvider options={paypalConfig}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PayPalScriptProvider>
  </StrictMode>
);