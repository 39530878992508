export type ListingCategory = 'single_room' | 'double_room' | 'triple_room' | 'quad_room';
export type HeatingType = 'central' | 'autonomous' | 'none';
export type ContractType = 'standard' | 'transitorio' | 'studenti';
export type EnergyClass = 'A4' | 'A3' | 'A2' | 'A1' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';
export type CooktopType = 'gas' | 'electric' | 'induction';
export type UtilitiesType = 'included' | 'notIncluded';
export type ListingStatus = 'active' | 'inactive' | 'rented';

export interface Utilities {
  type: UtilitiesType;
  monthlyFee: number;
}

export const categoryLabels: Record<ListingCategory, string> = {
  single_room: 'Stanza singola',
  double_room: 'Stanza doppia',
  triple_room: 'Stanza tripla',
  quad_room: 'Stanza quadrupla'
};

export const contractLabels: Record<ContractType, string> = {
  standard: 'Contratto standard',
  transitorio: 'Contratto transitorio',
  studenti: 'Contratto per studenti'
};

export const heatingLabels: Record<HeatingType, string> = {
  central: 'Centralizzato',
  autonomous: 'Autonomo',
  none: 'Non presente'
};

export const energyClassLabels: Record<EnergyClass, string> = {
  'A4': 'Classe A4',
  'A3': 'Classe A3',
  'A2': 'Classe A2',
  'A1': 'Classe A1',
  'B': 'Classe B',
  'C': 'Classe C',
  'D': 'Classe D',
  'E': 'Classe E',
  'F': 'Classe F',
  'G': 'Classe G'
};

export const cooktopLabels: Record<CooktopType, string> = {
  gas: 'Gas',
  electric: 'Elettrico',
  induction: 'Induzione'
};

export const utilitiesLabels: Record<UtilitiesType, string> = {
  included: 'Spese incluse',
  notIncluded: 'Spese non incluse'
};

export const listingStatusLabels: Record<ListingStatus, string> = {
  active: 'Disponibile',
  inactive: 'Non disponibile',
  rented: 'Affittato'
};

export const featuresLabels = {
  elevator: 'Ascensore',
  airConditioning: 'Aria condizionata',
  furnished: 'Arredato',
  wifi: 'Wi-Fi',
  bikeStorage: 'Deposito bici/moto',
  parking: 'Parcheggio',
  garden: 'Giardino',
  studyRoom: 'Sala studio',
  laundryRoom: 'Lavanderia',
  storage: 'Deposito',
  securityDoor: 'Porta blindata',
  doublePaneWindows: 'Doppi vetri'
} as const;

export const kitchenLabels = {
  separate: 'Cucina separata',
  window: 'Finestra',
  balcony: 'Balcone',
  oven: 'Forno',
  microwave: 'Microonde',
  dishwasher: 'Lavastoviglie',
  fridge: 'Frigorifero',
  freezer: 'Congelatore'
} as const;

export const bathroomLabels = {
  window: 'Finestra',
  shower: 'Doccia',
  bathtub: 'Vasca',
  bidet: 'Bidet',
  washingMachine: 'Lavatrice',
  dryer: 'Asciugatrice'
} as const;

export const commonAreaLabels = {
  livingRoom: 'Soggiorno',
  balcony: 'Balcone',
  terrace: 'Terrazza',
  garden: 'Giardino'
} as const;

export const rulesLabels = {
  smoking: 'Fumatori permessi',
  pets: 'Animali domestici',
  guests: 'Visite/Ospiti',
  parties: 'Feste',
  quietHours: 'Orari silenziosi',
  commonAreaCleaning: 'Pulizie spazi comuni'
} as const;

export const featuredPlans = [
  {
    id: 'daily',
    name: 'Giornaliero',
    price: 4.99,
    duration: '24 ore',
    description: 'Ideale per affitti urgenti'
  },
  {
    id: 'weekly',
    name: 'Settimanale',
    price: 24.99,
    duration: '7 giorni',
    description: 'La scelta più popolare',
    popular: true
  },
  {
    id: 'monthly',
    name: 'Mensile',
    price: 79.99,
    duration: '30 giorni',
    description: 'Massima visibilità garantita'
  }
] as const;

export interface Listing {
  id: string;
  userId: string;
  title: string;
  description: string;
  category: ListingCategory;
  contractType: ContractType;
  price: number;
  deposit: number;
  availableFrom: Date;
  minStay: number;
  noticePeriod: number;
  location: {
    coordinates: [number, number];
    placeId: string;
    formattedAddress: string;
  };
  size: number;
  rooms: number;
  bathrooms: number;
  floor: number;
  maxOccupants: number;
  availableSpots: number;
  rentedUnits?: number;
  energyClass: EnergyClass;
  yearBuilt?: number;
  yearRenovated?: number;
  hasApplied?: boolean;

  features: {
    elevator: boolean;
    airConditioning: boolean;
    heating: HeatingType;
    furnished: boolean;
    wifi: boolean;
    bikeStorage: boolean;
    parking: boolean;
    garden: boolean;
    studyRoom: boolean;
    laundryRoom: boolean;
    storage: boolean;
    securityDoor: boolean;
    doublePaneWindows: boolean;
  };

  kitchen: {
    separate: boolean;
    window: boolean;
    balcony: boolean;
    cooktopType: CooktopType;
    oven: boolean;
    microwave: boolean;
    dishwasher: boolean;
    fridge: boolean;
    freezer: boolean;
  };

  bathroom: {
    window: boolean;
    shower: boolean;
    bathtub: boolean;
    bidet: boolean;
    washingMachine: boolean;
    dryer: boolean;
  };

  commonAreas: {
    livingRoom: boolean;
    balcony: boolean;
    terrace: boolean;
    garden: boolean;
  };

  rules: {
    smoking: boolean;
    pets: boolean;
    guests: boolean;
    parties: boolean;
    quietHours: boolean;
    commonAreaCleaning: boolean;
  };

  utilities: Utilities;

  images: string[];
  floorPlan?: string;
  videoTour?: string;
  virtualTour?: string;

  status: ListingStatus;
  featured?: {
    active: boolean;
    plan: 'daily' | 'weekly' | 'monthly' | null;
    expiresAt: Date;
  };
  
  createdAt: Date;
  updatedAt?: Date;
  views: number;
  applications: number;
}

export const getCategoryMaxOccupants = (category: ListingCategory): number => {
  switch (category) {
    case 'single_room': return 1;
    case 'double_room': return 2;
    case 'triple_room': return 3;
    case 'quad_room': return 4;
    default: return 1;
  }
};