import { collection, query, where, getDocs, Timestamp, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Listing } from '../../types/listing';
import { Application } from '../../types/application';
import { isApplicationExpired } from '../dates';

export interface ListingStats {
  views: number;
  applications: number;
  applicationStats: {
    pending: number;
    accepted: number;
    rejected: number;
    expired: number;
  };
}

export async function getListingStats(listingId: string): Promise<ListingStats> {
  try {
    // Get listing document first to get the views count
    const listingRef = doc(db, 'listings', listingId);
    const listingDoc = await getDoc(listingRef);
    const listingData = listingDoc.exists() ? listingDoc.data() : null;
    const views = listingData?.views || 0;

    // Get applications
    const applicationsRef = collection(db, 'applications');
    const applicationsQuery = query(applicationsRef, where('listingId', '==', listingId));
    const applicationsSnapshot = await getDocs(applicationsQuery);

    // Initialize stats
    const applicationStats = {
      pending: 0,
      accepted: 0,
      rejected: 0,
      expired: 0
    };

    // Count applications by status
    applicationsSnapshot.forEach(doc => {
      const application = {
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        expiresAt: doc.data().expiresAt?.toDate()
      } as Application;

      if (application.status === 'accepted') {
        applicationStats.accepted++;
      } else if (application.status === 'rejected') {
        applicationStats.rejected++;
      } else if (application.status === 'expired' || isApplicationExpired(application.createdAt)) {
        applicationStats.expired++;
      } else {
        applicationStats.pending++;
      }
    });

    return {
      views,
      applications: applicationsSnapshot.size,
      applicationStats
    };
  } catch (error) {
    console.error('Error getting listing stats:', error);
    throw error;
  }
}

export async function getTotalStats(listings: Listing[]): Promise<ListingStats> {
  try {
    // Get all applications for all listings in one query
    const applicationsRef = collection(db, 'applications');
    const applicationsQuery = query(
      applicationsRef, 
      where('listingId', 'in', listings.map(l => l.id))
    );
    const applicationsSnapshot = await getDocs(applicationsQuery);

    const totalStats = {
      views: 0,
      applications: 0,
      applicationStats: {
        pending: 0,
        accepted: 0,
        rejected: 0,
        expired: 0
      }
    };

    // Sum up views from listings
    totalStats.views = listings.reduce((sum, listing) => sum + (listing.views || 0), 0);

    // Process all applications
    applicationsSnapshot.forEach(doc => {
      const application = {
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        expiresAt: doc.data().expiresAt?.toDate()
      } as Application;

      totalStats.applications++;

      if (application.status === 'accepted') {
        totalStats.applicationStats.accepted++;
      } else if (application.status === 'rejected') {
        totalStats.applicationStats.rejected++;
      } else if (application.status === 'expired' || isApplicationExpired(application.createdAt)) {
        totalStats.applicationStats.expired++;
      } else {
        totalStats.applicationStats.pending++;
      }
    });

    return totalStats;
  } catch (error) {
    console.error('Error getting total stats:', error);
    throw error;
  }
}