import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  getDoc,
  setDoc,
  Timestamp,
  serverTimestamp,
  writeBatch
} from 'firebase/firestore';
import { db } from './firebase';
import { AdminAvailability, TimeSlot, DayAvailability } from '../types/availability';
import { toast } from 'react-toastify';
import { format, addMinutes, parse, eachDayOfInterval, isBefore, startOfToday, endOfDay, startOfDay } from 'date-fns';
import { it } from 'date-fns/locale';

const availabilityCollection = collection(db, 'availability');

export async function checkLandlordAvailability(landlordId: string): Promise<boolean> {
  try {
    console.log('Debug - Checking availability for landlordId:', landlordId);
    
    const docRef = doc(availabilityCollection, landlordId);
    const docSnap = await getDoc(docRef);
    
    console.log('Debug - Availability document exists:', docSnap.exists());

    if (!docSnap.exists()) {
      return false;
    }

    const availability = docSnap.data() as AdminAvailability;
    
    const hasAvailableSlots = Object.values(availability.weeklySchedule).some(
      (slots) => slots && slots.length > 0
    );

    console.log('Debug - Has available slots:', hasAvailableSlots, {
      weeklySchedule: availability.weeklySchedule
    });

    return hasAvailableSlots;
  } catch (error) {
    console.error('Debug - Error checking availability:', error);
    return false;
  }
}

export async function getAvailableSlots(
  landlordId: string,
  startDate: Date,
  endDate: Date
): Promise<DayAvailability[]> {
  if (!landlordId) {
    console.error('landlordId richiesto per ottenere gli slot disponibili');
    return [];
  }

  try {
    console.log('Debug - Getting available slots for landlordId:', landlordId);
    
    const docRef = doc(availabilityCollection, landlordId);
    const docSnap = await getDoc(docRef);
    
    console.log('Debug - Availability document exists:', docSnap.exists());

    if (!docSnap.exists()) {
      return [];
    }

    const availability = docSnap.data() as AdminAvailability;
    
    if (!availability.weeklySchedule) {
      return [];
    }

    // Mappa per convertire i nomi dei giorni
    const dayNameMap: { [key: string]: string } = {
      'lunedì': 'monday',
      'martedì': 'tuesday',
      'mercoledì': 'wednesday',
      'giovedì': 'thursday',
      'venerdì': 'friday',
      'sabato': 'saturday',
      'domenica': 'sunday'
    };

    const today = new Date();
    const currentWeekStart = startOfDay(startDate);
    const endDateAdjusted = endOfDay(endDate);
    const startDateAdjusted = isBefore(currentWeekStart, today) ? today : currentWeekStart;

    // Genera gli slot disponibili
    const availabilityWithDays = eachDayOfInterval({ start: startDateAdjusted, end: endDateAdjusted })
      .map(date => {
        const italianDayName = format(date, 'EEEE', { locale: it }).toLowerCase();
        const englishDayName = dayNameMap[italianDayName];
        
        const daySlots = availability.weeklySchedule[englishDayName] || [];

        // Genera gli slot per questo giorno
        const availableSlots = daySlots.flatMap(timeSlot => {
          const slots = [];
          const start = parse(timeSlot.startTime, 'HH:mm', date);
          const end = parse(timeSlot.endTime, 'HH:mm', date);
          
          let current = start;
          while (isBefore(current, end)) {
            slots.push({
              id: `${format(date, 'yyyy-MM-dd')}-${format(current, 'HH:mm')}`,
              startTime: format(current, 'HH:mm'),
              endTime: format(
                addMinutes(current, availability.meetingDuration || 30),
                'HH:mm'
              )
            });
            current = addMinutes(
              current,
              (availability.meetingDuration || 30) + (availability.breakDuration || 15)
            );
          }
          return slots;
        });

        return {
          date: format(date, 'yyyy-MM-dd'),
          dayName: format(date, 'EEE', { locale: it }),
          dayNumber: format(date, 'd'),
          slots: availableSlots
        };
      });

    console.log('Debug - Generated availability:', {
      daysCount: availabilityWithDays.length,
      totalSlots: availabilityWithDays.reduce((acc, day) => acc + day.slots.length, 0)
    });

    return availabilityWithDays;
  } catch (error) {
    console.error('Debug - Error getting available slots:', error);
    return [];
  }
}

export async function getLandlordAvailability(landlordId: string): Promise<AdminAvailability | null> {
  try {
    console.log('Debug - Getting landlord availability for:', landlordId);
    
    const docRef = doc(availabilityCollection, landlordId);
    const docSnap = await getDoc(docRef);
    
    console.log('Debug - Availability document exists:', docSnap.exists());

    if (docSnap.exists()) {
      const data = docSnap.data() as AdminAvailability;
      console.log('Debug - Found availability data:', {
        weeklySchedule: Object.keys(data.weeklySchedule),
        hasSlots: Object.values(data.weeklySchedule).some(slots => slots && slots.length > 0)
      });
      return data;
    }

    return null;
  } catch (error) {
    console.error('Debug - Error getting landlord availability:', error);
    return null;
  }
}

export async function updateLandlordAvailability(
  landlordId: string, 
  availability: AdminAvailability | null
): Promise<void> {
  if (!landlordId || !availability) {
    throw new Error('landlordId and availability are required');
  }

  const batch = writeBatch(db);

  try {
    console.log('Debug - Updating availability for landlordId:', landlordId);
    
    // Update availability document
    const availabilityRef = doc(availabilityCollection, landlordId);
    batch.set(availabilityRef, {
      ...availability,
      updatedAt: serverTimestamp(),
      userId: landlordId
    }, { merge: true });

    // Update applications with new availability status
    const hasAvailableSlots = Object.values(availability.weeklySchedule).some(
      slots => slots && slots.length > 0
    );

    const applicationsRef = collection(db, 'applications');
    const q = query(
      applicationsRef,
      where('landlordId', '==', landlordId),
      where('status', '==', 'accepted')
    );

    const querySnapshot = await getDocs(q);
    
    querySnapshot.forEach(doc => {
      batch.update(doc.ref, {
        landlordAvailabilitySet: hasAvailableSlots,
        updatedAt: serverTimestamp()
      });
    });

    // Commit all changes in a single batch
    await batch.commit();
    
    console.log('Debug - Availability updated successfully');
    toast.success('Disponibilità aggiornata con successo');
  } catch (error) {
    console.error('Debug - Error updating availability:', error);
    toast.error('Errore durante l\'aggiornamento della disponibilità');
    throw error;
  }
}