export const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://api.unistanza.it'
  : 'http://localhost:3001';

export const API_ENDPOINTS = {
  meetings: {
    create: `${API_BASE_URL}/api/meetings/create`,
    availableSlots: `${API_BASE_URL}/api/meetings/available-slots`
  },
  payments: {
    createStripeSession: `${API_BASE_URL}/api/payments/create-checkout-session`,
    createPaypalOrder: `${API_BASE_URL}/api/payments/create-paypal-order`,
    capturePaypalOrder: `${API_BASE_URL}/api/payments/capture-paypal-order`,
  }
};

export const PAYMENT_CONFIG = {
  stripe: {
    publishableKey: 'pk_live_51Gv7lAKsQRuaV9pvvJUYbsFvwnOuzBcc2jJ0DyIHcx93q732FHHsLeLHmE5M6H5iMbahYH1vw8ppmtNH6NgS7jVN00MH2RscaB'
  },
  paypal: {
    clientId: 'ARjAEc-I4V7mO7s8dYvtEVBhDi4WX1ioQNv88byx7l6eMUUhEOC_lMuEadlOWjRHh4NKLsPz8RB2xt4v'
  }
};