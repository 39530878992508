import { Combobox } from '@headlessui/react';
import usePlacesAutocomplete, { getGeocode, getDetails } from 'use-places-autocomplete';
import { toast } from 'react-toastify';

interface PlacesAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
}

export function PlacesAutocomplete({ 
  onPlaceSelect, 
  defaultValue = '', 
  placeholder = '', 
  className = '',
  required = false 
}: PlacesAutocompleteProps) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: { 
      componentRestrictions: { country: 'it' },
      types: ['address']
    },
    debounce: 300,
    defaultValue
  });

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ 
        address,
        componentRestrictions: { country: 'it' }
      });
      
      const isStreetAddress = results[0].types.some(type => 
        ['street_address', 'route', 'premise'].includes(type)
      );
      
      if (!isStreetAddress) {
        toast.error('Per favore inserisci un indirizzo stradale completo');
        setValue('');
        return;
      }

      const place = await getDetails({
        placeId: results[0].place_id,
        fields: ['formatted_address', 'geometry', 'address_components']
      });

      onPlaceSelect(place);
    } catch (error) {
      console.error('😱 Error: ', error);
      toast.error('Si è verificato un errore nella selezione dell\'indirizzo');
    }
  };

  const filteredData = data.filter(suggestion => {
    return suggestion.types?.some(type => 
      ['street_address', 'route', 'premise'].includes(type)
    );
  });

  return (
    <Combobox onChange={handleSelect}>
      <div className="relative">
        <Combobox.Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className={className}
          placeholder={placeholder}
          required={required}
        />
        <Combobox.Options className="absolute z-50 w-full mt-1 overflow-auto bg-white rounded-md shadow-lg max-h-60">
          {status === 'OK' && filteredData.map(({ place_id, description }) => (
            <Combobox.Option
              key={place_id}
              value={description}
              className={({ active }) =>
                `relative cursor-default select-none py-2 px-4 ${
                  active ? 'bg-blue-50 text-blue-700' : 'text-gray-900'
                }`
              }
            >
              {description}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}