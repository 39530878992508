import React, { useState, useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { SlidersHorizontal, X } from 'lucide-react';

interface BottomSheetProps {
  children: React.ReactNode;
  title: string;
  totalResults: number;
  onToggleFilters: () => void;
  showFilters: boolean;
  isExpanded: boolean;
  onStateChange: (expanded: boolean) => void;
}

const COLLAPSED_HEIGHT = 200;
const DRAG_THRESHOLD = 50;

export function BottomSheet({ 
  children, 
  title, 
  totalResults, 
  onToggleFilters,
  showFilters,
  isExpanded,
  onStateChange 
}: BottomSheetProps) {
  const controls = useAnimation();
  const sheetRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartY = useRef(0);

  useEffect(() => {
    if (isExpanded) {
      controls.start({ height: 'calc(90vh - 64px)' });
    } else {
      controls.start({ height: COLLAPSED_HEIGHT });
    }
  }, [isExpanded, controls]);

  const handleDragStart = (event: MouseEvent | TouchEvent | PointerEvent) => {
    setIsDragging(true);
    dragStartY.current = event.pageY || (event as TouchEvent).touches?.[0]?.pageY || 0;
  };

  const handleDrag = (event: MouseEvent | TouchEvent | PointerEvent) => {
    if (!isDragging || !sheetRef.current) return;
    const currentY = event.pageY || (event as TouchEvent).touches?.[0]?.pageY || 0;
    const deltaY = currentY - dragStartY.current;
    
    if (deltaY < -50 && !isExpanded) {
      onStateChange(true);
    } else if (deltaY > 50 && isExpanded) {
      onStateChange(false);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const toggleExpanded = () => {
    onStateChange(!isExpanded);
  };

  return (
    <motion.div
      ref={sheetRef}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      dragElastic={0}
      dragMomentum={false}
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      animate={controls}
      initial={{ height: COLLAPSED_HEIGHT }}
      className="fixed bottom-0 left-0 right-0 bg-white rounded-t-2xl shadow-lg z-30 flex flex-col"
      style={{ 
        willChange: 'transform',
        touchAction: 'none',
        transform: 'translate3d(0, 0, 0)',
        maxHeight: 'calc(85vh - 64px)'
      }}
    >
      {/* Handle and Header */}
      <div 
        className="cursor-grab active:cursor-grabbing"
        onClick={toggleExpanded}
      >
        {/* Drag Handle */}
        <div className="w-12 h-1.5 bg-gray-300 rounded-full mx-auto my-3" />
        
        {/* Header Content */}
        <div className="px-4 pb-4">
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center gap-3">
                <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggleFilters();
                  }}
                  className={`relative inline-flex items-center justify-center w-10 h-10 rounded-full transition-colors ${
                    showFilters
                      ? 'bg-blue-600 text-white hover:bg-blue-700'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                >
                  {showFilters ? (
                    <X className="h-5 w-5" />
                  ) : (
                    <SlidersHorizontal className="h-5 w-5" />
                  )}
                </button>
              </div>
              <p className="text-sm text-gray-500 mt-1">{totalResults} alloggi trovati</p>
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-y-auto overscroll-contain px-4 pb-8">
        {children}
      </div>
    </motion.div>
  );
}