import React, { useState } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

interface ImageGalleryProps {
  images: string[];
}

export function ImageGallery({ images }: ImageGalleryProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openGallery = (index: number) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const closeGallery = () => {
    setIsOpen(false);
  };

  const nextImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const prevImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  // Single image layout
  if (images.length === 1) {
    return (
      <div 
        className="w-full rounded-lg overflow-hidden cursor-pointer mb-6"
        onClick={() => openGallery(0)}
      >
        <img
          src={images[0]}
          alt="Immagine principale"
          className="w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] object-cover hover:opacity-90 transition-opacity"
        />
      </div>
    );
  }

  // Multiple images grid layout (showing only 3)
  return (
    <>
      <div className="grid grid-cols-12 gap-2 sm:gap-4 mb-6">
        {/* Main large image */}
        <div
          className="col-span-12 sm:col-span-8 rounded-lg overflow-hidden cursor-pointer aspect-[4/3]"
          onClick={() => openGallery(0)}
        >
          <img
            src={images[0]}
            alt="Immagine principale"
            className="w-full h-full object-cover hover:opacity-90 transition-opacity"
          />
        </div>

        {/* Side images */}
        <div className="col-span-12 sm:col-span-4 grid grid-cols-2 sm:grid-cols-1 gap-2 sm:gap-4">
          {images.slice(1, 3).map((image, index) => (
            <div
              key={index + 1}
              className="relative rounded-lg overflow-hidden cursor-pointer aspect-[4/3]"
              onClick={() => openGallery(index + 1)}
            >
              <img
                src={image}
                alt={`Immagine ${index + 2}`}
                className="w-full h-full object-cover hover:opacity-90 transition-opacity"
              />
              {index === 1 && images.length > 3 && (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                  <span className="text-white text-sm sm:text-lg font-medium">
                    +{images.length - 3} foto
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Full Screen Gallery */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
          onClick={closeGallery}
        >
          <button
            className="absolute top-2 right-2 sm:top-4 sm:right-4 text-white p-2 hover:bg-white/10 rounded-full"
            onClick={closeGallery}
          >
            <X className="h-5 w-5 sm:h-6 sm:w-6" />
          </button>

          <button
            className="absolute left-2 sm:left-4 top-1/2 -translate-y-1/2 text-white p-2 hover:bg-white/10 rounded-full"
            onClick={prevImage}
          >
            <ChevronLeft className="h-6 w-6 sm:h-8 sm:w-8" />
          </button>

          <div className="relative max-w-4xl w-full max-h-[80vh] mx-4">
            <img
              src={images[currentImageIndex]}
              alt={`Immagine ${currentImageIndex + 1}`}
              className="max-h-[80vh] w-auto mx-auto"
            />
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 text-white bg-black/50 px-3 py-1 rounded-full text-xs sm:text-sm">
              {currentImageIndex + 1} / {images.length}
            </div>
          </div>

          <button
            className="absolute right-2 sm:right-4 top-1/2 -translate-y-1/2 text-white p-2 hover:bg-white/10 rounded-full"
            onClick={nextImage}
          >
            <ChevronRight className="h-6 w-6 sm:h-8 sm:w-8" />
          </button>
        </div>
      )}
    </>
  );
}