import { 
  collection,
  addDoc,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
  serverTimestamp,
  writeBatch,
  getDoc
} from 'firebase/firestore';
import { db } from './firebase';
import { uploadProductImage, deleteFile } from './storage';
import { toast } from 'react-toastify';
import { getAuth } from 'firebase/auth';

export interface Product {
  id: string;
  title: string;
  description: string;
  price: number;
  image: string;
  link: string;
  userType: 'student' | 'landlord';
  createdAt: Date;
  updatedAt?: Date;
}

const productsCollection = collection(db, 'products');

export async function getProducts(userType?: 'student' | 'landlord'): Promise<Product[]> {
  try {
    let q = query(productsCollection);
    
    if (userType) {
      q = query(productsCollection, where('userType', '==', userType));
    }
    
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate(),
      updatedAt: doc.data().updatedAt?.toDate()
    })) as Product[];
  } catch (error: any) {
    console.error('Error getting products:', error);
    throw new Error('Errore nel caricamento dei prodotti');
  }
}

export async function addProduct(data: Omit<Product, 'id' | 'createdAt'>, imageFile: File): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    throw new Error('Utente non autenticato');
  }

  try {
    // Upload image first
    const imageUrl = await uploadProductImage(imageFile);

    // Create product document
    const docRef = await addDoc(productsCollection, {
      ...data,
      image: imageUrl,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // Verifica che il documento sia stato creato
    const newDoc = await getDoc(docRef);
    if (!newDoc.exists()) {
      throw new Error('Errore nella creazione del prodotto');
    }

    toast.success('Prodotto aggiunto con successo');
  } catch (error: any) {
    console.error('Error creating product:', error);
    throw new Error(error.message || 'Errore durante la creazione del prodotto');
  }
}

export async function updateProduct(
  productId: string, 
  data: Partial<Product>, 
  imageFile?: File
): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    throw new Error('Utente non autenticato');
  }

  try {
    const productRef = doc(productsCollection, productId);
    const productDoc = await getDoc(productRef);

    if (!productDoc.exists()) {
      throw new Error('Prodotto non trovato');
    }

    let updateData = { ...data };

    if (imageFile) {
      const imageUrl = await uploadProductImage(imageFile);
      
      if (data.image) {
        await deleteFile(data.image).catch(err => {
          console.warn('Errore durante l\'eliminazione dell\'immagine precedente:', err);
        });
      }
      
      updateData.image = imageUrl;
    }

    updateData.updatedAt = serverTimestamp();

    await updateDoc(productRef, updateData);
    toast.success('Prodotto aggiornato con successo');

  } catch (error: any) {
    console.error('Error updating product:', error);
    throw new Error(error.message || 'Errore durante l\'aggiornamento del prodotto');
  }
}

export async function deleteProduct(productId: string, imageUrl: string): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    throw new Error('Utente non autenticato');
  }

  try {
    const batch = writeBatch(db);
    
    // Delete image from storage
    await deleteFile(imageUrl);
    
    // Delete product document
    const productRef = doc(productsCollection, productId);
    batch.delete(productRef);
    
    await batch.commit();
    toast.success('Prodotto eliminato con successo');
  } catch (error: any) {
    console.error('Error deleting product:', error);
    throw new Error(error.message || 'Errore durante l\'eliminazione del prodotto');
  }
}