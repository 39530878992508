import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ListingsMap } from '../components/ListingsMap';
import { ListingCard } from '../components/ListingCard';
import { Listing } from '../types/listing';
import { getListingsInBounds } from '../lib/listings';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { BottomSheet } from '../components/search/BottomSheet';
import { SearchFilters } from '../components/search/SearchFilters';
import debounce from 'lodash/debounce';

export function Search() {
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filters, setFilters] = useState({
    category: '',
    minPrice: '',
    maxPrice: '',
    maxOccupants: '',
    availableFrom: ''
  });
  
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const listingRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const [isBottomSheetExpanded, setIsBottomSheetExpanded] = useState(false);

  const fetchListingsInView = useCallback(async () => {
    if (!mapRef.current) return;

    const bounds = mapRef.current.getBounds();
    
    try {
      setLoading(true);
      setError(null);
      const fetchedListings = await getListingsInBounds({
        north: bounds.getNorth(),
        south: bounds.getSouth(),
        east: bounds.getEast(),
        west: bounds.getWest()
      });
      
      if (fetchedListings.length === 0) {
        setError('Nessun annuncio trovato in questa zona');
      }
      
      setListings(fetchedListings);
    } catch (error) {
      console.error('Errore nel recupero degli annunci:', error);
      setError('Si è verificato un errore durante il caricamento degli annunci');
    } finally {
      setLoading(false);
    }
  }, []);

  const debouncedFetch = useCallback(
    debounce(() => {
      fetchListingsInView();
    }, 500),
    [fetchListingsInView]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchListingsInView();
    }, 1000);

    return () => clearTimeout(timer);
  }, [fetchListingsInView]);

  const handleMapMove = useCallback(() => {
    debouncedFetch();
  }, [debouncedFetch]);

  const handleListingSelect = (listing: Listing) => {
    setSelectedListing(listing);
    
    if (mapRef.current && listing.location?.coordinates) {
      mapRef.current.flyTo({
        center: [listing.location.coordinates[0], listing.location.coordinates[1]],
        zoom: 15,
        duration: 1000
      });
    }
    
    if (listingRefs.current[listing.id]) {
      listingRefs.current[listing.id].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }

    if (isMobile) {
      setIsBottomSheetExpanded(true);
    }
  };

  const filteredListings = listings.filter(listing => {
    if (filters.category && listing.category !== filters.category) return false;
    if (filters.minPrice && listing.price < Number(filters.minPrice)) return false;
    if (filters.maxPrice && listing.price > Number(filters.maxPrice)) return false;
    if (filters.maxOccupants && listing.maxOccupants > Number(filters.maxOccupants)) return false;
    if (filters.availableFrom) {
      const availableDate = new Date(listing.availableFrom);
      const filterDate = new Date(filters.availableFrom);
      if (availableDate > filterDate) return false;
    }
    return true;
  });

  const renderResults = () => (
    <div className="space-y-4">
      {loading && listings.length === 0 ? (
        <div className="text-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-2 text-gray-500">Caricamento annunci...</p>
        </div>
      ) : error ? (
        <div className="text-center py-8">
          <p className="text-red-500">{error}</p>
          <button
            onClick={fetchListingsInView}
            className="mt-2 text-blue-600 hover:text-blue-700"
          >
            Riprova
          </button>
        </div>
      ) : filteredListings.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-500">Nessun risultato trovato</p>
        </div>
      ) : (
        filteredListings.map((listing) => (
          <div
            key={listing.id}
            ref={el => {
              if (el) listingRefs.current[listing.id] = el;
            }}
            className="transition-all"
            onClick={() => handleListingSelect(listing)}
          >
            <ListingCard listing={listing} isSelected={selectedListing?.id === listing.id} />
          </div>
        ))
      )}
    </div>
  );

  return (
    <div className="min-h-screen pt-16">
      {isMobile ? (
        // Mobile Layout
        <>
          <div className="fixed inset-0 top-16">
            <ListingsMap
              listings={filteredListings}
              onListingSelect={handleListingSelect}
              selectedListing={selectedListing}
              onMapMove={handleMapMove}
              mapRef={mapRef}
              onMarkerClick={() => setIsBottomSheetExpanded(true)}
            />
          </div>
          
          <BottomSheet 
            title="Risultati della ricerca"
            totalResults={filteredListings.length}
            onToggleFilters={() => setShowFilters(!showFilters)}
            showFilters={showFilters}
            isExpanded={isBottomSheetExpanded}
            onStateChange={(expanded) => setIsBottomSheetExpanded(expanded)}
          >
            {showFilters && (
              <SearchFilters
                filters={filters}
                onFilterChange={setFilters}
                showFilters={showFilters}
                onToggleFilters={() => setShowFilters(!showFilters)}
                totalResults={filteredListings.length}
              />
            )}
            <div className="overflow-y-auto">
              {renderResults()}
            </div>
          </BottomSheet>
        </>
      ) : (
        // Desktop/Tablet Layout
        <div className="grid grid-cols-12 h-[calc(100vh-64px)]">
          <div className="col-span-5 xl:col-span-4 flex flex-col overflow-hidden border-r border-gray-200">
            <div className="p-4 border-b border-gray-200 flex items-center justify-between">
              <div>
                <h2 className="text-lg font-semibold">Risultati della ricerca</h2>
                <p className="text-sm text-gray-500">{filteredListings.length} risultati trovati</p>
              </div>
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
              >
                Filtri
              </button>
            </div>
            <SearchFilters
              filters={filters}
              onFilterChange={setFilters}
              showFilters={showFilters}
              onToggleFilters={() => setShowFilters(!showFilters)}
              totalResults={filteredListings.length}
            />
            <div className="flex-1 overflow-y-auto p-4">
              {renderResults()}
            </div>
          </div>

          <div className="col-span-7 xl:col-span-8">
            <ListingsMap
              listings={filteredListings}
              onListingSelect={handleListingSelect}
              selectedListing={selectedListing}
              onMapMove={handleMapMove}
              mapRef={mapRef}
              onMarkerClick={() => setIsBottomSheetExpanded(true)}
            />
          </div>
        </div>
      )}
    </div>
  );
}