import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { AlertCircle, Send, User } from 'lucide-react';
import { ApplicationConfirmModal } from './applications/ApplicationConfirmModal';
import { hasAppliedToListing } from '../lib/applications';

interface ContactFormProps {
  listingId: string;
  listingOwnerId: string;
  listingTitle: string;
  featured?: {
    active: boolean;
    plan: 'daily' | 'weekly' | 'monthly' | null;
    expiresAt: Date;
  };
}

export function ContactForm({ listingId, listingOwnerId, listingTitle, featured }: ContactFormProps) {
  const { userProfile } = useAuthContext();
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkApplicationStatus = async () => {
      if (userProfile?.id) {
        const applied = await hasAppliedToListing(userProfile.id, listingId);
        setHasApplied(applied);
      }
      setLoading(false);
    };

    checkApplicationStatus();
  }, [userProfile?.id, listingId]);

  // If the user is the owner of the listing
  if (userProfile?.id === listingOwnerId) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="text-center">
          <AlertCircle className="mx-auto h-12 w-12 text-yellow-500" />
          <h3 className="mt-2 text-lg font-medium text-gray-900">
            Questo è il tuo annuncio
          </h3>
          <p className="mt-2 text-sm text-gray-500">
            Non puoi inviare una candidatura per il tuo stesso annuncio
          </p>
        </div>
      </div>
    );
  }

  // If the user is a landlord but not the owner
  if (userProfile?.userType === 'landlord') {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="text-center">
          <AlertCircle className="mx-auto h-12 w-12 text-yellow-500" />
          <h3 className="mt-2 text-lg font-medium text-gray-900">
            Accesso Limitato
          </h3>
          <p className="mt-2 text-sm text-gray-500">
            Per candidarti a questo annuncio devi essere registrato come studente
          </p>
        </div>
      </div>
    );
  }

  // If the user is logged in as a student
  if (userProfile?.userType === 'student') {
    if (loading) {
      return (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-4 text-gray-500">Caricamento...</p>
          </div>
        </div>
      );
    }

    if (hasApplied) {
      return (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="text-center">
            <AlertCircle className="mx-auto h-12 w-12 text-yellow-500" />
            <h3 className="mt-2 text-lg font-medium text-gray-900">
              Hai già inviato una candidatura
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Puoi visualizzare lo stato della tua candidatura nella dashboard
            </p>
            <Link
              to="/dashboard/student?tab=applications"
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              Vai alle tue candidature
            </Link>
          </div>
        </div>
      );
    }

    const hasStudentInfo = userProfile.studentInfo?.university && 
                          userProfile.studentInfo?.studyProgram && 
                          userProfile.studentInfo?.studyYear;

    const handleApply = () => {
      if (!hasStudentInfo) {
        // Redirect to student dashboard to complete profile
        navigate('/dashboard/student', { 
          state: { 
            activeTab: 'applications',
            openApplicationData: true,
            message: 'Inserisci i tuoi dati per poterti candidare'
          }
        });
      } else {
        // Show confirmation modal
        setShowConfirmModal(true);
      }
    };

    return (
      <>
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold">Candidati per questo alloggio</h2>
            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
              Disponibile
            </span>
          </div>

          {!hasStudentInfo && (
            <div className="mb-4 p-4 bg-yellow-50 border border-yellow-200 rounded-lg flex items-start gap-3">
              <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5 flex-shrink-0" />
              <div>
                <p className="text-sm text-yellow-700 font-medium">
                  Completa il tuo profilo
                </p>
                <p className="text-sm text-yellow-600 mt-1">
                  Per inviare una candidatura devi prima inserire le informazioni sul tuo percorso di studi
                </p>
              </div>
            </div>
          )}

          {hasStudentInfo && (
            <div className="mb-4 p-4 bg-blue-50 border border-blue-200 rounded-lg">
              <div className="flex items-center gap-2 mb-2">
                <User className="h-5 w-5 text-blue-500" />
                <span className="font-medium text-blue-700">Dati Candidatura</span>
              </div>
              <div className="text-sm text-blue-600 space-y-1">
                <p>{userProfile.studentInfo.university}</p>
                <p>{userProfile.studentInfo.studyProgram} - {userProfile.studentInfo.studyYear}° anno</p>
              </div>
            </div>
          )}

          <button
            onClick={handleApply}
            className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Send className="h-5 w-5" />
            {hasStudentInfo ? 'Conferma e Candidati' : 'Inserisci Dati e Candidati'}
          </button>

          <p className="mt-4 text-sm text-gray-500 text-center">
            La candidatura costa 5 crediti e scade dopo 24 ore se non ricevi risposta. I crediti ti verranno restituiti in caso di mancata risposta.
          </p>
        </div>

        {showConfirmModal && (
          <ApplicationConfirmModal
            onClose={() => setShowConfirmModal(false)}
            listingId={listingId}
            listingTitle={listingTitle}
            listingOwnerId={listingOwnerId}
          />
        )}
      </>
    );
  }

  // If the user is not logged in
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="text-center">
        <User className="h-12 w-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          Accedi per candidarti
        </h3>
        <p className="text-sm text-gray-500 mb-6">
          Per garantire la sicurezza di tutti, è necessario registrarsi o accedere prima di inviare una candidatura
        </p>
        <div className="space-y-3">
          <Link 
            to="/login"
            className="block w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
          >
            Accedi
          </Link>
          <Link 
            to="/register"
            className="block w-full bg-white text-blue-600 py-2 px-4 rounded-md border border-blue-600 hover:bg-blue-50 transition-colors"
          >
            Registrati
          </Link>
        </div>
      </div>
    </div>
  );
}