import React from 'react';
import { Check } from 'lucide-react';

interface Step {
  id: number;
  name: string;
}

interface StepIndicatorProps {
  steps: Step[];
  currentStep: number;
  onChange: (step: number) => void;
}

export function StepIndicator({ steps, currentStep, onChange }: StepIndicatorProps) {
  return (
    <div className="relative">
      {/* Progress Bar */}
      <div className="absolute top-5 left-0 w-full h-1 bg-gray-200 rounded-full">
        <div 
          className="absolute left-0 top-0 h-full bg-gradient-to-r from-blue-500 to-blue-600 rounded-full transition-all duration-500 ease-out"
          style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
        />
      </div>

      {/* Steps */}
      <div className="relative flex justify-between">
        {steps.map((step) => {
          const isCompleted = currentStep > step.id;
          const isCurrent = currentStep === step.id;
          const isClickable = step.id <= currentStep;

          return (
            <button
              key={step.id}
              onClick={() => isClickable && onChange(step.id)}
              disabled={!isClickable}
              className={`flex flex-col items-center ${isClickable ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            >
              {/* Step Circle */}
              <div
                className={`w-10 h-10 rounded-full flex items-center justify-center transition-all duration-200 ${
                  isCompleted
                    ? 'bg-gradient-to-r from-blue-500 to-blue-600 shadow-lg shadow-blue-500/20'
                    : isCurrent
                    ? 'bg-white border-2 border-blue-500 shadow-lg shadow-blue-500/20'
                    : 'bg-white border-2 border-gray-200'
                }`}
              >
                {isCompleted ? (
                  <Check className="h-5 w-5 text-white" />
                ) : (
                  <span
                    className={`text-sm font-semibold ${
                      isCurrent ? 'text-blue-600' : 'text-gray-400'
                    }`}
                  >
                    {step.id}
                  </span>
                )}
              </div>

              {/* Step Label */}
              <span
                className={`mt-3 text-sm font-medium transition-colors duration-200 ${
                  isCurrent
                    ? 'text-blue-600'
                    : isCompleted
                    ? 'text-gray-900'
                    : 'text-gray-400'
                }`}
              >
                {step.name}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
}