import React, { useState, useEffect } from 'react';
import { Package, Plus, Edit, Trash2 } from 'lucide-react';
import { CreditPackageModal } from './CreditPackageModal';
import { getCreditPackages, createCreditPackage, updateCreditPackage, deleteCreditPackage } from '../../../lib/plans';
import { toast } from 'react-toastify';

export function CreditPackagesTab() {
  const [packages, setPackages] = useState<CreditPackage[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<CreditPackage | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedUserType, setSelectedUserType] = useState<'student' | 'landlord'>('student');

  useEffect(() => {
    loadPackages();
  }, []);

  const loadPackages = async () => {
    try {
      setLoading(true);
      const data = await getCreditPackages();
      setPackages(data);
    } catch (error) {
      console.error('Error loading packages:', error);
      toast.error('Errore nel caricamento dei pacchetti');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (packageData: CreditPackage) => {
    try {
      setLoading(true);
      if (selectedPackage) {
        await updateCreditPackage(selectedPackage.id, packageData);
      } else {
        await createCreditPackage(packageData);
      }
      await loadPackages();
      setShowModal(false);
      setSelectedPackage(null);
    } catch (error) {
      console.error('Error saving package:', error);
      toast.error('Errore durante il salvataggio del pacchetto');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Sei sicuro di voler eliminare questo pacchetto?')) {
      return;
    }

    try {
      setLoading(true);
      await deleteCreditPackage(id);
      await loadPackages();
    } catch (error) {
      console.error('Error deleting package:', error);
      toast.error('Errore durante l\'eliminazione del pacchetto');
    } finally {
      setLoading(false);
    }
  };

  const filteredPackages = packages.filter(pkg => pkg.userType === selectedUserType);

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento pacchetti...</p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Gestione Pacchetti Crediti</h2>
        <button
          onClick={() => {
            setSelectedPackage(null);
            setShowModal(true);
          }}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
        >
          <Plus className="h-5 w-5 mr-2" />
          Nuovo Pacchetto
        </button>
      </div>

      <div className="mb-6">
        <div className="flex gap-4">
          <button
            onClick={() => setSelectedUserType('student')}
            className={`flex items-center gap-2 px-4 py-2 rounded-md ${
              selectedUserType === 'student'
                ? 'bg-blue-100 text-blue-700'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            <Package className="h-5 w-5" />
            Pacchetti Studenti
          </button>
          <button
            onClick={() => setSelectedUserType('landlord')}
            className={`flex items-center gap-2 px-4 py-2 rounded-md ${
              selectedUserType === 'landlord'
                ? 'bg-blue-100 text-blue-700'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            <Package className="h-5 w-5" />
            Pacchetti Proprietari
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {filteredPackages.map((pkg) => (
          <div
            key={pkg.id}
            className={`relative bg-white rounded-lg shadow-sm overflow-hidden ${
              pkg.popular ? 'ring-2 ring-blue-500' : ''
            }`}
          >
            {pkg.popular && (
              <div className="absolute top-3 right-3 bg-blue-500 text-white text-xs font-medium px-2.5 py-1 rounded-full">
                Più popolare
              </div>
            )}
            
            <div className="p-6">
              <Package className={`h-12 w-12 ${pkg.popular ? 'text-blue-500' : 'text-gray-400'} mb-4`} />
              <h3 className="text-2xl font-bold">{pkg.name}</h3>
              <p className="text-gray-600 mt-1">{pkg.description}</p>
              
              <div className="mt-6">
                <div className="text-3xl font-bold text-blue-600">
                  €{pkg.price}
                </div>
                <div className="text-gray-500">
                  €{pkg.pricePerCredit.toFixed(2)} per credito
                </div>
                <div className="text-lg font-semibold text-gray-700 mt-2">
                  {pkg.credits} crediti
                </div>
              </div>

              <ul className="mt-6 space-y-3">
                {pkg.features.map((feature, index) => (
                  <li key={index} className="flex items-center text-sm text-gray-600">
                    <Package className="h-4 w-4 text-blue-500 mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>

              <div className="mt-6 flex gap-2">
                <button
                  onClick={() => {
                    setSelectedPackage(pkg);
                    setShowModal(true);
                  }}
                  className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Edit className="h-4 w-4 mr-2" />
                  Modifica
                </button>
                <button
                  onClick={() => handleDelete(pkg.id)}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <CreditPackageModal
          package={selectedPackage}
          onClose={() => {
            setShowModal(false);
            setSelectedPackage(null);
          }}
          onSave={handleSave}
          defaultUserType={selectedUserType}
        />
      )}
    </div>
  );
}