import React, { useState, useEffect } from 'react';
import { X, Star } from 'lucide-react';
import { Listing } from '../../../types/listing';
import { updateListingFeatured } from '../../../lib/listings';
import { PaymentModal } from '../../payments/PaymentModal';
import { getFeaturedPlans, validateFeaturedPlan } from '../../../lib/plans';
import { FeaturedPlan } from '../../../types/plans';
import { toast } from 'react-toastify';
import { createPayment } from '../../../lib/payments';
import { useAuthContext } from '../../../contexts/AuthContext';

interface FeaturedModalProps {
  listing: Listing;
  onClose: () => void;
  onSuccess: () => void;
}

export function FeaturedModal({ listing, onClose, onSuccess }: FeaturedModalProps) {
  const { userProfile } = useAuthContext();
  const [plans, setPlans] = useState<FeaturedPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPlans();
  }, []);

  const loadPlans = async () => {
    try {
      setLoading(true);
      const data = await getFeaturedPlans();
      setPlans(data);
    } catch (error) {
      console.error('Error loading plans:', error);
      toast.error('Errore nel caricamento dei piani');
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentSuccess = async (paymentId: string) => {
    if (!selectedPlan || !listing?.id) {
      toast.error('Seleziona un piano valido');
      return;
    }

    if (!paymentId) {
      console.error('PaymentId mancante');
      toast.error('Errore nella transazione');
      return;
    }

    setLoading(true);
    try {
      // Verifica validità piano
      const isValid = await validateFeaturedPlan(selectedPlan);
      if (!isValid) {
        throw new Error('Piano non più disponibile');
      }

      console.log('Aggiornamento annuncio in evidenza:', {
        listingId: listing.id,
        planId: selectedPlan,
        paymentId
      });

      await updateListingFeatured(listing.id, selectedPlan, paymentId);
      toast.success('Annuncio messo in evidenza con successo');
      onSuccess();
    } catch (error: any) {
      console.error('Error updating featured status:', error);
      toast.error(error.message || 'Errore durante l\'aggiornamento dell\'annuncio');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg max-w-md w-full p-6">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
          <p className="text-center mt-4">Caricamento piani...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white border-b border-gray-200 p-6 z-10">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">Metti in evidenza il tuo annuncio</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>
        </div>
        
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className={`relative bg-white rounded-lg shadow-sm overflow-hidden cursor-pointer transition-all hover:shadow-md ${
                  plan.popular ? 'ring-2 ring-yellow-500' : ''
                }`}
                onClick={() => setSelectedPlan(plan.id)}
              >
                {plan.popular && (
                  <div className="absolute top-3 right-3 bg-yellow-500 text-white text-xs font-medium px-2.5 py-1 rounded-full">
                    Più popolare
                  </div>
                )}
                
                <div className="p-6">
                  <Star className={`h-12 w-12 ${plan.popular ? 'text-yellow-500' : 'text-gray-400'} mb-4`} />
                  <h3 className="text-2xl font-bold">{plan.name}</h3>
                  <p className="text-gray-600 mt-1">{plan.description}</p>
                  
                  <div className="mt-6">
                    <div className="text-3xl font-bold text-blue-600">
                      €{plan.price}
                    </div>
                    <div className="text-gray-500">{plan.durationLabel}</div>
                  </div>

                  <ul className="mt-6 space-y-3">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="flex items-center text-sm text-gray-600">
                        <Star className="h-4 w-4 text-yellow-500 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>

                  <div className="mt-6">
                    <div
                      className={`w-full h-1 rounded-full ${
                        selectedPlan === plan.id ? 'bg-yellow-500' : 'bg-gray-200'
                      }`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {selectedPlan && (
            <PaymentModal
              amount={plans.find(p => p.id === selectedPlan)?.price || 0}
              title="Metti in Evidenza"
              description={`Piano ${plans.find(p => p.id === selectedPlan)?.name}`}
              metadata={{
                type: 'featured',
                listingId: listing.id,
                plan: selectedPlan
              }}
              onClose={() => setSelectedPlan(null)}
              onSuccess={handlePaymentSuccess}
            />
          )}
        </div>
      </div>
    </div>
  );
}