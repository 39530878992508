import React from 'react';

interface BreakdownChartProps {
  title: string;
  data: Record<string, number>;
  total: number;
}

export function BreakdownChart({ title, data, total }: BreakdownChartProps) {
  const safeTotal = total || 1; // Prevent division by zero

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h3 className="text-lg font-medium mb-4">{title}</h3>
      <div className="space-y-4">
        {Object.entries(data).map(([key, value]) => {
          const percentage = Math.round((value / safeTotal) * 100);
          
          return (
            <div key={key} className="flex items-center justify-between">
              <span className="text-sm text-gray-600 capitalize">{key}</span>
              <div className="flex items-center">
                <div className="w-32 bg-gray-200 rounded-full h-2 mr-2">
                  <div
                    className="bg-blue-600 rounded-full h-2"
                    style={{
                      width: `${percentage}%`
                    }}
                  />
                </div>
                <span className="text-sm text-gray-600">
                  {percentage}%
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}