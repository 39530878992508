import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { SearchBar } from './SearchBar';

const cityCoordinates = {
  'Venezia': { lat: 45.4371, lng: 12.3326 },
  'Milano': { lat: 45.4642, lng: 9.1900 },
  'Roma': { lat: 41.9028, lng: 12.4964 },
  'Bologna': { lat: 44.4949, lng: 11.3426 },
  'Torino': { lat: 45.0703, lng: 7.6869 },
  'Firenze': { lat: 43.7696, lng: 11.2558 }
};

const images = [
  {
    url: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&q=80",
    caption: "Appartamenti moderni nel cuore della città"
  },
  {
    url: "https://images.unsplash.com/photo-1522708323590-d24dbb6b0267?auto=format&fit=crop&q=80",
    caption: "Stanze luminose e ben arredate"
  },
  {
    url: "https://images.unsplash.com/photo-1502672260266-1c1ef2d93688?auto=format&fit=crop&q=80",
    caption: "Spazi comuni accoglienti e funzionali"
  }
];

export function Hero() {
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSearch = (query: string) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 
      address: query,
      componentRestrictions: { country: 'it' }
    }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        const location = results[0].geometry.location;
        const city = results[0].address_components.find(
          component => component.types.includes('locality')
        )?.long_name || query;

        navigate('/search', {
          state: {
            center: { 
              lat: location.lat(), 
              lng: location.lng() 
            },
            zoom: 13,
            address: city
          }
        });
      } else {
        console.error('Città non trovata');
        navigate('/search', {
          state: {
            center: { lat: 45.4371, lng: 12.3326 },
            zoom: 13,
            address: query
          }
        });
      }
    });
  };

  const handleCityClick = (city: string) => {
    const coords = cityCoordinates[city as keyof typeof cityCoordinates];
    if (coords) {
      navigate('/search', {
        state: { 
          center: coords,
          zoom: 13,
          address: city
        }
      });
    }
  };

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="min-h-[85vh] bg-gray-50 pt-28 lg:pt-36" style={{ zIndex: 1 }}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="flex flex-col justify-center py-12 lg:py-0">
            <div className="max-w-xl mx-auto lg:mx-0 text-left">
              <h1 className="text-4xl md:text-4xl lg:text-5xl xl:text-6xl font-bold tracking-tight text-gray-900">
                Trova la tua casa universitaria
              </h1>
              <p className="mt-4 md:mt-6 text-base md:text-lg lg:text-xl text-gray-600">
                Migliaia di alloggi verificati per studenti in tutta Italia
              </p>
              
              {/* Search Box */}
              <div className="mt-8 md:mt-10">
                <SearchBar onSearch={handleSearch} />
              </div>

              {/* Popular Cities */}
              <div className="mt-6 md:mt-8">
                <div className="flex flex-wrap justify-start gap-2 md:gap-3">
                  {Object.keys(cityCoordinates).map((city) => (
                    <button
                      key={city}
                      onClick={() => handleCityClick(city)}
                      className="px-4 md:px-6 py-2 md:py-2.5 bg-blue-100 hover:bg-blue-200 text-blue-800 rounded-full transition-all duration-300 text-sm md:text-base"
                    >
                      {city}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Image Slider */}
          <div className="relative h-[400px] lg:h-auto">
            <div className="absolute inset-0 rounded-2xl overflow-hidden">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`absolute inset-0 transition-opacity duration-1000 ${
                    index === currentImage ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <div className="absolute inset-0 flex flex-col">
                    <img
                      className="absolute inset-0 w-full h-full object-cover"
                      src={image.url}
                      alt={image.caption}
                    />
                    {/* Overlay gradiente */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent" />
                    
                    {/* Contenitore testo */}
                    <div className="relative h-full flex items-end z-10 p-6 md:p-8">
                      <p className="text-base md:text-lg text-white font-medium tracking-wide">
                        {image.caption}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              {/* Slider Controls */}
              <button
                onClick={prevImage}
                className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 hover:bg-white text-gray-800 transition-all"
              >
                <ChevronLeft className="h-6 w-6" />
              </button>
              <button
                onClick={nextImage}
                className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 hover:bg-white text-gray-800 transition-all"
              >
                <ChevronRight className="h-6 w-6" />
              </button>

              {/* Dots Indicator */}
              <div className="absolute bottom-24 left-1/2 -translate-x-1/2 flex gap-2">
                {images.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentImage(index)}
                    className={`w-2 h-2 rounded-full transition-all ${
                      index === currentImage 
                        ? 'bg-white w-4' 
                        : 'bg-white/50 hover:bg-white/75'
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}