import React, { useState, useEffect } from 'react';
import { Star, Plus, Edit, Trash2 } from 'lucide-react';
import { FeaturedPlanModal } from './FeaturedPlanModal';
import { getFeaturedPlans, createFeaturedPlan, updateFeaturedPlan, deleteFeaturedPlan } from '../../../lib/plans';
import { toast } from 'react-toastify';

export function FeaturedPlansTab() {
  const [plans, setPlans] = useState<FeaturedPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<FeaturedPlan | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPlans();
  }, []);

  const loadPlans = async () => {
    try {
      setLoading(true);
      const data = await getFeaturedPlans();
      setPlans(data);
    } catch (error) {
      console.error('Error loading plans:', error);
      toast.error('Errore nel caricamento dei piani');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (planData: FeaturedPlan) => {
    try {
      setLoading(true);
      if (selectedPlan) {
        await updateFeaturedPlan(selectedPlan.id, planData);
      } else {
        await createFeaturedPlan(planData);
      }
      await loadPlans();
      setShowModal(false);
      setSelectedPlan(null);
    } catch (error) {
      console.error('Error saving plan:', error);
      toast.error('Errore durante il salvataggio del piano');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Sei sicuro di voler eliminare questo piano?')) {
      return;
    }

    try {
      setLoading(true);
      await deleteFeaturedPlan(id);
      await loadPlans();
      toast.success('Piano eliminato con successo');
    } catch (error) {
      console.error('Error deleting plan:', error);
      toast.error('Errore durante l\'eliminazione del piano');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Caricamento piani...</p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Gestione Piani in Evidenza</h2>
        <button
          onClick={() => {
            setSelectedPlan(null);
            setShowModal(true);
          }}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
        >
          <Plus className="h-5 w-5 mr-2" />
          Nuovo Piano
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {plans.map((plan) => (
          <div
            key={plan.id}
            className={`relative bg-white rounded-lg shadow-sm overflow-hidden ${
              plan.popular ? 'ring-2 ring-blue-500' : ''
            }`}
          >
            {plan.popular && (
              <div className="absolute top-3 right-3 bg-blue-500 text-white text-xs font-medium px-2.5 py-1 rounded-full">
                Più popolare
              </div>
            )}
            
            <div className="p-6">
              <Star className={`h-12 w-12 ${plan.popular ? 'text-blue-500' : 'text-gray-400'} mb-4`} />
              <h3 className="text-2xl font-bold">{plan.name}</h3>
              <p className="text-gray-600 mt-1">{plan.description}</p>
              
              <div className="mt-6">
                <div className="text-3xl font-bold text-blue-600">
                  €{plan.price}
                </div>
                <div className="text-gray-500">{plan.duration}</div>
              </div>

              <ul className="mt-6 space-y-3">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center text-sm text-gray-600">
                    <Star className="h-4 w-4 text-blue-500 mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>

              <div className="mt-6 flex gap-2">
                <button
                  onClick={() => {
                    setSelectedPlan(plan);
                    setShowModal(true);
                  }}
                  className="flex-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Edit className="h-4 w-4 mr-2" />
                  Modifica
                </button>
                <button
                  onClick={() => handleDelete(plan.id)}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <FeaturedPlanModal
          plan={selectedPlan}
          onClose={() => {
            setShowModal(false);
            setSelectedPlan(null);
          }}
          onSave={handleSave}
        />
      )}
    </div>
  );
}