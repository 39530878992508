import React, { useState } from 'react';
import { Search, MapPin } from 'lucide-react';

interface SearchBarProps {
  onSearch: (query: string) => void;
  placeholder?: string;
}

export function SearchBar({ onSearch, placeholder = 'Cerca per città...' }: SearchBarProps) {
  const [query, setQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (query.trim()) {
      onSearch(query.trim());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="relative max-w-xl mx-auto">
        <div 
          className={`group relative bg-white rounded-[28px] transition-all duration-200`}
          style={{ zIndex: 1 }}
        >
          {/* Focus ring overlay */}
          <div 
            className={`absolute -inset-0.5 rounded-[32px] transition-opacity duration-200 opacity-0`}
          />
          
          {/* Border */}
          <div className="absolute inset-0 rounded-[28px] ring-1 ring-inset ring-gray-200" />
          
          <div className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 z-10">
            <MapPin className="h-5 w-5" />
          </div>
          
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={placeholder}
            className="relative w-full pl-12 pr-16 sm:pr-24 py-4 text-gray-900 placeholder-gray-500 bg-transparent border-0 focus:ring-0 focus:outline-none z-10"
          />
          
          <button
            type="submit"
            className="absolute right-2 top-1/2 -translate-y-1/2 h-[42px] px-3 sm:px-4 bg-blue-600 text-white rounded-[21px] hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 z-10"
          >
            <Search className="h-5 w-5" />
            <span className="hidden sm:inline">Cerca</span>
          </button>
        </div>
      </div>
    </form>
  );
}