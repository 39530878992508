import React, { useState, useEffect } from 'react';
import { X, AlertTriangle, Info } from 'lucide-react';
import { updateListing, deleteListing, getListing } from '@/lib/listings';
import { toast } from 'react-toastify';
import { ListingForm } from '../../../components/listings/ListingForm';
import { Listing } from '../../types/listing';
import { generateNumericId } from '../../../utils/ids';

interface EditListingModalProps {
  listingId: string;
  onClose: () => void;
  onSuccess: () => void;
}

export function EditListingModal({ listingId, onClose, onSuccess }: EditListingModalProps) {
  const [loading, setLoading] = useState(false);
  const [loadingListing, setLoadingListing] = useState(true);
  const [listing, setListing] = useState<Listing | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const loadListing = async () => {
      if (!listingId) {
        setError('ID annuncio non valido');
        setLoadingListing(false);
        return;
      }

      try {
        setLoadingListing(true);
        setError(null);
        const data = await getListing(listingId);
        
        if (!data) {
          setError('Annuncio non trovato');
          toast.error('Annuncio non trovato');
          onClose();
          return;
        }

        // Format dates for form inputs
        const formattedData = {
          ...data,
          availableFrom: formatDateForInput(data.availableFrom)
        };

        setListing(formattedData);
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Errore nel caricamento dell\'annuncio';
        setError(message);
        toast.error(message);
        onClose();
      } finally {
        setLoadingListing(false);
      }
    };

    loadListing();
  }, [listingId, onClose]);

  const formatDateForInput = (date: Date | string): string => {
    const d = new Date(date);
    if (isNaN(d.getTime())) {
      return new Date().toISOString().split('T')[0];
    }
    d.setUTCHours(12, 0, 0, 0);
    return d.toISOString().split('T')[0];
  };

  const sanitizeFormData = (formData: any) => {
    // Convert string numbers to actual numbers
    const numericFields = ['price', 'deposit', 'size', 'rooms', 'bathrooms', 'floor', 'maxOccupants', 'minStay', 'noticePeriod'];
    const sanitized = { ...formData };

    numericFields.forEach(field => {
      if (field in sanitized) {
        sanitized[field] = Number(sanitized[field]);
      }
    });

    // Ensure proper date format
    if (sanitized.availableFrom) {
      sanitized.availableFrom = new Date(sanitized.availableFrom + 'T12:00:00Z');
    }

    // Remove undefined or null values
    Object.keys(sanitized).forEach(key => {
      if (sanitized[key] === undefined || sanitized[key] === null) {
        delete sanitized[key];
      }
    });

    return sanitized;
  };

  const handleSubmit = async (formData: any) => {
    if (!listing) return;

    setLoading(true);
    try {
      setError(null);

      const sanitizedData = sanitizeFormData(formData);
      
      await updateListing(listing.id, {
        ...sanitizedData,
        updatedAt: new Date()
      });

      toast.success('Annuncio aggiornato con successo!');
      onSuccess();
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Errore durante l\'aggiornamento dell\'annuncio';
      setError(message);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!listing) return;

    setLoading(true);
    try {
      setError(null);
      await deleteListing(listing.id);
      toast.success('Annuncio eliminato con successo');
      onSuccess();
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Errore durante l\'eliminazione dell\'annuncio';
      setError(message);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 flex justify-between items-center z-10">
          <div>
            <h2 className="text-xl font-semibold text-gray-900">
              Modifica Annuncio {listing && generateNumericId(listing.id)}
            </h2>
            {listing && (
              <p className="text-sm text-gray-500 mt-1">
                Pubblicato il {listing.createdAt.toLocaleDateString()}
              </p>
            )}
          </div>
          <button 
            onClick={onClose} 
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-6 w-6 text-gray-400" />
          </button>
        </div>

        <div className="p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}

          {listing && (
            <div className="space-y-6">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Tipologia Alloggio
                  <span className="ml-1 inline-block" title="Non è possibile modificare la tipologia dopo la creazione">
                    <Info className="h-4 w-4 text-gray-400" />
                  </span>
                </label>
                <input 
                  type="text"
                  readOnly
                  value={getCategoryLabel(listing.category)}
                  className="mt-1 block w-full px-3 py-2 text-base border border-gray-300 rounded-md bg-gray-50 text-gray-500 cursor-not-allowed"
                />
                <p className="mt-1 text-sm text-gray-500">
                  La tipologia non può essere modificata dopo la creazione dell'annuncio
                </p>
              </div>

              <ListingForm
                initialData={listing}
                onSubmit={handleSubmit}
                onCancel={onClose}
                loading={loading}
              />

              {/* Delete Section */}
              <div className="mt-8 pt-8 border-t border-gray-200">
                <div className="bg-red-50 rounded-lg p-4">
                  <h3 className="text-lg font-medium text-red-800 mb-2">
                    Zona Pericolosa
                  </h3>
                  <p className="text-sm text-red-600 mb-4">
                    L'eliminazione dell'annuncio è un'azione irreversibile. Tutti i dati associati verranno rimossi permanentemente.
                  </p>
                  {showDeleteConfirm ? (
                    <div className="space-y-4">
                      <p className="text-sm text-red-600 font-medium">
                        Sei sicuro di voler eliminare questo annuncio?
                      </p>
                      <div className="flex gap-4">
                        <button
                          onClick={handleDelete}
                          disabled={loading}
                          className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50"
                        >
                          {loading ? 'Eliminazione...' : 'Conferma Eliminazione'}
                        </button>
                        <button
                          onClick={() => setShowDeleteConfirm(false)}
                          className="px-4 py-2 bg-white text-red-600 border border-red-200 rounded-md hover:bg-red-50"
                        >
                          Annulla
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => setShowDeleteConfirm(true)}
                      className="px-4 py-2 bg-white text-red-600 border border-red-200 rounded-md hover:bg-red-50"
                    >
                      Elimina Annuncio
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// Funzione helper per ottenere l'etichetta della categoria
function getCategoryLabel(category: string): string {
  const categories = {
    single_room: 'Camera Singola',
    double_room: 'Camera Doppia',
    studio: 'Monolocale',
    apartment: 'Appartamento'
  };
  return categories[category as keyof typeof categories] || category;
}